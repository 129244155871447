var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _c("modal-heading", [
        _vm._v(_vm._s(_vm.trans.get("generic.modals.follow_up_to_all_title"))),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", [
        _vm._v(_vm._s(_vm.trans.get("generic.modals.follow_up_to_all_text"))),
      ]),
      _vm._v(" "),
      _c("base-textarea", {
        attrs: {
          error: _vm.errorMessageText,
          label: this.trans.get("generic.your_message"),
          placeholder: this.trans.get("generic.your_message"),
          rows: 5,
          autofocus: "",
          outlined: "",
          "icon-top": "",
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.messageText,
          callback: function ($$v) {
            _vm.messageText = $$v
          },
          expression: "messageText",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-5" },
        [
          _c(
            "base-button",
            {
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.sendFollowUpToAll },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get(
                      "generic.modals.follow_up_to_all_button_text",
                      { nb_clients: this.nbClients }
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }