var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { logo: "global/logo_prime.svg" } },
    [
      _c("base-title", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get(
                "chat.modals.start_free_prime_chat_live_activation_confirm_title"
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex py-4" }, [
        _c(
          "div",
          { staticClass: "flex-none" },
          [
            _c("font-awesome-icon", {
              staticClass: "text-star text-4xl mr-4",
              attrs: { icon: ["far", "messages"] },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c("base-title", { attrs: { level: 3 } }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.trans.get("chat.modals.prime_advantage_free_chat")
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("base-paragraph", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.modals.prime_advantage_free_chat_description"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          attrs: { color: "prime", loading: this.loading, block: "" },
          on: { click: _vm.startChat },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.trans.get("chat.modals.start_chat_live_button_label")
              ) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }