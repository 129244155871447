var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "game-wheel" }, [
    _c(
      "div",
      { staticClass: "game-wheel-content" },
      [
        _c("transition", { attrs: { css: false } }, [
          _c("img", {
            ref: "wheel",
            staticClass: "game-wheel-image",
            style: _vm.wheelStyle,
            attrs: { src: _vm.wheelSrc },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "game-wheel-buttons" },
          [
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              !_vm.played
                ? _c("img", {
                    key: "buttonPlay",
                    staticClass: "cursor-pointer select-none",
                    attrs: { src: _vm.buttonPlaySrc },
                    on: { click: _vm.play },
                  })
                : _c("img", {
                    key: "buttonPlayed",
                    attrs: { src: _vm.buttonPlayedSrc },
                  }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          _vm.showPrize
            ? _c("img", {
                key: "prize",
                staticClass: "game-wheel-prize",
                style: _vm.gameWheelPrizeStyle,
                attrs: { src: _vm.prizeSrc },
                on: { click: _vm.prizeCallback },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.hasBackgrounds
          ? _c(
              "div",
              {
                staticClass: "game-wheel-background",
                staticStyle: { "z-index": "-1" },
              },
              [_vm._t("backgrounds")],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }