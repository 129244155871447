<template>
    <div>
        <modal-heading :level=2>{{ title }} :</modal-heading>
        <base-textarea v-model="value"
                       :rows="rows"
                       :placeholder="placeholder"
                       :disabled="status === 'loading'"
                       :readonly="readonly"
                       outlined
        />

        <div v-if="showStatus" class="mt-1 text-sm text-right">
            <div v-if="status === 'loading'">
                <font-awesome-icon :icon="['fas', 'spinner']" spin/>
                {{ trans.get('generic.loading') }}
            </div>
            <div v-else-if="status === 'saving'">
                <font-awesome-icon :icon="['fas', 'spinner']" spin/>
                {{ trans.get('generic.saving_changes') }}
            </div>
            <div v-else-if="status === 'saved'" class="text-success">
                <font-awesome-icon :icon="['far', 'check']"></font-awesome-icon>
                {{ trans.get('generic.changes_saved') }}
            </div>
            <div v-else>&nbsp;</div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "../modals/elements/ModalHeading";
import BaseTextarea from "../elements/BaseTextarea";
import {ziggy_route} from "../ZiggyMixin";

export default {
    name: 'MasterUserNoteEditor',
    components: {BaseTextarea, BaseButton, ModalHeading},
    props: {
        userId: {
            type: Number | String,
        },
        noteType: {
            type: String,
        },
        rows: {
            type: Number,
            default: 8,
        },
        showStatus: Boolean,
        title: String,
        placeholder: String,
        readonly: Boolean,
    },
    methods: {
        load() {
            if (this.axiosCancelSource !== null) {
                this.axiosCancelSource.cancel('Cancel load.');
            }

            this.status = 'loading';
            this.value = '';
            this.axiosCancelSource = axios.CancelToken.source();

            axios.get(ziggy_route('master.user-notes.get', {
                userId: this.userId,
                type: this.noteType,
            }), {
                cancelToken: this.axiosCancelSource.token,
            }).then(response => {
                this.value = response.data.data.note;
                this.status = '';
            }).catch(error => {
            });
        },
        save() {
            if (this.status === 'loading') {
                return;
            }

            this.status = 'saving';

            axios.post(ziggy_route('master.user-notes.save'), {
                userId: this.userId,
                type: this.noteType,
                value: this.value,
            }).then(response => {
                this.status = 'saved';
            }).catch(error => {
            });
        },
    },
    mounted() {
        this.$watch('userId', () => this.load());
        this.$watch('status', (value) => this.$emit('status', value));
        this.load();
    },
    data() {
        return {
            value: '',
            status: '',
            axiosCancelSource: null,
        }
    }
}
</script>
