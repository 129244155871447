<template>
    <div class="flex text-dark space-x-1 items-start">
        <slot v-if="hasIcon" name="icon"/>

        <div>
            <div v-if="hasTitle">
                <div class="float-left pr-1">
                    <strong>
                        <slot name="title"/>
                    </strong>
                </div>
                <div v-if="hasDescription" class="float-left">
                    <slot name="description"/>
                </div>
            </div>
            <div v-else-if="hasDescription">
                <slot name="description"/>
            </div>
            <div v-else class="float-left">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseListItem",
    props: {
        title: String,
    },
    computed: {
        hasIcon() {
            return !!this.$slots.icon;
        },
        hasTitle() {
            return !!this.$slots.title;
        },
        hasDescription() {
            return !!this.$slots.description;
        },
    },
}
</script>
