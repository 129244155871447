var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conversation-list-to-display-header" }, [
    _c(
      "div",
      { staticClass: "conversation-list-header-to-be-processed-choice" },
      [
        _c(
          "base-button",
          {
            staticClass: "base-tab all-conversation",
            attrs: { color: _vm.allConversationTabState, small: "", pill: "" },
            on: { click: _vm.displayAllConversationList },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.trans.get("chat.header.list_to_display.all_conversations")
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "base-tab to-be-processed",
            attrs: { color: _vm.toBeProcessedTabState, small: "", pill: "" },
            on: { click: _vm.clickToBeProcessedConversationList },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.trans.get("chat.header.list_to_display.to_be_processed", {
                    to_be_processed_sum:
                      _vm.getToBeProcessedConversations.length,
                  })
                ) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    this.getConversationsListToDiplay === "to-be-processed"
      ? _c("div", { staticClass: "conversation-list-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.trans.get("chat.header.list_to_display.follow_up_title")
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }