<template>
    <development>
        <div>
            <development-heading>Alert</development-heading>
            <base-alert type="success">I'm an Alert Usage Example</base-alert>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>type</strong>: String - <em>'info', 'success', 'warning', 'danger'</em></li>
                <li><strong>color</strong>: String - <em>'info', 'success', 'warning', 'danger'</em></li>
                <li><strong>block</strong>: Boolean</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>text</strong>: Boolean</li>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>loading</strong>: Boolean</li>
                <li><strong>dismissible</strong>: Boolean</li>
            </ul>

            <development-heading>Type</development-heading>
            <development-paragraph><strong>type</strong> : info / success / warning / danger</development-paragraph>
            <div class="space-y-2">
                <base-alert type="info">I'm an Alert Usage Example</base-alert>
                <base-alert type="success">I'm an Alert Usage Example</base-alert>
                <base-alert type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert type="danger">I'm an Alert Usage Example</base-alert>
                <base-alert type="pack-1">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Colors</development-heading>
            <development-paragraph><strong>color</strong> : info / success / warning / danger</development-paragraph>
            <div class="space-y-2">
                <base-alert color="info">I'm an Alert Usage Example</base-alert>
                <base-alert color="success">I'm an Alert Usage Example</base-alert>
                <base-alert color="warning">I'm an Alert Usage Example</base-alert>
                <base-alert color="danger">I'm an Alert Usage Example</base-alert>
                <base-alert color="pack-1">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Icons</development-heading>
            <div class="space-y-2">
                <base-alert color="info">
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'message-lines']"/>
                    </template>
                    I'm an Alert Usage Example
                </base-alert>

                <base-alert color="success">
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'envelope']"/>
                    </template>
                    I'm an Alert Usage Example
                </base-alert>

                <base-alert color="warning">
                    <template #icon>
                        <font-awesome-icon :icon="['far', 'cake-candles']"/>
                    </template>
                    I'm an Alert Usage Example
                </base-alert>
                <base-alert color="danger">
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'paper-plane']"/>
                    </template>
                    I'm an Alert Usage Example
                </base-alert>
            </div>

            <development-heading>Elevated</development-heading>
            <development-paragraph><strong>type</strong> : info / success / warning / danger</development-paragraph>
            <div class="space-y-2">
                <base-alert elevated>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'envelope']" class="text-primary"/>
                    </template>
                    I'm an Alert Usage Example
                </base-alert>
                <base-alert elevated type="info">I'm an Alert Usage Example</base-alert>
                <base-alert elevated type="success">I'm an Alert Usage Example</base-alert>
                <base-alert elevated type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert elevated type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Action</development-heading>
            <div class="space-y-2">
                <base-alert type="info" ref="alertInfoAction">
                    Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris
                    blandit aliquet elit, eget tincidunt nibh pulvinar.
                    <template #action>
                        <base-button text @click="$refs.alertInfoAction.close()">J'ai compris !</base-button>
                    </template>
                </base-alert>
                <base-alert type="warning" ref="warningInfoAction">
                    Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris
                    blandit aliquet elit, eget tincidunt nibh pulvinar.
                    <template #action>
                        <base-button text @click="$refs.warningInfoAction.close()">Recharger mon crédit</base-button>
                    </template>
                </base-alert>
                <base-alert type="success" ref="successInfoAction">
                    I'm an Alert Usage Example
                    <template #action>
                        <base-button text @click="$refs.successInfoAction.close()">Fermer</base-button>
                    </template>
                </base-alert>
                <base-alert text-center elevated>
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin/>
                    </template>
                    Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                    neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.
                    Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat. Vivamus suscipit
                    tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Proin eget tortor risus. Cras
                    ultricies ligula sed magna dictum porta. Sed porttitor lectus nibh.
                    <template #action>
                        <base-button disabled>Envoyer dans
                            <base-timer v-slot="{seconds}" countdown :time="60000">{{ seconds }}</base-timer>
                        </base-button>
                    </template>
                </base-alert>
            </div>

            <development-heading>Block</development-heading>
            <development-paragraph><strong>block</strong> alerts extend the full available width.
            </development-paragraph>
            <div class="space-y-2">
                <base-alert block type="info">I'm an Alert Usage Example</base-alert>
                <base-alert block type="success">I'm an Alert Usage Example</base-alert>
                <base-alert block type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert block type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Dismissible</development-heading>
            <development-paragraph>An alert <strong>dismissible</strong> can be close.</development-paragraph>
            <div class="space-y-2">
                <base-alert dismissible type="success">I'm an Alert Usage Example</base-alert>
                <base-alert dismissible type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert dismissible type="danger">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                    neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.
                    Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat. Vivamus suscipit
                    tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Proin eget tortor risus. Cras
                    ultricies ligula sed magna dictum porta. Sed porttitor lectus nibh.
                </base-alert>
                <base-alert dismissible type="success" block>I'm a Block Alert Usage Example</base-alert>
            </div>


            <development-heading>Text center</development-heading>
            <div class="space-y-2">
                <base-alert text-center type="success">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                    posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                    Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit
                    amet ligula. Nulla porttitor accumsan tincidunt.
                </base-alert>
                <base-alert dismissible elevated text-center>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'envelope']" class="text-primary"/>
                    </template>
                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                    posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                    Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit
                    amet ligula. Nulla porttitor accumsan tincidunt.
                </base-alert>
                <base-alert type="info" elevated text-center large ref="centerTextInfoInfoAction">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin/>
                    </template>
                    Info elevated text-center large dismissible with icon and action. Vestibulum ante ipsum primis in
                    faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam
                    vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit
                    amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.
                    <template #action>
                        <base-button text @click="$refs.centerTextInfoInfoAction.close()">Fermer</base-button>
                    </template>
                </base-alert>
            </div>

            <development-heading>XSmall</development-heading>
            <div class="space-y-2">
                <base-alert x-small type="success">I'm an Alert Usage Example</base-alert>
                <base-alert x-small type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert x-small type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Small</development-heading>
            <div class="space-y-2">
                <base-alert small type="success">I'm an Alert Usage Example</base-alert>
                <base-alert small type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert small type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Large</development-heading>
            <div class="space-y-2">
                <base-alert large type="success">I'm an Alert Usage Example</base-alert>
                <base-alert large type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert large type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Pill</development-heading>
            <div class="space-y-2">
                <base-alert pill type="success">I'm an Alert Usage Example</base-alert>
                <base-alert pill type="warning">I'm an Alert Usage Example</base-alert>
                <base-alert pill type="danger">I'm an Alert Usage Example</base-alert>
            </div>

            <development-heading>Delay</development-heading>
            <development-paragraph><strong>delay</strong> before remove this alert</development-paragraph>
            <div class="space-y-2">
                <base-alert large type="info" :delay="5000">Remove after 5 sec</base-alert>
                <base-alert large type="success" :delay="10000">Remove after 10 sec</base-alert>
                <base-alert large type="warning" :delay="15000">Remove after 15 sec</base-alert>
                <base-alert large type="danger" :delay="20000">Remove after 20 sec</base-alert>
            </div>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import BaseAlert from "../elements/BaseAlert";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseButton from "../elements/BaseButton";
import BaseTimer from "../elements/BaseTimer";

export default {
    components: {BaseTimer, BaseButton, DevelopmentParagraph, DevelopmentHeading, BaseAlert, Development}
}
</script>
