<template>
    <development>
        <div>
            <development-heading>Bar</development-heading>

            <base-bar>Bar</base-bar>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>color</strong>: String - <em>'info', 'success', 'warning', 'danger', 'neutral'</em></li>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>elevated</strong>: Boolean</li>
            </ul>

            <development-heading>Elevated</development-heading>
            <base-bar elevated><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar elevated</base-bar>

            <development-heading>Type</development-heading>
            <development-paragraph><strong>type</strong> : info / success / warning / danger / neutral
            </development-paragraph>
            <div class="space-y-2">
                <base-bar elevated><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar default</base-bar>
                <base-bar color="info"><font-awesome-icon :icon="['fal', 'message-lines']"/> Info</base-bar>
                <base-bar color="success"><font-awesome-icon :icon="['fal', 'message-lines']"/> Success</base-bar>
                <base-bar color="warning"><font-awesome-icon :icon="['fal', 'message-lines']"/> Warning</base-bar>
                <base-bar color="danger"><font-awesome-icon :icon="['fal', 'message-lines']"/> Danger</base-bar>
                <base-bar color="neutral"><font-awesome-icon :icon="['fal', 'message-lines']"/> Neutral</base-bar>
                <base-bar color="pack-1"><font-awesome-icon :icon="['fal', 'message-lines']"/> Pack 1</base-bar>
            </div>


            <development-heading>Small</development-heading>
            <div class="space-y-2">
                <base-bar elevated small><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar default small</base-bar>
                <base-bar small color="info"><font-awesome-icon :icon="['fal', 'message-lines']"/> Info small</base-bar>
                <base-bar small color="success"><font-awesome-icon :icon="['fal', 'message-lines']"/> Success small</base-bar>
                <base-bar small color="warning"><font-awesome-icon :icon="['fal', 'message-lines']"/> Warning small</base-bar>
                <base-bar small color="danger"><font-awesome-icon :icon="['fal', 'message-lines']"/> Danger small</base-bar>
                <base-bar small color="neutral"><font-awesome-icon :icon="['fal', 'message-lines']"/> Neutral small</base-bar>
                <base-bar small color="pack-1"><font-awesome-icon :icon="['fal', 'message-lines']"/> Pack 1 small</base-bar>
            </div>

            <development-heading>Large</development-heading>
            <div class="space-y-2">
                <base-bar elevated large><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar default Large</base-bar>
                <base-bar large color="info"><font-awesome-icon :icon="['fal', 'message-lines']"/> Info Large</base-bar>
                <base-bar large color="success"><font-awesome-icon :icon="['fal', 'message-lines']"/> Success Large</base-bar>
                <base-bar large color="warning"><font-awesome-icon :icon="['fal', 'message-lines']"/> Warning Large</base-bar>
                <base-bar large color="danger"><font-awesome-icon :icon="['fal', 'message-lines']"/> Danger Large</base-bar>
                <base-bar large color="neutral"><font-awesome-icon :icon="['fal', 'message-lines']"/> Neutral Large</base-bar>
                <base-bar large color="pack-1"><font-awesome-icon :icon="['fal', 'message-lines']"/> Pack 1 Large</base-bar>
            </div>

            <development-heading>Pill</development-heading>
            <div class="space-y-2">
                <base-bar elevated pill><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar default Pill</base-bar>
                <base-bar pill color="info"><font-awesome-icon :icon="['fal', 'message-lines']"/> Info Pill</base-bar>
                <base-bar pill color="success"><font-awesome-icon :icon="['fal', 'message-lines']"/> Success Pill</base-bar>
                <base-bar pill color="warning"><font-awesome-icon :icon="['fal', 'message-lines']"/> Warning Pill</base-bar>
                <base-bar pill color="danger"><font-awesome-icon :icon="['fal', 'message-lines']"/> Danger Pill</base-bar>
                <base-bar pill color="neutral"><font-awesome-icon :icon="['fal', 'message-lines']"/> Neutral Pill</base-bar>
                <base-bar pill color="pack-1"><font-awesome-icon :icon="['fal', 'message-lines']"/> Pack 1 Pill</base-bar>
            </div>

            <development-heading>Delay</development-heading>
            <development-paragraph><strong>delay</strong> before remove this bar</development-paragraph>
            <div class="space-y-2">
                <base-bar :delay="5000" elevated><font-awesome-icon :icon="['fal', 'message-lines']"/> Bar (5 sec)</base-bar>
                <base-bar :delay="10000" color="info"><font-awesome-icon :icon="['fal', 'message-lines']"/> Info (10 sec)</base-bar>
                <base-bar :delay="15000" color="success"><font-awesome-icon :icon="['fal', 'message-lines']"/> Success (15 sec)</base-bar>
                <base-bar :delay="20000" color="warning"><font-awesome-icon :icon="['fal', 'message-lines']"/> Warning (20 sec)</base-bar>
                <base-bar :delay="25000" color="danger"><font-awesome-icon :icon="['fal', 'message-lines']"/> Danger (25 sec)</base-bar>
                <base-bar :delay="30000" color="neutral"><font-awesome-icon :icon="['fal', 'message-lines']"/> Neutral (30 sec)</base-bar>
            </div>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseBar from "../elements/BaseBar";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";

export default {
    components: {DevelopmentParagraph, BaseBar, DevelopmentHeading, Development}
}
</script>
