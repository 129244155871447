<template>
    <div :class="listClass">
        <base-alert class="conversation-list-alert" text-center elevated
                    v-if="false && (this.isLiveConversationInProgress || this.isLiveConversationWaiting)">
            <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-primary"/>
            {{ trans.get('chat.chat_live_in_progress_hide_list') }}
        </base-alert>

        <div class="conversation-list-header-container">
            <conversation-list-header/>
            <conversation-list-header-list-to-display-choice v-if="canBeDisplayingToBeProcessedChoice"/>
        </div>

        <div v-if="getConversationsListToDiplay =='all'" id="conversation-list-items" class="conversation-list-items">
            <template v-for="conversation in getConversations">
                <conversation-list-item :participants="conversation.participants"
                                        :lastMessage="conversation.messages[conversation.messages.length - 1]"
                                        :read="conversation.read"
                />
            </template>

            <div v-if="canLoadNextConversations" id="conversation-list-items-loader" class="text-center p-4">
                <font-awesome-icon :icon="['fas', 'spinner']" class="text-primary" spin size="3x"/>
            </div>
        </div>

        <div v-if="getConversationsListToDiplay =='to-be-processed'" id="to-be-processed-conversation-list-items" class="conversation-list-items">
            <template v-for="conversation in getToBeProcessedConversations">
                <conversation-list-item :participants="conversation.participants"
                                        :lastMessage="conversation.messages[conversation.messages.length - 1]"
                                        :read="conversation.read"
                />
            </template>

            <div v-if="canLoadNextToBeProcessedConversations" id="to-be-processed-conversation-list-items-loader" class="text-center p-4">
                <font-awesome-icon :icon="['fas', 'spinner']" class="text-primary" spin size="3x"/>
            </div>
        </div>

        <a :href="config.get('chat.conversation_list_redirect_button_url')">
            <base-button class="conversation-list-fixed-button" small>
                <font-awesome-icon :icon="['fas', 'users']"/> {{ trans.get('chat.conversation_list_redirect_button') }}
            </base-button>
        </a>
    </div>
</template>

<style lang="scss">
.conversation-list {
    @apply relative;

    &--disabled {
        & div.conversation-list-header-container,
        & div.conversation-list-items {
            @apply pointer-events-none select-none overflow-hidden;
            filter: blur(5px);
        }
    }
}

.conversation-list-items {
    @apply flex-1 overflow-y-scroll divide-y divide-neutral-300;
}

.conversation-list-alert {
    @apply absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-10/12 z-1;
}

.conversation-list-fixed-button {
    @apply fixed mb-4 shadow-large bottom-0 left-2/4 transform -translate-x-1/2 md:hidden;
}
</style>

<script>
import ConversationListItem from './ConversationListItem';
import ConversationListSearchbar from './ConversationListSearchbar';
import {mapActions, mapGetters} from 'vuex';
import ChatMixin from './ChatMixin';
import BaseAlert from '../elements/BaseAlert';
import BaseButton from "../elements/BaseButton";
import ConversationListHeader from "./ConversationListHeader";
import ConversationListHeaderListToDisplayChoice from "./ConversationListHeaderListToDisplayChoice";

export default {
    name: 'ConversationList',
    components: {
        ConversationListHeaderListToDisplayChoice,
        ConversationListHeader, BaseButton, BaseAlert, ConversationListSearchbar, ConversationListItem},
    mixins: [ChatMixin],
    methods: {
        ...mapActions('chat', ['loadConversations', 'loadToBeProcessedConversations',  'loadNextConversations', 'loadLiveSession', 'loadNextToBeProcessedConversations']),
    },
    computed: {
        ...mapGetters('chat', ['isConversationsLoading', 'getConversationsListToDiplay', 'canLoadNextConversations', 'canLoadNextToBeProcessedConversations', 'getConversations', 'getToBeProcessedConversations', 'isLiveConversationInProgress', 'isLiveConversationWaiting']),
        listClass() {
            return {
                'conversation-list': true,
                'conversation-list--disabled': this.isLiveConversationWaiting || this.isLiveConversationInProgress,
            };
        },
        canBeDisplayingToBeProcessedChoice() {
            return this.meFromUserStore().is_master;
        },
    },
    mounted() {
        const conversationListItems = document.querySelector('.conversation-list-items');

        conversationListItems.addEventListener('scroll', e => {
            if (this.getConversationsListToDiplay == 'all' && this.canLoadNextConversations && conversationListItems.scrollTop + conversationListItems.clientHeight >= conversationListItems.scrollHeight - document.querySelector('#conversation-list-items-loader').clientHeight) {
                this.loadNextConversations();
            }

            if (this.getConversationsListToDiplay == 'to-be-processed' && this.canLoadNextToBeProcessedConversations && conversationListItems.scrollTop + conversationListItems.clientHeight >= conversationListItems.scrollHeight - document.querySelector('#to-be-processed-conversation-list-items-loader').clientHeight) {
                this.loadNextToBeProcessedConversations();
            }

        });
        this.loadConversations();
        if(this.canBeDisplayingToBeProcessedChoice) {
            this.loadToBeProcessedConversations();
        }
    },
    data() {
        return {
            'displaySearchBar': false
        }
    },
};
</script>
