<template>
    <base-modal>
        <form @submit="postForm" v-if="!postFormSucceed">
            <modal-heading class="text-center">
                {{ trans.get('email_catcher.wait') }}
            </modal-heading>

            <modal-paragraph class="text-center">
                <strong>
                    {{ trans.get('email_catcher.a_consultation_offered_for_several_minutes') }}
                </strong>
            </modal-paragraph>

            <div class="w-full text-center">
                <img class="inline-block max-h-48"
                     :src="assetManager.getImageAsset('email-catcher/email-catcher.gif')" />
            </div>

            <div class="my-4">
                <base-text-input type="email"
                                 outlined
                                 v-model="email_value"
                                 :label="trans.get('generic.email')"
                                 :placeholder="trans.get('generic.email')"
                                 :error="email_error"
                                 @keydown="email_error = ''">
                    <template #icon><font-awesome-icon :icon="['fal', 'envelope']"/></template>
                </base-text-input>
            </div>

            <base-checkbox v-if="!config.get('app.email_catcher_optional_newsletter')"
                           ref="checkbox_right_of_withdrawal"
                           block
                           v-model="newsletter_value"
                           :error="newsletter_error"
                           @change="newsletter_error = ''">
                <span v-html="this.trans.get('email_catcher.i_agree_to_receive_emails_from_the_site')"></span>
            </base-checkbox>

            <base-button type="submit" :loading="formLoading" block>
                {{ trans.get('email_catcher.lets_go') }}
            </base-button>
        </form>

        <form v-else action="ajax/go-to-second-register-step.js" method="POST" target="ajax_upload">
            <input type="hidden" name="newsletter_already_accepted" value="1"/>
            <input type="hidden" name="email" v-model="email_value"/>

            <modal-heading>
                {{ trans.get('email_catcher.well_done') }}
            </modal-heading>

            <modal-paragraph v-html="this.trans.get('email_catcher.your_free_consultation_awaits_you')"/>

            <modal-paragraph
                v-html="this.trans.get('email_catcher.find_the_answers_to_your_questions_and_consult_the_best_psychics')"/>

            <base-button type="submit" block @click="$emit('cancel')">
                {{ trans.get('email_catcher.i_continue_my_registration') }}
            </base-button>

            <div class="text-main mt-4 text-xs">
                {{ trans.get('email_catcher.check_your_spam_emails') }}
            </div>

        </form>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BaseTextInput from '../elements/BaseTextInput';
import BaseButton from '../elements/BaseButton';
import BaseCheckbox from '../elements/BaseCheckbox';
import ValidationLang from '../ValidationLang.js';
import BaseLink from '../elements/BaseLink';
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: 'ModalEmailCatcher',
    components: {BaseLink, BaseCheckbox, BaseButton, BaseTextInput, ModalParagraph, ModalHeading},
    methods: {
        postForm(e)
        {
            e.preventDefault();

            this.formLoading = true;

            axios.post(ziggy_route('email_catcher.store'), {
                email: this.email_value,
                newsletter: this.newsletter_value,
            }).then((res) => {
                this.postFormSucceed = true;
            }).catch((errorResponse) => {
                let errorsInResponse = errorResponse.response.data.errors;
                let validationLang = new ValidationLang();
                let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);

                if (validationErrorsMap.get('email') !== undefined) {
                    this.email_error = validationErrorsMap.get('email').join('<br/>');
                } else {
                    this.email_error = '';
                }

                if (validationErrorsMap.get('newsletter') !== undefined) {
                    this.newsletter_error = validationErrorsMap.get('newsletter').join('<br/>');
                } else {
                    this.newsletter_error = '';
                }
            }).finally(() => {
                this.formLoading = false;
            });
        },
    },
    data() {
        return {
            postFormSucceed: false,
            formLoading: false,
            email_value: '',
            email_error: '',
            newsletter_value: !!this.config.get('app.email_catcher_optional_newsletter'),
            newsletter_error: '',
        }
    },
}
</script>
