<template>
    <div class="offer-list-container">
        <div v-if="hasHeader" class="sm:mb-1 mb-2 px-4 md:px-2">
            <a v-if="hasBackLink" :href="backLinkUrl"
               class="lg:px-0 hover:text-primary-hover text-primary lg:text-base text-sm">
                <h2 class="inline font-medium mr-2">{{ backLinkTitle }}</h2>
                <font-awesome-icon :icon="['fal', 'chevron-right']"/>
            </a>

            <div class="flex items-center justify-between">
                <h1 v-if="hasTitle" class="text-primary lg:text-2xl text-xl font-bold">{{ categoryTitle }}</h1>
                <base-select-conversation-type v-if="!disableFilters" v-model="conversationType" @change="initList"/>
            </div>
        </div>

        <div class="offer-list" ref="offer-list">
            <div v-if="!disableComments" class="comments">
                <comment-list :category-id="categoryId"/>
            </div>

            <div v-if="!disableCustomerCareCard" class="offer-list-card offer-list-customer-care-card">
                <customer-care-card/>
            </div>

            <div v-if="!disableCustomerAdCard" class="offer-list-card offer-list-customer-ad-card">
                <customer-ad-card/>
            </div>

            <div v-for="(offer, index) in offers" class="offer-list-card">
                <offer-card ref="offer-card"
                            :key="offer.id"
                            :number="index+1"
                            :offer="offer"
                            :user="getLoggedUserInfos"
                            :offer_list_type="offerListType"
                            :is_prime_enabled="config.get('prime.enabled')"
                            :is_free_chat_enabled_for_prospect="config.get('chat.free_chat_enabled_for_prospect')"
                            :user_credits="getCredits"
                />
            </div>

            <div v-if="canLoadNextPage" v-for="n in cardsPerPage" class="offer-list-card">
                <offer-card-loader ref="offer-card-loader" :key="'offer-card-loader-' + n"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.offer-list-container {
    .offer-list {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3;

        .offer-list-card {
            @apply px-4 md:px-2 py-2;
        }

        &-customer-care-card {
            @apply row-start-7 md:row-start-3 md:col-start-2 lg:row-start-2 lg:col-start-3;
        }

        &-customer-ad-card {
            @apply row-start-14 md:row-start-8 md:col-start-1 lg:row-start-6 lg:col-start-1;
        }

        .comments {
            @apply row-start-4 col-start-1 col-span-1 md:row-start-5 md:col-start-1 md:col-span-2 lg:row-start-4 lg:col-start-1 lg:col-span-3 py-2;
        }
    }
}
</style>

<script>
import BaseSelectConversationType from "../elements/custom/BaseSelectConversationType";
import CustomerCareCard from "./CustomerCareCard.vue";
import CustomerAdCard from "./CustomerAdCard.vue";
import OfferCard from "./OfferCard.vue";
import OfferCardLoader from "./OfferCardLoader.vue";
import OfferListMixin from "./OfferListMixin";

import {mapGetters} from "vuex";
import {ziggy_route} from "../ZiggyMixin";
import CommentCard from "../comments/CommentCard.vue";
import CommentList from "../comments/CommentList.vue";

export default {
    name: "OfferList",
    mixins: [OfferListMixin],
    components: {CommentList, CommentCard, CustomerCareCard, OfferCardLoader, OfferCard, BaseSelectConversationType, CustomerAdCard},
    props: {
        backLinkTitle: String,
        backLinkUrl: String,
        categoryId: Number,
        categoryTitle: String,
        disableComments: Boolean,
        disableCustomerCareCard: Boolean,
        disableCustomerAdCard: Boolean,
        disableFilters: Boolean,
        offerListType: String,
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
        ...mapGetters('payment', ['getCredits']),
        hasHeader() {
            return this.hasBackLink || this.hasTitle || !this.disableFilters;
        },
        hasBackLinkUrl() {
            return this.backLinkUrl !== undefined && this.backLinkUrl !== null && this.backLinkUrl.length > 0;
        },
        hasBackLinkTitle() {
            return this.backLinkTitle !== undefined && this.backLinkTitle !== null && this.backLinkTitle.length > 0;
        },
        hasBackLink() {
            return this.hasBackLinkUrl && this.hasBackLinkTitle;
        },
        hasTitle() {
            return this.categoryTitle !== undefined && this.categoryTitle !== null && this.categoryTitle.length > 0;
        },
        canLoadNextPage() {
            return (this.currentPage === 0 || this.currentPage < this.lastPage);
        },
        cardsPerPage() {
            return 24;
        }
    },
    mounted() {
        this.initList();
        this.initStatusChangedListener();

        window.addEventListener('scroll', e => {
            this.scrollEventListener();
        });
    },
    methods: {
        initList() {
            this.offers = [];
            this.currentPage = 0;
            this.lastPage = 0;
            this.loading = false;
            this.scrollBefore = 0;

            this.loadNextPage();
        },
        scrollEventListener() {
            if (!this.canLoadNextPage) {
                return false;
            }

            if (this.$refs['offer-card-loader'] === undefined || this.$refs['offer-card-loader'][0] === undefined || this.$refs['offer-card-loader'][0].$el === undefined) {
                return false;
            }

            let triggerPosition = window.innerHeight + window.innerHeight + window.pageYOffset;
            let loaderPosition = window.scrollY + this.$refs['offer-card-loader'][0].$el.getBoundingClientRect().top;

            if (triggerPosition >= loaderPosition) {
                this.loadNextPage();
            }
        },
        loadNextPage() {
            if (this.loading || !this.canLoadNextPage) {
                return false;
            }

            this.loading = true;

            let params = {
                category_id: this.categoryId,
                offer_list_type: this.offerListType,
                page: this.currentPage + 1,
                filters: [],
            };

            if (this.conversationType !== null) {
                params.filters.push(this.conversationType);
            }

            if (this.axiosCancelSource !== null) {
                this.axiosCancelSource.cancel('Cancel load.');
            }

            this.axiosCancelSource = axios.CancelToken.source();

            axios.get(ziggy_route('offer-list.get', params), {
                cancelToken: this.axiosCancelSource.token,
            }).then(response => {
                this.scrollBefore = window.scrollY;
                response.data.data.forEach((offer) => {
                    this.createOrUpdateOffer(offer);
                });

                this.loading = false;
                this.currentPage = response.data.meta.current_page;
                this.lastPage = response.data.meta.last_page;
                this.offerListReady = true;

                this.$nextTick(() => {
                    if (this.scrollBefore !== window.scrollY) {
                        window.scrollTo(0, this.scrollBefore);
                    }

                    this.scrollEventListener();
                });
            }).catch(error => {
                console.error(error);
            });
        },
    },
    data() {
        return {
            axiosCancelSource: null,
            conversationType: null,
            currentPage: 0,
            lastPage: 0,
            loading: false,
            scrollBefore: 0,
        }
    }
}
</script>
