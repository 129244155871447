var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "customer-care-card",
      style: { backgroundImage: "url(" + _vm.backgroundImage + ")" },
    },
    [
      _c("div", { staticClass: "flex flex-col h-full w-10/12" }, [
        _c("p", { staticClass: "font-bold text-xl" }, [
          _vm._v(_vm._s(_vm.trans.get("home.customer_care.title"))),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "leading-6 mb-2",
          domProps: {
            innerHTML: _vm._s(_vm.trans.get("home.customer_care.text")),
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex h-full items-center" }, [
          _c(
            "div",
            {
              staticClass:
                "rounded-md bg-white text-primary p-1.5 inline-block text-2xl font-bold mb-2",
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "tel:" + _vm.config.get("app.phone_number_customer_care"),
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "mobile-screen-button"] },
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.config.get("app.phone_number_customer_care")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              staticClass: "text-white font-bold",
              attrs: {
                href:
                  "mailto:" + _vm.config.get("mail.recipients.customer_care"),
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fal", "envelope"] } }),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.config.get("mail.recipients.customer_care")) +
                  "\n            "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }