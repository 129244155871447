var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-banner-bats" }, [
    _c("div", { staticClass: "home-banner-bats-bat-overlay" }, [
      _c(
        "svg",
        {
          staticStyle: { "enable-background": "new 0 0 600 400" },
          attrs: {
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            x: "0px",
            y: "0px",
            viewBox: "0 0 600 400",
            "xml:space": "preserve",
          },
        },
        [
          _c("g", { staticClass: "home-banner-bats-bat-1" }, [
            _c("path", {
              attrs: {
                d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-right",
              attrs: {
                d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                    c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                    c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-left",
              attrs: {
                d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                    c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                    c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
              },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "295.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "304.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
          ]),
          _vm._v(" "),
          _c("g", { staticClass: "home-banner-bats-bat-2" }, [
            _c("path", {
              attrs: {
                d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-right",
              attrs: {
                d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                    c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                    c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-left",
              attrs: {
                d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                    c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                    c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
              },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "295.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "304.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
          ]),
          _vm._v(" "),
          _c("g", { staticClass: "home-banner-bats-bat-3" }, [
            _c("path", {
              attrs: {
                d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-right",
              attrs: {
                d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                    c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                    c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "wing-left",
              attrs: {
                d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                    c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                    c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
              },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "295.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
            _vm._v(" "),
            _c("ellipse", {
              staticClass: "home-banner-bats-bat-eye",
              attrs: { cx: "304.2", cy: "161.8", rx: "1.5", ry: "3" },
            }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "home-banner-bats-bat-overlay home-banner-bats-bat-shadow mt-12",
      },
      [
        _c(
          "svg",
          {
            staticStyle: { "enable-background": "new 0 0 600 400" },
            attrs: {
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
              x: "0px",
              y: "0px",
              viewBox: "0 0 600 400",
              "xml:space": "preserve",
            },
          },
          [
            _c("g", { staticClass: "home-banner-bats-bat-1" }, [
              _c("path", {
                attrs: {
                  d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-right",
                attrs: {
                  d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                    c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                    c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-left",
                attrs: {
                  d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                    c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                    c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "home-banner-bats-bat-2" }, [
              _c("path", {
                attrs: {
                  d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-right",
                attrs: {
                  d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-left",
                attrs: {
                  d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "home-banner-bats-bat-3" }, [
              _c("path", {
                attrs: {
                  d: "M306.9,145.4l-7.3,7.3l-7.3-7.3c-0.6-0.6-1.6-0.2-1.6,0.6v7.9h7.6h2.5h7.6V146C308.5,145.2,307.5,144.8,306.9,145.4z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-right",
                attrs: {
                  d: "M408.4,167.5c-3.3-2.7-7.6-4.4-12.2-4.4c-9.3,0-17.1,6.6-18.9,15.4c-2.8-1.6-6.1-2.5-9.6-2.5c-8.5,0-15.7,5.5-18.3,13.1\n                        c-3-6.6-9.7-11.3-17.5-11.3c-7.7,0-14.4,4.6-17.5,11.1c-6.9-4.7-11-10.7-11-17.1c0-2.4,0.6-4.7,1.6-6.9c0.2,0,0.4,0,0.7,0\n                        c24.7,0,45.4-9,51.2-21.1C383,144.1,404.5,154.3,408.4,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "wing-left",
                attrs: {
                  d: "M191.6,167.5c3.3-2.7,7.6-4.4,12.2-4.4c9.3,0,17.1,6.6,18.9,15.4c2.8-1.6,6.1-2.5,9.6-2.5c8.5,0,15.7,5.5,18.3,13.1\n                        c3-6.6,9.7-11.3,17.5-11.3c7.7,0,14.4,4.6,17.5,11.1c6.9-4.7,11-10.7,11-17.1c0-2.4-0.6-4.7-1.6-6.9c-0.2,0-0.4,0-0.7,0\n                        c-24.7,0-45.4-9-51.2-21.1C217,144.1,195.5,154.3,191.6,167.5z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M312.3,158.2c0,5.2-6,27.3-12.9,27.3c-7,0-12.3-22.1-12.3-27.3s5.7-9.5,12.6-9.5C306.6,148.8,312.3,153,312.3,158.2z",
                },
              }),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }