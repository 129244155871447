var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-card-small" }, [
    _c("div", { staticClass: "flex items-center w-full" }, [
      _c("div", { staticClass: "offer-card-small-col-left" }, [
        _c(
          "a",
          { attrs: { href: _vm.offer.url } },
          [
            _vm.hasSegment
              ? _c(
                  "base-badge",
                  {
                    attrs: {
                      content: _vm.segmentContent,
                      color: _vm.segmentColor,
                      outlined: "",
                      bottom: "",
                      center: "",
                      square: "",
                      block: "",
                    },
                  },
                  [
                    _c("base-avatar", {
                      attrs: {
                        photo: _vm.offer.photo,
                        color: "neutral-secondary",
                        "x-large": "",
                      },
                    }),
                  ],
                  1
                )
              : _c("base-avatar", {
                  attrs: {
                    photo: _vm.offer.photo,
                    color: "neutral-secondary",
                    "x-large": "",
                  },
                }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "offer-card-small-col-right" },
        [
          _c("a", { attrs: { href: _vm.offer.url } }, [
            _c("div", { staticClass: "offer-card-small-col-right-header" }, [
              _c("p", { staticClass: "offer-card-small-name" }, [
                _vm._v(_vm._s(_vm.offer.name)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "offer-card-small-price" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatMoneyPerMinute")(
                      _vm.offer.cost_by_minute / 100
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow flex min-w-0" }, [
              _c("div", { staticClass: "flex-grow min-w-0" }, [
                _c("p", { staticClass: "offer-card-small-description" }, [
                  _vm._v(_vm._s(_vm.offer.short_description)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("offer-card-actions", {
            attrs: {
              offer: _vm.offer,
              user: _vm.user,
              offer_list_type: _vm.offer_list_type,
              is_prime_enabled: _vm.is_prime_enabled,
              is_free_chat_enabled_for_prospect:
                _vm.is_free_chat_enabled_for_prospect,
              user_credits: _vm.user_credits,
              small: "",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }