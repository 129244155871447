import ChatMixin from './ChatMixin';
import requests from './requests';
import listeners from './listeners';
import {ziggy_route} from '../ZiggyMixin';
import moment from 'moment';
import Vue from "vue";
import {commit} from "lodash/seq";

export default {
    namespaced: true,

    state: {
        conversationsData: {
            conversations: [], last_page: 0, current_page: 1, total: 0, isLoading: false,
        }, toBeProcessedConversationsData: {
            conversations: [], last_page: 0, current_page: 1, total: 0, isLoading: false,
        },
        clientInformation: {
            number_consultation: 0, date_last_consultation: 0, register_date: 0, segment: null,
        },
        liveSession: {},
        stickers: [],
        stickersQueue: [],
        conversationInformationsIsOpen: false,
        conversationStickersMenuIsOpen: false,
        conversationListOpened: 'all',
        conversationSearchIsOpen: false,
        isChatSessionAlmostOver: false,
        refillStatus: null,
        extendWithCreditStatus: null,
        isFollowUpMode: false,
        chatMainComponentIsMounted: false,
        hasMasterConnectedViaChatBot: false,
        connectionState: '',
        addFundAlertDisplayed: false,
        continueWithCreditAlertDisplayed: false,
        hearts: 0,
        isConversationInputFocused:false,
    },

    mutations: {
        setConversationListOpened(state, listName) {
            state.conversationListOpened = listName;
        },
        setClientInformation(state, ClientInformationRessource) {
            state.clientInformation.date_last_consultation = ClientInformationRessource.data.data.date_last_consultation;
            state.clientInformation.number_consultation = ClientInformationRessource.data.data.number_consultation;
            state.clientInformation.register_date = ClientInformationRessource.data.data.register_date;
            state.clientInformation.segment = ClientInformationRessource.data.data.segment;
        },
        setConversationSelected: function (state, {participants, conversationListData}) {
            let conversation = conversationListData.conversations.find(function (conversation) {
                let newParticipants = participants.map(participant => ({id: participant.id, type: participant.type}));
                let newConvParticipants = conversation.participants.map(participant => ({
                    id: participant.id,
                    type: participant.type
                }));

                return JSON.stringify(newConvParticipants) === JSON.stringify(newParticipants);
            });

            Vue.set(conversation, 'selected', true);
        },
        selectParticipants(state, participants) {
            state.conversationsData.conversations.forEach(function (conversation) {
                Vue.set(conversation, 'selected', false);
            });
            state.toBeProcessedConversationsData.conversations.forEach(function (conversation) {
                Vue.set(conversation, 'selected', false);
            });

            if (participants !== null) {
                let conversation = state.conversationsData.conversations.find(function (conversation) {
                    let newParticipants = participants.map(participant => ({
                        id: participant.id,
                        type: participant.type
                    }));
                    let newConvParticipants = conversation.participants.map(participant => ({
                        id: participant.id,
                        type: participant.type
                    }));

                    return JSON.stringify(newConvParticipants) === JSON.stringify(newParticipants);
                });
                if (conversation) {
                    Vue.set(conversation, 'selected', true);
                }
                let toBeProcessedConversation = state.toBeProcessedConversationsData.conversations.find(function (toBeProcessedConversation) {
                    let newParticipants = participants.map(participant => ({
                        id: participant.id,
                        type: participant.type
                    }));
                    let newConvParticipants = toBeProcessedConversation.participants.map(participant => ({
                        id: participant.id,
                        type: participant.type
                    }));

                    return JSON.stringify(newConvParticipants) === JSON.stringify(newParticipants);
                });
                if (toBeProcessedConversation) {
                    Vue.set(toBeProcessedConversation, 'selected', true);
                }
            }
        },
        setIsTyping(state, {conversation, isTyping, type}) {
            Vue.set(conversation, 'isTyping', isTyping);
            Vue.set(conversation, 'isTypingType', type);

            if (conversation.hasOwnProperty('isTypingWorker')) {
                clearInterval(conversation.isTypingWorker);
            }

            if (isTyping) {
                Vue.set(conversation, 'isTypingWorker', setInterval(() => {
                    Vue.set(conversation, 'isTyping', false);
                }, 5000));
            }
        },
        setConversationMessages(state, {conversation, messages}) {
            messages.sort(function (a, b) {
                return (a.created_at !== b.created_at) ? moment(a.created_at).valueOf() - moment(b.created_at).valueOf() : a.id - b.id;
            });

            Object.assign(conversation, {messages: messages});
        },
        setConversationInformationsIsOpen(state, isOpen) {
            state.conversationInformationsIsOpen = isOpen;
        },
        setConversationStickersMenuIsOpen(state, isOpen) {
            state.conversationStickersMenuIsOpen = isOpen;
        },
        setConversationSearchIsOpen(state, isOpen) {
            state.conversationSearchIsOpen = isOpen;
        },
        setConversationsData(state, data) {
            state.conversationsData.last_page = data.last_page;
            state.conversationsData.current_page = data.current_page;
            state.conversationsData.total = data.total;
        },
        setToBeProcessedConversationsData(state, data) {
            state.toBeProcessedConversationsData.last_page = data.last_page;
            state.toBeProcessedConversationsData.current_page = data.current_page;
            state.toBeProcessedConversationsData.total = data.total;
        },
        setConversations(state, conversations) {
            state.conversationsData.conversations = conversations;
        },
        setToBeProcessedConversations(state, conversations) {
            state.toBeProcessedConversationsData.conversations = conversations;
        },
        setConversationsCurrentPage(state, current_page) {
            state.conversationsData.current_page = current_page;
        },
        setToBeProcessedConversationsCurrentPage(state, current_page) {
            state.toBeProcessedConversationsData.current_page = current_page;
        },
        setConversationsIsLoading(state, is_loading) {
            state.conversationsData.isLoading = is_loading;
        },
        setToBeProcessedConversationsIsLoading(state, is_loading) {
            state.toBeProcessedConversationsData.isLoading = is_loading;
        },
        setLiveSession(state, session) {
            state.liveSession = session;
        },
        setStickers(state, stickers) {
            state.stickers = stickers;
        },
        setConversationReadStatus(state, {conversation, status}) {
            Object.assign(conversation, {read: status});
        },
        addMessageToConversation(state, {conversation, message}) {
            conversation.messages.push(message);
        },
        removeMessageFromConversation(state, {conversation, chat_message_id}) {
            for (const [key, message] of Object.entries(conversation.messages)) {
                if (message.id === chat_message_id) {
                    conversation.messages.splice(key, 1);
                }
            }
        },
        setChatSessionAlmostOver(state, isAlmostOver) {
            state.isChatSessionAlmostOver = isAlmostOver;
        },
        setRefillStatus(state, status) {
            state.refillStatus = status;
        },
        setExtendWithCreditStatus(state, status) {
            state.extendWithCreditStatus = status;
        },
        setChatBot(state, {conversation, chat_bot}) {
            Object.assign(conversation, {chat_bot});
        },
        setFollowUpMode(state, isFollowUpMode) {
            state.isFollowUpMode = isFollowUpMode;
        },
        setFollowUp(state, {conversation, follow_up}) {
            Object.assign(conversation, {follow_up: follow_up});
        },
        setHasMasterConnectedViaChatBot(state, status) {
            state.hasMasterConnectedViaChatBot = status;
        },
        setHearts(state, hearts) {
            state.hearts = hearts;
        },
        addStickerToQueue(state, sticker) {
            state.stickersQueue.push(sticker);
        },
        removeStickerInQueue(state, stickerToRemove) {
            state.stickersQueue = state.stickersQueue.filter((sticker) => sticker.id !== stickerToRemove.id);
        },
        setMostRecentConversation(state, conversation) {
            state.conversationsData.conversations = state.conversationsData.conversations.filter(function (c) {
                let newParticipants = conversation.participants.map(participant => ({
                    id: participant.id,
                    type: participant.type
                }));
                let newConvParticipants = c.participants.map(participant => ({
                    id: participant.id,
                    type: participant.type
                }));

                return JSON.stringify(newConvParticipants) !== JSON.stringify(newParticipants);
            });
            state.conversationsData.conversations.unshift(conversation);
        },
        setConnectionState(state, connectionState) {
            state.connectionState = connectionState;
        },
        setAddFundAlertDisplayed(state, addFundAlertDisplayed) {
            state.addFundAlertDisplayed = addFundAlertDisplayed;
        },
        setContinueWithCreditAlertDisplayed(state, continueWithCreditAlertDisplayed) {
            state.continueWithCreditAlertDisplayed = continueWithCreditAlertDisplayed;
        },
        setIsConversationInputFocused(state, isConversationInputFocused){
            state.isConversationInputFocused = isConversationInputFocused;
        }
    },
    actions: {
        displayAllConversationList({commit}) {
            commit('setConversationListOpened', 'all');
        },
        displayToBeProcessedConversationList({commit}) {
            commit('setConversationListOpened', 'to-be-processed');
        },
        addMessageToConversation({commit}, {conversation, message}) {
            commit('addMessageToConversation', {conversation, message});
        },
        removeMessageFromConversation({commit}, {conversation, chat_message_id}) {
            commit('removeMessageFromConversation', {conversation, chat_message_id});
        },
        liveChatStarted({dispatch}, session) {
            dispatch('setLiveSession', session);
        },
        liveChatAccepted({dispatch}, session) {
            dispatch('setLiveSession', session);
        },
        liveChatRefused({dispatch}) {
            dispatch('setLiveSession', {});
        },
        liveChatStopped({dispatch}) {
            dispatch('toggleStickersMenu', false);
            dispatch('setLiveSession', {});
        },
        clearConversationMessages({commit}, conversation) {
            commit('setConversationMessages', {conversation: conversation, messages: []});
        },
        markConversationAsRead({commit, state, getters}, participants) {
            requests.markConversationAsRead(participants).then(() => {
                let conversation = getters.getConversation(participants);
                if (conversation) {
                    commit('setConversationReadStatus', {conversation: conversation, status: true});
                }
                let toBeProcessedConversation = getters.getToBeProcessedConversation(participants);
                if (toBeProcessedConversation) {
                    commit('setConversationReadStatus', {conversation: toBeProcessedConversation, status: true});
                }
            });
        },
        markConversationAsUnread({commit, state, getters}, participants) {
            requests.markConversationAsUnread(participants).then(() => {
                let conversation = getters.getConversation(participants);
                if (conversation) {
                    commit('setConversationReadStatus', {conversation: conversation, status: false});
                }
                let toBeProcessedConversation = getters.getToBeProcessedConversation(participants);
                if (toBeProcessedConversation) {
                    commit('setConversationReadStatus', {conversation: toBeProcessedConversation, status: false});
                }
            });
        },
        selectParticipants({commit, dispatch, state, getters}, participants) {
            let conversation = getters.getConversation(participants);
            let toBeProcessedConversation = getters.getToBeProcessedConversation(participants);
            commit('selectParticipants', participants);

            if ((conversation && conversation.is_existing) || (toBeProcessedConversation && toBeProcessedConversation.is_existing)) {
                dispatch('loadConversationMessages', {
                    participants: participants,
                    callback: function () {
                        dispatch('markConversationAsRead', participants);
                    }
                });
            }

            dispatch('listenForMessageSentTypingEvents', participants);
            commit('setFollowUpMode', false);
            commit('setHasMasterConnectedViaChatBot', false);
        },
        async loadLiveSession({dispatch}) {
            await requests.getLiveSession(response => dispatch('setLiveSession', response.data.data));
        },
        addConversations({dispatch}, conversations) {
            conversations.forEach((conversation) => {
                dispatch('addConversation', {conversation});
            });
        },
        addToBeProcessedConversations({dispatch}, conversations) {
            conversations.forEach((conversation) => {
                dispatch('addToBeProcessedConversation', {conversation});
            });
        },
        addConversation({commit, getters, state, dispatch}, {conversation, addOnTop = false}) {
            if (getters.getConversation(conversation.participants) === undefined) {
                let conversations = getters.getConversations;

                if (addOnTop) {
                    conversations.unshift(conversation);
                } else {
                    conversations.push(conversation);
                }

                commit('setConversations', conversations);
            }
        },
        addToBeProcessedConversation({commit, getters, state, dispatch}, {conversation, addOnTop = false}) {
            if (getters.getToBeProcessedConversation(conversation.participants) === undefined) {
                let conversations = getters.getToBeProcessedConversations;

                if (addOnTop) {
                    conversations.unshift(conversation);
                } else {
                    conversations.push(conversation);
                }

                commit('setToBeProcessedConversations', conversations);
            }
        },
        loadConversations({commit, getters, state, dispatch}, loadDigitalArtsPresaleConversations = false) {
            commit('setConversationsIsLoading', true);
            requests.getConversations(state.conversationsData.current_page).then((response) => {
                commit('setConversationsData', response.data);
                dispatch('addConversations', response.data.data);

                response.data.data.forEach(function (conversation) {
                    dispatch('listenForMessageSentTypingEvents', conversation.participants);
                });

                dispatch('loadLiveSession');
            }).catch().then(() => {
                if (loadDigitalArtsPresaleConversations) {
                    dispatch('loadDigitalArtPresaleConversations');
                } else {
                    commit('setConversationsIsLoading', false);
                }
            });
        },
        loadNextConversations({commit, getters, state, dispatch}) {
            if (!getters.isConversationsLoading && getters.canLoadNextConversations) {
                commit('setConversationsIsLoading', true);
                requests.getConversations(state.conversationsData.current_page + 1).then((response) => {
                    commit('setConversationsCurrentPage', response.data.current_page);
                    dispatch('addConversations', response.data.data);

                    response.data.data.forEach(function (conversation) {
                        dispatch('listenForMessageSentTypingEvents', conversation.participants);
                    });
                }).catch().then(() => {
                    commit('setConversationsIsLoading', false);
                });
            }
        },
        loadToBeProcessedConversations({commit, state, dispatch}) {
            commit('setToBeProcessedConversationsIsLoading', true);
            requests.getConversations(state.toBeProcessedConversationsData.current_page, 'to_be_processed').then((response) => {
                commit('setToBeProcessedConversationsData', response.data);
                dispatch('addToBeProcessedConversations', response.data.data);

                response.data.data.forEach(function (conversation) {
                    dispatch('listenForMessageSentTypingEvents', conversation.participants);
                });

                dispatch('loadLiveSession');
            }).catch().then(() => {
                commit('setToBeProcessedConversationsIsLoading', false);
            });
        },
        loadNextToBeProcessedConversations({commit, getters, state, dispatch}) {

            if (!getters.isToBeProcessedConversationsLoading && getters.canLoadNextToBeProcessedConversations) {
                commit('setToBeProcessedConversationsIsLoading', true);
                requests.getConversations(state.toBeProcessedConversationsData.current_page + 1, 'to_be_processed').then((response) => {
                    commit('setToBeProcessedConversationsCurrentPage', response.data.current_page);
                    dispatch('addToBeProcessedConversations', response.data.data);

                    response.data.data.forEach(function (conversation) {
                        dispatch('listenForMessageSentTypingEvents', conversation.participants);
                    });
                }).catch().then(() => {
                    commit('setToBeProcessedConversationsIsLoading', false);
                });
            }
        },
        loadClientInformation({commit, getters}) {
            requests.getClientInformation(getters.getSelectedParticipants, (ClientInformationRessource) => {
                commit('setClientInformation', ClientInformationRessource);
            });
        },
        toggleConversationInformations({commit, getters, state}, display) {
            if (display === true || display === false) {
                commit('setConversationInformationsIsOpen', display);
            } else {
                commit('setConversationInformationsIsOpen', !getters.isConversationInformationsOpen);
            }
        },
        toggleStickersMenu({commit, getters, state}, display) {
            const isOpen = display === true || display === false
                                            ? display : !getters.isConversationStickersMenuOpen;
            if (isOpen) {
                commit('setIsConversationInputFocused', false);
            }
            commit('setConversationStickersMenuIsOpen', isOpen);
        },
        toggleConversationSearch({commit, getters, state}, display) {
            if (display === true || display === false) {
                commit('setConversationSearchIsOpen', display);
            } else {
                commit('setConversationSearchIsOpen', !getters.isConversationSearchOpen);
            }
        },
        closeConversation({commit}) {
            commit('setConversationInformationsIsOpen', false);
            commit('selectParticipants', null);
        },
        refreshConversationInformations({getters, dispatch}) {
            let selectedParticipants = getters.getSelectedParticipants;
            if (selectedParticipants.length > 0) {
                let offerId = getters.getOfferIdFromParticipants(selectedParticipants);
                dispatch('loadMasterStatus', offerId);
                dispatch('loadConversationMessages', {participants: selectedParticipants});
            }
        },
        loadMasterStatus({dispatch}, offerId) {
            try {
                if (offerId !== null) {
                    axios.get(ziggy_route('chat.offer.get', {
                        offerId: offerId,
                    })).then((response) => {
                        let offer = response.data.data;
                        dispatch('updateMasterStatus', {status: offer.status, user_id: offer.user_id});
                    });
                }
            } catch (e) {
            }
        },
        loadConversationMessages({commit, dispatch, state, getters}, args) {
            let participants = args.participants;

            requests.loadConversationMessages(participants).then((response) => {
                let conversation = getters.getConversation(participants) ? getters.getConversation(participants) : getters.getToBeProcessedConversation(participants);
                let messages = conversation.messages.slice();

                let isExistingMessage = (message) => {
                    let getId = function (e) {
                        return e.id;
                    };
                    let ids = messages.map(getId);
                    return ids.indexOf(message.id) !== -1;
                };

                response.data.data.forEach(function (message) {
                    if (!isExistingMessage(message)) {
                        messages.push(message);
                    }

                    if (message.type === "sticker" && !message.read && message.participant.id !== ChatMixin.methods.me().id) {
                        dispatch('addStickerToQueue', message);
                    }
                });

                commit('setConversationMessages', {
                    conversation: conversation, messages: messages,
                });

                if (typeof args.callback === 'function') {
                    args.callback();
                }
            });
        },
        setConversationMessages({commit}, {conversation, messages}) {
            commit('setConversationMessages', {
                conversation: conversation, messages: messages,
            });
        },
        setConversationUrl({commit, dispatch, getters}, participants) {
            if (participants !== null) {
                const url = getters.getConversationUrl(participants);

                let data = {
                    participants: participants,
                };

                history.pushState(data, url, url);
            }
        },
        async startLiveChatWithOfferId({dispatch}, {offerId, billing_type}) {
            let offer = {};

            await axios.get(ziggy_route('chat.offer.get', {
                offerId: offerId,
            })).then(response => offer = response.data.data);

            let participants = [
                ChatMixin.methods.meFromUserStore(), offer,
            ];

            await dispatch('loadConversations');
            await dispatch('loadToBeProcessedConversations');
            await dispatch('startLiveChat', {participants: participants, billing_type: billing_type});
        },
        async startLiveChat({dispatch, getters}, {participants, billing_type, billing_advantage = null}) {

            await requests.startLiveChat({
                    participants: participants,
                    billing_type: billing_type,
                    billing_advantage: billing_advantage
                }, (response) => {
                    dispatch('setLiveSession', response.data.data);

                    try {
                        dispatch('login/beamClientLog', null, {root: true});
                    } catch (error) {
                        console.error(error);
                    }
                },
                (error) => {
                    dispatch('setLiveSession', {});

                    try {
                        let code = null;

                        try {
                            code = error.response.data.errors.paymentResponse[0].code;
                        } catch (e) {
                        }

                        if (code === null) {
                            try {
                                code = error.response.data.errors.status[0].code;
                            } catch (e) {
                            }
                        }

                        switch (code) {
                            case 'ERROR_PAY_PER_USE_RETURNED_3DS':
                                ajax_popup('buy', {
                                    oid: getters.getOfferIdFromParticipants(participants), type: 'chat',
                                });
                                break;
                            case 'ERROR_NO_CREDIT_CARD_FOUND':
                                ajax_popup('ppu_card', {
                                    oid: getters.getOfferIdFromParticipants(participants), conversationType: 'chat',
                                });
                                break;
                            case 'ERROR_PAY_PER_USE_PRE_AUTH_FAILED':
                                dispatch('modals/openModalMessage', {
                                        title: window.globalVue.trans.get('generic.an_error_has_occurred'),
                                        description: window.globalVue.trans.get('errors.payment.generic'),
                                        icon: ['fal', 'face-sad-tear'],
                                        buttonText: window.globalVue.trans.get('generic.back_to_site'),
                                    },
                                    {root: true});
                                break;
                            case 'ERROR_START_CONVERSATION_WRONG_MASTER_STATUS_BUSY':
                                dispatch('modals/openModal', {
                                        name: globals.MASTER_IS_NO_LONGER_AVAILABLE, param: {
                                            master: ChatMixin.methods.interlocutor(participants),
                                        },
                                    },
                                    {root: true});
                                break;
                            case 'ERROR_START_CONVERSATION_WRONG_MASTER_STATUS_OFFLINE':
                                dispatch('modals/openModal', {
                                        name: globals.MASTER_OFFLINE_AT_THE_MOMENT, param: {
                                            master: ChatMixin.methods.interlocutor(participants),
                                        },
                                    },
                                    {root: true});
                                break;
                            default:
                                dispatch('modals/openModal', {
                                        name: globals.GENERIC_ERROR,
                                    },
                                    {root: true});
                        }
                    } catch (error) {
                        dispatch('modals/openModal', {
                                name: globals.GENERIC_ERROR,
                            },
                            {root: true});
                    }
                });
        },
        async acceptLiveChat({commit, dispatch, getters}) {
            await requests.acceptLiveChat({sessionId: getters.getChatLiveSessionId}, (response) => {
                    commit('modals/hide', globals.CHAT_MASTER_CONFIRMATION_TO_START_LIVE_CHAT, {root: true});
                    dispatch('setLiveSession', response.data.data);
                },
                (error) => {
                    window.location.reload();
                });
        },
        async refuseLiveChat({commit, getters, dispatch}) {
            await requests.refuseLiveChat({
                    sessionId: getters.getChatLiveSessionId
                }, () => {
                }, () => window.location.reload()
            );

            commit('modals/hide', globals.CHAT_MASTER_CONFIRMATION_TO_START_LIVE_CHAT, {root: true});
            dispatch('setLiveSession', {});
        },
        async stopLiveChat({getters}) {
            await requests.stopLiveChat({
                sessionId: getters.getChatLiveSessionId
            });
        },
        async updateSessionStickers({dispatch, getters}) {
            if (getters.getChatLiveSessionId === undefined || getters.getChatLiveSessionId === 0) {
                dispatch('setStickers', []);
                return;
            }

            if (getters.getStickers.length > 0) {
                return;
            }

            await axios.get(ziggy_route('chat.get-stickers', {
                chat_session_id: getters.getChatLiveSessionId,
                user_type: ChatMixin.methods.meFromUserStore().is_master ? 'master' : 'client'
            })).then((response) => {
                dispatch('setStickers', response.data);
            }).catch(() => {
                dispatch('setStickers', []);
            });
        },
        chatSessionAlmostOver({commit, getters, rootGetters}) {
            commit('setChatSessionAlmostOver', true);
            if (!getters.iAmMaster && getters.sessionIsCredit) {
                const enoughTimeWithCredits = (rootGetters['payment/getCredits'] / getters.getChatLiveSession.cost_by_minute) >= 1.5;

                if (getters.isFreeChatSession && enoughTimeWithCredits && !getters.isChatSessionAlreadyExtended) {
                    commit('setContinueWithCreditAlertDisplayed', true);
                } else if (rootGetters['payment/hasEnabledCreditCards']) {
                    commit('setAddFundAlertDisplayed', true);
                    commit('setRefillStatus', null);
                }
            }
        },
        listenForMessageSentTypingEvents({state, commit, getters, dispatch}, participants) {
            let selectedParticipants = participants.map(object => ({...object}));
            let whisperChannel = ChatMixin.Echo.private(ChatMixin.methods.getChannelNameForParticipants(selectedParticipants));
            let conversation = getters.getConversation(participants);
            listeners.listenForWhisper(whisperChannel, 'IsTyping', (e) => {
                // Insert isTyping only if the other person is typing
                if (e.sender.id !== ChatMixin.methods.meFromUserStore().id) {
                    commit('setIsTyping', {conversation: conversation, isTyping: true, type: e.type});
                }
            });
            listeners.listenForWhisper(whisperChannel, 'HeartSent', (e) => {
                if (e.sender.id !== ChatMixin.methods.meFromUserStore().id && e.sender.id === ChatMixin.methods.interlocutor().id) {
                    commit('setHearts', getters.getHearts + 1);
                }
            });
            listeners.listen(getters.getChatPrivateChannel, 'MessageSent', (e) => {
                // Insert message in conversation found from message participants
                // (otherwise messages received are inserted in the first conversation)
                let conversation = getters.getConversation([e.to, e.from]);
                if (conversation === undefined) {
                    conversation = getters.getConversation([e.from, e.to]);
                }

                commit('addMessageToConversation', {conversation: conversation, message: e.message});
                commit('setIsTyping', {conversation: conversation, isTyping: false});

                if (e.message.type === 'sticker') {
                    dispatch('addStickerToQueue', e.message);
                }

                if (conversation.participants === getters.getSelectedParticipants) {
                    dispatch('markConversationAsRead', conversation.participants);
                } else {
                    commit('setConversationReadStatus', {conversation: conversation, read: false});
                }

                commit('setMostRecentConversation', conversation);
            });
            listeners.listen(getters.getChatPrivateChannel, 'MessageRead', (e) => {
                conversation.messages.forEach(function (message, index) {
                    if (message.read !== true && message.id <= e.message.id) {
                        Vue.set(conversation.messages, index, Object.assign({}, message, {read: true}));
                    }
                });
            });
        },
        sendIsTyping({getters}, type) {
            const selectedParticipants = getters.getSelectedParticipants.map(object => ({...object}));
            ChatMixin.Echo.private(ChatMixin.methods.getChannelNameForParticipants(selectedParticipants)).whisper('IsTyping', {
                sender: ChatMixin.methods.me(), type: type,
            });
        },
        sendHeart({commit, getters}) {
            commit('setHearts', getters.getHearts + 1);
            const selectedParticipants = getters.getSelectedParticipants.map(object => ({...object}));
            ChatMixin.Echo.private(ChatMixin.methods.getChannelNameForParticipants(selectedParticipants)).whisper('HeartSent', {
                sender: ChatMixin.methods.me(),
            });
        },
        listenForLiveChatBillingStartedEvent({dispatch, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatBillingStarted', (e) => {
                dispatch('setLiveSession', e.session);
            });
        },
        displayChatMasterEndChatModal({commit, getters, dispatch}, session) {
            let sessionId = getters.getChatLiveSessionId;
            dispatch('liveChatStopped');
            let modalName = globals.CHAT_MASTER_END_CHAT;
            let interlocutor = ChatMixin.methods.interlocutor();
            commit('modals/addParam', {
                    name: modalName, param: {
                        name: interlocutor.name,
                        sessionId: sessionId,
                        duration: Math.abs(new Date(session.billing_stopped_at) - new Date(session.billing_started_at)),
                        gain: session.cash_net_excluding_vat_master / 100,
                    },
                },
                {root: true});
            commit('modals/show', modalName, {root: true});
        },
        handlingChatStoppedForMasterEventMissing({getters, dispatch}) {
            dispatch('stopRecordInProgress');
            dispatch('displayChatMasterEndChatModal', getters.getChatLiveSession);
        },
        displayChatClientEndChatModal({commit, getters, dispatch}, session) {
            login(true);
            dispatch('payment/loadPaymentInfo', {}, {root: true});
            let sessionId = getters.getChatLiveSessionId;
            dispatch('liveChatStopped');

            let modalName = globals.CHAT_CUSTOMER_END_CHAT;
            commit('modals/addParam', {
                name: modalName,
                param: {
                    master: ChatMixin.methods.interlocutor(),
                    client: ChatMixin.methods.meFromUserStore(),
                    sessionId: sessionId,
                    duration: Math.abs(new Date(session.billing_stopped_at) - new Date(session.billing_started_at)),
                    cost: session.cost / 100,
                    advantages: session.billing_advantages,
                },
            }, {root: true});
            commit('modals/show', modalName, {root: true});
        },
        listenForLiveChatAcceptedEvent({dispatch, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatAccepted', (e) => {
                if (ChatMixin.methods.meFromUserStore().is_master && !window.globalVue.chatAskedSound.paused) {
                    dispatch('stopChatAskedSound');
                }
                dispatch('liveChatAccepted', e.session);
            });
        },
        listenForLiveChatStoppedEvent({dispatch, commit, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatStopped', (e) => {
                let client = e.session.chat_conversation.participants.find(function (participant) {
                    return participant.type === 'client';
                });

                dispatch('stopRecordInProgress');
                if (client.id === ChatMixin.methods.meFromUserStore().id) {
                    dispatch('displayChatClientEndChatModal', e.session);
                } else {
                    dispatch('displayChatMasterEndChatModal', e.session);
                }
                commit('setChatSessionAlmostOver', false);
                commit('setAddFundAlertDisplayed', false);
            });
        },
        listenForLiveChatUpdated({commit, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatUpdated', (e) => {
                commit('setLiveSession', e.session);
            });
        },
        handlingChatStoppedForClientEventMissing({commit, getters, dispatch}) {
            dispatch('stopRecordInProgress');
            dispatch('displayChatClientEndChatModal', getters.getChatLiveSession);
            commit('setChatSessionAlmostOver', false);
        },
        listenForLiveChatRefusedEvent({commit, dispatch, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatRefused', () => {
                dispatch('liveChatRefused');
                if (ChatMixin.methods.meFromUserStore().is_master) {
                    dispatch('stopChatAskedSound');
                    commit('modals/hide', globals.CHAT_MASTER_CONFIRMATION_TO_START_LIVE_CHAT, {root: true});
                } else {
                    let modalName = globals.CHAT_CUSTOMER_INFO_MASTER_REFUSED_LIVE_CHAT;
                    commit('modals/addParam', {
                            name: modalName, param: {
                                name: ChatMixin.methods.interlocutor().name,
                            },
                        },
                        {root: true});
                    commit('modals/show', modalName, {root: true});
                }
            });
        },
        listenForChatBotAskedQuestion({commit, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'ChatBotQuestionAsked', (e) => {
                commit('setChatBot', {
                    conversation: getters.getConversation(e.conversation.participants),
                    chat_bot: e.conversation.chat_bot,
                });
            });
        },
        listenForChatBotAnsweredQuestion({commit, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'ChatBotQuestionAnswered', (e) => {
                commit('setChatBot', {
                    conversation: getters.getConversation(e.conversation.participants),
                    chat_bot: e.conversation.chat_bot,
                });
            });
        },
        listenForLiveChatStartedEvent({dispatch, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'LiveChatStarted', (e) => {
                // Switch isMe in conversation participants because
                // conversation sent by user (isMe == client) and event received by master (isMe should be offer)
                e.session.chat_conversation.participants.forEach(function (participant) {
                    participant.isMe = !participant.isMe;
                });
                dispatch('liveChatStarted', e.session);
            });
        },

        listenForFollowUpSent({commit, getters}) {
            listeners.listen(getters.getChatPrivateChannel, 'FollowUpSent', (e) => {
                commit('setFollowUp', {
                    conversation: getters.getConversation(e.conversation.participants),
                    follow_up: e.conversation.follow_up,
                });
            });
        },
        setLiveSession({state, commit, dispatch, getters}, session) {
            commit('setLiveSession', session);
            dispatch('updateSessionStickers');

            if (session !== undefined && session.hasOwnProperty('chat_conversation')) {
                dispatch('addConversation', {conversation: session.chat_conversation});
                dispatch('selectParticipants', session.chat_conversation.participants);
            }
        },
        setStickers({commit}, stickers) {
            commit('setStickers', stickers);
        },
        setSelectedConversation({state, commit, dispatch, getters}, {conversation, addOnTop}) {
            if (conversation !== undefined && conversation.hasOwnProperty('participants')) {
                dispatch('addConversation', {conversation, addOnTop});
                dispatch('selectParticipants', conversation.participants);
            }
        },
        buyRefill({dispatch, commit, getters}, offerId) {
            return dispatch('payment/buyPack', {
                    type: 'recharge_chat', offerId: offerId, avoid3DS: true, sessionId: getters.getChatLiveSessionId,
                },
                {root: true}).then((response) => {
                if (response.data.status === 'success') {
                    commit('setRefillStatus', 'success');
                    commit('setChatSessionAlmostOver', false);

                    refresh_main_header_v4();
                } else if (response.data.status === '3DS') {
                    ajax_popup('tds_red');
                } else {
                    commit('setRefillStatus', 'failed');
                    commit('setChatSessionAlmostOver', false);
                }
            });
        },

        extendChatWithCredit({dispatch, commit, getters}) {
            return axios.post(ziggy_route('chat.extendWithCredit'), {sessionId: getters.getChatLiveSessionId}).then(response => {
                commit('setExtendWithCreditStatus', 'success');
                commit('setChatSessionAlmostOver', false);
                refresh_main_header_v4();
            });
        },
        setFollowUpMode({commit}) {
            commit('setFollowUpMode', true);
        },
        removeFollowUpMode({commit}) {
            commit('setFollowUpMode', false);
        },
        addStickerToQueue({getters, commit}, stickerToAdd) {
            if (getters.getStickersQueue.some(sticker => sticker.id === stickerToAdd.id)) {
                return false;
            }
            commit('addStickerToQueue', stickerToAdd);
        },
        removeStickerInQueue({commit}, sticker) {
            commit('removeStickerInQueue', sticker);
        },
        askChatBotQuestion({getters}, participants = []) {
            if (participants.length === 0) {
                participants = getters.getSelectedParticipants;
            }
            requests.askChatBotQuestion(participants, {}, {});
        },
        selectConversationAsFollowUp({dispatch, getters, commit, state}, conversation) {
            dispatch('modals/closeModal', {}, {root: true});

            if (!getters.getConversation(conversation.participants)) {
                state.conversationsData.conversations.unshift(conversation);
            }

            dispatch('selectParticipants', conversation.participants);
            dispatch('setFollowUpMode');
        },
        onChatMainComponentMount({state, dispatch}) {
            document.body.classList.add('chat_opened');

            state.chatMainComponentIsMounted = true;

            ChatMixin.Echo.connector.pusher.connection.bind('state_change', (states) => {
                dispatch('connectionStateChange', states);
            });
        },
        onChatMainComponentDestroyed({state}) {
            document.body.classList.remove('chat_opened');

            state.chatMainComponentIsMounted = false;
        },
        updateMasterStatus({state, dispatch}, {user_id, status}) {
            let conversations = state.conversationsData.conversations;

            for (let i = 0; i < conversations.length; i++) {
                let participants = conversations[i].participants;

                for (let j = 0; j < participants.length; j++) {
                    if (participants[j].type === 'offer' && participants[j].user_id === user_id) {
                        participants[j].status = status;
                    }
                }
            }

            let masterParticipant = ChatMixin.methods.interlocutor();
            if (status === 'online' && masterParticipant && masterParticipant.user_id === user_id && (!state.liveSession || state.liveSession.status !== 'in_progress')) {
                dispatch('displayMasterConnectedModal', masterParticipant);
            }
        },
        updateOfferStatus({state}, {status_details}) {
            let conversations = state.conversationsData.conversations;

            for (let i = 0; i < conversations.length; i++) {
                let participants = conversations[i].participants;

                for (let j = 0; j < participants.length; j++) {
                    if (participants[j].type === 'offer' && participants[j].id === status_details.id) {
                        participants[j].status_details = status_details;
                    }
                }
            }
        },
        connectMasterFromChatBot({commit}, participant) {
            let master_id = (participant !== undefined) ? participant.user_id : 0;
            requests.changeMasterStatus({user_id: master_id, status: 'online', from_action: 'chat_bot'}, (response) => {
                    if (response.data.status === 'success') {
                        commit('setHasMasterConnectedViaChatBot', true);
                    }
                },
                {});
        },
        displayMasterConnectedModal({dispatch}, masterParticipant) {
            dispatch('modals/openModal', {
                    name: globals.CHAT_MASTER_CONNECTED, param: {
                        master: masterParticipant,
                    },
                },
                {root: true});
        },
        stopListeningAllEvents() {
            let channelList = listeners.getAllChannelsListened();
            channelList.forEach(function (channelName) {
                ChatMixin.Echo.leaveChannel(channelName);
                listeners.removeChannelFromList(channelName);
            });
        },
        disconnect({commit, dispatch}) {
            dispatch('stopListeningAllEvents');
            commit('setConversations', []);
            commit('setConversationsData', {last_page: 0, current_page: 1, total: 0});
            commit('setToBeProcessedConversationsData', {last_page: 0, current_page: 1, total: 0});
            commit('setLiveSession', {});
            commit('setStickers', []);
        },
        async startLiveChatProcess({commit, getters, dispatch, rootGetters}, {offerId, voucher = false}) {
            await dispatch('user/fetchUserInfos', {}, {root: true});

            let loadPaymentInfo = await dispatch('payment/loadPaymentInfo', {}, {root: true});

            if (loadPaymentInfo.isAxiosError && loadPaymentInfo.response.status === 401) {
                ajax_popup('register', {step: 1, is_app: 0, oid: offerId, type: 'chat'});
                return false;
            }

            if (window.globalVue.config.get('chat.free_chat_enabled_for_prospect') && !ChatMixin.methods.meFromUserStore().mobile_phone_is_verified) {
                dispatch('modals/openModal', {
                        name: globals.USER_PHONE_VERIFICATION,
                        param: {
                            phone_number: ChatMixin.methods.meFromUserStore().mobile_phone,
                            onSuccessVerificationCallback: () => dispatch('startLiveChatProcess', {offerId, voucher}),
                        },
                    }, {root: true}
                );
            } else {
                dispatch('modals/openModal', {
                        name: globals.PAYMENT_OPTIONS,
                        param: {
                            'offer_id': offerId,
                            'fast_pass': false,
                            'consultation_type': 'chat',
                            'voucher': voucher,
                        }
                    }, {root: true}
                );
            }
        },
        async startPrimeFreeLiveChatProcess({commit, getters, dispatch, rootGetters}, offerId) {
            await dispatch('user/fetchUserInfos', {}, {root: true});

            dispatch('modals/openModal', {
                    name: globals.CHAT_CONFIRMATION_FOR_PRIME_FREE_LIVE_CHAT, param: {
                        offerId: offerId, available_credits: rootGetters['payment/getCredits'] / 100,
                    },
                },
                {root: true});

        },
        loadDigitalArtPresaleConversations({commit, dispatch}) {
            commit('setConversationsIsLoading', true);
            requests.loadDigitalArtPresaleConversations({page: 1}, (response) => {
                    dispatch('addConversationsDigitalArts', response.data.data);
                },
                {}).then(() => {
                commit('setConversationsIsLoading', false);
            });
        },
        addConversationsDigitalArts({state}, conversations) {
            conversations.forEach((conversation) => {
                state.conversationsData.conversations.push(conversation);
            });
        },
        connectionStateChange({commit, getters, dispatch}, states) {
            commit('setConnectionState', states.current);

            dispatch('loadLiveSession');

            if ((states.previous === 'connecting' && states.current === 'connected' && getters.isLiveConversation) || (states.previous === 'unavailable' && states.current === 'connected' && getters.isLiveConversation)) {
                dispatch('refreshConversationInformations');
            }
        },
        stopRecordInProgress({dispatch, rootGetters}) {
            if (rootGetters['media_devices/isRecordInProgress']) {
                dispatch('media_devices/stopAudioRecord', {}, {root: true});
                dispatch('media_devices/deleteAudioRecord', {}, {root: true});
            }
        },
        stopChatAskedSound() {
            window.globalVue.chatAskedSound.pause();
            window.globalVue.chatAskedSound.currentTime = 0;
        },
        resetRefillStatus({commit}) {
            commit('setRefillStatus', null);
        },
        resetExtendWithCreditStatus({commit}) {
            commit('setExtendWithCreditStatus', null);
        },
        toggleAddFundAlert({commit, getters}) {
            commit('setAddFundAlertDisplayed', !getters.isAddFundAlertDisplayed);
        },
        toggleContinueWithCreditAlert({commit, getters}) {
            commit('setContinueWithCreditAlertDisplayed', !getters.isContinueWithCreditAlertDisplayed);
        },
        setIsConversationInputFocused({commit}, isConversationInputFocused) {
            commit('setIsConversationInputFocused', isConversationInputFocused);
        },
    },

    getters: {
        getHearts: (state) => {
            return state.hearts
        },
        getStickersQueue: (state) => {
            return state.stickersQueue;
        },
        getNextStickerInQueue: (state) => {
            return state.stickersQueue.length > 0 ? state.stickersQueue[0] : null;
        },
        getConnectionState: (state) => {
            return state.connectionState;
        },
        getClientInformationData: (state) => {
            return state.clientInformation !== undefined ? state.clientInformation : null;
        },
        getConversation: (state) => (participants) => {
            return state.conversationsData.conversations.find(function (conversation) {
                let newParticipants = participants.map(participant => ({id: participant.id, type: participant.type}));
                let newConvParticipants = conversation.participants.map(participant => ({
                    id: participant.id,
                    type: participant.type
                }));

                return JSON.stringify(newConvParticipants) === JSON.stringify(newParticipants);
            });
        },
        getToBeProcessedConversation: (state) => (participants) => {
            return state.toBeProcessedConversationsData.conversations.find(function (conversation) {
                let newParticipants = participants.map(participant => ({id: participant.id, type: participant.type}));
                let newConvParticipants = conversation.participants.map(participant => ({
                    id: participant.id,
                    type: participant.type
                }));

                return JSON.stringify(newConvParticipants) === JSON.stringify(newParticipants);
            });
        },
        getConversationsDataListByName: (state) => (listName) => {
            if (listName === 'toBeProcessedConversations') {
                return state.toBeProcessedConversationsData;
            } else {
                return state.conversationsData;
            }
        },
        getConversationsListToDiplay: (state) => {
            return state.conversationListOpened;
        },
        getConversations: (state) => {
            return state.conversationsData.conversations;
        },
        getToBeProcessedConversations: (state) => {
            return state.toBeProcessedConversationsData.conversations;
        },
        isConversationsLoading: (state) => {
            return state.conversationsData.isLoading;
        },
        isToBeProcessedConversationsLoading: (state) => {
            return state.toBeProcessedConversationsData.isLoading;
        },
        canLoadNextConversations: (state) => {
            return state.conversationsData.conversations.length === 0 || state.conversationsData.current_page < state.conversationsData.last_page;
        },
        canLoadNextToBeProcessedConversations: (state) => {
            return state.toBeProcessedConversationsData.conversations.length === 0 || state.toBeProcessedConversationsData.current_page < state.toBeProcessedConversationsData.last_page;
        },
        iAmMaster: () => {
            return ChatMixin.methods.me().type === 'offer';
        },
        speakingWithMaster: () => {
            return ChatMixin.methods.interlocutor().type === 'offer';
        },
        speakingWithClient: () => {
            return ChatMixin.methods.interlocutor().type === 'client';
        },
        speakingWithServiceClient: () => {
            return ChatMixin.methods.interlocutor().type === 'customer_care';
        },
        speakingWithServiceMaster: () => {
            return ChatMixin.methods.interlocutor().type === 'master_care';
        },
        isTyping: (state, getters) => (participants) => {
            let conversation = getters.getConversation(participants);

            if (conversation !== undefined && conversation.hasOwnProperty('isTyping')) {
                return conversation.isTyping;
            }

            return false;
        },
        isTypingType: (state, getters) => (participants) => {
            let conversation = getters.getConversation(participants);

            if (conversation !== undefined && conversation.hasOwnProperty('isTypingType')) {
                return conversation.isTypingType;
            }

            return null;
        },
        isConversationOpen: (state, getters) => {
            return getters.getSelectedParticipants.length > 0;
        },
        isConversationInformationsOpen: state => {
            return state.conversationInformationsIsOpen;
        },
        isConversationStickersMenuOpen: state => {
            return state.conversationStickersMenuIsOpen;
        },
        isConversationSearchOpen: state => {
            return state.conversationSearchIsOpen;
        },
        getSelectedConversation: state => {
            let selectedToBeProcessedConversation = state.toBeProcessedConversationsData.conversations.find(function (conversation) {
                return conversation.hasOwnProperty('selected') && conversation.selected;
            });
            if (selectedToBeProcessedConversation) {
                return selectedToBeProcessedConversation;
            }

            return state.conversationsData.conversations.find(function (conversation) {
                return conversation.hasOwnProperty('selected') && conversation.selected;
            });
        },
        getSelectedParticipants: state => {
            let selectedConversation = state.conversationsData.conversations.find(function (conversation) {
                return conversation.hasOwnProperty('selected') && conversation.selected;
            });

            if (selectedConversation !== undefined) {
                return selectedConversation.participants;
            }

            let selecteToBeProcessedConversation = state.toBeProcessedConversationsData.conversations.find(function (conversation) {
                return conversation.hasOwnProperty('selected') && conversation.selected;
            });

            if (selecteToBeProcessedConversation !== undefined) {
                return selecteToBeProcessedConversation.participants;
            }

            return [];
        },
        getOfferIdFromParticipants: () => participants => {
            let participant = participants.find(participant => {
                return !participant.isMe && participant.type === 'offer';
            });

            return participant !== undefined && participant.hasOwnProperty('id') ? participant.id : null;
        },
        getConversationMessages: (state, getters) => (participants) => {
            let conversation = getters.getConversation(participants);

            if (conversation !== undefined) {
                return conversation.messages;
            }

            return [];
        },
        getChatLiveSessionId: state => {
            return state.liveSession !== undefined && state.liveSession ? state.liveSession.id : 0;
        },
        getChatLiveSession: state => {
            return state.liveSession !== undefined && state.liveSession ? state.liveSession : {};
        },
        getBillingAdvantagesLiveSession: state => {
            return state !== undefined && state.liveSession !== undefined && state.liveSession.billing_advantages !== undefined ? state.liveSession.billing_advantages : [];
        },
        getOptionsLiveSession: state => {
            return state.liveSession !== undefined && state.liveSession.options !== undefined ? state.liveSession.options : [];
        },
        isFreeChatSession: (state, getters) => {
            let billingAdvantages = getters.getBillingAdvantagesLiveSession;
            const extendableByPayingAdvantage = billingAdvantages.find((advantage) => advantage.advantage === 'free_seconds');
            return extendableByPayingAdvantage !== undefined;
        },
        isChatSessionExtendableByPaying: (state, getters) => {
            let billingAdvantages = getters.getBillingAdvantagesLiveSession;
            const extendableByPayingAdvantage = billingAdvantages.find((advantage) => advantage.extendable_by_paying === 'yes');
            return extendableByPayingAdvantage !== undefined;
        },
        isChatSessionAlreadyExtended: (state, getters) => {
            let options = getters.getOptionsLiveSession;

            return options.find((option) => option.option === 'extend_with_credit') !== undefined;
        },
        isLiveConversation: (state, getters) => {
            return getters.getChatLiveSessionId > 0;
        },
        isLiveConversationWaiting: state => {
            return state.liveSession ? state.liveSession.status === 'waiting' : false;
        },
        isLiveConversationInProgress: state => {
            return state.liveSession ? state.liveSession.status === 'in_progress' : false;
        },
        canSendHearts: (state, getters) => {
            return getters.isLiveConversationWaiting || getters.isLiveConversationInProgress;
        },
        canSendStickers: (state, getters) => {
            return getters.isLiveConversationInProgress && getters.getStickers.length > 0;
        },
        hasLiveSessionBillingStarted: state => {
            return state.liveSession ? new Date(state.liveSession.billing_started_at) > 0 : false;
        },
        getLiveSessionMaxBillingTime: state => {
            if (!state.liveSession) {
                return 0;
            }

            return Math.abs(new Date(state.liveSession.max_end_at) - new Date(state.liveSession.billing_started_at));
        },
        getLiveSessionBillingTime: state => {
            if (!state.liveSession) {
                return 0;
            }

            return Math.abs(new Date() - new Date(state.liveSession.billing_started_at));
        },
        getLiveSessionBillingTimeLeft: state => {
            if (!state.liveSession) {
                return 0;
            }

            return Math.abs(new Date(state.liveSession.max_end_at) - new Date());
        },
        getStickers: state => {
            if (!state.stickers) {
                return [];
            }

            return state.stickers;
        },
        sessionIsCredit: state => state.liveSession && state.liveSession.billing_type === 'credit',
        isChatSessionAlmostOver: state => state.isChatSessionAlmostOver,
        refillSuccess: state => state.refillStatus !== null && state.refillStatus.toString() === 'success',
        refillFailed: state => state.refillStatus !== null && state.refillStatus.toString() === 'failed',
        refillEmpty: state => state.refillStatus === null,
        extendWithCreditSuccess: state => state.extendWithCreditStatus !== null && state.extendWithCreditStatus.toString() === 'success',
        canFollowUp: (state, getters) => (participants) => getters.getFollowUp(participants).enabled,
        getFollowUpReason: (state, getters) => (participants) => getters.getFollowUp(participants).reason,
        isChatBotShown: (state, getters) => (participants) => getters.getChatBot(participants).enabled,
        getChatBotReason: (state, getters) => (participants) => getters.getChatBot(participants).reason,
        getChatBotMastersAsked: (state, getters) => (participants) => getters.getChatBot(participants).masters_asked,
        getHoursBeforeAskingEnabledAgain: (state, getters) => (participants) => {
            let chatBotMinDate = new Date();
            if (getters.getChatBot(participants).min_date_to_ask_this_master_again !== '') {
                chatBotMinDate = getters.getChatBot(participants).min_date_to_ask_this_master_again ?? new Date();
            }
            return Math.ceil(parseFloat(Math.abs(new Date() - moment(chatBotMinDate).toDate()) / 36e5));
        },
        hasChatBotReasonToShow: (state, getters) => (participants) => {
            return getters.getChatBot(participants).reason !== '';
        },
        getChatBot: (state, getters) => (participants) => {
            const conversation = getters.getConversation(participants),
                interlocutor = ChatMixin.methods.interlocutor(participants), defaultEmptyResult = {
                    enabled: false, reason: '', masters_asked: 0, min_date_to_ask_this_master_again: new Date(),
                };

            if (conversation === undefined) {
                return defaultEmptyResult;
            }

            if (interlocutor.type !== 'offer') {
                return defaultEmptyResult;
            }

            if (getters.isLiveConversationWaiting || getters.isLiveConversationInProgress) {
                return defaultEmptyResult;
            }

            if (!['offline'].includes(interlocutor.status)) {
                return defaultEmptyResult;
            }

            const conversationChatBot = conversation.chat_bot;

            if (conversationChatBot.enabled) {
                let disabledChatBots = state.conversationsData.conversations.filter(c => !c.chat_bot.enabled && c.chat_bot.reason !== 'chat_bot_not_enabled');
                disabledChatBots.sort(function (a, b) {
                    return new Date(a.chat_bot.min_date_to_ask_this_master_again) - new Date(b.chat_bot.min_date_to_ask_this_master_again);
                });

                if (disabledChatBots.length >= window.globalVue.config.get('chat.chat_bot_maximum_masters_asked_with_no_answer')) {
                    return {
                        enabled: false,
                        reason: 'asked_too_many_masters_recently',
                        masters_asked: disabledChatBots.length,
                        min_date_to_ask_this_master_again: disabledChatBots[0].chat_bot.min_date_to_ask_this_master_again,
                    };
                }
            }

            return conversationChatBot;
        },
        getFollowUp: (state, getters) => (participants) => {
            const conversation = getters.getConversation(participants),
                interlocutor = ChatMixin.methods.interlocutor(participants), defaultEmptyResult = {
                    enabled: false, reason: '',
                };

            if (conversation === undefined) {
                return defaultEmptyResult;
            }

            if (getters.isLiveConversationWaiting || getters.isLiveConversationInProgress) {
                return defaultEmptyResult;
            }

            return conversation.follow_up;
        },
        openChatModal: (state) => {
            return window.location.href.indexOf('/chat_v2') === -1 && state.liveSession && ['waiting', 'in_progress'].includes(state.liveSession.status);
        },
        openMasterConfirmationToStartLiveChatModal: (state, getters) => {
            let client = getters.getSelectedParticipants.find(function (participant) {
                return participant.type === 'client';
            });

            return state.liveSession && state.liveSession.status === 'waiting' && ChatMixin.methods.meFromUserStore().is_master && client.id !== ChatMixin.methods.meFromUserStore().id;
        },
        isFollowUpMode: state => state.isFollowUpMode,
        isLastMessageReceivedLessThanFiveMinutesAgo: (state, getters) => (participants) => {
            let conversationMessages = getters.getConversationMessages(participants);
            if (conversationMessages.length > 0) {
                let lastMessageReceived = conversationMessages.slice().reverse().find(function (message) {
                    return message.participant.isMe === false;
                });

                if (lastMessageReceived === undefined) {
                    return false;
                }

                return (Math.abs(new Date() - new Date(lastMessageReceived.created_at)) / 60000) <= 5;
            }
            return false;
        },
        isChatBotAnswerMode: (state, getters) => {
            const conversation = getters.getConversation(getters.getSelectedParticipants);
            if (conversation) {
                return getters.iAmMaster && !getters.getChatLiveSessionId && !getters.isFollowUpMode && ChatMixin.methods.me().status !== 'busy' && conversation.chat_bot && !conversation.chat_bot.enabled && conversation.chat_bot.masters_asked > 0 && conversation.chat_bot.reason !== 'asked_master_too_recently_with_answer';
            }
            return false;
        },
        getConversationUrl: state => participants => {
            if (participants !== null) {
                let type_1 = ChatMixin.methods.me(participants).type;
                let id_1 = ChatMixin.methods.me(participants).id;

                let type_2 = ChatMixin.methods.interlocutor(participants).type;
                let id_2 = (ChatMixin.methods.interlocutor(participants).id !== undefined) ? ChatMixin.methods.interlocutor(participants).id : 0;

                return 'chat_v2/' + type_1 + '/' + id_1 + '/' + type_2 + '/' + id_2;
            }

            return '';
        },
        mainChatComponentIsMounted: state => {
            return state.chatMainComponentIsMounted;
        },
        hasMasterConnectedViaChatBot: state => state.hasMasterConnectedViaChatBot,
        getDigitalArtsMessageUrl: state => conversation => {
            let url = '';
            if (ChatMixin.methods.meFromUserStore().is_master) {
                if (conversation.digital_order_id) {
                    // mon-compte/mes-livraisons-djobs
                    return ziggy_route('master.digital-art.djob', {
                        id: conversation.digital_order_id,
                    }, true);
                } else {
                    // messagerie-messagep
                    return window.globalVue.trans.get('routes.da.master.mailbox_message', {digital_mailbox_id: conversation.digital_mailbox_id});
                }
            } else {
                url += window.globalVue.trans.get('routes.account.my_account') + '/';
                if (conversation.digital_order_id) {
                    // mon-compte/mes-djobs
                    url += window.globalVue.trans.get('routes.da.client.djob', {digital_order_id: conversation.digital_order_id});
                } else {
                    // mon-compte/message-prive
                    url += window.globalVue.trans.get('routes.da.client.mailbox_message', {digital_mailbox_id: conversation.digital_mailbox_id});
                }
            }
            return url;
        },
        getDigitalArtMailboxUrl: (state) => {
            let url = '';
            if (ChatMixin.methods.meFromUserStore().is_master) {
                // messagerie.html
                url += window.globalVue.trans.get('routes.da.master.url_mailbox');
            } else {
                // mon-compte/ma-messagerie.html
                url += window.globalVue.trans.get('routes.account.my_account') + '/' + window.globalVue.trans.get('routes.da.client.url_account_messagerie');
            }
            return url;
        },
        getChatPrivateChannel: (state) => {
            if (ChatMixin.methods.meFromUserStore().id) {
                return ChatMixin.Echo.private(ChatMixin.methods.getChannelNameForGeneralChat());
            }
            return null;
        },
        isAddFundAlertDisplayed: state => state.addFundAlertDisplayed,
        isAddFundButtonDisplayed: (state, getters, rootState, rootGetters) => {
            return !getters.iAmMaster && getters.sessionIsCredit && getters.hasLiveSessionBillingStarted && rootGetters['payment/hasEnabledCreditCards'] && (!getters.isFreeChatSession || (getters.isChatSessionExtendableByPaying && getters.isChatSessionAlmostOver));
        },
        hasEnoughTimeWithCredits({getters, rootGetters}) {
            return (rootGetters['payment/getCredits'] / getters.getChatLiveSession.cost_by_minute) >= window.globalVue.config.get('chat.min_time_required_to_extend_with_credit');
        },
        isContinueWithCreditAlertDisplayed: (state, getters, rootState, rootGetters) => {
            return state.continueWithCreditAlertDisplayed;
        },
        isContinueWithCreditButtonDisplayed: (state, getters, rootState, rootGetters) => {
            return !getters.iAmMaster && getters.sessionIsCredit && getters.hasLiveSessionBillingStarted && getters.hasEnoughTimeWithCredits && getters.isFreeChatSession && getters.isChatSessionAlmostOver;
        },
        masterWaitingQueue: () => {
            if (ChatMixin.methods.me().status_details !== undefined) {
                return ChatMixin.methods.me().status_details.waiting_queue;
            }
            return {};
        },
        masterHasFastPassWaiting: (state, getters) => {
            return getters.masterWaitingQueue !== undefined && getters.masterWaitingQueue.length > 0;
        },
        isConversationInputFocused: (state) => {
            return state.isConversationInputFocused;
        },
    },
};

