<template>
    <div :class="ratingClass" ref="baseRating">
        <template v-for="starIndex in maxValue">
            <div @click="rate(starIndex)" @mouseup="rate(starIndex)" :class="buttonClass(starIndex)">
                <font-awesome-icon :icon="['fas', 'star']" class="base-rating-icon--active" :size="size"/>
                <font-awesome-icon :icon="['fal', 'star']" class="base-rating-icon--inactive" :size="size"/>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
@mixin hover-supported {
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}

.base-rating {
    @apply inline-block;

    .base-rating-button {
        @apply inline-block text-star;

        &--force-active {
            .base-rating-icon--active {
                @apply inline-block;
            }

            .base-rating-icon--inactive {
                @apply hidden;
            }
        }

        &--active {
            .base-rating-icon--active {
                @apply inline-block;
            }

            .base-rating-icon--inactive {
                @apply hidden;
            }
        }

        &:not(.base-rating-button--active):not(.base-rating-button--force-active) {
            .base-rating-icon--active {
                @apply hidden;
            }

            .base-rating-icon--inactive {
                @apply inline-block;
            }
        }
    }

    &:not(.base-rating--disabled) {
        @include hover-supported() {
            .base-rating-button {
                .base-rating-icon--active {
                    @apply inline-block;
                }

                .base-rating-icon--inactive {
                    @apply hidden;
                }
            }
        }

        .base-rating-button {
            @include hover-supported() {
                ~ .base-rating-button:not(.base-rating-button--force-active) {
                    .base-rating-icon--active {
                        @apply hidden;
                    }

                    .base-rating-icon--inactive {
                        @apply inline-block;
                    }
                }
            }
        }
    }
}
</style>

<script>
export default {
    name: "BaseRating",
    props: {
        maxValue: {
            type: Number,
            default: 5
        },
        value: {
            type: Number,
            default: null
        },
        minValue: {
            type: Number,
            default: 0
        },
        size: {
            type: String,
            validator: function (value) {
                return ['xs', 'sm', 'lg'].indexOf(value) !== -1;
            },
        },
        disabled: Boolean,
        small: Boolean,
    },
    computed: {
        ratingClass() {
            return {
                'base-rating': true,
                'base-rating--disabled': this.disabled,
            }
        }
    },
    methods: {
        rate(newValue) {
            if (!this.disabled) {
                if (newValue > this.maxValue) {
                    newValue = this.maxValue;
                } else if (newValue < this.minValue) {
                    newValue = this.minValue;
                }
                this.currentValue = newValue;
                this.$emit('input', this.currentValue);
            }
        },
        buttonClass(starIndex) {
            return {
                'base-rating-button': true,
                'base-rating-button--force-active': starIndex <= this.minValue,
                'base-rating-button--active': starIndex <= this.currentValue,
            }
        },
        getValue() {
            return this.currentValue;
        }
    },
    data() {
        return {
            currentValue: 0
        }
    },
    mounted() {
        this.currentValue = this.value;

        this.$refs.baseRating.addEventListener("touchmove", (e) => {
            let deltaX = (e.changedTouches[0].clientX - this.$refs.baseRating.getBoundingClientRect().left) / this.$refs.baseRating.clientWidth * 100;
            this.rate(Math.round(deltaX * this.maxValue / 100));
        });
    }
}
</script>
