<template>
    <development>
        <div>
            <development-heading>Wheel</development-heading>
            <game-wheel ref="wheel"
                        @play="play"
                        wheel-image="game/example_development/wheel.png"
                        button-play-image="game/example_development/button_play.png"
                        button-played-image="game/example_development/button_played.png"
                        :prize-image="prizeImage"
                        :prize-action="prizeAction">
            </game-wheel>

            <development-heading :level="2">GameWheel - props</development-heading>
            <ul>
                <li><strong>maxValue</strong>: Number - <em>Number of prizes (8, 10 ...)</em></li>
                <li><strong>buttonPlayImage</strong>: String</li>
                <li><strong>buttonPlayedImage</strong>: String</li>
                <li><strong>prizeAction</strong>: String</li>
                <li><strong>prizeImage</strong>: String</li>
                <li><strong>wheelImage</strong>: String</li>
            </ul>

            <development-heading :level="2">GameWheelBackground - props</development-heading>
            <development-paragraph>The background can be a video, an image or a color.</development-paragraph>
            <ul>
                <li><strong>color</strong>: String</li>
                <li><strong>fullscreen</strong>: Boolean</li>
                <li><strong>image</strong>: String</li>
                <li><strong>order</strong>: Number</li>
                <li><strong>scale</strong>: Number - <em>Size in % (125, 150, 200 ...)</em></li>
                <li><strong>spin</strong>: Boolean</li>
                <li><strong>video</strong>: Object - <em>Example : {mp4: '/wheel_background.mp4', webm: '/wheel_background.webm'}</em></li>
            </ul>

            <development-heading :level="2">Game builder</development-heading>
            <development-paragraph>
                <base-link :href="ziggy_route('development.wheel.builder')">game-builder</base-link>
            </development-paragraph>
        </div>
    </development>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import BaseTextInput from "../elements/BaseTextInput";
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import GameWheel from "../game/elements/GameWheel";
import GameWheelBackground from "../game/elements/GameWheelBackground";
import BaseLink from "../elements/BaseLink";

export default {
    components: {
        BaseLink,
        BaseButton,
        BaseTextInput,
        Development,
        DevelopmentHeading,
        DevelopmentParagraph,
        GameWheel,
        GameWheelBackground,
    },
    methods: {
        play() {
            let maxValue = this.$refs.wheel.maxValue;
            let value = Math.floor(Math.random() * maxValue) + 1;
            this.prizeImage = "game/example_development/prize_" + value + ".png";
            this.prizeAction = () => {
                alert("Value : " + value);
                this.prizeAction = null;
            }
            this.$refs.wheel.rotateWheel(value);
        }
    },
    data() {
        return {
            prizeImage: '',
            prizeAction: null,
        };
    },
}
</script>
