<template>
    <p :class="textClass">
        <span v-html="text"></span>
        <span class="conversation-message-content-text--status">
            <conversation-message-metadata :status="messageStatus"/>
        </span>
    </p>
</template>

<style lang="scss">
.conversation-message-content-text {
    @apply p-3 overflow-hidden overflow-ellipsis whitespace-pre-line text-sm;

    &--emoji {
        @apply text-4xl;
    }

    &--status {
        @apply inline-block relative float-right px-0.5 -bottom-2 -right-1.5;
    }
}
</style>

<script>
import ConversationMessageMetadata from "./ConversationMessageMetadata";

export default {
    name: "ConversationMessageContentText",
    components: {ConversationMessageMetadata},
    props: {
        text: {
            type: String | Number
        },
        messageStatus: String,
    },
    computed: {
        textClass() {
            return {
                'conversation-message-content-text': true,
                'conversation-message-content-text--emoji': this.isEmoji,
            }
        },
        isEmoji() {
            try {
                const onlyEmojis = String(this.text).replace(new RegExp('[\u0000-\u1eeff]', 'g'), '');
                const visibleChars = String(this.text).replace(new RegExp('[\n\r\s]+|( )+', 'g'), '');
                return onlyEmojis.length === visibleChars.length && onlyEmojis.length <= 9;
            } catch (e) {
                return false;
            }
        },
    },
}
</script>
