import AdyenCheckout from '@adyen/adyen-web';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrf_token;

window.Pusher = require('pusher-js');

let pusherHasConnected = false;

window.Pusher.bind('connected', () => {
    console.log('Pusher connected successfully');
    pusherHasConnected = true;
});
window.Pusher.bind('error', () => pusherHasConnected ? window.location.reload : '');

// window.Pusher.logToConsole = true;

require('clickout-event');

window.AdyenCheckout = AdyenCheckout;
window.AdyenConfiguration = {
    environment: process.env.MIX_ADYEN_AUTHENTICATION_ENVIRONMENT,
    clientKey: process.env.MIX_ADYEN_AUTHENTICATION_CLIENT_KEY,
    analytics: {
        enabled: true,
    },
    session: {
        id: 0,
        sessionData: '',
    },
    onPaymentCompleted: (result, component) => {
        console.info(result, component);
    },
    onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
    },
    paymentMethodsConfiguration: {
        card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: false,
        }
    },
};
