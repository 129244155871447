var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: {
        id: "search_form",
        action: "ajax/search.js",
        method: "POST",
        target: "ajax_upload",
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "cat" },
        domProps: { value: this.categoryId },
      }),
      _vm._v(" "),
      _c("base-text-input", {
        ref: "searchInput",
        attrs: {
          type: "text",
          name: "keyword",
          id: "textfieldsearch",
          autocomplete: "off",
          outlined: _vm.config.get("components.header.search.outlined"),
          placeholder: _vm.trans.get("header.search"),
          "x-small": "",
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "base-button",
                  {
                    class: _vm.searchButtonClass,
                    attrs: { type: "submit", icon: "", small: "" },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-500",
                      attrs: { icon: ["fal", "magnifying-glass"] },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    class: _vm.clearButtonClass,
                    attrs: { icon: "", small: "" },
                    on: { click: _vm.clearSearch },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-400",
                      attrs: { icon: ["fas", "circle-xmark"] },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.searchValue,
          callback: function ($$v) {
            _vm.searchValue = $$v
          },
          expression: "searchValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }