var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-banner-snowfall" },
    _vm._l(50, function (i) {
      return _c("div", { staticClass: "home-banner-snowfall-snowflake" })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }