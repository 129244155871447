<template>
    <base-modal>
        <div>
            <div class="mb-2">
                <master-user-note-editor ref="general"
                                         :title="trans.get('master.customer_notes')"
                                         :placeholder="trans.get('master.write_customer_notes')"
                                         :user-id="userId"
                                         note-type="general"
                                         readonly
                                         @status="changeStatus"
                />
            </div>

            <div class="mb-2">
                <master-user-note-editor ref="consultation"
                                         :title="trans.get('master.reading_notes')"
                                         :placeholder="trans.get('master.write_reading_notes')"
                                         :user-id="userId"
                                         note-type="consultation"
                                         show-status
                                         readonly
                                         @status="changeStatus"
                />
            </div>

            <div class="text-center">
                <base-button :loading="loading" @click="closeModal">
                    {{ this.trans.get('generic.close') }}
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseTextarea from "../elements/BaseTextarea";
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import MasterUserNoteEditor from "../master/MasterUserNoteEditor";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ModalMasterUserNotes",
    components: {MasterUserNoteEditor, BaseTextarea, BaseButton, ModalHeading},
    props: {
        userId: {
            type: Number | String,
        },
    },
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['closeModal']),
        changeStatus() {
            this.loading = this.$refs.general.status === 'loading' || this.$refs.general.status === 'saving' || this.$refs.consultation.status === 'loading' || this.$refs.consultation.status === 'saving';
        },
    },
    mounted() {
        this.userId = this.getParam['master-user-notes']['user_id'];
    },
    data() {
        return {
            loading: false,
        }
    },
}
</script>

<style scoped>

</style>
