<template>
    <development>
        <div>
            <development-heading>Radio button</development-heading>
            <base-radio-input>Test</base-radio-input>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>inputHidden</strong>: Boolean</li>
                <li><strong>name</strong>: String</li>
                <li><strong>selectedValue</strong>: String|Number</li>
                <li><strong>value</strong>: String|Number</li>
            </ul>

            <development-heading>inputHidden</development-heading>
            <base-radio-input input-hidden>Input hidden</base-radio-input>

            <development-heading>Disabled</development-heading>
            <base-radio-input disabled>Item disabled</base-radio-input>

            <development-heading>Radio group</development-heading>
            <template v-for="item in this.radioGroupItems">
                <base-radio-input v-model="radioGroupSelectedValue" name="radio-group" :value="item">
                    {{ item }}
                </base-radio-input>
            </template>
            <span>Selected Value : {{ radioGroupSelectedValue }}</span>

            <development-heading>Custom slots</development-heading>
            <template v-for="item in this.radioGroupItems">
                <base-radio-input
                    v-model="radioGroupCustomSlotsSelectedValue"
                    name="custom-slots"
                    :value="item"
                    input-hidden>
                    <template>
                        <div
                            class="flex relative w-full p-4 my-4 border border-neutral-400 text-neutral-400 rounded-lg cursor-pointer">
                            {{ item }}
                        </div>
                    </template>
                    <template #checked>
                        <div
                            class="flex relative w-full p-4 my-4 border border-primary text-primary rounded-lg cursor-pointer">
                            {{ item }}
                        </div>
                    </template>
                </base-radio-input>
            </template>
            <span>Selected Value : {{ radioGroupCustomSlotsSelectedValue }}</span>

            <development-heading>Billing Type Radio Input</development-heading>
            <base-radio-input-billing-type
                v-model="billingType"
                name="billing_type"
                value="credit"
                :title="trans.get('generic.available_credit')"
                :description="available_credits | formatMoney"/>
            <base-radio-input-billing-type
                v-model="billingType"
                name="billing_type"
                value="pay_per_use"
                :title="trans.get('generic.pay_per_use_title')"
                description="2,90€/min"/>
            <br/>
            <span>Selected Value : {{ billingType }}</span>

            <development-heading>Pack Radio Input</development-heading>
            <div class="grid grid-cols-3">
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="1"
                    :cost="2990"
                    :pack-number="1"/>
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="2"
                    :cost="4990"
                    :pack-number="2"
                    most-popular/>
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="3"
                    :cost="9990"
                    :pack-number="3"/>
            </div>
            <br/>

            <div class="grid grid-cols-3">
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="1"
                    :cost="2990"
                    :credits="12990"
                    :pack-number="1"/>
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="2"
                    :cost="4990"
                    :credits="14990"
                    :pack-number="2"
                    most-popular/>
                <base-radio-input-pack
                    v-model="selectedPack"
                    name="pack"
                    :value="3"
                    :cost="9990"
                    :credits="19990"
                    :pack-number="3"/>
            </div>
            <br/>
            <span>Selected Value : {{ selectedPack }}</span>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseRadioInput from "../elements/BaseRadioInput";
import BaseRadioInputBillingType from "../elements/custom/BaseRadioInputBillingType";
import BaseRadioInputPack from "../elements/custom/BaseRadioInputPack";

export default {
    name: "DevelopmentRadioInput",
    components: {BaseRadioInputPack, BaseRadioInputBillingType, BaseRadioInput, DevelopmentHeading, Development},
    data() {
        return {
            radioGroupItems: ['One', 'Two', 'Three'],
            radioGroupSelectedValue: '',
            radioGroupCustomSlotsSelectedValue: 'Two',
            billingType: '',
            available_credits: 12.20,
            packs: [{
                id: 1,
                price: 2990,
                bonus: 1000
            }, {
                id: 2,
                price: 4990,
                bonus: 1000
            }, {
                id: 3,
                price: 9990,
                bonus: 1000
            }],
            selectedPack: null
        }
    }
}
</script>
