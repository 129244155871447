var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conversation-message-content-image" },
    [
      _c("div", { staticClass: "thumbnail" }, [
        _c("img", {
          attrs: { src: _vm.thumbnail },
          on: {
            click: function ($event) {
              return _vm.toggleFullscreen(true)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "conversation-message-content-image--status" },
          [
            _c("conversation-message-metadata", {
              attrs: { status: _vm.messageStatus },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.hasText
        ? _c("conversation-message-content-text", {
            staticClass: "pt-1",
            attrs: { text: _vm.text },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fullscreen
        ? _c(
            "div",
            { staticClass: "image-fullscreen" },
            [
              _c("div", { staticClass: "fixed" }, [
                _c("div", {
                  staticClass: "image-fullscreen-overlay",
                  on: {
                    click: function ($event) {
                      return _vm.toggleFullscreen(false)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("img", { attrs: { src: _vm.url } }),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  staticClass: "z-30",
                  attrs: { color: "light", small: "", fab: "" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleFullscreen(false)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "xmark"] },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }