<template>
    <div class="customer-care-card" :style="{backgroundImage: 'url(' + backgroundImage + ')' }">
        <div class="flex flex-col h-full w-10/12">
            <p class="font-bold text-xl">{{ trans.get('home.customer_care.title') }}</p>
            <p class="leading-6 mb-2" v-html="trans.get('home.customer_care.text')"></p>
            <div class="flex h-full items-center">
                <div class="rounded-md bg-white text-primary p-1.5 inline-block text-2xl font-bold mb-2">
                    <a :href="'tel:' + config.get('app.phone_number_customer_care')">
                        <font-awesome-icon :icon="['far', 'mobile-screen-button']"/>
                        {{ config.get('app.phone_number_customer_care') }}
                    </a>
                </div>
            </div>
            <p>
                <a :href="'mailto:' + config.get('mail.recipients.customer_care')" class="text-white font-bold">
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                    {{ config.get('mail.recipients.customer_care') }}
                </a>
            </p>
        </div>
    </div>
</template>

<style lang="scss">
.customer-care-card {
    @apply shadow-small rounded-2xl bg-customer-care bg-right-bottom bg-contain bg-no-repeat text-white h-full w-full max-w-full mx-auto p-3;
}
</style>

<script>
export default {
    name: 'CustomerCareCard',
    computed: {
        backgroundImage() {
            return this.assetManager.getImageAsset('home/customer_care.jpg');
        }
    }
}
</script>
