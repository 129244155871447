<template>
    <development>
        <div>
            <development-heading>Modal</development-heading>

            <development-heading :level="2">Small</development-heading>
            <development-paragraph>To display a <strong>small</strong> modal, the prop small must be added into the
                base-modal tag like this :<br/>&lt;base-modal small&gt;&lt;/base-modal&gt;
            </development-paragraph>

            <development-heading :level="2">List of modals in production</development-heading>
            <div class="p-4 space-y-2">
                <template v-for="modalName in modals">
                    <base-button block @click="modal(modalName)">{{ modalName }}</base-button>
                    <component v-bind:is="'modal-' + modalName" v-if="modalsIsShown(modalName)"/>
                </template>
            </div>

            <development-heading>Component Base Modal</development-heading>

            <base-button @click="modal_1 = true">Base modal</base-button>
            <base-modal v-if="modal_1" @close="modal_1 = false">
                <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet
                    quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum
                    id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                    Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero
                    malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit
                    aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.</p>
            </base-modal>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>dense</strong>: Boolean</li>
                <li><strong>scrollableContent</strong>: Boolean</li>
                <li><strong>small</strong>: Boolean</li>
            </ul>

            <development-heading>Dense</development-heading>
            <base-button @click="modal_2 = true">Base modal dense</base-button>
            <base-modal v-if="modal_2" @close="modal_2 = false" dense>
                <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet
                    quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum
                    id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                    Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero
                    malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit
                    aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.</p>
            </base-modal>

            <development-heading>Scrollable content</development-heading>
            <base-button @click="modal_3 = true">Base modal dense</base-button>
            <base-modal v-if="modal_3" @close="modal_3 = false" scrollableContent>
                <div class="space-y-4">
                    <p v-for="n in 10">Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum
                        ac diam sit amet
                        quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in
                        ipsum
                        id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                        Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut
                        libero
                        malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit
                        aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.</p>
                </div>
            </base-modal>

            <development-heading>Small</development-heading>
            <base-button @click="modal_4 = true">Base modal</base-button>
            <base-modal v-if="modal_4" @close="modal_4 = false" small>
                <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet
                    quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum
                    id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                    Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero
                    malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit
                    aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.</p>
            </base-modal>

            <development-heading>Header and footer</development-heading>
            <base-button @click="modal_5 = true">Base modal</base-button>
            <base-modal v-if="modal_5" @close="modal_5 = false" scrollableContent>
                <template #content-header>
                    <div class="p-6">Content Header</div>
                </template>
                <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet
                    quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum
                    id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
                    Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero
                    malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit
                    aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.</p>
                <template #content-footer>
                    <div class="p-6">Content Footer</div>
                </template>
            </base-modal>

            <development-heading>Full option modal</development-heading>
            <base-button @click="modal_6 = true">Base modal</base-button>
            <base-modal v-if="modal_6" @close="modal_6 = false" dense scrollableContent>
                <template #content-header>
                    <div class="p-6">Content Header</div>
                </template>
                <div class="divide-y divide-neutral-300">
                    <div v-for="n in 100" tabindex="0"
                         class="p-4 cursor-pointer outline-none hover:bg-neutral-100 focus:bg-neutral-100">
                        Item {{ n }}
                    </div>
                </div>
                <template #content-footer>
                    <div class="p-3 text-right">
                        <base-button @click="modal_6 = false">Fermer</base-button>
                    </div>
                </template>
            </base-modal>

            <development-heading>Modal Message</development-heading>

            <base-button @click="openMessageNoIcon">Without icon</base-button>
            <base-button @click="openMessageSmile">With smile icon</base-button>
            <base-button @click="openMessageCheck">Small with check icon</base-button>

            <development-heading :level="2">Params :</development-heading>
            <ul>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>title</strong>: String</li>
                <li><strong>description</strong>: String</li>
                <li><strong>icon (optional)</strong>: Array for font awesome icon (ex: ['fal', 'check'])</li>
                <li><strong>buttonText</strong>: String</li>
            </ul>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import BaseButton from "../elements/BaseButton";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";

export default {
    components: {DevelopmentParagraph, DevelopmentHeading, BaseButton, Development},
    data: function () {
        return {
            modals: globals,
            modal_1: false,
            modal_2: false,
            modal_3: false,
            modal_4: false,
            modal_5: false,
            modal_6: false,
            modal_7: false,
            modal_8: false,
            modal_9: false,
            modal_10: false,
        };
    },
    methods: {
        modal: function (name, param = {}) {
            window.globalVue.$store.commit('modals/addParam', {'name': name, 'param': param});
            window.globalVue.$store.commit('modals/show', name);
        },
        modalsIsShown: function (name) {
            return this.$store.getters['modals/isShown'](name);
        },
        openMessageNoIcon: function() {
            window.globalVue.$store.dispatch('modals/openModalMessage', {
                title: 'No icon',
                description: 'Modal Message without icon',
                buttonText: 'Close'
            })
        },
        openMessageSmile: function() {
            window.globalVue.$store.dispatch('modals/openModalMessage', {
                title: 'Smile icon',
                description: 'Modal Message with smile icon',
                icon: ['fal', 'face-smile'],
                buttonText: 'Close'
            })
        },
        openMessageCheck: function() {
            window.globalVue.$store.dispatch('modals/openModalMessage', {
                small: true,
                title: 'Check icon',
                description: 'Modal Message with check icon',
                icon: ['fas', 'circle-check'],
                buttonText: 'Close'
            })
        }
    }
}
</script>
