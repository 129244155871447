var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conversation-alert" },
    [
      _vm.isTypeAlert("client_isLiveConversationWaiting")
        ? _c(
            "base-alert",
            {
              attrs: { type: "info", block: "", elevated: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "spinner"], spin: "" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1766846794
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.chat_live_waiting_for_master_notif_customer",
                      { master_name: this.interlocutor().name }
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("client_isFreeLiveConversationWaiting")
        ? _c(
            "base-alert",
            {
              attrs: { type: "info", block: "", elevated: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "spinner"], spin: "" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1766846794
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.free_chat_live_waiting_for_master_notif_customer",
                      { master_name: this.interlocutor().name }
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert(
        "client_isLiveConversationInProgress_and_hasNotLiveSessionBillingStarted"
      )
        ? _c(
            "base-alert",
            {
              attrs: { type: "success", block: "", elevated: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2322687394
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get("chat.chat_live_in_progress_notif_customer", {
                      master_name: this.interlocutor().name,
                    })
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("invite_master_to_connect")
        ? _c(
            "base-alert",
            {
              attrs: { type: "info", block: "", elevated: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "user-check"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { color: "white", outlined: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.connectMasterFromChatBot(_vm.me())
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get(
                                    "chat.alert_action_connect_master"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2436577025
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.trans.get("chat.alert_invite_master_to_connect")) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("master_connected_via_chatbot")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "success", delay: 5000 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "user-check"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1524600031
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.alert_success_master_connected_via_chatbot"
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("offline_chat_so_follow_up_the_user")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "info" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "envelope"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { color: "white", outlined: "", small: "" },
                            on: { click: _vm.setFollowUpMode },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get("chat.follow_up_button_title")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1630886374
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.follow_up.offline_chat_so_follow_up_the_user"
                    )
                  ) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("offline_chat_but_reason_forbid_follow_up")
        ? _c(
            "base-alert",
            {
              ref: "offlineChatButTheUserIsAlreadyBeingFollowedUp",
              attrs: { block: "", elevated: "", type: "warning" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { color: "white", outlined: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.offlineChatButTheUserIsAlreadyBeingFollowedUp.close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.trans.get("generic.i_understood")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                767248338
              ),
            },
            [
              parseInt(this.getFollowUpReason(this.getSelectedParticipants)) > 0
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.trans.choice(
                            "chat.follow_up.offline_chat_but_the_user_is_already_being_followed_up",
                            parseInt(
                              this.getFollowUpReason(
                                this.getSelectedParticipants
                              )
                            ),
                            {
                              days: this.getFollowUpReason(
                                this.getSelectedParticipants
                              ),
                            }
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                : this.getFollowUpReason(this.getSelectedParticipants) ===
                  "CANNOT_FOLLOW_UP_CUSTOMER_NEW"
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.follow_up.offline_chat_but_the_user_is_too_recently_register"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                : this.getFollowUpReason(this.getSelectedParticipants) ===
                  "CANNOT_FOLLOW_UP_CUSTOMER_NOT_REGISTER"
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.follow_up.offline_chat_but_the_user_is_out_of_service"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                : this.getFollowUpReason(this.getSelectedParticipants) ===
                  "CANNOT_FOLLOW_UP_MASTER_BLOCKED"
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.follow_up.offline_chat_but_the_user_blocked_master"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("master_isMasterWithChatLiveDisabled")
        ? _c(
            "base-alert",
            {
              attrs: { type: "info", block: "", elevated: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-info"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-link",
                          { attrs: { href: _vm.interlocutor().url } },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  color: "white",
                                  outlined: "",
                                  small: "",
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "user"], size: "1x" },
                                }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.trans.get(
                                        "generic.see_the_master_profile"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1779443235
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get(
                      "chat.your_master_is_not_available_by_chat_you_can_call_it",
                      { master_name: this.interlocutor().name }
                    )
                  ) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_audio_message_record_almost_over")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "warning" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "microphone-lines"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3519440140
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get(
                        "chat.alert_chat_audio_message_record_almost_over",
                        { max_duration: this.getAudioMessageMaxDurationText }
                      )
                    ) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_audio_message_record_over")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "danger" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "microphone-lines"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3519440140
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get("chat.alert_chat_audio_message_record_over")
                    ) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_session_almost_over")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "warning" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              color: "white",
                              outlined: "",
                              small: "",
                              loading: _vm.loadingRefill,
                            },
                            on: { click: _vm.buyChatLiveRefillPack },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get("chat.alert_recharge_action", {
                                    amount: _vm.refillAmount,
                                  })
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                202632796
              ),
            },
            [
              _vm._v(" "),
              [
                _c("base-timer", {
                  ref: "live_chat_refill_alert_timer",
                  attrs: {
                    time: _vm.getTimeLeftForRefill,
                    "auto-start": "",
                    countdown: "",
                  },
                  on: { end: () => (_vm.seconds = 0) },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ minutes, seconds }) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get(
                                    "chat.alert_chat_session_almost_over",
                                    {
                                      time:
                                        (minutes > 0 ? minutes + "min" : "") +
                                        seconds,
                                    }
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2980133812
                  ),
                }),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_continue_with_credit")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "warning" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              color: "white",
                              outlined: "",
                              small: "",
                              loading: _vm.loadingRefill,
                            },
                            on: { click: _vm.continueWithCredit },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get(
                                    "chat.alert_continue_with_credit_action"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3822326334
              ),
            },
            [
              _vm._v(" "),
              [
                _c("base-timer", {
                  ref: "live_chat_continue_with_credit_alert_timer",
                  attrs: {
                    time: _vm.getTimeLeftForRefill,
                    "auto-start": "",
                    countdown: "",
                  },
                  on: { end: () => (_vm.seconds = 0) },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ minutes, seconds }) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get(
                                    "chat.alert_continue_with_credit"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2408487503
                  ),
                }),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_add_fund")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "info" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-plus"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              color: "white",
                              outlined: "",
                              small: "",
                              loading: _vm.loadingRefill,
                            },
                            on: { click: _vm.buyChatLiveRefillPack },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.trans.get("chat.alert_recharge_action", {
                                    amount: _vm.refillAmount,
                                  })
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1080340889
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("chat.alert_chat_add_fund")) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_live_refill_success")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "success" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-check"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3601049340
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("chat.chat_live_refill_success")) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_live_extend_with_credit_success")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "success" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-check"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3601049340
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get("chat.chat_live_extend_with_credit_success")
                    ) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("chat_live_refill_error")
        ? _c(
            "base-alert",
            {
              attrs: { block: "", elevated: "", type: "danger" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-info"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2588061172
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("chat.chat_live_refill_error")) +
                    "\n        "
                ),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTypeAlert("master_has_fast_pass_waiting")
        ? _c(
            "base-alert",
            {
              ref: "fastPassWaiting",
              attrs: { block: "", elevated: "", type: "info" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "phone"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { color: "white", outlined: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fastPassWaiting.close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.trans.get("generic.i_understood")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2009144290
              ),
            },
            [
              _vm._v(" "),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get("chat.alert_fast_pass_waiting", {
                        nb_fast_pass: _vm.masterWaitingQueue.length,
                      })
                    ) +
                    "\n            "
                ),
                _vm.config.get(
                  "chat.display_client_in_waiting_queue_nickname_in_chat"
                )
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            "(" +
                              _vm.getNextCustomerInWaitingQueueNickName +
                              ")"
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }