<template>
    <div :class="avatarClass" :title="name">
        <template v-if="skeletonLoader">
            <div class="base-avatar-shape base-avatar-skeleton-loader"></div>
        </template>
        <template v-else-if="hasPhoto">
            <div v-if="!isLoaded" class="base-avatar-shape base-avatar-skeleton-loader"></div>
            <img v-show="isLoaded" :src="photo" :alt="name" @load="isLoaded = true"/>
        </template>
        <template v-else>
            <div class="base-avatar-shape base-avatar-name">{{ initials }}</div>
        </template>
        <span v-if="hasStatus" class="base-avatar-badge-container">
            <span class="base-avatar-badge"></span>
        </span>
    </div>
</template>

<style lang="scss">
.base-avatar {
    @apply relative;

    .base-avatar-shape, img {
        @apply w-full h-full;
    }

    .base-avatar-name {
        @apply text-2xl flex items-center justify-center;
    }

    .base-avatar-skeleton-loader {
        @apply animate-pulse;
    }

    .base-avatar-badge-container {
        @apply flex-initial left-0 top-0 h-full pointer-events-none absolute w-full;

        .base-avatar-badge {
            @apply absolute rounded-full border-white;
        }
    }

    &--tile {
        .base-avatar-badge {
            @apply transform -translate-y-1/2 -translate-x-1/2;
        }
    }

    &--available {
        .base-avatar-badge {
            @apply bg-status-available;
        }
    }

    &--busy {
        .base-avatar-badge {
            @apply bg-status-busy;
        }
    }

    &--offline {
        .base-avatar-badge {
            @apply bg-status-offline;
        }
    }

    &:not(.base-avatar--tile) {
        img, .base-avatar-shape {
            @apply rounded-full;
        }
    }

    &--outlined .base-avatar-shape {
        @apply border bg-white;
    }

    &--color {
        &-primary {
            &:not(.base-avatar--outlined) .base-avatar-shape {
                @apply bg-primary text-white;
            }

            &.base-avatar--outlined .base-avatar-shape {
                @apply border-primary text-primary;
            }
        }

        &-secondary {
            &:not(.base-avatar--outlined) .base-avatar-shape {
                @apply bg-secondary text-white;
            }

            &.base-avatar--outlined .base-avatar-shape {
                @apply border-secondary text-secondary;
            }
        }

        &-neutral {
            &:not(.base-avatar--outlined) .base-avatar-shape {
                @apply bg-neutral-500 text-white;
            }

            &.base-avatar--outlined .base-avatar-shape {
                @apply border-neutral-500 text-neutral-500;
            }
        }

        &-neutral-secondary {
            &:not(.base-avatar--outlined) .base-avatar-shape {
                @apply bg-neutral-300 text-white;
            }

            &.base-avatar--outlined .base-avatar-shape {
                @apply border-neutral-300 text-neutral-300;
            }
        }

        &-light {
            &:not(.base-avatar--outlined) .base-avatar-shape {
                @apply bg-white text-primary;
            }

            &.base-avatar--outlined .base-avatar-shape {
                @apply border-primary text-primary;
            }
        }
    }

    &--small {
        @apply h-9 w-9;

        .base-avatar-badge {
            @apply h-2 w-2 border;
        }

        &:not(.base-avatar--tile) .base-avatar-badge {
            top: 0.1rem;
            left: 0.1rem;
        }
    }

    &--medium {
        @apply h-12 w-12;

        .base-avatar-badge {
            @apply h-3 w-3 border-2;
        }

        &:not(.base-avatar--tile) .base-avatar-badge {
            top: 0.1rem;
            left: 0.1rem;
        }
    }

    &--large {
        @apply h-16 w-16;

        .base-avatar-badge {
            @apply h-3 w-3 border-2;
        }

        &:not(.base-avatar--tile) .base-avatar-badge {
            @apply top-1 left-1;
        }
    }

    &--x-large {
        @apply h-26 w-26;

        .base-avatar-badge {
            @apply h-6 w-6 border-2;
        }

        &:not(.base-avatar--tile) .base-avatar-badge {
            @apply top-0.5 left-0.5;
        }
    }
}
</style>

<script>
export default {
    name: "BaseAvatar",
    props: {
        color: {
            type: String,
            default: "primary",
            validator: function (value) {
                return ['primary', 'secondary', 'neutral', 'neutral-secondary', 'light'].indexOf(value) !== -1
            }
        },
        status: {
            type: String,
            validator: function (value) {
                return ['online', 'busy', 'offline'].indexOf(value) !== -1
            }
        },
        large: Boolean,
        name: String,
        outlined: Boolean,
        photo: String,
        skeletonLoader: Boolean,
        small: Boolean,
        tile: Boolean,
        xLarge: Boolean,
    },
    computed: {
        avatarClass() {
            return {
                "base-avatar": true,
                'base-avatar--color-primary': this.color === 'primary',
                'base-avatar--color-secondary': this.color === 'secondary',
                'base-avatar--color-neutral': this.color === 'neutral',
                'base-avatar--color-neutral-secondary': this.color === 'neutral-secondary',
                'base-avatar--color-light': this.color === 'light',
                'base-avatar--outlined': this.outlined,
                'base-avatar--tile': this.tile,
                'base-avatar--photo': this.hasPhoto,
                'base-avatar--small': this.small,
                'base-avatar--medium': !this.small && !this.large && !this.xLarge,
                'base-avatar--large': this.large,
                'base-avatar--x-large': this.xLarge,
                'base-avatar--skeleton-loader': this.skeletonLoader,
                "base-avatar--available": this.status === 'online',
                "base-avatar--busy": this.status === 'busy',
                "base-avatar--offline": this.status === 'offline'
            }
        },
        hasPhoto() {
            return this.photo !== undefined && this.photo !== null;
        },
        hasStatus() {
            return this.status !== undefined && this.status !== null;
        },
        isLoading() {
            return this.skeletonLoader || (this.hasPhoto && this.isLoaded);
        },
        initials() {
            if (this.name !== undefined && this.name !== null) {
                return this.name.toString().charAt(0).toUpperCase();
            } else if (!this.skeletonLoader) {
                return 'A';
            }
        }
    },
    data() {
        return {
            isLoaded: false,
        }
    }
}
</script>
