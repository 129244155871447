var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("List Banner")]),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "category", "category-id": 4 },
        }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("offerListType")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("categoryId")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("timeLeftPrimePromotion")]),
            _vm._v(": String"),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Image Banners")]),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "category", "category-id": 4 },
        }),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "category", "category-id": 23 },
        }),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "voyance_by_chat", "category-id": 4 },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Prime Banners")]),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "prime_free_call" },
        }),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: {
            "offer-list-type": "prime_free_call_masters_never_consulted",
          },
        }),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: { "offer-list-type": "prime_free_chat" },
        }),
        _vm._v(" "),
        _c("offer-list-banner", {
          attrs: {
            "offer-list-type": "prime_promotion",
            "time-left-prime-promotion": "5 heures",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }