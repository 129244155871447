var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: {
        action: "ajax/go-to-second-register-step.js",
        method: "POST",
        target: "ajax_upload",
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("base-text-input", {
            staticClass: "flex-grow mr-2",
            attrs: {
              type: "email",
              name: "email",
              label: _vm.trans.get("generic.email"),
              placeholder: _vm.trans.get("generic.email"),
              error: _vm.email_error,
              outlined: "",
            },
            on: {
              keydown: function ($event) {
                _vm.email_error = ""
              },
            },
            model: {
              value: _vm.email_value,
              callback: function ($$v) {
                _vm.email_value = $$v
              },
              expression: "email_value",
            },
          }),
          _vm._v(" "),
          _c(
            "base-button",
            { staticClass: "flex-none", attrs: { type: "submit" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "paper-plane"], size: "lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }