var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { error: _vm.loadingError } }, [
    _vm.postFormSucceed
      ? _c(
          "div",
          [
            _c("modal-paragraph", [
              _vm._v(_vm._s(_vm.trans.get("generic.email_sent"))),
            ]),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { block: "" }, on: { click: _vm.cancel } },
              [_vm._v("OK")]
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("modal-paragraph", [
              _vm._v(_vm._s(_vm.trans.get("generic.password_link_expired"))),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(_vm._s(_vm.trans.get("generic.send_new_password_link"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-2 gap-4" },
              [
                _c(
                  "base-button",
                  { attrs: { outlined: "" }, on: { click: _vm.cancel } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.cancel")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    attrs: { autofocus: "", loading: _vm.formLoading },
                    on: { click: _vm.postForm },
                  },
                  [_vm._v("\n                Ok\n            ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }