<template>
    <development>
        <div>
            <development-heading>Console</development-heading>
            <base-console></base-console>

            <div class="space-y-2">
                <base-button color="primary" @click="info">Info</base-button>
                <base-button color="neutral" @click="log">Log</base-button>
                <base-button color="busy" @click="warn">Warn</base-button>
                <base-button color="danger" @click="error">Error</base-button>
            </div>

            <development-heading>Utilisation</development-heading>
            <development-paragraph>Il est possible d'activer la console en ajoutant la balise dans un fichier v4 ou v5 :</development-paragraph>
            <development-paragraph><code>&lt;base-console&gt;&lt;/base-console&gt;</code></development-paragraph>
            <development-paragraph class="text-danger"><strong>Attention : </strong> Ne pas oublier de retirer la balise avant toute mise en production !</development-paragraph>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseButton from "../elements/BaseButton";
import BaseConsole from "../elements/BaseConsole";

export default {
    components: {BaseConsole, BaseButton, DevelopmentParagraph, DevelopmentHeading, Development},
    methods: {
        info() {
            console.info('This is a console.info()');
        },
        log() {
            console.log('This is a console.log()');
        },
        warn() {
            console.warn('This is a console.warn()');
        },
        error() {
            console.error('This is a console.error()');
        }
    }
}
</script>
