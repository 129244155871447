var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conversation-list-header" }, [
    _c(
      "div",
      { staticClass: "conversation-list-header-searchbar-container" },
      [
        _vm.canSendFollowUp
          ? _c(
              "base-button",
              {
                staticClass: "absolute ml-3",
                attrs: { link: "", "font-normal": "", "normal-case": "" },
                on: { click: _vm.openModalFollowUpChoice },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: ["fal", "pen-to-square"], size: "lg" },
                }),
                _vm._v(
                  _vm._s(_vm.trans.get("chat.follow_up_button_title")) +
                    "\n        "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !this.meFromUserStore().is_master
          ? _c("div", { staticClass: "conversation-list-header-title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.my_messages")) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "absolute right-0",
            attrs: { icon: "" },
            on: { click: _vm.openSearch },
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "magnifying-glass"] },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }