<template>
    <div class="offer-page-actions">
        <template v-if="isOffline">
            <offer-card-action :label="trans.get('generic.status_offline')" color="offline"/>
        </template>

        <template v-else-if="isBusy">
            <offer-card-action v-if="fastPassActivated"
                               :label="trans.get('generic.button.fast_pass_active')"
                               :icon="['fas', 'phone']"
                               color="prime" />
            <offer-card-action v-else-if="waitingQueueFull"
                               :label="trans.get('generic.button.already_reserved_prime')"
                               :icon="['fas', 'stars']"
                               color="busy" />
            <offer-card-action v-else-if="fastPassUnavailable"
                               :label="trans.get('generic.button.fast_pass_unavailable')"
                               :icon="['fas', 'phone-slash']"
                               color="prime" />
            <offer-card-action v-else-if="canPurchaseFastPass"
                               :label="trans.get('generic.button.purchase_fast_pass')"
                               :icon="['fas', 'phone']"
                               color="prime"
                               @click="purchaseFastPass"/>
            <offer-card-action v-else-if="canCallWithFastPass"
                               :label="trans.get('generic.button.use_fast_pass')"
                               :icon="['fas', 'phone']"
                               color="prime"
                               @click="startCallWithFastPass"/>
            <offer-card-action v-else-if="canShowFastPassPresentation"
                               :label="trans.get('generic.button.use_fast_pass')"
                               :icon="['fas', 'phone']"
                               color="prime"
                               @click="showFastPassPresentation"/>
            <offer-card-action v-else-if="callInProgress"
                               :label="trans.get('generic.button.in_consultation')"
                               :icon="['fas', 'phone-slash']"
                               color="busy" />
            <offer-card-action v-else-if="chatInProgress"
                               :label="trans.get('generic.button.in_consultation')"
                               :icon="['fas', 'message-slash']"
                               color="busy" />
            <offer-card-action v-else color="busy"
                               :label="trans.get('generic.button.busy')"
                               :icon="['fas', 'user-clock']" />
        </template>

        <template v-else-if="!canChat && !canCall">
            <offer-card-action :label="trans.get('generic.button.busy')"
                               :icon="['fas', 'user-clock']"
                               color="busy" />
        </template>

        <template v-else>
            <template v-if="canCall">
                <div>
                    <offer-card-action v-if="canCallFreePrime"
                                       :label="trans.get('generic.button.free_call')"
                                       :icon="['fas', 'phone']"
                                       @click="startCallFreePrime"/>
                    <offer-card-action v-else-if="canCallFreePrimeWithMastersNeverConsulted"
                                       :label="trans.get('generic.button.free_call')"
                                       :icon="['fas', 'phone']"
                                       @click="startCallFreePrimeWithMastersNeverConsulted"/>
                    <offer-card-action v-else-if="canCallFree"
                                       :label="trans.get('generic.button.free_call')"
                                       :icon="['fas', 'phone']"
                                       @click="startCallFree"/>
                    <offer-card-action v-else-if="canCallWithVoucher"
                                       :label="trans.get('generic.button.minutes_gift', {'minutes': 10})"
                                       :icon="['fas', 'phone']"
                                       @click="startCallWithVoucher"/>
                    <offer-card-action v-else
                                       :label="trans.get('generic.button.call')"
                                       :icon="['fas', 'phone']"
                                       @click="startCall"/>
                </div>
            </template>

            <template v-if="canChat">
                <div>
                    <offer-card-action v-if="canChatFreePrime"
                                       :label="trans.get('generic.button.free_chat')"
                                       :icon="['fas', 'message-lines']"
                                       color="available"
                                       @click="startChatFreePrime"/>
                    <offer-card-action v-else-if="canChatFree"
                                       :label="trans.get('generic.button.free_chat')"
                                       :icon="['fas', 'message-lines']"
                                       color="available"
                                       @click="startChatFree"/>
                    <offer-card-action v-else-if="canChatWithVoucher"
                                       :label="trans.get('generic.button.minutes_gift', {'minutes': 10})"
                                       :icon="['fas', 'message-lines']"
                                       color="available"
                                       @click="startChatWithVoucher"/>
                    <offer-card-action v-else
                                       :label="trans.get('generic.button.chat')"
                                       :icon="['fas', 'message-lines']"
                                       color="available"
                                       @click="startChat"/>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss">
.offer-page-actions {
    @apply min-h-20 max-h-32;

    .offer-card-action {
        @apply w-full h-15 min-w-0 mb-2 text-base rounded-md;
    }
}
</style>

<script>
import OfferCardAction from "./OfferCardAction";
import OfferMixin from "./OfferMixin";
import {makeEchoInstance} from "../../utilities/echo-factory";
import user_store from "../user/store";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "OfferPageActions",
    components: {OfferCardAction},
    mixins: [OfferMixin],
    props: {
        offer_prop: {
            type: Object,
            default() {
                return {}
            }
        },
        user_prop: {
            type: Object | Array,
            default() {
                return {}
            }
        },
        offer_list_type: String,
        is_prime_enabled: Boolean,
        is_free_chat_enabled_for_prospect: Boolean,
        user_credits_prop: Number,
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
    },
    methods: {
        ...mapActions('user', ['loadUserInfos']),
    },
    mounted() {
        let echo = makeEchoInstance();
        let offerChannelName = 'offer_' + window.service_id;
        echo.channel(offerChannelName).listen('Offer.OfferStatusUpdated', e => {
            if (this.offer.id === e.resource.id) {
                this.offer.status_details = e.resource;
                this.$forceUpdate();
            }
        });

        let user_infos = this.getLoggedUserInfos;
        if (user_infos.id) {
            echo.private('user.' + user_infos.id).listen('User.UserUpdated', e => {
                this.loadUserInfos(e.userResource);
                this.$forceUpdate();
            });
        }
    },
    data() {
        return {
            offer: this.offer_prop,
            user: this.user_prop,
            user_credits: this.user_credits_prop,
        }
    },
}
</script>

<style scoped>

</style>
