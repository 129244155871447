var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "development",
    [
      _c(
        "div",
        [
          _c("development-heading", [_vm._v("Code input")]),
          _vm._v(" "),
          _c("base-code-input"),
          _vm._v(" "),
          _c("development-heading", { attrs: { level: 2 } }, [
            _vm._v("Props :"),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_c("strong", [_vm._v("fields")]), _vm._v(": Number")]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [_vm._v("autofocus")]),
              _vm._v(": Boolean"),
            ]),
            _vm._v(" "),
            _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
            _vm._v(" "),
            _c("li", [_c("strong", [_vm._v("error")]), _vm._v(": String")]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [_vm._v("placeholder")]),
              _vm._v(": String"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Fields")]),
      _vm._v(" "),
      _c("base-code-input", { attrs: { fields: 10 } }),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Placeholder")]),
      _vm._v(" "),
      _c("base-code-input", { attrs: { placeholder: "X" } }),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Disabled")]),
      _vm._v(" "),
      _c("base-code-input", { attrs: { disabled: "" } }),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Error")]),
      _vm._v(" "),
      _c("base-code-input", { attrs: { error: "This is an error" } }),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Events")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "space-y-2" },
        [
          _c("base-code-input", {
            on: {
              change: function ($event) {
                return _vm.addEvent("onChange", "info")
              },
              complete: function ($event) {
                return _vm.addEvent("onComplete", "success")
              },
            },
            model: {
              value: _vm.codeValue,
              callback: function ($$v) {
                _vm.codeValue = $$v
              },
              expression: "codeValue",
            },
          }),
          _vm._v(" "),
          _c("development-paragraph", [
            _vm._v("Value : " + _vm._s(_vm.codeValue)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notifications" },
        [
          _vm._l(_vm.componentEvents, function (timerEvent, index) {
            return [
              _c(
                "base-alert",
                {
                  staticClass: "mb-2",
                  attrs: {
                    type: timerEvent.type,
                    dismissible: "",
                    elevated: "",
                    delay: 2000,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(timerEvent.message) +
                      "\n            "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }