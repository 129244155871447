var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-screen h-screen overflow-x-hidden" },
    [
      _c(
        "div",
        {
          staticClass:
            "max-w-screen-sm lg:max-w-screen-md mx-auto text-center px-4",
        },
        [
          _c("img", {
            staticClass: "mx-auto w-72 md:w-96 select-none",
            attrs: { src: _vm.titleImageSrc },
          }),
          _vm._v(" "),
          _c("game-wheel", {
            ref: "wheel",
            attrs: {
              "wheel-image": _vm.wheelImage,
              "button-play-image": _vm.buttonPlayImage,
              "button-played-image": _vm.buttonPlayedImage,
              "prize-image": _vm.prizeImage,
              "prize-action": _vm.prizeAction,
            },
            on: { play: _vm.run },
            scopedSlots: _vm._u([
              {
                key: "backgrounds",
                fn: function () {
                  return [
                    _c("game-wheel-background", {
                      attrs: { color: _vm.backgroundColor, fullscreen: "" },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.backgrounds, function (background, index) {
                      return background.enabled
                        ? _c("game-wheel-background", {
                            key: index,
                            attrs: {
                              snowfall: background.snowfall,
                              fullscreen: background.fullscreen,
                              image: background.image,
                              scale: background.scale,
                              repeat: background.repeat,
                              spin: background.spin,
                            },
                          })
                        : _vm._e()
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("base-link", { attrs: { href: "/" } }, [
                _c("img", {
                  staticClass: "h-12 md:h-20 mx-auto select-none",
                  attrs: { src: _vm.buttonBackImageSrc },
                }),
              ]),
              _vm._v(" "),
              _c(
                "base-link",
                {
                  attrs: {
                    href: this.assetManager.getDocumentAsset(
                      "game/reglement_grand-jeu-de-noel_2021.pdf"
                    ),
                    color: "white",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("game.consult_regulation")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "fixed top-4 left-4 z-100",
          attrs: { outlined: "", fab: "", small: "" },
          on: {
            click: function ($event) {
              _vm.settingsOpen = !_vm.settingsOpen
            },
          },
        },
        [
          !_vm.settingsOpen
            ? _c("font-awesome-icon", {
                attrs: { icon: ["far", "ellipsis-vertical"] },
              })
            : _c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.settingsOpen
        ? _c(
            "div",
            { staticClass: "fixed left-0 top-0 h-full w-96 bg-white z-90" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-full w-full mt-16 pt-4 px-4 pb-20 overflow-y-scroll border-t-2 border-neutral-300",
                },
                [
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Title image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.titleImage,
                      callback: function ($$v) {
                        _vm.titleImage = $$v
                      },
                      expression: "titleImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Wheel image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.wheelImage,
                      callback: function ($$v) {
                        _vm.wheelImage = $$v
                      },
                      expression: "wheelImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Button play image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.buttonPlayImage,
                      callback: function ($$v) {
                        _vm.buttonPlayImage = $$v
                      },
                      expression: "buttonPlayImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Button played image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.buttonPlayedImage,
                      callback: function ($$v) {
                        _vm.buttonPlayedImage = $$v
                      },
                      expression: "buttonPlayedImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Button back image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.buttonBackImage,
                      callback: function ($$v) {
                        _vm.buttonBackImage = $$v
                      },
                      expression: "buttonBackImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Prize image",
                      placeholder: "http://",
                    },
                    model: {
                      value: _vm.prizeImage,
                      callback: function ($$v) {
                        _vm.prizeImage = $$v
                      },
                      expression: "prizeImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("base-text-input", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      label: "Background color",
                      placeholder: "#000000",
                    },
                    model: {
                      value: _vm.backgroundColor,
                      callback: function ($$v) {
                        _vm.backgroundColor = $$v
                      },
                      expression: "backgroundColor",
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.backgrounds, function (background, index) {
                    return _c(
                      "div",
                      {
                        key: "form_bg_" + index,
                        staticClass:
                          "mt-10 border border-neutral-300 pt-2 px-4",
                      },
                      [
                        _c(
                          "base-checkbox",
                          {
                            model: {
                              value: background.enabled,
                              callback: function ($$v) {
                                _vm.$set(background, "enabled", $$v)
                              },
                              expression: "background.enabled",
                            },
                          },
                          [_vm._v("Background " + _vm._s(index + 1))]
                        ),
                        _vm._v(" "),
                        background.enabled
                          ? _c(
                              "div",
                              { staticClass: "flex flex-col" },
                              [
                                _c("base-text-input", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    outlined: "",
                                    label: "Background image",
                                    placeholder: "http://",
                                  },
                                  model: {
                                    value: background.image,
                                    callback: function ($$v) {
                                      _vm.$set(background, "image", $$v)
                                    },
                                    expression: "background.image",
                                  },
                                }),
                                _vm._v(" "),
                                _c("base-select", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    outlined: "",
                                    label: "Scale (en %)",
                                    placeholder: "Scale (en %)",
                                    items: [100, 125, 150, 200],
                                  },
                                  model: {
                                    value: background.scale,
                                    callback: function ($$v) {
                                      _vm.$set(background, "scale", $$v)
                                    },
                                    expression: "background.scale",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "base-checkbox",
                                  {
                                    model: {
                                      value: background.repeat,
                                      callback: function ($$v) {
                                        _vm.$set(background, "repeat", $$v)
                                      },
                                      expression: "background.repeat",
                                    },
                                  },
                                  [_vm._v("Repeat")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "base-checkbox",
                                  {
                                    model: {
                                      value: background.fullscreen,
                                      callback: function ($$v) {
                                        _vm.$set(background, "fullscreen", $$v)
                                      },
                                      expression: "background.fullscreen",
                                    },
                                  },
                                  [_vm._v("Fullscreen")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "base-checkbox",
                                  {
                                    model: {
                                      value: background.snowfall,
                                      callback: function ($$v) {
                                        _vm.$set(background, "snowfall", $$v)
                                      },
                                      expression: "background.snowfall",
                                    },
                                  },
                                  [_vm._v("Snowfall")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "base-checkbox",
                                  {
                                    model: {
                                      value: background.spin,
                                      callback: function ($$v) {
                                        _vm.$set(background, "spin", $$v)
                                      },
                                      expression: "background.spin",
                                    },
                                  },
                                  [_vm._v("Spin")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-4",
                      attrs: { color: "secondary" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("Réinitialiser")]
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }