var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Radio button")]),
        _vm._v(" "),
        _c("base-radio-input", [_vm._v("Test")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("inputHidden")]),
            _vm._v(": Boolean"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("selectedValue")]),
            _vm._v(": String|Number"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("value")]),
            _vm._v(": String|Number"),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("inputHidden")]),
        _vm._v(" "),
        _c("base-radio-input", { attrs: { "input-hidden": "" } }, [
          _vm._v("Input hidden"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-radio-input", { attrs: { disabled: "" } }, [
          _vm._v("Item disabled"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Radio group")]),
        _vm._v(" "),
        _vm._l(this.radioGroupItems, function (item) {
          return [
            _c(
              "base-radio-input",
              {
                attrs: { name: "radio-group", value: item },
                model: {
                  value: _vm.radioGroupSelectedValue,
                  callback: function ($$v) {
                    _vm.radioGroupSelectedValue = $$v
                  },
                  expression: "radioGroupSelectedValue",
                },
              },
              [_vm._v("\n                " + _vm._s(item) + "\n            ")]
            ),
          ]
        }),
        _vm._v(" "),
        _c("span", [
          _vm._v("Selected Value : " + _vm._s(_vm.radioGroupSelectedValue)),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Custom slots")]),
        _vm._v(" "),
        _vm._l(this.radioGroupItems, function (item) {
          return [
            _c(
              "base-radio-input",
              {
                attrs: {
                  name: "custom-slots",
                  value: item,
                  "input-hidden": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "checked",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex relative w-full p-4 my-4 border border-primary text-primary rounded-lg cursor-pointer",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.radioGroupCustomSlotsSelectedValue,
                  callback: function ($$v) {
                    _vm.radioGroupCustomSlotsSelectedValue = $$v
                  },
                  expression: "radioGroupCustomSlotsSelectedValue",
                },
              },
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex relative w-full p-4 my-4 border border-neutral-400 text-neutral-400 rounded-lg cursor-pointer",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
              ],
              2
            ),
          ]
        }),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "Selected Value : " + _vm._s(_vm.radioGroupCustomSlotsSelectedValue)
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Billing Type Radio Input")]),
        _vm._v(" "),
        _c("base-radio-input-billing-type", {
          attrs: {
            name: "billing_type",
            value: "credit",
            title: _vm.trans.get("generic.available_credit"),
            description: _vm._f("formatMoney")(_vm.available_credits),
          },
          model: {
            value: _vm.billingType,
            callback: function ($$v) {
              _vm.billingType = $$v
            },
            expression: "billingType",
          },
        }),
        _vm._v(" "),
        _c("base-radio-input-billing-type", {
          attrs: {
            name: "billing_type",
            value: "pay_per_use",
            title: _vm.trans.get("generic.pay_per_use_title"),
            description: "2,90€/min",
          },
          model: {
            value: _vm.billingType,
            callback: function ($$v) {
              _vm.billingType = $$v
            },
            expression: "billingType",
          },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", [_vm._v("Selected Value : " + _vm._s(_vm.billingType))]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Pack Radio Input")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-3" },
          [
            _c("base-radio-input-pack", {
              attrs: { name: "pack", value: 1, cost: 2990, "pack-number": 1 },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
            _vm._v(" "),
            _c("base-radio-input-pack", {
              attrs: {
                name: "pack",
                value: 2,
                cost: 4990,
                "pack-number": 2,
                "most-popular": "",
              },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
            _vm._v(" "),
            _c("base-radio-input-pack", {
              attrs: { name: "pack", value: 3, cost: 9990, "pack-number": 3 },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-3" },
          [
            _c("base-radio-input-pack", {
              attrs: {
                name: "pack",
                value: 1,
                cost: 2990,
                credits: 12990,
                "pack-number": 1,
              },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
            _vm._v(" "),
            _c("base-radio-input-pack", {
              attrs: {
                name: "pack",
                value: 2,
                cost: 4990,
                credits: 14990,
                "pack-number": 2,
                "most-popular": "",
              },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
            _vm._v(" "),
            _c("base-radio-input-pack", {
              attrs: {
                name: "pack",
                value: 3,
                cost: 9990,
                credits: 19990,
                "pack-number": 3,
              },
              model: {
                value: _vm.selectedPack,
                callback: function ($$v) {
                  _vm.selectedPack = $$v
                },
                expression: "selectedPack",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", [_vm._v("Selected Value : " + _vm._s(_vm.selectedPack))]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }