Vue.component('base-alert', require('./elements/BaseAlert').default);
Vue.component('base-button', require('./elements/BaseButton.vue').default);
Vue.component('base-recaptcha', require('./elements/BaseReCaptcha.vue').default);
Vue.component('base-text-input', require('./elements/BaseTextInput.vue').default);
Vue.component('base-tooltip', require('./elements/BaseTooltip').default);
Vue.component('footer-email-catcher', require('./footer/FooterEmailCatcher.vue').default);
Vue.component('highlighted-offer-list', require('./offer/HighlightedOfferList').default);
Vue.component('home-banner-bats', require('./home/elements/HomeBannerBats').default);
Vue.component('home-banner-glitch', require('./home/elements/HomeBannerGlitch').default);
Vue.component('home-banner-hearts', require('./home/elements/HomeBannerHearts').default);
Vue.component('home-banner-snow', require('./home/elements/HomeBannerSnow').default);
Vue.component('home-top-sellers-list', require('./home/HomeTopSellersList').default);
Vue.component('horoscope-offer-list', require('./horoscope/HoroscopeOfferList').default);
Vue.component('master-user-notes-editor', require('./master/MasterUserNotesEditor').default);
Vue.component('offer-list', require('./offer/OfferList.vue').default);
Vue.component('offer-list-banner', require('./offer-list/OfferListBanner').default);
Vue.component('comment-list', require('./comments/CommentList').default);
Vue.component('vertical-comment-list', require('./comments/VerticalCommentList').default);
Vue.component('clients-also-consulted-offer-list', require('./offer/ClientsAlsoConsultedOfferList').default);
Vue.component('offer-page-actions', require('./offer/OfferPageActions').default);
