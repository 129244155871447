<template>
    <canvas ref="player"/>
</template>

<script>
import {DotLottie} from "@lottiefiles/dotlottie-web";

export default {
    name: "BaseLottieFilePlayer",
    props: {
        src: String,
        loop: Boolean,
        autoplay: Boolean,
    },
    methods: {
        init() {
            if (this.player) {
                this.destroy();
            }

            this.player = new DotLottie({
                autoplay: this.autoplay,
                loop: this.loop,
                canvas: this.$refs.player,
                src: this.src,
            });

            this.player.addEventListener("load", (event) => {
                this.$emit('load');
            });

            this.player.addEventListener("play", (event) => {
                this.$emit('play');
            });

            this.player.addEventListener("frame", (event) => {
                this.player.resize();
            });

            this.player.addEventListener("pause", (event) => {
                this.$emit('pause');
            });

            this.player.addEventListener("stop", (event) => {
                this.$emit('stop');
            });

            this.player.addEventListener("loop", (event) => {
                this.$emit('loop');
            });

            this.player.addEventListener("complete", (event) => {
                this.$emit('complete');
            });
        },
        play() {
            this.player.play();
        },
        pause() {
            this.player.pause();
        },
        stop() {
            this.player.stop();
        },
        destroy() {
            this.player.destroy();
        },
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            player: null,
        }
    },
}
</script>
