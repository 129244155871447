import {makeEchoInstance} from '../../utilities/echo-factory';
import chat_store from '../chat/store';
import user_store from '../user/store';

const ChatMixin = {
    Echo: makeEchoInstance('App.Events.Chat'),
    methods: {
        me: function(participants = []) {
            if (!participants || participants.length === 0) {
                participants = chat_store.getters.getSelectedParticipants(chat_store.state);
            }

            if (!participants || participants.length === 0) {
                return {};
            }

            let me = participants.find(participant => participant.isMe);
            if (me === undefined) {
                me = this.meFromUserStore();
            }
            return me;
        },
        meFromUserStore: function() {
            let me = user_store.getters.getLoggedUserInfos(user_store.state);

            if (me !== null) {
                me.type = 'client';
                me.isMe = true;
            }

            return me;
        },
        interlocutor: function(participants = []) {
            if (!participants || participants.length === 0) {
                participants = chat_store.getters.getSelectedParticipants(chat_store.state);
            }

            if (!participants || participants.length === 0) {
                return {};
            }

            return participants.find(participant => participant.isMe === false);
        },
        getParticipantName: function(participant) {
            switch (participant.type) {
                case 'customer_care':
                    return this.trans.get('generic.chat_customer_care_name');
                case 'master_care':
                    return this.trans.get('generic.chat_master_care_name');
                default:
                    return participant.name;
            }
        },
        getChannelNameForParticipants: function(participants) {
            participants[0].id = participants[0].id === undefined ? 0 : participants[0].id;
            participants[1].id = participants[1].id === undefined ? 0 : participants[1].id;

            participants.sort(function(a, b) {
                let aId = a['id'];
                let bId = b['id'];
                return aId === bId ? (a['type'] > b['type']) - (a['type'] < b['type']) : (aId > bId) - (aId < bId);
            });

            return `chat.${participants[0].type}.${participants[0].id}.${participants[1].type}.${participants[1].id}`;
        },
        getChannelNameForCurrentChatLiveSession: function() {
            return `chat.session.${chat_store.getters.getChatLiveSessionId(chat_store.state)}`;
        },
        getChatLiveSessionId: function() {
            return chat_store.getters.getChatLiveSessionId(chat_store.state);
        },
        getChannelNameForGeneralChat: function() {
            return `chat.${this.meFromUserStore().id}`;
        },
        isConversationWithCare: function() {
            return chat_store.getters.speakingWithServiceClient() || chat_store.getters.speakingWithServiceMaster();
        },
        isMasterOffline: function(master) {
            return master.status === 'offline';
        },
        isMasterBusy: function(master) {
            return master.status === 'busy';
        },
        isMasterOnline: function(master) {
            return master.status === 'online';
        },
        isMasterWithChatLiveEnabled: function(master) {
            return master.chatLiveEnabled === true;
        },
        isMasterAvailableForChat: function(master) {
            return this.isMasterOnline(master) && this.isMasterWithChatLiveEnabled(master);
        },
        scrollMessagesToBottom: function() {
            let conversationMessages = document.getElementById('conversation-messages-list-container');
            if (typeof (conversationMessages) != 'undefined' && conversationMessages != null) {
                conversationMessages.scrollTop = conversationMessages.scrollHeight;
            }
        },
        hasPrimeFreeChatAdvantage: function() {
            let hasPrimeFreeChatAdvantage = false;

            chat_store.getters.getBillingAdvantagesLiveSession(chat_store.state).forEach((billing_advantage) => {
                if (billing_advantage.prime_free_chat) {
                    hasPrimeFreeChatAdvantage = true;
                }
            });

            return hasPrimeFreeChatAdvantage;
        },
        hasMasterGiftToClientAdvantage: function() {
            let hasMasterGiftToClientAdvantage = false;

            chat_store.getters.getBillingAdvantagesLiveSession(chat_store.state).forEach((billing_advantage) => {
                if (billing_advantage.master_gift_to_client) {
                    hasMasterGiftToClientAdvantage = true;
                }
            });

            return hasMasterGiftToClientAdvantage;
        },
    },
};

export default ChatMixin;
