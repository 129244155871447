var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("List - Offer Card")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid lg:grid-cols-2 grid-cols-1 gap-4" },
          _vm._l(_vm.datas, function (data, index) {
            return _c("offer-card", {
              key: data.offer.id,
              attrs: {
                number: index + 1,
                offer: data.offer,
                user: data.user,
                offer_list_type: data.offer_list_type,
                is_prime_enabled: data.is_prime_enabled,
                is_free_chat_enabled_for_prospect:
                  data.is_free_chat_enabled_for_prospect,
                user_credits: data.user_credits,
              },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }