var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "follow-up-to-one-list-item" }, [
    _c("div", { staticClass: "follow-up-to-one-list-item-client-infos" }, [
      _c("p", [
        _c("span", { staticClass: "follow-up-to-one-list-item-client-name" }, [
          _vm._v(_vm._s(_vm.customer.name)),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "text-sm whitespace-nowrap" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["far", "cake-candles"] },
            }),
            _vm._v(
              " " +
                _vm._s(_vm._f("formatDate")(_vm.customer.dt_birth)) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-sm" }, [
        _vm._v(
          "\n            " +
            _vm._s(
              _vm.trans.get(
                "generic.modals.follow_up_to_one_last_connection_text"
              )
            ) +
            " " +
            _vm._s(
              _vm._f("diffFromNow")(_vm.conversation.dt_last_conversation)
            ) +
            "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-none self-center" },
      [
        _c(
          "base-button",
          {
            attrs: {
              outlined: "",
              small: "",
              disabled: !_vm.conversation.follow_up.enabled,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("selectItem", _vm.conversation)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "mx-3",
              attrs: { icon: ["far", "message-lines"] },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }