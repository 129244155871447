<template>
    <base-modal :error="loadingError">
        <modal-heading>
            {{ trans.get('chat.modals.master_end_chat_title', {'client_name': name}) }}
        </modal-heading>

        <div class="ml-4">
            <base-list>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']" /></template>
                    <template #title>{{ trans.get('generic.pseudo') }} : </template>
                    <template #description>{{ name }}</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']" /></template>
                    <template #title>{{ trans.get('generic.duration') }} : </template>
                    <template #description>
                        <base-timer ref="timerDuration" v-slot="{hours, minutes, seconds}">
                            <template v-if="hours > 0">{{ hours + 'h' }}</template>
                            <template v-if="minutes > 0">{{ minutes + 'min' }}</template>
                            <template v-if="seconds > 0">{{ seconds + 's' }}</template>
                            <template v-if="seconds == 0">0s</template>
                        </base-timer>
                    </template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']" /></template>
                    <template #title>{{ trans.get('generic.gain') }} : </template>
                    <template #description>{{ gain | formatMoney }} ({{ trans.get('generic.stickers_included') }})</template>
                </base-list-item>
            </base-list>
        </div>

        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button autofocus @click="closeModal">
                {{ trans.get('generic.ok') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import BaseTimer from "../elements/BaseTimer";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    name: "ModalChatMasterEndChat",
    components: {BaseListItem, BaseList, BaseTimer, BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
    data() {
        return {
            loadingError: '',
            name: '',
            duration: 0,
            sessionId: 0,
            gain: 0
        };
    },
    mounted()
    {
        this.name = this.getParam['chat-master-end-chat']['name'];
        this.duration = this.getParam['chat-master-end-chat']['duration'];
        this.sessionId = this.getParam['chat-master-end-chat']['sessionId'];
        this.gain = this.getParam['chat-master-end-chat']['gain'];
        this.$refs.timerDuration.setTime(this.duration);
    },
}
</script>

<style scoped>

</style>
