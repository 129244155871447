var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "base-tooltip",
      class: _vm.classes,
      on: { mouseover: _vm.handleTooltipPosition },
    },
    [
      _vm._v(_vm._s(_vm.text) + "\n    "),
      _vm.icon
        ? _c(
            "div",
            { staticClass: "inline-block" },
            [_c("font-awesome-icon", { attrs: { icon: _vm.icon } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "tooltip-text", class: _vm.classesPosition }, [
        _vm.icon
          ? _c(
              "div",
              { staticClass: "px-1" },
              [_c("font-awesome-icon", { attrs: { icon: _vm.icon } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "text-left text-black" }, [
          _vm._v("\n            " + _vm._s(_vm.textTooltip) + "\n        "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }