var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _vm.showGameBanner
        ? _c("img", {
            staticClass: "w-full mb-4",
            attrs: { src: _vm.gameBanner },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(_vm.trans.get("payment.credit_my_account")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-4 mb-6" },
        [
          _c(
            "base-list",
            [
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.credit")) +
                            " :\n                "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatMoneyShort")(
                              _vm.availableCredits / 100
                            )
                          )
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showBuyPack
        ? _c(
            "div",
            { staticClass: "grid grid-cols-3" },
            [
              _c("base-radio-input-pack", {
                attrs: {
                  value: _vm.packs[0].id,
                  cost: _vm.packs[0].cost,
                  credits: _vm.packs[0].credits,
                  "pack-number": 1,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
              _vm._v(" "),
              _c("base-radio-input-pack", {
                attrs: {
                  value: _vm.packs[1].id,
                  cost: _vm.packs[1].cost,
                  credits: _vm.packs[1].credits,
                  "pack-number": 2,
                  "most-popular": "",
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
              _vm._v(" "),
              _c("base-radio-input-pack", {
                attrs: {
                  value: _vm.packs[2].id,
                  cost: _vm.packs[2].cost,
                  credits: _vm.packs[2].credits,
                  "pack-number": 3,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "mt-6",
          attrs: { block: "", loading: this.loading },
          on: { click: _vm.submit },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.trans.get("generic.validate_my_choice")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-center mt-4 ml-2 sm:ml-4 sm:mb-8" },
        _vm._l(
          _vm.config.get("payment-methods.ppu.displayed_brands"),
          function (brand) {
            return _c("div", { staticClass: "mr-2 sm:mr-4 max-w-12" }, [
              _c("img", {
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    "payment/brands/" + brand + ".png"
                  ),
                  alt: "Brands",
                },
              }),
            ])
          }
        ),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }