<template>
    <div>
        <div class="flex">
            <div :class="inputClass"
                 :data-sitekey="siteKey"
                 data-callback="baseRecaptchaCallback"
                 :error="captchaError"
                 @validate="this.$emit('recaptchavalidate', $event)">
            </div>
        </div>

        <div v-if="hasError" :class="messageClass" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.g-recaptcha {
    @apply rounded-md;

    &--error {
        @apply border border-danger;
    }
}
.g-recaptcha-message {
    @apply mt-1 text-sm;

    &--error {
        @apply text-danger;
    }
}
</style>

<script>
export default {
    name: 'BaseReCaptcha',
    props: {
        id: {
            type: String,
            default() {
                return `text-input-${this._uid}`;
            }
        },
        siteKey: {
            type: String,
            validator: function (value) {
                const regex = /[a-zA-Z0-9-_]+/g;
                return value.match(regex);
            }
        },
        error: String
    },
    data() {
        return {
            captchaError: ''
        };
    },
    mounted() {
        if (!document.querySelector('#recaptcha-script')) {
            const recaptchaScript = document.createElement('script');
            recaptchaScript.id = 'recaptcha-script';
            recaptchaScript.setAttribute('src', this.config.get('spam_protection.recaptcha.urls.api'));
            recaptchaScript.setAttribute('async', 'async');
            recaptchaScript.setAttribute('defer', 'defer');
            document.head.appendChild(recaptchaScript);
        }

        window.baseRecaptchaCallback = this.updateValue;
    },
    computed: {
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
        messageClass() {
            return {
                'g-recaptcha-message': true,
                'g-recaptcha-message--error': this.hasError
            };
        },
        inputClass() {
            return {
                'g-recaptcha': true,
                'g-recaptcha--error': this.hasError
            };
        },
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
            this.$emit('recaptchaValidate')
        }
    },
    beforeDestroy() {
        delete window.baseRecaptchaCallback;
    },
};
</script>
