<template>
    <base-modal small>
        <modal-heading>{{ trans.get('chat.modals.authorize_microphone_title') }}</modal-heading>
        <modal-paragraph v-html="description"></modal-paragraph>
        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button @click="closeModal">{{ trans.get('generic.i_understood') }}</base-button>
        </div>
    </base-modal>
</template>

<style scoped>

</style>

<script>
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";
export default {
    name: "ModalAuthorizeMicrophone",
    components: {BaseButton, ModalParagraph, ModalHeading},
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
    data() {
        return {
            description: ''
        }
    },
    mounted() {
        this.description = this.trans.get('chat.modals.authorize_microphone_text');
    }
}
</script>
