var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.avatarClass, attrs: { title: _vm.name } },
    [
      _vm.skeletonLoader
        ? [
            _c("div", {
              staticClass: "base-avatar-shape base-avatar-skeleton-loader",
            }),
          ]
        : _vm.hasPhoto
        ? [
            !_vm.isLoaded
              ? _c("div", {
                  staticClass: "base-avatar-shape base-avatar-skeleton-loader",
                })
              : _vm._e(),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoaded,
                  expression: "isLoaded",
                },
              ],
              attrs: { src: _vm.photo, alt: _vm.name },
              on: {
                load: function ($event) {
                  _vm.isLoaded = true
                },
              },
            }),
          ]
        : [
            _c("div", { staticClass: "base-avatar-shape base-avatar-name" }, [
              _vm._v(_vm._s(_vm.initials)),
            ]),
          ],
      _vm._v(" "),
      _vm.hasStatus
        ? _c("span", { staticClass: "base-avatar-badge-container" }, [
            _c("span", { staticClass: "base-avatar-badge" }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }