const CategoriesMixin = {
    methods: {
        isAstrologyCategory: function () {
            let category = this.config.get('categories.categories').find((category) => category.id === this.categoryId);
            if (category !== undefined) {
                return category.type === 'astrology';
            }

            let astroCategory = this.config.get('categories.categories').find((category) => category.type === 'astrology')
            if (astroCategory.sub_categories === undefined) {
                return false;
            }

            return astroCategory.sub_categories.find((category) => category.id === this.categoryId) !== undefined;
        },
    },
};

export default CategoriesMixin;
