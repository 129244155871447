var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-radio-input",
    {
      staticClass: "mr-2 text-center",
      attrs: {
        name: _vm.name,
        value: _vm.value,
        disabled: _vm.disabled,
        "input-hidden": "",
      },
      scopedSlots: _vm._u([
        {
          key: "checked",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "base-radio-input-pack--checked",
                  class: _vm.baseRadioInputPackClass,
                  style: _vm.cssVars,
                },
                [
                  _vm.mostPopular
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "base-radio-input-pack-most-popular base-radio-input-pack-most-popular--checked",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.trans.get("generic.most_popular_pack"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "base-radio-input-pack-title-container" },
                    [
                      _c("p", { staticClass: "base-radio-input-pack-title" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatMoneyShort")(_vm.cost / 100))
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.hasBonus
                        ? _c(
                            "p",
                            { staticClass: "base-radio-input-pack-bonus" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "modals.refill.bonus_amount_pack",
                                      { bonus_amount: _vm.bonus }
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "base-radio-input-pack-icon--checked",
                      class: _vm.baseRadioInputPackIconClass,
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-check"], size: "lg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.pack,
        callback: function ($$v) {
          _vm.pack = $$v
        },
        expression: "pack",
      },
    },
    [
      [
        _c("div", { class: _vm.baseRadioInputPackClass }, [
          _vm.mostPopular
            ? _c("div", { staticClass: "base-radio-input-pack-most-popular" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.trans.get("generic.most_popular_pack"))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "base-radio-input-pack-title-container" }, [
            _c("p", { staticClass: "base-radio-input-pack-title" }, [
              _vm._v(_vm._s(_vm._f("formatMoneyShort")(_vm.cost / 100))),
            ]),
            _vm._v(" "),
            _vm.hasBonus
              ? _c("p", { staticClass: "base-radio-input-pack-bonus" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.trans.get("modals.refill.bonus_amount_pack", {
                          bonus_amount: _vm.bonus,
                        })
                      ) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-neutral-400",
              class: _vm.baseRadioInputPackIconClass,
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle"], size: "lg" },
              }),
            ],
            1
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }