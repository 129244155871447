var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "" } },
    [
      _c("modal-heading", [
        _vm._v(
          _vm._s(
            _vm.trans.get("payment.something_went_wrong_with_your_payment")
          )
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", [
        _c("p", { staticClass: "font-smaller" }, [
          _vm._v(_vm._s(_vm.trans.get("payment.not_a_big_deal"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-smaller" }, [
          _vm._v(_vm._s(_vm.trans.get("payment.contact_us"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-5" },
        [
          _c("base-button", { on: { click: _vm.closeModal } }, [
            _vm._v("\n      " + _vm._s(_vm.trans.get("generic.ok")) + "\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }