<template>
    <div class="conversation-message-content-image">
        <div class="thumbnail">
            <img :src="thumbnail" @click="toggleFullscreen(true)"/>
            <span class="conversation-message-content-image--status">
                <conversation-message-metadata :status="messageStatus"/>
            </span>
        </div>
        <conversation-message-content-text v-if="hasText" :text="text" class="pt-1"/>

        <div class="image-fullscreen" v-if="fullscreen">
            <div class="fixed">
                <div class="image-fullscreen-overlay" @click="toggleFullscreen(false)"></div>
            </div>
            <img :src="url"/>
            <base-button class="z-30" color="light" small fab @click="toggleFullscreen(false)">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-message-content-image {
    @apply p-1;

    .thumbnail {
        @apply relative h-48 rounded-lg;

        img {
            @apply h-full object-cover rounded-lg;
        }
    }

    &--status {
        @apply absolute pl-1 bottom-0.5 right-2;
    }
}

.image-fullscreen {
    @apply fixed w-full h-full top-0 left-0 z-20 flex flex-col items-center justify-center space-y-2;

    img {
        @apply bg-neutral-300;
    }
}

.image-fullscreen .image-fullscreen-overlay {
    @apply fixed z-20 w-full h-full top-0 left-0 bg-black opacity-85;
}

.image-fullscreen img {
    @apply z-30;
    max-height: 85vh;
    max-width: 100vw;
}
</style>

<script>
import ConversationMessageContentText from "./ConversationMessageContentText";
import BaseButton from "../elements/BaseButton";
import ConversationMessageMetadata from "./ConversationMessageMetadata";

export default {
    name: "ConversationMessageContentImage",
    components: {ConversationMessageMetadata, BaseButton, ConversationMessageContentText},
    props: {
        url_thumbnail: String,
        url: String,
        text: String,
        messageStatus: String,
    },
    computed: {
        hasText() {
            return this.text !== undefined && this.text !== null && this.text.length > 0;
        },
        thumbnail() {
            if (this.url_thumbnail !== undefined && this.url_thumbnail !== null && this.url_thumbnail.length > 0) {
                return this.url_thumbnail;
            } else {
                return this.url;
            }
        }
    },
    methods: {
        toggleFullscreen(showFullscreen) {
            if (showFullscreen !== undefined) {
                this.fullscreen = !this.fullscreen;
            } else {
                this.fullscreen = showFullscreen;
            }
        }
    },
    data: function () {
        return {
            fullscreen: false
        }
    }
}
</script>
