var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { "disable-closing": "" } }, [
    _c(
      "div",
      [
        _vm.isLoading
          ? _c(
              "div",
              [
                _c("modal-heading", [
                  _vm._v(_vm._s(_vm.trans.get("payment.please_wait"))),
                ]),
              ],
              1
            )
          : _vm.isAuthorized
          ? _c(
              "div",
              [
                _c("modal-heading", [
                  _vm._v(_vm._s(_vm.trans.get("payment.payment_authorized"))),
                ]),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("modal-heading", [
                  _vm._v(_vm._s(_vm.trans.get("errors.payment.generic"))),
                ]),
              ],
              1
            ),
        _vm._v(" "),
        _vm.isLoading
          ? _c(
              "div",
              [
                _c("modal-paragraph", [
                  _vm._v(_vm._s(_vm.trans.get("payment.loading"))),
                ]),
              ],
              1
            )
          : _vm.isAuthorized
          ? _c(
              "div",
              [
                _c("modal-paragraph", [
                  _vm._v(_vm._s(_vm.trans.get("payment.payment_authorized"))),
                ]),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    attrs: { autofocus: "", block: "" },
                    on: { click: _vm.closeModal },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.back_to_site")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("modal-paragraph", [
                  _vm._v(_vm._s(_vm.trans.get("errors.payment.generic"))),
                ]),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    attrs: { autofocus: "", block: "" },
                    on: { click: _vm.closeModal },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.back_to_site")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
        _vm._v(" "),
        _c("base-timer", {
          attrs: { "auto-start": "", "threshold-events": _vm.thresholdEvents },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }