var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "", error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("generic.modals.notif_master_online_title", {
                mastername: _vm.master.name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        this.canModifyPhoneNumber
          ? _c(
              "div",
              [
                _c(
                  "base-list",
                  { staticClass: "ml-3 mb-4" },
                  [
                    _c("base-list-item", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fas", "circle-chevron-right"],
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.modals.notif_master_online_text"
                                    )
                                  ) + " :"
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3641045653
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _c("base-phone-input", {
                      ref: "input_phone_number",
                      attrs: {
                        outlined: "",
                        autofocus: "",
                        "only-countries": _vm.config.get(
                          "sms.phone_number_available_countries"
                        ),
                        "initial-country": _vm.config.get(
                          "sms.default_phone_number_country_code"
                        ),
                        label: this.trans.get("generic.phone"),
                        placeholder: this.trans.get("generic.phone"),
                      },
                      model: {
                        value: _vm.userFullMobilePhoneNumber,
                        callback: function ($$v) {
                          _vm.userFullMobilePhoneNumber = $$v
                        },
                        expression: "userFullMobilePhoneNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "base-list",
                  { staticClass: "ml-3 mb-4" },
                  [
                    _c("base-list-item", {
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "circle-chevron-right"],
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.trans.get(
                                    "generic.modals.notif_master_online_text_without_phone"
                                  )
                                ) + " :\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "pl-6" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatPhoneNumber")(
                          _vm.userFullMobilePhoneNumber,
                          "national"
                        )
                      ) +
                      "\n                "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "underline cursor-pointer",
                      on: { click: _vm.displayInputPhoneNumber },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trans.get(
                              "generic.modals.notif_master_online_change_phone_number"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _c("base-button", { on: { click: _vm.sendNotifMasterOnline } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans.get("generic.validate")) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }