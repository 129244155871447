<template>
    <div class="development">
        <div class="lg:flex">
            <div class="lg:flex-none p-4">
                <div class="mb-4 lg:mt-6 border-b border-neutral-100">
                    <base-text-input ref="searchInput" placeholder="Rechercher ..." v-model="filter" small>
                        <template #icon>
                            <font-awesome-icon :icon="['fal', 'magnifying-glass']"/>
                        </template>
                        <template #append>
                            <base-button v-if="hasSearchValue" icon small @click="clearSearch">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-neutral-300"/>
                            </base-button>
                        </template>
                    </base-text-input>
                </div>

                <div class="mb-4">
                    <base-title color="neutral" :level="2">Style</base-title>
                    <ul>
                        <development-menu-item route="development.style.text-and-typography"
                                               label="Text and typography"
                                               :filter="filter"/>
                    </ul>
                </div>

                <div class="mb-4">
                    <base-title color="neutral" :level="2">Base components</base-title>
                    <ul>
                        <development-menu-item route="development.alert" label="Alert" :filter="filter"/>
                        <development-menu-item route="development.audio-player" label="Audio Player" :filter="filter"/>
                        <development-menu-item route="development.avatar" label="Avatar" :filter="filter"/>
                        <development-menu-item route="development.badge" label="Badge" :filter="filter"/>
                        <development-menu-item route="development.bar" label="Bar" :filter="filter"/>
                        <development-menu-item route="development.button" label="Button" :filter="filter"/>
                        <development-menu-item route="development.checkbox" label="Checkbox" :filter="filter"/>
                        <development-menu-item route="development.code-input" label="Code Input" :filter="filter"/>
                        <development-menu-item route="development.console" label="Console" :filter="filter"/>
                        <development-menu-item route="development.icon" label="Icon" :filter="filter"/>
                        <development-menu-item route="development.link" label="Link" :filter="filter"/>
                        <development-menu-item route="development.list" label="List" :filter="filter"/>
                        <development-menu-item route="development.lottie-file-player" label="Lottie file Player" :filter="filter"/>
                        <development-menu-item route="development.menu" label="Menu" :filter="filter"/>
                        <development-menu-item route="development.modal" label="Modal" :filter="filter"/>
                        <development-menu-item route="development.phone-input" label="Phone Input" :filter="filter"/>
                        <development-menu-item route="development.radio-input" label="Radio Input" :filter="filter"/>
                        <development-menu-item route="development.rating" label="Rating" :filter="filter"/>
                        <development-menu-item route="development.select" label="Select" :filter="filter"/>
                        <development-menu-item route="development.separator" label="Separator" :filter="filter"/>
                        <development-menu-item route="development.slider" label="Slider" :filter="filter"/>
                        <development-menu-item route="development.text-input" label="Text Input" :filter="filter"/>
                        <development-menu-item route="development.textarea" label="Textarea" :filter="filter"/>
                        <development-menu-item route="development.timer" label="Timer" :filter="filter"/>
                        <development-menu-item route="development.title" label="Title" :filter="filter"/>
                        <development-menu-item route="development.tooltip" label="Tooltip" :filter="filter"/>
                        <development-menu-item route="development.webm-player" label="Webm Player" :filter="filter"/>
                    </ul>
                </div>

                <div class="mb-4">
                    <base-title color="neutral" :level="2">Game</base-title>
                    <ul>
                        <development-menu-item route="development.wheel" label="Wheel" :filter="filter"/>
                    </ul>
                </div>

                <div class="mb-4">
                    <base-title color="neutral" :level="2">List</base-title>
                    <ul>
                        <development-menu-item route="development.offer-list.banner"
                                               label="Banner"
                                               :filter="filter"/>
                        <development-menu-item route="development.offer-list.offer-card"
                                               label="Offer Card"
                                               :filter="filter"/>
                        <development-menu-item route="development.offer-list.offer-card-small"
                                               label="Offer Card Small"
                                               :filter="filter"/>
                    </ul>
                </div>
            </div>

            <div class="lg:flex-grow p-4">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.development {
    @apply relative max-w-screen-lg xl:max-w-screen-xl mx-auto pb-24;
}
</style>

<script>
import BaseButton from "../elements/BaseButton";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentLink from "./elements/DevelopmentLink";
import BaseTitle from "../elements/BaseTitle";
import DevelopmentMenuItem from "./elements/DevelopmentMenuItem";
import BaseTextInput from "../elements/BaseTextInput";

export default {
    name: "Development",
    components: {BaseTextInput, DevelopmentMenuItem, BaseTitle, DevelopmentLink, DevelopmentHeading, BaseButton},
    computed: {
        hasSearchValue() {
            return this.filter.trim().length > 0;
        },
    },
    methods: {
        clearSearch() {
            this.filter = '';
        },
    },
    data: function () {
        return {
            filter: '',
        }
    },
}
</script>
