var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "development",
    [
      _c("development-heading", [_vm._v("Lottie File Player")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "conversation-messages-list-container conversation-messages-list-container--background h-100",
        },
        [
          _c("base-lottie-file-player", {
            ref: "player",
            staticClass: "w-full h-full",
            attrs: {
              src: "https://lottie.host/4db68bbd-31f6-4cd8-84eb-189de081159a/IGmMCqhzpt.lottie",
              autoplay: _vm.autoplay,
              loop: _vm.loop,
            },
            on: {
              load: function ($event) {
                return _vm.addLog("load")
              },
              play: function ($event) {
                return _vm.addLog("play")
              },
              pause: function ($event) {
                return _vm.addLog("pause")
              },
              stop: function ($event) {
                return _vm.addLog("stop")
              },
              loop: function ($event) {
                return _vm.addLog("loop")
              },
              complete: function ($event) {
                return _vm.addLog("complete")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex gap-2 my-2" },
        [
          _c("base-button", { on: { click: _vm.play } }, [_vm._v("Play")]),
          _vm._v(" "),
          _c("base-button", { on: { click: _vm.pause } }, [_vm._v("Pause")]),
          _vm._v(" "),
          _c("base-button", { on: { click: _vm.stop } }, [_vm._v("Stop")]),
          _vm._v(" "),
          _c("base-button", { on: { click: _vm.init } }, [_vm._v("Init")]),
          _vm._v(" "),
          _c(
            "base-checkbox",
            {
              model: {
                value: _vm.autoplay,
                callback: function ($$v) {
                  _vm.autoplay = $$v
                },
                expression: "autoplay",
              },
            },
            [_vm._v("Autoplay")]
          ),
          _vm._v(" "),
          _c(
            "base-checkbox",
            {
              model: {
                value: _vm.loop,
                callback: function ($$v) {
                  _vm.loop = $$v
                },
                expression: "loop",
              },
            },
            [_vm._v("Loop")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _c("base-textarea", {
            staticClass: "w-full",
            attrs: {
              label: "Logs",
              placeholder: "Logs",
              rows: 8,
              disabled: "",
              outlined: "",
            },
            model: {
              value: _vm.logs,
              callback: function ($$v) {
                _vm.logs = $$v
              },
              expression: "logs",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_c("strong", [_vm._v("src")]), _vm._v(": String")]),
        _vm._v(" "),
        _c("li", [_c("strong", [_vm._v("loop")]), _vm._v(": Boolean")]),
        _vm._v(" "),
        _c("li", [_c("strong", [_vm._v("autoplay")]), _vm._v(": Boolean")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }