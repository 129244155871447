<template>
    <development>
        <div>
            <development-heading>Select</development-heading>
            <base-select :items="items_1"/>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>items</strong>: Array</li>
                <li><strong>outlined</strong>: Boolean</li>
            </ul>

            <development-heading>Outlined</development-heading>
            <base-select outlined :items="items_1"></base-select>

            <development-heading>Transparent</development-heading>
            <div class="bg-primary w-full p-4">
                <base-select transparent block :items="items_1"></base-select>
            </div>

            <development-heading>Placeholder</development-heading>
            <base-select outlined placeholder="Input with placeholder" :items="items_1"/>

            <development-heading>Label</development-heading>
            <base-select outlined label="Label" :items="items_1"/>

            <development-heading>Label + Placeholder</development-heading>
            <base-select :items="items_1" placeholder="Input with placeholder" label="Label" outlined/>

            <development-heading>Default and selected value</development-heading>
            <base-select v-model="activeItem" :items="items_1" placeholder="Input with placeholder" label="Label"
                         outlined/>
            <base-list class="pt-4">
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Value : </template>
                    <template #description>{{ activeItem }}</template>
                </base-list-item>
            </base-list>

            <development-heading>Block</development-heading>
            <base-select block outlined placeholder="Input with placeholder" label="Label" :items="items_1"/>

            <development-heading>Disabled</development-heading>
            <base-select placeholder="Input disabled" outlined disabled/>

            <development-heading>Error</development-heading>
            <base-select error="This is an error" outlined placeholder="Input with placeholder" label="Label"
                         :items="items_1"/>

            <development-heading>Array name/value</development-heading>
            <base-select v-model="activeItem2" outlined placeholder="Input with placeholder" label="Label"
                         :items="items_2"/>
            <base-list class="pt-4">
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Value : </template>
                    <template #description>{{ activeItem2 }}</template>
                </base-list-item>
            </base-list>

            <development-heading>Custom slots</development-heading>
            <base-select outlined v-model="activeCountry" :items="getCountries(['FR', 'BE', 'GB', 'US'])">
                <template v-slot:placeholder>
                    <span class="vti__flag inline-block"/>
                    <span>+</span>
                </template>

                <template v-slot:selected>
                    <span :class="activeCountry.iso2.toLowerCase()" class="vti__flag inline-block"/>
                    <span>{{ `+${activeCountry.dialCode}` }}</span>
                </template>

                <template v-slot:item="data">
                    <span :class="data.item.iso2.toLowerCase()" class="vti__flag inline-block"/>
                    <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
                </template>
            </base-select>

            <base-list class="pt-4">
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Iso : </template>
                    <template #description>{{ activeCountry.iso2 }}</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Name : </template>
                    <template #description>{{ activeCountry.name }}</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Dial Code : </template>
                    <template #description>{{ activeCountry.dialCode }}</template>
                </base-list-item>
            </base-list>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseSelect from "../elements/BaseSelect";
import allCountries from "../../all-countries";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    name: "DevelopmentSelect",
    components: {BaseListItem, BaseList, DevelopmentHeading, Development, BaseSelect},
    methods: {
        getCountries(list = []) {
            if (list.length > 0) {
                return list
                    .map(countryCode => this.findCountry(countryCode))
                    .filter(Boolean);
            }
            return allCountries;
        },
        findCountry(iso = '') {
            return allCountries.find(
                country => country.iso2 === iso.toUpperCase(),
            );
        },
    },
    data() {
        return {
            items_1: ['Chocolat', 'Banane', 'Orange', 'Pomme'],
            activeItem: 'Banane',
            items_2: [
                {name: 'Chocolat', value: 1},
                {name: 'Banane', value: 2},
                {name: 'Orange', value: 3},
                {name: 'Pomme', value: 4},
            ],
            activeItem2: null,
            activeCountry: {
                iso2: '',
                name: '',
                dialCode: ''
            },
        }
    }
}
</script>

<style scoped>

</style>
