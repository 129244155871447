import ChatMixin from '../chat/ChatMixin';
import * as PusherPushNotifications from '@pusher/push-notifications-web';


export default {
    namespaced: true,

    state: {},

    actions: {
        connect({commit, dispatch}, {user_infos, chat_live_session, chat_selected_conversation, chat_follow_up_mode}) {
            dispatch('user/loadUserInfos', JSON.parse(user_infos), {root: true});
            dispatch('chat/setLiveSession', JSON.parse(chat_live_session), {root: true});
            if (chat_selected_conversation) {
                dispatch('chat/setSelectedConversation', {conversation: JSON.parse(chat_selected_conversation)},
                    {root: true});
            }
            if (chat_follow_up_mode) {
                commit('chat/setFollowUpMode', true, {root: true});
            }

            if (ChatMixin.methods.meFromUserStore().id !== null && ChatMixin.methods.meFromUserStore().id !== undefined) {
                if (chat_live_session != "[]") {
                    dispatch('payment/loadPaymentInfo', null, {root: true});
                }
                dispatch('chat/listenForLiveChatStartedEvent', null, {root: true});
                dispatch('chat/listenForLiveChatStoppedEvent', null, {root: true});
                dispatch('offer/listenForOfferStatusChangedEvent', null, {root: true});
                dispatch('user/listenForUserUpdatedEvent', null, {root: true});
                dispatch('user/listenForMasterMissedCallEvent', null, {root: true});
            } else {
                dispatch('modals/initEmailCatcher', null, {root: true});
            }
        },
        disconnect({dispatch}) {
            dispatch('chat/disconnect', null, {root: true});
            dispatch('payment/disconnect', null, {root: true});
            dispatch('user/disconnect', null, {root: true});
        },
        beamClientLog() {
            const beamsClient = new PusherPushNotifications.Client({
                instanceId: process.env.MIX_BEAM_INSTANCE_ID,
            });
            const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                url: "/pusher/beams-auth",
            });

            beamsClient
                .start()
                .then((beamsClient) => beamsClient.getDeviceId())
                .then(() => beamsClient.addDeviceInterest("main"))
                .then(() => beamsClient.getDeviceInterests())
                .then(() => beamsClient.setUserId(ChatMixin.methods.meFromUserStore().id.toString(), beamsTokenProvider));
        }
    }
}
