<template>
    <h1 class="text-2xl mt-8 mb-2 font-bold border-b border-neutral-100" v-if="level === 1">
        <slot></slot>
    </h1>
    <h2 class="text-xl mt-8 mb-2 font-bold border-b border-neutral-100" v-else-if="level === 2">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: "DevelopmentHeading",
    props: {
        level: {
            type: Number,
            default: 1,
            validator: function (value) {
                return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1
            }
        }
    }
};
</script>
