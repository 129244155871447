<template>
    <div class="modal-follow-up-to-one-header">
        <div class="flex-grow">
            <base-text-input type="text"
                             :placeholder="trans.get('generic.modals.follow_up_to_one_search_placeholder')"
                             small
                             outlined
                             @input="$emit('input', $event)">
                <template #icon><font-awesome-icon :icon="['fal', 'magnifying-glass']"/></template>
            </base-text-input>
        </div>
        <base-button small color="light" @click="$emit('back')">
            {{ trans.get('generic.cancel') }}
        </base-button>
    </div>
</template>


<style lang="scss">
.modal-follow-up-to-one-header {
    @apply flex w-full items-center p-4;
}
</style>

<script>
import BaseTextInput from "../elements/BaseTextInput";
import BaseButton from "../elements/BaseButton";

export default {
    name: "FollowUpToOneSearchBar",
    components: {BaseTextInput, BaseButton}
}
</script>
