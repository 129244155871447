var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reset-this leading-normal" },
    [
      _c(
        "base-menu",
        {
          ref: "messageMenu",
          attrs: { loading: _vm.isLoading, "drop-left": "", scrollable: "" },
          on: { open: _vm.openMenu },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center font-bold text-primary text-lg py-3",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("chat.header.my_messages")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "items",
              fn: function () {
                return [
                  _vm.conversations.length > 0
                    ? _c(
                        "div",
                        { staticClass: "divide-y divide-neutral-300 max-w-96" },
                        [
                          _vm._l(_vm.conversations, function (conversation) {
                            return [
                              _c(
                                "conversation-item",
                                _vm._b(
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectConversation(
                                          conversation
                                        )
                                      },
                                    },
                                  },
                                  "conversation-item",
                                  _vm.getConversationItemProps(conversation),
                                  false
                                )
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-3 max-w-96 text-neutral-800 text-left",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.trans.get("chat.header.no_messages")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "p-3" },
                    [
                      !_vm.containsOnlyDigitalArtMessage
                        ? _c(
                            "base-button",
                            {
                              attrs: { block: "" },
                              on: { click: _vm.openChat },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.trans.get("chat.header.see_all_chat")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _c(
                            "base-button",
                            {
                              attrs: { block: "" },
                              on: { click: _vm.openDigitalMailBox },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.trans.get("chat.header.see_all_chat")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticStyle: { "line-height": "55px" } },
            [
              _c(
                "base-button",
                {
                  staticClass: "messages-button messages-button--desktop",
                  attrs: { small: "", color: "light" },
                },
                [
                  _vm.isOpen()
                    ? _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "envelope-open-text"] },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "envelope"] },
                      }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("chat.header.messages")) +
                      "\n                "
                  ),
                  _vm.newConversationsCount > 0
                    ? _c("base-pin", {
                        attrs: { amount: _vm.newConversationsCount },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  staticClass: "messages-button messages-button--mobile",
                  attrs: { small: "", color: "light" },
                },
                [
                  _vm.isOpen()
                    ? _c("font-awesome-icon", {
                        attrs: {
                          icon: ["fas", "envelope-open-text"],
                          size: "lg",
                        },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "envelope"], size: "lg" },
                      }),
                  _vm._v(" "),
                  _vm.newConversationsCount > 0
                    ? _c("base-pin", {
                        attrs: { amount: _vm.newConversationsCount },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }