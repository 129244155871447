<template>
    <div :class="classes" @mouseover="handleTooltipPosition" ref="base-tooltip">{{text}}
        <div v-if="icon" class="inline-block">
            <font-awesome-icon :icon="icon"/>
        </div>
        <div :class="classesPosition" ref="tooltip-text">
            <div v-if="icon" class="px-1">
                <font-awesome-icon :icon="icon"/>
            </div>
            <div class="text-left text-black">
                {{textTooltip}}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.base-tooltip {
    position: relative;
    display: inline-block;

    &--color {
        &-info {
            @apply text-primary;
        }

        &-success {
            @apply text-success;
        }

        &-warning {
            @apply text-warning;
        }

        &-danger {
            @apply text-danger;
        }
    }

    .tooltip-text {
        visibility: hidden;
        width: 300px;
        background: white;
        border-radius: 6px;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;

        &-top {
            top: 0;
            transform: translate(-50%, -100%);
            left: 50%;
        }

        &-right {
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
        }

        &-left {
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);
        }

        &-bottom {
            bottom: 0;
            transform: translate(-50%, 100%);
            left: 50%;
        }

    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}
</style>

<script>
export default {
    name: "BaseTooltip",
    props: {
        text: String,
        textTooltip: String,
        position : {
            type: String,
            validator: function (value) {
                return ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1
            }
        },
        icon : Array,
        type: {
            type: String,
            validator: function (value) {
                return ['info', 'success', 'warning', 'danger'].indexOf(value) !== -1
            }
        },
    },
    computed: {
        classes() {
            return {
                'base-tooltip': true,
                'base-tooltip--color-info': this.type === 'info' || this.color === 'info',
                'base-tooltip--color-success': this.type === 'success' || this.color === 'success',
                'base-tooltip--color-warning': this.type === 'warning' || this.color === 'warning',
                'base-tooltip--color-danger': this.type === 'danger' || this.color === 'danger',
            }
        },
        classesPosition() {
            return {
                'tooltip-text' : true,
                'flex' : true,
                'p-2' : true,
                'absolute' : true,
                'text-xs' : true,
                'text-center' : true,
                'filter' : true,
                'drop-shadow-md' : true,
                'tooltip-text-top': this.position === 'top',
                'tooltip-text-bottom': this.position === 'bottom',
                'tooltip-text-left': this.position === 'left',
                'tooltip-text-right': this.position === 'right',
            }
        },
    },
    methods : {
        handleTooltipPosition() {
            this.tooltip = this.$refs["tooltip-text"];
            this.placeholder = this.$refs["base-tooltip"];

            const tooltipRect = this.tooltip.getBoundingClientRect();
            const placeholderRect = this.placeholder.getBoundingClientRect();

            const tooltipRightX = tooltipRect.x + tooltipRect.width;
            const placeholderRightX = placeholderRect.x + placeholderRect.width;

            if (tooltipRect.x < 0) {
                this.tooltip.style.left = '0';
                this.tooltip.style.right = 'auto';
                let y = '100%';
                if (['left', 'right'].find((element) => element === this.position)) {
                    y = '50%';
                } else if (this.position === 'top') {
                    y = '-100%';
                }
                this.tooltip.style.transform = `translate(${-placeholderRect.x}px, ${y})`;
            } else if (tooltipRightX > window.outerWidth) {
                this.tooltip.style.left = 'auto';
                this.tooltip.style.right = '0';
                let y = '100%';
                if (['left', 'right'].find((element) => element === this.position)) {
                    y = '50%';
                } else if (this.position === 'top') {
                    y = '-100%';
                }
                this.tooltip.style.transform = `translate(${window.outerWidth - placeholderRightX}px, ${y})`;
            }
        }
    }
}
</script>
