export default {
    install(Vue, options) {
        Vue.prototype.urlManager = {
            isUrl: function (string) {
                let url;

                try {
                    url = new URL(string);
                } catch (_) {
                    return false;
                }

                return url.protocol === "http:" || url.protocol === "https:";
            },
        };
    }
}
