Vue.component('base-modal', require('./BaseModal.vue').default);
Vue.component('modal-password-reset', require('./ModalPasswordReset.vue').default);
Vue.component('modal-password-reset-invalid-token', require('./ModalPasswordResetInvalidToken.vue').default);
Vue.component('modal-password-lost', require('./ModalPasswordLost.vue').default);
Vue.component('modal-chat-master-confirmation-to-start-live-chat', require('./ModalChatMasterConfirmationToStartLiveChat.vue').default);
Vue.component('modal-chat-customer-info-master-refused-live-chat', require('./ModalChatCustomerInfoMasterRefusedLiveChat.vue').default);
Vue.component('modal-chat-customer-confirmation-end-chat-session', require('./ModalChatConfirmationEndChatClient.vue').default);
Vue.component('modal-chat-master-end-chat', require('./ModalChatMasterEndChat.vue').default);
Vue.component('modal-chat-customer-end-chat', require('./ModalChatCustomerEndChat.vue').default);
Vue.component('modal-chat-customer-notif-master-online', require('./ModalChatCustomerNotifMasterOnline.vue').default);
Vue.component('modal-chat-add-comment', require('./ModalChatAddComment.vue').default);
Vue.component('modal-chat-confirmation-for-prime-free-live-chat', require('./ModalChatConfirmationForPrimeFreeLiveChat.vue').default);
Vue.component('modal-chat-report-problem', require('./ModalChatReportProblem.vue').default);
Vue.component('modal-follow-up-type-choice', require('./ModalFollowUpTypeChoice.vue').default);
Vue.component('modal-follow-up-to-all', require('./ModalFollowUpToAll.vue').default);
Vue.component('modal-follow-up-to-one', require('./ModalFollowUpToOne.vue').default);
Vue.component('modal-message', require('./ModalMessage.vue').default);
Vue.component('modal-image', require('./ModalImage.vue').default);
Vue.component('modal-chat', require('../chat/modals/ModalChat.vue').default);
Vue.component('modal-user-phone-verification', require('./ModalUserPhoneVerification.vue').default);
Vue.component('modal-user-phone-verification-code', require('./ModalUserPhoneVerificationCode.vue').default);
Vue.component('modal-user-phone-verification-already-used', require('./ModalUserPhoneVerificationAlreadyUsed.vue').default);
Vue.component('modal-chat-master-connected', require('../chat/modals/ModalChatMasterConnected.vue').default);
Vue.component('modal-master-offline-at-the-moment', require('./ModalMasterOfflineAtTheMoment.vue').default);
Vue.component('modal-master-is-no-longer-available', require('./ModalMasterIsNoLongerAvailable.vue').default);
Vue.component('modal-generic-error', require('./ModalGenericError.vue').default);
Vue.component('modal-chat-recharge-live', require('./ModalChatRechargeLive.vue').default);
Vue.component('modal-promo-code-gift', require('./ModalPromoCodeGift.vue').default);
Vue.component('modal-email-catcher', require('./ModalEmailCatcher.vue').default);
Vue.component('modal-game-first-day', require('../game/modals/ModalGameFirstDay.vue').default);
Vue.component('modal-recall-customer', require('./ModalRecallCustomer.vue').default);
Vue.component('modal-authorize-microphone', require('./ModalAuthorizeMicrophone.vue').default);
Vue.component('modal-conversation-report', require('./ModalConversationReport.vue').default);
Vue.component('modal-payment-processing', require('../payment/modals/ModalPaymentProcessing').default);
Vue.component('modal-payment-options', require('../payment/modals/ModalPaymentOptions').default);
Vue.component('modal-pack-options', require('../payment/modals/ModalPackOptions').default);
Vue.component('modal-birthday-coupon', require('./ModalBirthdayCoupon.vue').default);
Vue.component('modal-whatsapp-catcher', require('./ModalWhatsappCatcher').default);
Vue.component('modal-master-user-notes', require('./ModalMasterUserNotes').default);
Vue.component('modal-failed-pay-per-use-payment', require('./ModalFailedPayPerUsePayment.vue').default);
