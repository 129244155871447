var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "text-primary text-2xl font-bold my-4" }, [
      _vm._v(_vm._s(_vm.trans.get("contact.page_title"))),
    ]),
    _vm._v(" "),
    _vm.postFormSucceed
      ? _c("div", [
          _c("span", { staticClass: "text-success" }, [
            _vm._v(_vm._s(_vm.trans.get("contact.form_sent_successfully"))),
          ]),
        ])
      : _c("div", [
          _c(
            "form",
            { attrs: { id: "contact_form" }, on: { submit: _vm.postForm } },
            [
              _c("base-paragraph", [
                _vm._v(_vm._s(_vm.trans.get("contact.before_form_paragraph"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-text-input", {
                    attrs: {
                      type: "text",
                      autofocus: "",
                      outlined: "",
                      label: _vm.trans.get("generic.your_name"),
                      placeholder: _vm.trans.get("generic.your_name"),
                      error: _vm.errors.name,
                    },
                    on: {
                      keydown: function ($event) {
                        return _vm.clearError("name")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "user"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-text-input", {
                    attrs: {
                      type: "email",
                      outlined: "",
                      label: _vm.trans.get("generic.your_email"),
                      placeholder: _vm.trans.get("generic.your_email"),
                      error: _vm.errors.email,
                    },
                    on: {
                      keydown: function ($event) {
                        return _vm.clearError("email")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fal", "envelope"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-text-input", {
                    attrs: {
                      outlined: "",
                      label: _vm.trans.get("generic.your_phone_number"),
                      placeholder: _vm.trans.get("generic.your_phone_number"),
                      error: _vm.errors.phone,
                    },
                    on: {
                      keydown: function ($event) {
                        return _vm.clearError("phone")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "mobile-screen-button"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-select", {
                    attrs: {
                      label: _vm.trans.get("generic.your_reason"),
                      placeholder: _vm.trans.get("generic.your_reason"),
                      items: _vm.reasonItems,
                      error: _vm.errors.reason,
                      block: "",
                      outlined: "",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.clearError("reason")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "circle-exclamation"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-textarea", {
                    attrs: {
                      label: this.trans.get("generic.your_message"),
                      placeholder: this.trans.get("generic.your_message"),
                      rows: 7,
                      error: _vm.errors.message,
                      "icon-top": "",
                      outlined: "",
                    },
                    on: {
                      keydown: function ($event) {
                        return _vm.clearError("message")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fal", "message-lines"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.message,
                      callback: function ($$v) {
                        _vm.message = $$v
                      },
                      expression: "message",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("base-recaptcha", {
                    attrs: {
                      siteKey: this.config.get(
                        "spam_protection.recaptcha.sitekey"
                      ),
                      error: _vm.errors.captcha,
                    },
                    on: {
                      recaptchaValidate: function ($event) {
                        return _vm.clearError("captcha")
                      },
                    },
                    model: {
                      value: _vm.captcha,
                      callback: function ($$v) {
                        _vm.captcha = $$v
                      },
                      expression: "captcha",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-button",
                { attrs: { type: "submit", loading: _vm.formLoading } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("generic.send")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }