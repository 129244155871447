var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Text input")]),
        _vm._v(" "),
        _c("base-text-input"),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("type")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'text', 'email', 'password', 'phone_number'")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autofocus")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("autocomplete")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("error")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("label")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("placeholder")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("x-small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("value")]),
            _vm._v(": String|Number"),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Placeholder")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: { outlined: "", placeholder: "Input with placeholder" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label")]),
        _vm._v(" "),
        _c("base-text-input", { attrs: { outlined: "", label: "Label" } }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label + Placeholder")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            outlined: "",
            label: "Focus me",
            placeholder: "Placeholder",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("outlined")]),
          _vm._v(" input"),
        ]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: { outlined: "", placeholder: "Outlined input" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("disabled")]),
          _vm._v(" input"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-text-input", {
              attrs: { disabled: "", placeholder: "Disabled input" },
            }),
            _vm._v(" "),
            _c("base-text-input", {
              attrs: { disabled: "", placeholder: "Disabled input" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "envelope"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("With icon")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            label: "Input with icon",
            placeholder: "Input with icon",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            large: "",
            label: "Large input with icon",
            placeholder: "Large input",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            small: "",
            label: "Small input with icon",
            placeholder: "Small input",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("X-small")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            "x-small": "",
            label: "X-small input with icon",
            placeholder: "X-small input",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Error")]),
        _vm._v(" "),
        _c("base-text-input", {
          attrs: {
            error: "This is an error",
            outlined: "",
            label: "Focus me",
            placeholder: "Placeholder",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Prepend")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-text-input", {
              attrs: {
                label: "Focus me",
                placeholder: "Placeholder",
                outlined: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend",
                  fn: function () {
                    return [
                      _c("base-select", {
                        attrs: {
                          value: "Item 1",
                          items: ["Item 1", "Item 2", "Item 3"],
                          transparent: "",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-text-input", {
              attrs: {
                label: "Focus me",
                placeholder: "Placeholder",
                outlined: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend",
                  fn: function () {
                    return [_c("span", { staticClass: "px-4" }, [_vm._v("$")])]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Append")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-text-input", {
              attrs: {
                label: "Focus me",
                placeholder: "Placeholder",
                outlined: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("base-select", {
                        attrs: {
                          value: "Item 1",
                          items: ["Item 1", "Item 2", "Item 3"],
                          transparent: "",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-text-input", {
              attrs: {
                label: "Focus me",
                placeholder: "Placeholder",
                outlined: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [_c("span", { staticClass: "px-4" }, [_vm._v("€")])]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Example")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-text-input", {
              ref: "searchInput",
              staticClass: "conversation-search-input",
              attrs: {
                type: "text",
                placeholder: "Rechercher",
                outlined: "",
                small: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      !_vm.hasSearchValue
                        ? _c("font-awesome-icon", {
                            attrs: { icon: ["fal", "magnifying-glass"] },
                          })
                        : _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "spinner"], spin: "" },
                          }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _vm.hasSearchValue
                        ? _c(
                            "base-button",
                            {
                              attrs: { icon: "", small: "" },
                              on: { click: _vm.clearSearch },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "text-neutral-300",
                                attrs: { icon: ["fas", "circle-xmark"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.searchValue,
                callback: function ($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue",
              },
            }),
            _vm._v(" "),
            _vm.hasSearchValue
              ? _c("base-alert", { attrs: { type: "info" } }, [
                  _vm._v("Recherche en cours : " + _vm._s(_vm.searchValue)),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }