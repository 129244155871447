<template>
    <development>
        <div>
            <development-heading>List</development-heading>
            <base-list>
                <base-list-item>Item 1</base-list-item>
                <base-list-item>Item 2</base-list-item>
                <base-list-item>Item 3</base-list-item>
                <base-list-item>Item 4</base-list-item>
            </base-list>

            <development-heading>List with title and description</development-heading>
            <base-list>
                <base-list-item>
                    <template #title>Title 1 :</template>
                    <template #description>Item 1</template>
                </base-list-item>
                <base-list-item>
                    <template #title>Title 2 :</template>
                    <template #description>Item 2</template>
                </base-list-item>
                <base-list-item>
                    <template #title>Title 3 :</template>
                    <template #description>Item 3</template>
                </base-list-item>
                <base-list-item>
                    <template #title>Title 4 :</template>
                    <template #description>Item 4</template>
                </base-list-item>
            </base-list>

            <development-heading>List with icon, title and description</development-heading>
            <base-list>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Aliquet quam :</template>
                    <template #description>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed
                        magna dictum porta</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Velit nisi :</template>
                    <template #description>Dictum porta. Quisque velit nisi, pretium ut lacinia in, elementum id
                        enim</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Sapien massa :</template>
                    <template #description>Praesent sapien massa, convallis a pellentesque nec</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Sit amet ligula :</template>
                    <template #description>Ullamcorper sit amet ligula</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Dictum porta quisque velit nisi pretium ut lacinia in elementum id enim
                        :</template>
                    <template #description>Curabitur aliquet quam id dui posuere blandit</template>
                </base-list-item>
            </base-list>

            <development-heading>Items - Array</development-heading>
            <base-list :items="list_1"/>

            <development-heading>Items - Object</development-heading>
            <base-list :items="list_2"/>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import DevelopmentLink from "./elements/DevelopmentLink";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    components: {BaseListItem, BaseList, DevelopmentLink, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            list_1: ['item 1', 'item 2', 'item 3', 'item 4'],
            list_2: [
                {icon: ['fas', 'circle-chevron-right'], title: "Title 1 : ", description: "description 1"},
                {icon: ['fas', 'circle-chevron-right'], title: "Title 2 : ", description: "description 2"},
                {icon: ['fas', 'circle-chevron-right'], title: "Title 3 : ", description: "description 3"},
                {icon: ['fas', 'circle-chevron-right'], title: "Title 4 : ", description: "description 4"},
            ],
        }
    }
}
</script>
