import {makeEchoInstance} from '../../utilities/echo-factory';
import ChatMixin from "../chat/ChatMixin";

export default {
    namespaced: true,

    state: {},
    mutations: {},
    getters: {},
    actions: {
        listenForOfferStatusChangedEvent({dispatch})
        {
            let echo = makeEchoInstance();

            let offerChannelName = 'offer_' + window.service_id;
            echo.channel(offerChannelName).listen('MasterStatusChanged', e => {
                dispatch('chat/updateMasterStatus', {status: e.master.status, user_id: e.master.user_id}, {root: true});
                if (e.master.user_id === ChatMixin.methods.meFromUserStore().id) {
                    dispatch('updateHeaderStatus', e.master.status);
                }
            });

            echo.channel(offerChannelName).listen('Offer.OfferStatusUpdated', e => {
                dispatch('chat/updateOfferStatus', {status_details: e.resource}, {root: true});
            });
        },
        updateHeaderStatus({}, status)
        {
            let mobileStatusBox = jQuery('#mobile_statut_box').find('span').first(),
                statusBox = jQuery('#statut_box').find('span').first();

            if (mobileStatusBox.length) {
                mobileStatusBox.removeClass().
                                addClass('labels').
                                addClass(status).
                                text(jQuery('#mobile_statut_box').find('li.' + status).first().text());
            }
            if (statusBox.length) {
                statusBox.removeClass().
                          addClass('labels').
                          addClass(status).
                          text(jQuery('#statut_box').find('li.' + status).first().text());
            }
        },
    },
};
