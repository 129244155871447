var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    { tag: "component" },
    [
      _vm._t("default", null, {
        formattedTimer: _vm.formattedTimer,
        years: _vm.years,
        months: _vm.months,
        days: _vm.days,
        hours: _vm.hours,
        minutes: _vm.minutes,
        seconds: _vm.seconds,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }