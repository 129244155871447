var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", { class: _vm.textClass }, [
    _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "conversation-message-content-text--status" },
      [
        _c("conversation-message-metadata", {
          attrs: { status: _vm.messageStatus },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }