<template>
    <div class="bg-white">
        <base-timer ref="billing_timer_left"
                    :time="getTimeLeft()"
                    :threshold-events="thresholdEvents"
                    countdown
                    auto-start/>

        <div class="shadow">
            <div class="flex w-full items-center">
                <base-button link font-normal normal-case class="h-12 ml-4" @click="toggleStickersMenu(false)">
                    <font-awesome-icon :icon="['fal', 'xmark']" size="2x" class="mr-2"/>
                    {{ trans.get('generic.chat_close_conversation_informations') }}
                </base-button>
            </div>

            <template v-if="!this.meFromUserStore().is_master">
                <base-bar v-if="!stickersDisabled" color="pack-1" x-small>
                    <span v-html="trans.get('chat.surprise_your_advisor_with_a_gift')" class="font-semibold uppercase"/>
                </base-bar>

                <base-bar v-else color="warning" x-small>
                    <span v-html="trans.get('chat.top_up_to_offer_gift_again')" class="font-semibold uppercase"/>
                </base-bar>
            </template>
        </div>

        <div class="flex">
            <div class="sticker-list-container">
                <div class="sticker-list">
                    <template v-for="sticker in getStickers">
                        <conversation-sticker :ref="'sticker-' + sticker.id" :key="sticker.id" :sticker="sticker"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.sticker-list-container {
    @apply h-80 p-2 relative overflow-y-scroll;

    .sticker-list {
        @apply grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8;
    }
}
</style>

<script>
import BaseBar from "../elements/BaseBar.vue";
import BaseTimer from "../elements/BaseTimer.vue";
import ChatMixin from "./ChatMixin";
import ConversationSticker from "./ConversationSticker.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ConversationStickersMenu',
    components: {BaseBar, BaseTimer, ConversationSticker},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', ['getStickers', 'getChatLiveSession']),
    },
    methods: {
        ...mapGetters('chat', ['hasLiveSessionBillingStarted', 'getLiveSessionMaxBillingTime']),
        ...mapActions('chat', ['toggleStickersMenu']),
        getTimeLeft() {
            return Math.abs(new Date(this.getChatLiveSession.max_end_at) - new Date());
        },
        setThresholdEvents() {
            this.getStickers.forEach((sticker) => {
                this.thresholdEvents[sticker.id] = {
                    time: sticker.max_seconds_threshold * 1000,
                    callback: () => {
                        this.$refs['sticker-' + sticker.id][0].toggle(false);
                        this.disableStickers();
                    }
                };
            });
        },
        disableStickers() {
            let stickersDisabled = true;

            this.getStickers.forEach((sticker) => {
                if (this.getTimeLeft() > sticker.max_seconds_threshold * 1000) stickersDisabled = false;
            });

            this.stickersDisabled = stickersDisabled;
        }
    },
    created() {
        this.unwatchMaxBillingTime = this.$store.watch(this.getLiveSessionMaxBillingTime, () => {
            if (this.hasLiveSessionBillingStarted()) {
                this.$nextTick(() => {
                    this.$refs.billing_timer_left.setTime(this.getTimeLeft());
                    this.getStickers.forEach((sticker) => {
                        let enabled = this.getTimeLeft() > sticker.max_seconds_threshold * 1000;
                        this.$refs['sticker-' + sticker.id][0].toggle(enabled);
                    });
                    this.disableStickers();
                    this.$refs.billing_timer_left.resetThresholdEvents();
                });
            }
        });

        this.setThresholdEvents();
        this.disableStickers();
    },
    beforeDestroy() {
        this.thresholdEvents = {};
        this.unwatchMaxBillingTime();
    },
    data() {
        return {
            unwatchMaxBillingTime: null,
            thresholdEvents: {},
            stickersDisabled: false,
        }
    },
}
</script>
