<template>
    <development>
        <div>
            <development-heading>Icon</development-heading>
            <development-paragraph>All icons are from <strong>Font Awesome</strong> and we are using <strong>vue-fontawesome</strong>
                as a component.
            </development-paragraph>

            <development-heading>Documentation :</development-heading>
            <development-paragraph><strong>fontawesome</strong> :
                <development-link
                    href="https://fontawesome.com/"
                    target="_blank">
                    https://fontawesome.com/
                </development-link>
            </development-paragraph>
            <development-paragraph><strong>vue-fontawesome</strong> :
                <development-link
                    href="https://github.com/FortAwesome/vue-fontawesome"
                    target="_blank"> https://github.com/FortAwesome/vue-fontawesome
                </development-link>
            </development-paragraph>

            <development-heading>List of icons used :</development-heading>
            <div class="w-full md:w-96 mb-4">
                <base-text-input ref="searchInput"
                                 class="conversation-search-input"
                                 type="text"
                                 placeholder="Search icon ..."
                                 v-model="search"
                                 outlined
                                 small>
                    <template #icon>
                        <font-awesome-icon :icon="['fal', 'magnifying-glass']"/>
                    </template>
                    <template #append>
                        <base-button v-if="hasSearchValue" icon small @click="clearSearch">
                            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-neutral-300"/>
                        </base-button>
                    </template>
                </base-text-input>
            </div>

            <ul>
                <li v-for="icon in filteredList">
                    <span class="inline-block w-4"><font-awesome-icon :icon="icon"/></span>
                    &lt;font-awesome-icon :icon="['{{ icon[0] }}', '{{ icon[1] }}']"&gt;&lt;/font-awesome-icon&gt;
                </li>
            </ul>

            <development-heading>Color</development-heading>
            <font-awesome-icon size="2x" :icon="['fas', 'trophy-star']" class="text-star"/>
            <font-awesome-icon size="2x" :icon="['fas', 'trophy']" class="text-neutral-400"/>
            <font-awesome-icon size="2x" :icon="['fas', 'gem']" class="text-dark"/>
            <font-awesome-icon size="2x" :icon="['fas', 'user']" class="text-primary"/>
            <font-awesome-icon size="2x" :icon="['fas', 'face-smile-plus']" class="text-primary"/>

            <development-heading>Spin</development-heading>
            <font-awesome-icon :icon="['fas', 'spinner']" size="2x" spin/>

            <development-heading>Size</development-heading>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="xs"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="lg"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="3x"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="6x"/>

            <development-heading>Rotate</development-heading>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" rotation="90"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" rotation="180"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" rotation="270"/>

            <development-heading>Flip</development-heading>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" flip="horizontal"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" flip="vertical"/>
            <font-awesome-icon :icon="['fal', 'paper-plane']" size="2x" flip="both"/>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import DevelopmentLink from "./elements/DevelopmentLink";
import {faUserClock as fasUserClock} from "@fortawesome/pro-solid-svg-icons";

export default {
    components: {DevelopmentLink, DevelopmentParagraph, DevelopmentHeading, Development},
    computed: {
        filteredList() {
            return this.icons.filter(icon => {
                return icon[0].toLowerCase().includes(this.search.toLowerCase()) || icon[1].toLowerCase().includes(this.search.toLowerCase());
            })
        },
        hasSearchValue() {
            return this.search.trim().length > 0;
        },
    },
    methods: {
        clearSearch() {
            this.search = '';
        },
    },
    data: function () {
        return {
            search: '',
            icons: [
                ['fab', 'apple'],
                ['fab', 'facebook-f'],
                ['fab', 'instagram'],
                ['fab', 'linkedin-in'],
                ['fab', 'tiktok'],
                ['fab', 'twitter'],
                ['fab', 'youtube'],
                ['fal', 'ban'],
                ['fal', 'browser'],
                ['fal', 'calendar-star'],
                ['fal', 'camera'],
                ['fal', 'chevron-down'],
                ['fal', 'chevron-left'],
                ['fal', 'chevron-right'],
                ['fal', 'circle-chevron-right'],
                ['fal', 'circle-exclamation'],
                ['fal', 'circle-info'],
                ['fal', 'circle-question'],
                ['fal', 'circle-user'],
                ['fal', 'circle-xmark'],
                ['fal', 'ellipsis-vertical'],
                ['fal', 'envelope'],
                ['fal', 'envelope-circle-check'],
                ['fal', 'face-sad-tear'],
                ['fal', 'face-smile'],
                ['fal', 'hashtag'],
                ['fal', 'id-card'],
                ['fal', 'link'],
                ['fal', 'magnifying-glass'],
                ['fal', 'message-lines'],
                ['fal', 'microphone-lines'],
                ['fal', 'newspaper'],
                ['fal', 'paper-plane'],
                ['fal', 'pen-to-square'],
                ['fal', 'star'],
                ['fal', 'trash-can'],
                ['fal', 'xmark'],
                ['far', 'bell'],
                ['far', 'cake-candles'],
                ['far', 'check'],
                ['far', 'check-double'],
                ['far', 'circle'],
                ['far', 'circle-check'],
                ['far', 'circle-exclamation'],
                ['far', 'circle-xmark'],
                ['far', 'ellipsis-vertical'],
                ['far', 'face-frown'],
                ['far', 'gift'],
                ['far', 'heart'],
                ['far', 'image'],
                ['far', 'message-lines'],
                ['far', 'message-xmark'],
                ['far', 'messages'],
                ['far', 'mobile-screen-button'],
                ['far', 'phone'],
                ['far', 'xmark'],
                ['fas', 'bell'],
                ['fas', 'camera'],
                ['fas', 'caret-down'],
                ['fas', 'caret-up'],
                ['fas', 'chevron-down'],
                ['fas', 'circle-check'],
                ['fas', 'circle-chevron-right'],
                ['fas', 'circle-exclamation'],
                ['fas', 'circle-heart'],
                ['fas', 'circle-info'],
                ['fas', 'circle-phone-flip'],
                ['fas', 'circle-plus'],
                ['fas', 'circle-question'],
                ['fas', 'circle-xmark'],
                ['fas', 'ellipsis-vertical'],
                ['fas', 'envelope'],
                ['fas', 'envelope-open-text'],
                ['fas', 'exclamation'],
                ['fas', 'face-smile-plus'],
                ['fas', 'gem'],
                ['fas', 'gift'],
                ['fas', 'heart'],
                ['fas', 'lock'],
                ['fas', 'message'],
                ['fas', 'message-lines'],
                ['fas', 'message-slash'],
                ['fas', 'note-sticky'],
                ['fas', 'paper-plane'],
                ['fas', 'pause'],
                ['fas', 'phone'],
                ['fas', 'phone-slash'],
                ['fas', 'phone-volume'],
                ['fas', 'play'],
                ['fas', 'rotate-right'],
                ['fas', 'share-nodes'],
                ['fas', 'spinner'],
                ['fas', 'square'],
                ['fas', 'star'],
                ['fas', 'stars'],
                ['fas', 'triangle-exclamation'],
                ['fas', 'trophy'],
                ['fas', 'trophy-star'],
                ['fas', 'user'],
                ['fas', 'user-check'],
                ['fas', 'user-clock'],
                ['fas', 'user-hair'],
                ['fas', 'user-hair-long'],
                ['fas', 'users'],
            ],
        };
    },
}
</script>
