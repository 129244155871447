<template>
    <development>
        <div>
            <development-heading>Menu</development-heading>
            <base-menu>
                <base-button>Dropdown</base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>dropLeft</strong>: Boolean</li>
                <li><strong>dropTop</strong>: Boolean</li>
                <li><strong>loading</strong>: Boolean</li>
                <li><strong>open</strong>: Boolean</li>
                <li><strong>openOnHover</strong>: Boolean</li>
                <li><strong>scrollable</strong>: Boolean</li>
            </ul>

            <development-heading>Default slot</development-heading>
            <development-paragraph><strong>Default slot</strong> can be any html tag</development-paragraph>
            <base-menu>
                Click on me !
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>
            <base-menu>
                <base-button>Button</base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>
            <base-menu>
                <base-button icon>
                    <font-awesome-icon :icon="['fas', 'ellipsis-vertical']"/>
                </base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Items</development-heading>
            <base-menu>
                <base-button icon>
                    <font-awesome-icon :icon="['fas', 'ellipsis-vertical']"/>
                </base-button>
                <template #items>
                    <base-menu-item>Item 1</base-menu-item>
                    <base-menu-item color="info"><font-awesome-icon :icon="['fas', 'circle-info']"/> Item info
                    </base-menu-item>
                    <base-menu-item color="success"><font-awesome-icon :icon="['fas', 'circle-check']"/> Item success
                    </base-menu-item>
                    <base-menu-item color="warning"><font-awesome-icon :icon="['fas', 'triangle-exclamation']"/> Item
                        warning
                    </base-menu-item>
                    <base-menu-item color="danger"><font-awesome-icon :icon="['fas', 'circle-exclamation']"/> Item
                        danger
                    </base-menu-item>
                    <base-menu-item selected>Selected item</base-menu-item>
                    <base-menu-item separator></base-menu-item>
                    <base-menu-item>Separated item</base-menu-item>
                    <base-menu-item separator></base-menu-item>
                    <base-menu-item dense>Dense item</base-menu-item>
                    <base-menu-item separator></base-menu-item>
                    <base-menu-item>
                        Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Curabitur
                        arcu erat, accumsan id imperdiet et, porttitor at sem.
                    </base-menu-item>
                </template>
            </base-menu>

            <development-heading>Scrollable</development-heading>
            <base-menu>
                <base-button>Not scrollable</base-button>
                <template #items>
                    <base-menu-item v-for="n in 20" :key="n">Item {{ n }}</base-menu-item>
                </template>
            </base-menu>

            <base-menu scrollable>
                <base-button>Scrollable</base-button>
                <template #items>
                    <base-menu-item v-for="n in 20" :key="n">Item {{ n }}</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Header / Footer</development-heading>
            <base-menu scrollable>
                <base-button>Scrollable</base-button>
                <template #header>
                    <div class="p-6">Header</div>
                </template>
                <template #items>
                    <base-menu-item v-for="n in 20" :key="n">Item {{ n }}</base-menu-item>
                </template>
                <template #footer>
                    <div class="p-6">Footer</div>
                </template>
            </base-menu>

            <development-heading>Loading</development-heading>
            <base-menu scrollable loading>
                <base-button>Loading</base-button>
                <template #items>
                    <base-menu-item v-for="n in 20" :key="n">Item {{ n }}</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Disabled</development-heading>
            <base-menu disabled>
                <base-button disabled>Dropdown</base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>
            <base-menu disabled>
                <base-button disabled icon>
                    <font-awesome-icon :icon="['fas', 'ellipsis-vertical']"/>
                </base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Open on hover</development-heading>
            <base-menu open-on-hover>
                <base-button>Dropdown</base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Events</development-heading>
            <base-menu @open="addEvent('opened', 'info')">
                <base-button>Dropdown</base-button>
                <template #items>
                    <base-menu-item @click="addEvent('Item 1', 'info')">Item 1</base-menu-item>
                    <base-menu-item @click="addEvent('Item 2', 'info')">Item 2</base-menu-item>
                    <base-menu-item @click="addEvent('Item 3', 'info')">Item 3</base-menu-item>
                    <base-menu-item @click="addEvent('Item 4', 'info')">Item 4</base-menu-item>
                </template>
            </base-menu>

            <development-heading>Drop direction</development-heading>
            <div class="flex">
                <div class="flex-none">
                    <base-menu>
                        <base-button>
                            default
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Curabitur aliquet quam', 'info')">Curabitur aliquet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Vestibulum ac diam sit amet quam', 'info')">Vestibulum ac
                                diam
                                sit amet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Praesent sapien massa', 'info')">Praesent sapien massa
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Pellentesque in ipsum id orci porta dapibus
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.
                                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>

                <div class="flex-grow">&nbsp;</div>

                <div class="flex-none">
                    <base-menu drop-left>
                        <base-button>
                            left
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Curabitur aliquet quam', 'info')">Curabitur aliquet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Vestibulum ac diam sit amet quam', 'info')">Vestibulum ac
                                diam
                                sit amet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Praesent sapien massa', 'info')">Praesent sapien massa
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Pellentesque in ipsum id orci porta dapibus
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.
                                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>
            </div>

            <div class="flex mt-4">
                <div class="flex-none">
                    <base-menu drop-top>
                        <base-button>
                            top
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Curabitur aliquet quam', 'info')">Curabitur aliquet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Vestibulum ac diam sit amet quam', 'info')">Vestibulum ac
                                diam
                                sit amet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Praesent sapien massa', 'info')">Praesent sapien massa
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Pellentesque in ipsum id orci porta dapibus
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.
                                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>

                <div class="flex-grow">&nbsp;</div>

                <div class="flex-none">
                    <base-menu drop-top drop-left>
                        <base-button>
                            top / left
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Curabitur aliquet quam', 'info')">Curabitur aliquet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Vestibulum ac diam sit amet quam', 'info')">Vestibulum ac
                                diam
                                sit amet quam
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Praesent sapien massa', 'info')">Praesent sapien massa
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Pellentesque in ipsum id orci porta dapibus
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Pellentesque in ipsum id orci porta dapibus', 'info')">
                                Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.
                                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>
            </div>

            <development-heading>Chat example</development-heading>
            <div class="flex">
                <div class="flex-none">
                    <base-menu icon>
                        <base-button icon>
                            <font-awesome-icon :icon="['fas', 'ellipsis-vertical']"/>
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Vous avez offert 10 minutes', 'success')">
                                <font-awesome-icon :icon="['far', 'gift']"/> Offrir 10 minutes cadeau chat
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Conversation non lue', 'info')">
                                <font-awesome-icon :icon="['fal', 'envelope']"/> Marquer la conversation comme non lue
                            </base-menu-item>
                            <base-menu-item color="danger"
                                            @click="addEvent('Êtes-vous sûr de vouloir signaler Pseudo ?', 'warning')">
                                <font-awesome-icon :icon="['far', 'circle-exclamation']"/> Signaler Pseudo
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>

                <div class="flex-grow">&nbsp;</div>

                <div class="flex-none">
                    <base-menu icon drop-left>
                        <base-button icon>
                            <font-awesome-icon :icon="['fas', 'ellipsis-vertical']"/>
                        </base-button>
                        <template #items>
                            <base-menu-item @click="addEvent('Vous avez offert 10 minutes', 'success')">
                                <font-awesome-icon :icon="['far', 'gift']"/> Offrir 10 minutes cadeau chat
                            </base-menu-item>
                            <base-menu-item @click="addEvent('Conversation non lue', 'info')">
                                <font-awesome-icon :icon="['fal', 'envelope']"/> Marquer la conversation comme non lue
                            </base-menu-item>
                            <base-menu-item color="danger"
                                            @click="addEvent('Êtes-vous sûr de vouloir signaler Pseudo ?', 'warning')">
                                <font-awesome-icon :icon="['far', 'circle-exclamation']"/> Signaler Pseudo
                            </base-menu-item>
                        </template>
                    </base-menu>
                </div>
            </div>

            <development-heading>Input photo example</development-heading>
            <div class="block bg-neutral-100 h-36"></div>
            <div class="flex">
                <div class="flex-grow relative self-center py-1 pl-2">
                    <base-textarea outlined
                                   :rows="1"
                                   :max-rows="3"
                                   auto-grow
                                   placeholder="Rédigez votre réponse"/>
                </div>

                <div class="flex-none self-end">
                    <base-menu icon open drop-left>
                        <base-button icon large color="neutral">
                            <font-awesome-icon :icon="['fal', 'camera']"/>
                        </base-button>
                        <template #items>
                            <base-alert>
                                <template #icon>
                                    <font-awesome-icon :icon="['fas', 'camera']" class="text-primary"/>
                                </template>
                                <p class="text-primary w-48">
                                    Nouveau ! Vous pouvez envoyer des photos (ex: photo de l'être aimé).
                                </p>
                            </base-alert>
                        </template>
                    </base-menu>
                </div>
            </div>

        </div>

        <div class="notifications">
            <template v-for="(timerEvent, index) in timerEvents">
                <base-alert :type="timerEvent.type" dismissible elevated class="mb-2" :delay="5000">
                    {{ timerEvent.message }}
                </base-alert>
            </template>
        </div>
    </development>
</template>

<style>
.notifications {
    @apply fixed top-0 right-0 flex flex-col-reverse p-2;
}
</style>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseMenu from "../elements/BaseMenu";
import BaseButton from "../elements/BaseButton";
import BaseMenuItem from "../elements/BaseMenuItem";
import BaseAlert from "../elements/BaseAlert";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseTextarea from "../elements/BaseTextarea";

export default {
    name: "DevelopmentMenu",
    components: {
        BaseTextarea,
        DevelopmentParagraph, BaseAlert, BaseMenuItem, BaseButton, BaseMenu, DevelopmentHeading, Development
    },
    data() {
        return {
            timerEvents: [],
        };
    },
    methods: {
        menuItemClick(message) {
            console.log(message);
        },
        addEvent: function (message, type) {
            this.timerEvents.push({message: message, type: type});
        },
    }
}
</script>
