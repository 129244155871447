var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.listClass },
    [
      false &&
      (this.isLiveConversationInProgress || this.isLiveConversationWaiting)
        ? _c(
            "base-alert",
            {
              staticClass: "conversation-list-alert",
              attrs: { "text-center": "", elevated: "" },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "text-primary",
                attrs: { icon: ["fas", "circle-exclamation"] },
              }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.trans.get("chat.chat_live_in_progress_hide_list")
                  ) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conversation-list-header-container" },
        [
          _c("conversation-list-header"),
          _vm._v(" "),
          _vm.canBeDisplayingToBeProcessedChoice
            ? _c("conversation-list-header-list-to-display-choice")
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.getConversationsListToDiplay == "all"
        ? _c(
            "div",
            {
              staticClass: "conversation-list-items",
              attrs: { id: "conversation-list-items" },
            },
            [
              _vm._l(_vm.getConversations, function (conversation) {
                return [
                  _c("conversation-list-item", {
                    attrs: {
                      participants: conversation.participants,
                      lastMessage:
                        conversation.messages[conversation.messages.length - 1],
                      read: conversation.read,
                    },
                  }),
                ]
              }),
              _vm._v(" "),
              _vm.canLoadNextConversations
                ? _c(
                    "div",
                    {
                      staticClass: "text-center p-4",
                      attrs: { id: "conversation-list-items-loader" },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-primary",
                        attrs: {
                          icon: ["fas", "spinner"],
                          spin: "",
                          size: "3x",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.getConversationsListToDiplay == "to-be-processed"
        ? _c(
            "div",
            {
              staticClass: "conversation-list-items",
              attrs: { id: "to-be-processed-conversation-list-items" },
            },
            [
              _vm._l(
                _vm.getToBeProcessedConversations,
                function (conversation) {
                  return [
                    _c("conversation-list-item", {
                      attrs: {
                        participants: conversation.participants,
                        lastMessage:
                          conversation.messages[
                            conversation.messages.length - 1
                          ],
                        read: conversation.read,
                      },
                    }),
                  ]
                }
              ),
              _vm._v(" "),
              _vm.canLoadNextToBeProcessedConversations
                ? _c(
                    "div",
                    {
                      staticClass: "text-center p-4",
                      attrs: {
                        id: "to-be-processed-conversation-list-items-loader",
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-primary",
                        attrs: {
                          icon: ["fas", "spinner"],
                          spin: "",
                          size: "3x",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: _vm.config.get("chat.conversation_list_redirect_button_url"),
          },
        },
        [
          _c(
            "base-button",
            {
              staticClass: "conversation-list-fixed-button",
              attrs: { small: "" },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fas", "users"] } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.trans.get("chat.conversation_list_redirect_button")
                  ) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }