import {ziggy_route} from '../ZiggyMixin';
import listeners from "../chat/listeners";
import {makeEchoInstance} from "../../utilities/echo-factory";
import requests from './requests';
import user_store from "../user/store";
import axios from "axios";

export default {
    namespaced: true,

    state: {
        creditCards: [],
        paypals: [],
        packs: [],
        partners: [],
        credits: 0,
        canPpu: false,
        exemptedFromAdditionalCostByCall: false,
        exemptedFromMobileTax: false,
        selectFirstPackByDefault: false,
        payment: {
            status: null,
            reference: null,
            paymentMethodType: null,
            callbackType: null,
            offerId: null,
        },
        billingHistoryId : null,
        amountPaid : 0,
    },

    mutations: {
        setCreditCards: (state, creditCards) => {
            state.creditCards = creditCards;
        },
        setPaypals: (state, paypals) => {
            state.paypals = paypals;
        },
        setPacks: (state, packs) => {
            state.packs = packs;
        },
        setPartners: (state, partners) => {
            state.partners = partners;
        },
        setCredits: (state, credits) => {
            state.credits = credits;
        },
        setCanPpu: (state, canPpu) => {
            state.canPpu = canPpu;
        },
        setExemptedFromAdditionalCostByCall: (state, exemptedFromAdditionalCostByCall) => {
            state.exemptedFromAdditionalCostByCall = exemptedFromAdditionalCostByCall;
        },
        setExemptedFromMobileTax: (state, exemptedFromMobileTax) => {
            state.exemptedFromMobileTax = exemptedFromMobileTax;
        },
        setSelectFirstPackByDefault: (state, selectFirstPackByDefault) => {
            state.selectFirstPackByDefault = selectFirstPackByDefault;
        },
        setPaymentStatus: (state, status) => {
            state.payment.status = status;
        },
        setPaymentReference: (state, reference) => {
            state.payment.reference = reference;
        },
        setPaymentOfferId: (state, offerId) => {
            state.payment.offerId = offerId;
        },
        setPaymentCallbackType: (state, callbackType) => {
            state.payment.callbackType = callbackType;
        },
        setPaymentMethodType: (state, paymentMethodType) => {
            state.payment.paymentMethodType = paymentMethodType;
        },
        setBillingHistoryId: (state, billingHistoryId) => {
            state.billingHistoryId = billingHistoryId;
        },
        setAmountPaid: (state, amountPaid) => {
            state.amountPaid = amountPaid;
        },
    },
    getters: {
        hasEnabledCreditCards: (state) => state.creditCards.length > 0,
        getPackByType: (state) => (type) => state.packs.find(pack => pack.pack_type === type),
        getCredits: (state) => state.credits,
        getPaypals: (state) => state.paypals,
        canPpu: (state) => state.canPpu,
        isExemptedFromAdditionalCostByCall: (state) => state.exemptedFromAdditionalCostByCall,
        isExemptedFromMobileTax: (state) => state.exemptedFromMobileTax,
        getPacksToDisplay: (state) => {
            let packsToDisplay = [];

            if (state.packs.length === 0) {
                return packsToDisplay;
            }

            let packsTypeDisplayOrder = [
                'recharge_speciale_plus',
                'recharge_speciale_game_client',
                'recharge_speciale_game_prospect',
                'recharge_speciale',
                '1st_offer',
                'special_operation_refill',
                'recharge_decouverte_jeu_noel',
                'partnerOffer',
                'credit',
            ];

            let displayedPackType = ''
            if (state.packs.length > 0) {
                while (packsToDisplay.length === 0 && packsTypeDisplayOrder.length > 0) {
                    displayedPackType = packsTypeDisplayOrder.shift();
                    packsToDisplay = state.packs.filter(pack => pack.pack_type === displayedPackType);
                }
            }

            packsToDisplay = packsToDisplay.sort((pack1, pack2) => pack1.display_order - pack2.display_order);

            return [
                packsToDisplay[0], packsToDisplay[1], packsToDisplay[packsToDisplay.length - 1]
            ];
        },
        selectFirstPackByDefault: (state) => state.selectFirstPackByDefault,
        getPaymentPrivateChannel: (state, getters, rootState, rootGetters) => {
            if (rootGetters['user/getLoggedUserInfos'].id) {
                const echo = makeEchoInstance('App.Events.Payment');
                return echo.private(`payment.` + rootGetters['user/getLoggedUserInfos'].id);
            }
            return null;
        },
        getPaymentStatus: (state) => state.payment.status,
        getPaymentReference: (state) => state.payment.reference,
        getPaymentMethodType: (state) => state.payment.paymentMethodType,
        getPaymentOfferId: (state) => state.payment.offerId,
        getPaymentCallbackType: (state) => state.payment.callbackType,
        getUserInfos : () => user_store.getters.getLoggedUserInfos(user_store.state),
        getBillingHistoryId: (state) => state.billingHistoryId,
        getAmountPaid: (state) => state.amountPaid,
    },
    actions: {
        loadPaymentInfo({commit}, successCallback) {
            return axios.get(ziggy_route('payment.info')).then(response => {
                commit('setCreditCards', response.data.credit_cards);
                commit('setPaypals', response.data.paypals);
                commit('setPacks', response.data.packs);
                commit('setPartners', response.data.partners);
                commit('setCredits', response.data.credits);
                commit('setCanPpu', response.data.can_ppu);
                commit('setExemptedFromAdditionalCostByCall', response.data.exempted_from_additional_cost_by_call);
                commit('setExemptedFromMobileTax', response.data.exempted_from_mobile_tax);
                commit('setSelectFirstPackByDefault', response.data.select_first_pack_by_default);

                if (successCallback !== undefined && successCallback instanceof Function) {
                    successCallback(response);
                }

                return response;
            }).catch(error => error);
        },
        gtmEventPayment({dispatch, getters}) {
            const current = new Date();
            const currentDate = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;

            return dispatch('user/fetchUserInfos', {}, {root: true}).then(() => {

                const userInfo = getters.getUserInfos;
                return dispatch('loadBillingInfos', userInfo.id ).then(() => {
                    Vue.gtm.trackEvent({
                        event: 'payment',
                        userId: userInfo.id,
                        md5email: userInfo.md5email,
                        userEmail: userInfo.email,
                        userFirstDate: userInfo.dt_create,
                        campagneRegieName: userInfo.campagne_regie_name,
                        userValue: userInfo.value / 100,
                        userGender: userInfo.gender,
                        userAge: userInfo.age,
                        keyword : userInfo.user_keyword,
                        transfo: userInfo.transfo,
                        phone: userInfo.favorite_phone,
                        userNickname: userInfo.name,
                        dateBirth: userInfo.dt_birth,
                        userNbTransac: userInfo.nb_transaction,
                        transId: getters.getBillingHistoryId,
                        transAffiliation : 'kang',
                        transDate: currentDate,
                        transCurrency: 'EUR',
                        creditAmount: getters.getAmountPaid / 100,
                        transVendorType: 'click_to_call',
                        transType: getters.getPaymentCallbackType ? getters.getPaymentCallbackType : 'recharge-credit',
                        offerId: getters.getPaymentOfferId,
                        action: getters.getPaymentMethodType,
                        value: getters.getCredits,
                    });
                })
            });
        },
        loadBillingInfos({commit}, userId)  {
            return axios.get(ziggy_route('billing-history.get', { user_id: userId})).then(response => {
                commit('setBillingHistoryId', response.data.id);
                commit('setAmountPaid', response.data.amount_paid);
            }).catch(error => error);
        },
        createSession({}, {data, successCallback}) {
            return axios.post(ziggy_route('payment.create-session'), data).then(response => {
                if (successCallback !== undefined && successCallback instanceof Function) {
                    successCallback(response);
                }

                return response;
            }).catch(error => error);
        },
        buyPack({commit, getters}, {type, offerId, avoid3DS = false, sessionId = null}) {
            let id = getters.getPackByType(type).id;

            return axios.post(ziggy_route('payment.buy_pack_existing_card'),
                {id, type, offerId, avoid3DS, sessionId}
            ).then(response => response);
        },
        disconnect({commit}) {
            commit('setCreditCards', []);
            commit('setPacks', []);
            commit('setPartners', []);
            commit('setCredits', 0);
            commit('setCanPpu', false);
            commit('setExemptedFromAdditionalCostByCall', false);
            commit('setExemptedFromMobileTax', false);
            commit('setSelectFirstPackByDefault', false);
        },
        startPaymentProcessing({getters, commit, dispatch}, {
            reference,
            callbackType = null,
            offerId = null,
            paymentMethodType = 'credit_card'
        }) {
            const channel = getters.getPaymentPrivateChannel;
            commit('setPaymentStatus', 'loading');
            commit('setPaymentReference', reference);
            commit('setPaymentCallbackType', callbackType);
            commit('setPaymentOfferId', offerId);
            commit('setPaymentMethodType', paymentMethodType);

            listeners.listen(channel, 'PaymentProcessed', (e) => {
                dispatch('user/updateUserCredits', null, {root: true});

                if (e.status === 'success') {
                    dispatch('onPaymentSuccess');
                } else {
                    commit('setPaymentStatus', 'error');
                }
            });
        },
        getPaymentStatusFromServer({getters, commit, dispatch}, {canRetry = false}) {
            if (getters.getPaymentReference !== null) {
                requests.getPaymentStatusByReference({
                    reference: getters.getPaymentReference,
                    paymentMethodType: getters.getPaymentMethodType
                }, (response) => {
                    if (response.data.status === 'success') {
                        dispatch('onPaymentSuccess');
                    } else if (!canRetry) {
                        commit('setPaymentStatus', 'error');
                    }
                }, (errorResponse) => {
                    commit('setPaymentStatus', 'error');
                }, () => {
                    dispatch('user/updateUserCredits', null, {root: true});
                });
            }
        },
        onPaymentSuccess({getters, commit, dispatch}) {
            commit('setPaymentStatus', 'authorized');
            dispatch('gtmEventPayment');

            if (getters.getPaymentCallbackType === 'call' && getters.getPaymentOfferId !== null) {
                dispatch('modals/closeModal', null, {root: true});
                parent.ajax_popup("call-start", {oid: getters.getPaymentOfferId, "paid_previously": 1});
            }

            if (getters.getPaymentCallbackType === 'call_ppu' && getters.getPaymentOfferId !== null) {
                dispatch('modals/closeModal', null, {root: true});
                parent.ajax_popup("call-start", {
                    oid: getters.getPaymentOfferId,
                    "step": 2,
                    "ppu": 1,
                    ppu_payment_method: 'credit_card'
                });
            }

            if (getters.getPaymentCallbackType === 'call_ppu_paypal' && getters.getPaymentOfferId !== null) {
                dispatch('modals/closeModal', null, {root: true});
                parent.ajax_popup("call-start", {
                    oid: getters.getPaymentOfferId,
                    "step": 2,
                    "ppu": 1,
                    ppu_payment_method: 'paypal'
                });
            }

            if (getters.getPaymentCallbackType === 'chat' && getters.getPaymentOfferId !== null) {
                dispatch('modals/closeModal', null, {root: true});
                dispatch('chat/startLiveChatProcess', {offerId: getters.getPaymentOfferId}, {root: true});
            }

            if (getters.getPaymentCallbackType === 'chat_ppu' && getters.getPaymentOfferId !== null) {
                dispatch('modals/closeModal', null, {root: true});
                dispatch('chat/startLiveChatWithOfferId', {
                    offerId: getters.getPaymentOfferId,
                    billing_type: 'pay_per_use'
                }, {root: true});
            }

            if (getters.getPaymentCallbackType === 'prime_subscription') {
                dispatch('modals/closeModal', null, {root: true});
                parent.ajax_popup('prime_subscribe_success');
            }

            if (getters.getPaymentCallbackType === 'digital') {
                dispatch('modals/closeModal', null, {root: true});
                axios.post('/ajax/da_finalize_digital_order.js', {transactionId: getters.getPaymentReference}).then((response) => {
                    window.location.href = response.data;
                });
            }

            if (getters.getPaymentCallbackType === 'fast_pass') {
                dispatch('modals/closeModal', null, {root: true});
                parent.ajax_popup('prime_purchase_fast_pass_success', {
                    quantity: window.globalVue.config.get('prime.nb_buyable_fast_pass'),
                    oid: getters.getPaymentOfferId
                });
            }

            if (getters.getPaymentCallbackType === 'boost') {
                dispatch('modals/closeModal', null, {root: true});
                axios.post('/ajax/boost_after_payment.js', {transactionId: getters.getPaymentReference}).then((response) => {
                    eval(response.data);
                });
            }
        }
    },
};
