<template>
    <li v-if="visible">
        <development-link :href="ziggy_route(route)">{{ label }}</development-link>
    </li>
</template>

<script>
import DevelopmentLink from "./DevelopmentLink";

export default {
    name: "DevelopmentMenuItem",
    components: {DevelopmentLink},
    props: {
        route: String,
        label: String,
        filter: String,
    },
    computed: {
        hasFilter() {
            return this.filter.trim().length > 0;
        },
        visible() {
            return !this.hasFilter ||
                this.ziggy_route(this.route).toLowerCase().includes(this.filter.toLowerCase()) ||
                this.route.toLowerCase().includes(this.filter.toLowerCase()) ||
                this.label.toLowerCase().includes(this.filter.toLowerCase());
        }
    }
};
</script>
