var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.blurEvent,
          expression: "blurEvent",
        },
      ],
      class: _vm.containerClass,
    },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          {
            ref: "baseMenu",
            class: _vm.selectClass,
            attrs: { tabindex: "0", role: "button" },
            on: { click: _vm.clickEvent },
          },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "itemList",
            class: _vm.itemListClass,
            style: _vm.itemListStyle,
            on: {
              mouseover: function ($event) {
                _vm.listHover = true
              },
              mouseleave: function ($event) {
                _vm.listHover = false
              },
              click: function ($event) {
                _vm.listHover = false
              },
            },
          },
          [
            _vm.hasHeader
              ? _c(
                  "div",
                  { staticClass: "base-menu-item-list-header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "py-4 px-12 cursor-default" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-primary",
                      attrs: { icon: ["fas", "spinner"], spin: "", size: "2x" },
                    }),
                  ],
                  1
                )
              : _c("div", { class: _vm.itemsClass }, [_vm._t("items")], 2),
            _vm._v(" "),
            _vm.hasFooter
              ? _c(
                  "div",
                  { staticClass: "base-menu-item-list-footer" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }