var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Checkbox")]),
        _vm._v(" "),
        _c("base-checkbox", [_vm._v("Checkbox")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [
              _vm._v(
                "primary (default) / secondary / available / busy / neutral /\n                danger / prime / whatsapp"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Colors")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("color")]),
          _vm._v(
            " : primary (default) / secondary / available / busy / offline /\n            neutral / danger / prime / facebook / whatsapp\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "primary", block: "", value: true } },
          [_vm._v("Checkbox primary")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "secondary", block: "", value: true } },
          [_vm._v("Checkbox secondary")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "available", block: "", value: true } },
          [_vm._v("Checkbox available")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "busy", block: "", value: true } },
          [_vm._v("Checkbox busy")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "offline", block: "", value: true } },
          [_vm._v("Checkbox offline")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "neutral", block: "", value: true } },
          [_vm._v("Checkbox neutral")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "danger", block: "", value: true } },
          [_vm._v("Checkbox danger")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "prime", block: "", value: true } },
          [_vm._v("Checkbox prime")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "facebook", block: "", value: true } },
          [_vm._v("Checkbox facebook")]
        ),
        _vm._v(" "),
        _c(
          "base-checkbox",
          { attrs: { color: "whatsapp", block: "", value: true } },
          [_vm._v("Checkbox whatsapp")]
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { disabled: "" } }, [_vm._v("Checkbox")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { value: true, disabled: "" } }, [
          _vm._v("Checkbox"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Block")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { block: "" } }, [_vm._v("Checkbox 1")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { block: "" } }, [_vm._v("Checkbox 2")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { block: "" } }, [_vm._v("Checkbox 3")]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Error")]),
        _vm._v(" "),
        _c("base-checkbox", { attrs: { error: "This is an error" } }, [
          _vm._v("Checkbox"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("v-model")]),
        _vm._v(" "),
        _c(
          "base-checkbox",
          {
            model: {
              value: _vm.checkbox,
              callback: function ($$v) {
                _vm.checkbox = $$v
              },
              expression: "checkbox",
            },
          },
          [_vm._v("Checkbox : " + _vm._s(String(_vm.checkbox)))]
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Long content")]),
        _vm._v(" "),
        _c("base-checkbox", [
          _vm._v(
            "Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus\n            nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum\n            sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam\n            id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras\n            ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-checkbox",
          {
            attrs: {
              error:
                "Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.",
            },
          },
          [
            _vm._v(
              "Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Sed porttitor lectus\n            nibh. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum\n            sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam\n            id dui posuere blandit. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Cras\n            ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }