var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Avatar")]),
        _vm._v(" "),
        _c("base-avatar"),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'primary', 'secondary', 'neutral', 'light'")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("status")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'online', 'busy', 'offline'")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("photo")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("tile")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("xLarge")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Colors")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("color")]),
          _vm._v(" : primary "),
          _c("small", [_vm._v("(default)")]),
          _vm._v(" / secondary / neutral\n        "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { color: "primary" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { color: "secondary" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { color: "neutral" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { color: "light" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Status")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("status")]),
          _vm._v(" : online / busy / offline"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { status: "online" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { status: "busy" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { status: "offline" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Name")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { name: "A" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { name: "B" } }),
            _vm._v(" "),
            _c("base-avatar", { attrs: { name: "C" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { outlined: "", color: "primary" } }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { outlined: "", color: "secondary", status: "online" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { tile: "", outlined: "", color: "neutral" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { tile: "", outlined: "", color: "light" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Photo")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("photo")]),
          _vm._v(" (not working with outlined)"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", {
              attrs: {
                photo: "https://via.placeholder.com/150",
                status: "online",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                photo: "https://via.placeholder.com/150",
                status: "busy",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                photo: "https://via.placeholder.com/150",
                status: "offline",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Tile")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("tile")]),
          _vm._v(
            " prop removes the border radius from v-avatar leaving you with a\n            simple square avatar\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", {
              attrs: { photo: "https://via.placeholder.com/150", tile: "" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                photo: "https://via.placeholder.com/150",
                status: "online",
                tile: "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { small: "" } }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { small: "", photo: "https://via.placeholder.com/150" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                small: "",
                photo: "https://via.placeholder.com/150",
                status: "online",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                small: "",
                photo: "https://via.placeholder.com/150",
                tile: "",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                small: "",
                photo: "https://via.placeholder.com/150",
                tile: "",
                status: "online",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { large: "" } }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { large: "", photo: "https://via.placeholder.com/150" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                large: "",
                photo: "https://via.placeholder.com/150",
                status: "online",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                large: "",
                photo: "https://via.placeholder.com/150",
                tile: "",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                large: "",
                photo: "https://via.placeholder.com/150",
                tile: "",
                status: "online",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("xLarge")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { "x-large": "" } }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                "x-large": "",
                photo: "https://via.placeholder.com/150",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                "x-large": "",
                photo: "https://via.placeholder.com/150",
                status: "online",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                "x-large": "",
                photo: "https://via.placeholder.com/150",
                tile: "",
              },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                "x-large": "",
                photo: "https://via.placeholder.com/150",
                tile: "",
                status: "online",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Skeleton-loader")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          [
            _c("base-avatar", { attrs: { "skeleton-loader": "", small: "" } }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { "skeleton-loader": "", color: "secondary" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: { "skeleton-loader": "", color: "neutral", large: "" },
            }),
            _vm._v(" "),
            _c("base-avatar", {
              attrs: {
                "skeleton-loader": "",
                color: "neutral-secondary",
                "x-large": "",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }