var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-list-container" }, [
    _vm.hasHeader
      ? _c("div", { staticClass: "sm:mb-1 mb-2 px-4 md:px-2" }, [
          _vm.hasBackLink
            ? _c(
                "a",
                {
                  staticClass:
                    "lg:px-0 hover:text-primary-hover text-primary lg:text-base text-sm",
                  attrs: { href: _vm.backLinkUrl },
                },
                [
                  _c("h2", { staticClass: "inline font-medium mr-2" }, [
                    _vm._v(_vm._s(_vm.backLinkTitle)),
                  ]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "chevron-right"] },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center justify-between" },
            [
              _vm.hasTitle
                ? _c(
                    "h1",
                    {
                      staticClass: "text-primary lg:text-2xl text-xl font-bold",
                    },
                    [_vm._v(_vm._s(_vm.categoryTitle))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.disableFilters
                ? _c("base-select-conversation-type", {
                    on: { change: _vm.initList },
                    model: {
                      value: _vm.conversationType,
                      callback: function ($$v) {
                        _vm.conversationType = $$v
                      },
                      expression: "conversationType",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "offer-list", staticClass: "offer-list" },
      [
        !_vm.disableComments
          ? _c(
              "div",
              { staticClass: "comments" },
              [
                _c("comment-list", {
                  attrs: { "category-id": _vm.categoryId },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.disableCustomerCareCard
          ? _c(
              "div",
              { staticClass: "offer-list-card offer-list-customer-care-card" },
              [_c("customer-care-card")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.disableCustomerAdCard
          ? _c(
              "div",
              { staticClass: "offer-list-card offer-list-customer-ad-card" },
              [_c("customer-ad-card")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.offers, function (offer, index) {
          return _c(
            "div",
            { staticClass: "offer-list-card" },
            [
              _c("offer-card", {
                key: offer.id,
                ref: "offer-card",
                refInFor: true,
                attrs: {
                  number: index + 1,
                  offer: offer,
                  user: _vm.getLoggedUserInfos,
                  offer_list_type: _vm.offerListType,
                  is_prime_enabled: _vm.config.get("prime.enabled"),
                  is_free_chat_enabled_for_prospect: _vm.config.get(
                    "chat.free_chat_enabled_for_prospect"
                  ),
                  user_credits: _vm.getCredits,
                },
              }),
            ],
            1
          )
        }),
        _vm._v(" "),
        _vm._l(_vm.cardsPerPage, function (n) {
          return _vm.canLoadNextPage
            ? _c(
                "div",
                { staticClass: "offer-list-card" },
                [
                  _c("offer-card-loader", {
                    key: "offer-card-loader-" + n,
                    ref: "offer-card-loader",
                    refInFor: true,
                  }),
                ],
                1
              )
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }