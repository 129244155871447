<template>
    <transition name="modal">
        <div class="reset-this">
            <div id="BaseModal" :class="[containerClass, zIndex]">

                <div class="fixed" @click="closeModal">
                    <div class="base-modal-overlay"></div>
                </div>

                <div class="base-modal-loading" v-if="getLoading">
                    <div>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin size="3x"/>
                    </div>
                    <div class="my-3 font-extrabold">
                        {{ trans.get('generic.loading') }}...
                    </div>
                </div>

                <div v-else-if="error" class="error">
                    <p>{{ error }}</p>
                </div>

                <div v-else :class="modalClass">

                    <div v-if="small" class="base-modal-header-small" @click="closeModal">
                        <base-button v-if="!disableClosing" small color="light" icon>
                            <font-awesome-icon :icon="['fal', 'circle-xmark']" size="lg"/>
                        </base-button>
                    </div>
                    <div v-else-if="fullscreen">
                    </div>
                    <div v-else class="base-modal-header">
                        <img class="h-10"
                             :src="assetManager.getImageAsset(logo)"
                             :alt="trans.get('generic.site_name')"/>
                        <base-button v-if="!disableClosing" icon @click="closeModal">
                            <font-awesome-icon :icon="['far', 'circle-xmark']"
                                               class="text-modal-header-button hover:text-modal-header-button"
                                               size="lg"/>
                        </base-button>
                    </div>

                    <div v-if="hasContentHeader" class="base-modal-content-header">
                        <slot name="content-header"/>
                    </div>

                    <div :class="contentClass" ref="modalContent" @scroll="$emit('scroll', $event)">
                        <slot/>
                    </div>

                    <div v-if="hasContentFooter" class="base-modal-content-footer">
                        <slot name="content-footer"/>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<style lang="scss">
.base-modal-container {
    @apply fixed w-full h-full top-0 left-0 overflow-x-hidden overflow-y-auto;
}

.base-modal-overlay {
    @apply fixed w-full h-full top-0 left-0 bg-black opacity-85;
}

.base-modal-loading {
    @apply my-1/2 transform -translate-y-1/2 text-white text-center;
}

.base-modal {
    @apply bg-white relative max-w-640 mx-auto min-h-full sm:min-h-0 sm:max-w-500 sm:my-10 sm:rounded-b sm:rounded;

    &--small {
        @apply bg-transparent fixed bottom-0 w-full min-h-0 p-4 sm:relative sm:mx-auto sm:my-1/2 sm:transform sm:-translate-y-1/2 ;
    }

    &--fullscreen {
        @apply bg-transparent fixed bottom-0 w-full h-full my-0 max-w-full flex items-end;
    }

    &--scrollable-content {
        @apply flex flex-col h-full overflow-hidden;

        @media (min-width: 640px) {
            height: auto;
            max-height: calc(100% - 5rem);
        }

        .base-modal-header, .base-modal-content-header, .base-modal-content-footer {
            @apply flex-none;
        }

        .base-modal-content {
            @apply flex-1 overflow-y-auto;
        }
    }
}

.base-modal-header {
    @apply bg-modal-header flex justify-between items-center px-6 py-3 sm:rounded-t;
}

.base-modal-header-small {
    @apply bg-transparent;
}

.base-modal-content {
    &:not(.base-modal-content--dense) {
        @apply p-6;
    }

    &--small {
        @apply bg-white rounded-lg;

        &:not(.base-modal-content--dense) {
            @apply p-4;
        }
    }

    &--fullscreen {
        @apply bg-white h-full w-full overflow-hidden mx-auto lg:rounded-t-lg lg:w-4/5 lg:h-4/5;

        &:not(.base-modal-content--dense) {
            @apply p-4;
        }
    }
}

.base-modal-content-header {
    @apply border-b border-neutral-300;
}

.base-modal-content-footer {
    @apply border-t border-neutral-300;
}
</style>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import BaseButton from '../elements/BaseButton';

export default {
    components: {BaseButton},
    props: {
        error: {
            default: '',
            required: false,
        },
        dense: Boolean,
        fullscreen: Boolean,
        disableClosing: Boolean,
        scrollableContent: Boolean,
        small: Boolean,
        zIndex: {
            type: String,
            default: "z-20",
            required: false
        },
        logo: {
            type: String,
            default: "global/logo.svg",
            required: false
        }
    },
    mounted: function () {
        document.body.style.overflow = 'hidden';

        let mobileBackBtn = document.getElementById('mobile_back_btn');
        if (document.body.contains(mobileBackBtn)) {
            mobileBackBtn.style.display = 'none';
        }
    },
    destroyed: function () {
        document.body.style.overflow = '';

        let mobileBackBtn = document.getElementById('mobile_back_btn');
        if (document.body.contains(mobileBackBtn)) {
            mobileBackBtn.style.display = '';
        }

        window.removeEventListener('keydown', this.keydown);
    },
    computed: {
        ...mapState('modals', ['show']),
        ...mapGetters('modals', [
            'getLoading',
        ]),
        containerClass() {
            return {
                'base-modal-container': true,
            }
        },
        modalClass() {
            return {
                'base-modal': true,
                'base-modal--small': this.small,
                'base-modal--scrollable-content': this.scrollableContent,
                'base-modal--fullscreen': this.fullscreen,
            }
        },
        contentClass() {
            return {
                'base-modal-content': true,
                'base-modal-content--small': this.small,
                'base-modal-content--dense': this.dense,
                'base-modal-content--fullscreen': this.fullscreen,
            }
        },
        hasContentHeader() {
            return !!this.$slots['content-header'];
        },
        hasContentFooter() {
            return !!this.$slots['content-footer'];
        },
    },
    methods: {
        ...mapMutations('modals', [
            'hide', 'addParam'
        ]),

        keydown(event) {
            if (event.key === 'Escape') {
                this.closeModal();
            }
        },

        closeModal() {
            if (this.getLoading || this.disableClosing) {
                return;
            }
            this.hide();
            this.$destroy();
            this.$emit('close');
        }
    },
    created() {
        this.$parent.$on('cancel', this.closeModal);
        this.modalName = this.show;

        window.addEventListener('keydown', this.keydown);
    },
    data() {
        return {
            modalName: '',
        }
    },
    beforeDestroy() {
        this.addParam({'name': this.modalName, 'param': null});
    }
};
</script>
