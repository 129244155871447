<template>
    <base-modal small>
        <modal-heading>
            {{ trans.get('generic.modals.user_phone_verification_already_used.phone_number_already_used') }}
        </modal-heading>

        <modal-paragraph>
            {{
                trans.get(
                    'generic.modals.user_phone_verification_already_used.this_number_is_already_used_on_the_following_account',
                    {
                        email: email,
                        phone_number_customer_care: config.get('app.phone_number_customer_care'),
                    })
            }}
        </modal-paragraph>

        <div class="grid grid-cols-1 mt-4 mb-6">
            <base-button autofocus @click="closeModal">
                {{ trans.get('generic.i_understood') }}
            </base-button>
        </div>

        <modal-paragraph class="text-center">
            {{ trans.get('generic.modals.user_phone_verification_already_used.did_you_use_the_wrong_account') }}
            <div @click="openLoginModal">
                <base-link>
                    {{ trans.get('generic.modals.user_phone_verification_already_used.connect_with_another_account') }}
                </base-link>
            </div>
        </modal-paragraph>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BasePhoneInput from '../elements/BasePhoneInput';
import BaseButton from '../elements/BaseButton';
import BaseTextInput from '../elements/BaseTextInput';
import BaseLink from '../elements/BaseLink';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ModalUserPhoneVerificationAlreadyUsed',
    components: {BaseLink, BaseTextInput, BaseButton, BasePhoneInput, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        openLoginModal()
        {
            window.location.href = '/loginpop';
            return;
        },
        ...mapActions('modals', ['closeModal'])
    },
    data()
    {
        return {
            'email': '',
        };
    },
    mounted()
    {
        this.email = this.getParam['user-phone-verification-already-used']['email'];
    },
};
</script>
