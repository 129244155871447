var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { small: _vm.small } }, [
    _c("img", {
      attrs: { src: _vm.url, alt: _vm.trans.get("generic.close") },
      on: { click: _vm.showGame },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid grid-cols-1 gap-4 mt-5" },
      [
        _c("base-button", { on: { click: _vm.closeAndRedirect } }, [
          _vm._v(_vm._s(_vm.trans.get("game.play"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }