var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", { attrs: { href: _vm.url } }, [
    _c("img", {
      staticClass: "h-12 inline-block",
      attrs: {
        src: _vm.assetManager.getImageAsset(_vm.logo),
        alt: _vm.trans.get("generic.site_name"),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }