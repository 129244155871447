var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full text-neutral-800" },
    [
      _c("base-audio-player", {
        ref: "audio-player",
        attrs: { src: _vm.url, initialDuration: _vm.duration },
        on: { update: _vm.update },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "flex items-stretch" }, [
        _c(
          "div",
          { staticClass: "flex-none pr-2" },
          [
            _vm.hasError
              ? _c(
                  "base-button",
                  { attrs: { icon: "", large: "" }, on: { click: _vm.reload } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-800",
                      attrs: { icon: ["fas", "rotate-right"] },
                    }),
                  ],
                  1
                )
              : _vm.isLoading
              ? _c(
                  "base-button",
                  { attrs: { icon: "", large: "" } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-800",
                      attrs: { icon: ["fas", "spinner"], spin: "" },
                    }),
                  ],
                  1
                )
              : _vm.isPlaying
              ? _c(
                  "base-button",
                  { attrs: { icon: "", large: "" }, on: { click: _vm.pause } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-800",
                      attrs: { icon: ["fas", "pause"] },
                    }),
                  ],
                  1
                )
              : _c(
                  "base-button",
                  { attrs: { icon: "", large: "" }, on: { click: _vm.play } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-neutral-800",
                      attrs: { icon: ["fas", "play"] },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grow flex" }, [
          _c("input", {
            staticClass: "progressBar",
            attrs: { type: "range", min: "0", max: "100", step: "0.1" },
            domProps: { value: _vm.playingProgression },
            on: { input: _vm.setCurrentTime },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-none ml-2 w-10 text-center flex items-center" },
          [
            _vm.displayCurrentTime
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("formatSecondsToTimer")(_vm.currentTime))
                  ),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm._f("formatSecondsToTimer")(_vm.duration))),
                ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }