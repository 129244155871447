<template>
    <div class="customer-ad-card" :style="{backgroundImage: 'url(' + backgroundImage + ')' }">
        <div class="flex flex-col justify-around h-full w-9/12">
            <template v-if="isProspect">
                <p class="font-bold text-xl">{{ trans.get('offer-list.customer_ad.prospect.title') }}</p>
                <p class="leading-6 mb-2">
                    <span class="leading-6 mb-2" v-html="trans.get('offer-list.customer_ad.prospect.text')"></span>
                </p>
                <div>
                    <base-button class="shadow-large mb-2" color="white" block small
                                 @click="openRefillModal">
                        <span class="text-pack1-from">
                            <font-awesome-icon :icon="['fas', 'circle-plus']"/> {{ trans.get('offer-list.customer_ad.prospect.btn') }}
                        </span>
                    </base-button>
                </div>
            </template>

            <template v-else-if="isCustomer">
                <p class="font-bold text-xl">{{ trans.get('offer-list.customer_ad.customer.title') }}</p>
                <p class="leading-6 mb-2">
                    <span class="leading-6 mb-2" v-html="trans.get('offer-list.customer_ad.customer.text')"></span>
                </p>
                <div>
                    <base-button class="shadow-large mb-2 text-center" color="white" block small
                                 :href="ziggy_route('account.sponsorship')">
                        <span class="text-pack1-from">
                            <font-awesome-icon :icon="['fas', 'circle-plus']"/> {{ trans.get('offer-list.customer_ad.customer.btn') }}
                        </span>
                    </base-button>
                </div>
            </template>

            <template v-else>
                <p class="font-bold text-xl">{{ trans.get('offer-list.customer_ad.hot_prospect.title') }}</p>
                <p class="leading-6 mb-2">
                    <font-awesome-icon :icon="['fas', 'phone-volume']"/>

                    <template v-if="isHotProspect">
                        <span class="leading-6 mb-2" v-html="trans.get('offer-list.customer_ad.hot_prospect.text')"></span>
                    </template>

                    <template v-else>
                        <span class="leading-6 mb-2" v-html="trans.get('offer-list.customer_ad.signout.text')"></span>
                    </template>
                </p>
            </template>

            <p>
                <a :href="ziggy_route('footer.faq')" class="text-white font-bold">
                    <font-awesome-icon :icon="['fas', 'circle-question']"/>
                    <span class="underline">{{ trans.get('offer-list.customer_ad.faq') }}</span>
                </a>
            </p>
        </div>
    </div>
</template>

<style lang="scss">
.customer-ad-card {
    @apply shadow-small rounded-2xl bg-customer-ad bg-right-bottom bg-contain bg-no-repeat text-white h-full w-full max-w-full mx-auto p-3;
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import ChatMixin from '../chat/ChatMixin';

export default {
    name: 'CustomerAdCard',
    components: {BaseButton},
    mixins: [ChatMixin],
    computed: {
        backgroundImage() {
            if (this.isProspect) {
                return this.assetManager.getImageAsset('offer-list/customer-ad/prospect.png');
            } else if (this.isCustomer) {
                return this.assetManager.getImageAsset('offer-list/customer-ad/customer.png');
            } else {
                return this.assetManager.getImageAsset('offer-list/customer-ad/hot_prospect.png');
            }
        },
        isHotProspect() {
            return !this.isProspect && !this.isCustomer && this.meFromUserStore().id !== undefined;
        },
        isProspect() {
            return this.meFromUserStore().is_prospect;
        },
        isCustomer() {
            return this.meFromUserStore().transfo === 'client';
        }
    },
    methods: {
        openRefillModal: function() {
            ajax_popup('buy');
        }
    }
}
</script>
