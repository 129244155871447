<template>
    <div class="reset-this horoscope-list-container">
        <div class="horoscope-list" ref="horoscope-list">
            <offer-card-medium v-for="(offer, index) in offers"
                               :key="offer.id"
                               :number="index+1"
                               :offer="offer"
                               :user="getLoggedUserInfos"
                               :user_credits="getCredits"
                               :is_prime_enabled="config.get('prime.enabled')"
                               :is_free_chat_enabled_for_prospect="config.get('chat.free_chat_enabled_for_prospect')"
                               offer_list_type="horoscope"
            />

            <div v-if="loading" v-for="n in cardsPerPage">
                <offer-card-medium-loader ref="offer-card-loader" :key="'offer-card-loader-' + n"/>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.horoscope-list-container {
    @apply px-4 sm:px-0;

    .horoscope-list {
        @apply grid grid-cols-1 gap-4;
    }
}
</style>

<script>
import OfferListMixin from "../offer/OfferListMixin";

import {mapGetters} from "vuex";
import {ziggy_route} from "../ZiggyMixin";

import OfferCardMedium from "../offer/OfferCardMedium";
import OfferCardMediumLoader from "../offer/OfferCardMediumLoader.vue";

export default {
    name: "HoroscopeOfferList",
    mixins: [OfferListMixin],
    components: {OfferCardMediumLoader, OfferCardMedium},
    props: {
        categoryId: Number,
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
        ...mapGetters('payment', ['getCredits']),
    },
    mounted() {
        this.initList();
    },
    methods: {
        initList() {
            this.offers = [];
            this.loading = false;
            this.fetchOffers();
        },
        fetchOffers() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            let params = {
                category_id: this.categoryId,
                offer_list_type: 'horoscope',
                filters: [],
            };

            if (this.axiosCancelSource !== null) {
                this.axiosCancelSource.cancel('Cancel load.');
            }

            this.axiosCancelSource = axios.CancelToken.source();

            axios.get(ziggy_route('offer-list.get', params), {
                cancelToken: this.axiosCancelSource.token,
            }).then(response => {
                response.data.data.forEach((offer) => {
                    this.createOrUpdateOffer(offer);
                });
                this.loading = false;

            }).catch(error => {
                console.error(error);
            });
        },
    },
    data() {
        return {
            axiosCancelSource: null,
            cardsPerPage: 8,
            loading: false,
        }
    }
}
</script>
