var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.containerClass }, [
    _c("div", { staticClass: "relative" }, [
      _c(
        "div",
        {
          ref: "selectButton",
          class: _vm.selectClass,
          attrs: {
            disabled: _vm.disabled,
            tabindex: "0",
            role: "button",
            id: _vm.id,
          },
          on: {
            click: function ($event) {
              return _vm.toggleFocused()
            },
            blur: function ($event) {
              _vm.toggleFocused(false)
              _vm.$emit("blur", $event)
            },
            keydown: _vm.keydownHandler,
          },
        },
        [
          _vm.hasSelectedItem
            ? _c(
                "span",
                { staticClass: "base-select-selected-item" },
                [
                  _vm._t("selected", function () {
                    return [_vm._v(_vm._s(_vm.itemName(_vm.selectedItem)))]
                  }),
                ],
                2
              )
            : _vm.hasPlaceholderSlot
            ? _c(
                "span",
                { staticClass: "base-select-placeholder" },
                [_vm._t("placeholder")],
                2
              )
            : _vm.placeholder
            ? _c("span", { staticClass: "base-select-placeholder" }, [
                _vm._v(_vm._s(_vm.placeholder)),
              ])
            : _vm.label
            ? _c("span", { staticClass: "invisible" }, [
                _vm._v(_vm._s(_vm.label)),
              ])
            : _c("span", [_vm._v(" ")]),
        ]
      ),
      _vm._v(" "),
      _vm.label
        ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.caretDownClass },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "caret-down"] } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "itemList",
          class: _vm.itemListClass,
          style: _vm.itemListStyle,
          attrs: { tabindex: "-1" },
          on: {
            mouseover: function ($event) {
              _vm.listHover = true
            },
            mouseleave: function ($event) {
              _vm.listHover = false
            },
          },
        },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            {
              class: {
                "base-select-item": true,
                "base-select-item--selected": item === _vm.selectedItem,
              },
              attrs: { tabindex: "-2" },
              on: {
                click: function ($event) {
                  return _vm.selectItem({
                    item: item,
                    index: index,
                    closeOnSelect: true,
                  })
                },
              },
            },
            [
              _vm._t(
                "item",
                function () {
                  return [_vm._v(_vm._s(_vm.itemName(item)))]
                },
                { item: item }
              ),
            ],
            2
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", {
          class: _vm.messageClass,
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }