var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c("div", { staticClass: "flex space-x-4" }, [
            _vm.hasIcon
              ? _c("div", { staticClass: "flex-none" }, [_vm._t("icon")], 2)
              : this.type === "info"
              ? _c(
                  "div",
                  { staticClass: "flex-none" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "circle-info"] },
                    }),
                  ],
                  1
                )
              : this.type === "success"
              ? _c(
                  "div",
                  { staticClass: "flex-none" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "circle-check"] },
                    }),
                  ],
                  1
                )
              : this.type === "warning"
              ? _c(
                  "div",
                  { staticClass: "flex-none" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                  ],
                  1
                )
              : this.type === "danger"
              ? _c(
                  "div",
                  { staticClass: "flex-none" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "circle-exclamation"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow base-alert-text" },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _vm.dismissible
              ? _c(
                  "div",
                  { staticClass: "flex-none self-center" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "circle-xmark"] },
                      on: { click: _vm.close },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.hasAction
            ? _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [_vm._t("action")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasTime
            ? _c("base-timer", {
                attrs: { "auto-start": "", countdown: "", time: _vm.delay },
                on: { end: _vm.close },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }