var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { fullscreen: "", dense: "", "disable-closing": "" } },
    [_c("chat")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }