<template>
    <development>
        <div>
            <development-heading>Link</development-heading>

            <base-link href="#">Link</base-link>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>color</strong>: String - <em>'primary', 'secondary'</em></li>
            </ul>

            <development-heading>Color</development-heading>
            <development-paragraph><strong>type</strong> : primary / secondary</development-paragraph>
            <development-paragraph>
                <base-link href="#" color="primary">Link primary</base-link>
            </development-paragraph>
            <development-paragraph>
                <base-link href="#" color="secondary">Link secondary</base-link>
            </development-paragraph>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseLink from "../elements/BaseLink";

export default {
    components: {BaseLink, DevelopmentParagraph, DevelopmentHeading, Development}
}
</script>
