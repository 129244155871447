<template>
    <base-button :class="classes"
                 :color="color"
                 :outlined="outlined"
                 small
                 @click="clickEvent">
        <span class="offer-card-button-label">
            <font-awesome-icon v-if="hasIcon" :icon="icon" size="lg" class="mx-2"/>
            <template v-if="!small">{{ label }}</template>
        </span>
    </base-button>
</template>

<style lang="scss">
.offer-card-button-label {
    @apply max-w-full inline-block align-middle whitespace-nowrap overflow-ellipsis overflow-hidden;
}
</style>

<script>

import BaseButton from "../elements/BaseButton";

export default {
    name: 'OfferCardAction',
    components: {BaseButton},
    props: {
        label: String,
        color: String,
        icon: Array,
        outlined: Boolean,
        small: Boolean,
    },
    methods: {
        clickEvent() {
            if (!this.hasClickListener) {
                return false;
            }

            this.$emit('click');
        }
    },
    computed: {
        hasClickListener() {
            return this.$listeners && this.$listeners.click;
        },
        hasIcon() {
            return this.icon !== undefined;
        },
        classes() {
            return {
                'offer-card-action': true,
                'pointer-events-none': !this.hasClickListener,
            }
        },
    }
}
</script>
