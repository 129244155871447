<template>
    <div v-if="displayBasket" class="reset-this pl-4 pr-2">
        <base-menu icon drop-left open-on-hover>
            <base-button icon small color="white" @click="orderAction">
                <font-awesome-icon :icon="['fas', 'basket-shopping']" size="lg" />
                <base-pin class="text-xs" :amount="getNbItemsBasket" />
            </base-button>
            <template #header>
                <div class="header-basket-menu-title">{{ trans.get('header.basket.my_basket') }}</div>
            </template>
            <template #items>
                <base-menu-item v-if="isBasketEmpty" class="header-basket-menu-item">
                    {{ trans.get('header.basket.your_basket_is_empty') }}
                </base-menu-item>
                <base-menu-item v-else background-color="dark" color="white" class="text-sm">
                    <base-button icon small color="white" class="float-right" @click="emptyBasket">
                        <font-awesome-icon :icon="['fal', 'trash-can']" />
                    </base-button>
                    <div @click="redirectToOfferPage">
                        <p>{{ getBasketDetails.digitalOfferProductTitle }}</p>
                        <p class="grid grid-cols-2">
                            <span class="italic">{{ trans.get('header.basket.quantity') }} : {{ getBasketDetails.quantity }}</span>
                            <span class="text-right">{{ getBasketDetails.orderPrice / 100 | formatMoney }}</span>
                        </p>
                    </div>
                </base-menu-item>
            </template>
            <template #footer>
                <div v-if="!isBasketEmpty" class="flex bg-neutral-500 justify-center py-2">
                    <base-button small color="danger" class="text-white uppercase" @click="orderAction">
                        {{ trans.get('header.basket.order') }}
                    </base-button>
                </div>
            </template>
        </base-menu>
    </div>
</template>

<style lang="scss">
    .header-basket-menu-title {
        @apply h-8 bg-neutral-800 text-white text-sm p-2 uppercase;
    }
</style>

<script>
import BaseButton from '../elements/BaseButton';
import BaseMenu from '../elements/BaseMenu';
import BaseMenuItem from '../elements/BaseMenuItem';
import BasePin from '../elements/BasePin';
import {mapActions, mapGetters} from "vuex";
export default {
    name: "Basket",
    components: {BaseButton, BaseMenu, BaseMenuItem, BasePin},
    computed: {
        ...mapGetters('digital', ['getBasketDetails', 'getNbItemsBasket', 'getUrlAction', 'isBasketEmpty']),
        displayBasket: function() {
            return !this.isBasketEmpty;
        }
    },
    methods: {
        ...mapActions('digital', ['emptyBasket', 'fetchBasket']),
        redirectToOfferPage() {
            window.location.href = this.getBasketDetails.digitalOfferUrl;
        },
        orderAction() {
            window.location.href = this.getUrlAction;
        }
    },
    mounted() {
        this.fetchBasket();
    },
}
</script>
