var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasError && _vm.errorTop
      ? _c("div", {
          staticClass: "text-danger mb-1 text-sm",
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "relative" }, [
      _c(
        "div",
        {
          ref: "input_container",
          class: _vm.inputContainerClasses,
          on: { click: _vm.focusInput },
        },
        [
          _c("textarea", {
            ref: "input",
            class: _vm.inputClasses,
            style: _vm.inputStyles,
            attrs: {
              id: _vm.id,
              name: _vm.name,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              maxlength: _vm.maxLength,
              "auto-grow": _vm.autoGrow,
              rows: _vm.rows,
              readonly: _vm.readonly,
            },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.updateValueAndInputSize($event.target.value)
              },
              keydown: function ($event) {
                return _vm.$emit("keydown", $event)
              },
              keypress: function ($event) {
                return _vm.$emit("keypress", $event)
              },
              focus: function ($event) {
                return _vm.$emit("focus", $event)
              },
              blur: function ($event) {
                return _vm.$emit("blur", $event)
              },
            },
          }),
          _vm._v(" "),
          _vm.hasLabel
            ? _c("label", { class: _vm.labelClasses, attrs: { for: _vm.id } }, [
                _vm._v(_vm._s(_vm.label)),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.hasIcon
        ? _c("div", { class: _vm.iconClasses }, [_vm._t("icon")], 2)
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.hasError && !_vm.errorTop
      ? _c("div", {
          staticClass: "text-danger mt-1 text-sm",
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }