var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("List")]),
        _vm._v(" "),
        _c(
          "base-list",
          [
            _c("base-list-item", [_vm._v("Item 1")]),
            _vm._v(" "),
            _c("base-list-item", [_vm._v("Item 2")]),
            _vm._v(" "),
            _c("base-list-item", [_vm._v("Item 3")]),
            _vm._v(" "),
            _c("base-list-item", [_vm._v("Item 4")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("List with title and description")]),
        _vm._v(" "),
        _c(
          "base-list",
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Title 1 :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v("Item 1")]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Title 2 :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v("Item 2")]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Title 3 :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v("Item 3")]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Title 4 :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v("Item 4")]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [
          _vm._v("List with icon, title and description"),
        ]),
        _vm._v(" "),
        _c(
          "base-list",
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Aliquet quam :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm._v(
                        "Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed\n                    magna dictum porta"
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Velit nisi :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm._v(
                        "Dictum porta. Quisque velit nisi, pretium ut lacinia in, elementum id\n                    enim"
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Sapien massa :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm._v(
                        "Praesent sapien massa, convallis a pellentesque nec"
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Sit amet ligula :")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v("Ullamcorper sit amet ligula")]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        "Dictum porta quisque velit nisi pretium ut lacinia in elementum id enim\n                    :"
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm._v("Curabitur aliquet quam id dui posuere blandit"),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Items - Array")]),
        _vm._v(" "),
        _c("base-list", { attrs: { items: _vm.list_1 } }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Items - Object")]),
        _vm._v(" "),
        _c("base-list", { attrs: { items: _vm.list_2 } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }