var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Select")]),
        _vm._v(" "),
        _c("base-select", { attrs: { items: _vm.items_1 } }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("items")]), _vm._v(": Array")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c("base-select", { attrs: { outlined: "", items: _vm.items_1 } }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Transparent")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bg-primary w-full p-4" },
          [
            _c("base-select", {
              attrs: { transparent: "", block: "", items: _vm.items_1 },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Placeholder")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            outlined: "",
            placeholder: "Input with placeholder",
            items: _vm.items_1,
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: { outlined: "", label: "Label", items: _vm.items_1 },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label + Placeholder")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            items: _vm.items_1,
            placeholder: "Input with placeholder",
            label: "Label",
            outlined: "",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Default and selected value")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            items: _vm.items_1,
            placeholder: "Input with placeholder",
            label: "Label",
            outlined: "",
          },
          model: {
            value: _vm.activeItem,
            callback: function ($$v) {
              _vm.activeItem = $$v
            },
            expression: "activeItem",
          },
        }),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "pt-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Value : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeItem))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Block")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            block: "",
            outlined: "",
            placeholder: "Input with placeholder",
            label: "Label",
            items: _vm.items_1,
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: { placeholder: "Input disabled", outlined: "", disabled: "" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Error")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            error: "This is an error",
            outlined: "",
            placeholder: "Input with placeholder",
            label: "Label",
            items: _vm.items_1,
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Array name/value")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            outlined: "",
            placeholder: "Input with placeholder",
            label: "Label",
            items: _vm.items_2,
          },
          model: {
            value: _vm.activeItem2,
            callback: function ($$v) {
              _vm.activeItem2 = $$v
            },
            expression: "activeItem2",
          },
        }),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "pt-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Value : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeItem2))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Custom slots")]),
        _vm._v(" "),
        _c("base-select", {
          attrs: {
            outlined: "",
            items: _vm.getCountries(["FR", "BE", "GB", "US"]),
          },
          scopedSlots: _vm._u([
            {
              key: "placeholder",
              fn: function () {
                return [
                  _c("span", { staticClass: "vti__flag inline-block" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("+")]),
                ]
              },
              proxy: true,
            },
            {
              key: "selected",
              fn: function () {
                return [
                  _c("span", {
                    staticClass: "vti__flag inline-block",
                    class: _vm.activeCountry.iso2.toLowerCase(),
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(`+${_vm.activeCountry.dialCode}`)),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function (data) {
                return [
                  _c("span", {
                    staticClass: "vti__flag inline-block",
                    class: data.item.iso2.toLowerCase(),
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(data.item.name) +
                        " " +
                        _vm._s(`+${data.item.dialCode}`)
                    ),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.activeCountry,
            callback: function ($$v) {
              _vm.activeCountry = $$v
            },
            expression: "activeCountry",
          },
        }),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "pt-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Iso : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeCountry.iso2))]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Name : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeCountry.name))]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Dial Code : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeCountry.dialCode))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }