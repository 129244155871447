<template>
    <div v-show="displayList">
        <h1 class="text-center text-primary lg:text-2xl text-xl font-bold mb-1">
            {{ trans.get('generic.highlighted_masters') }}
        </h1>

        <div class="highlighted-offer-list-container">
            <div ref="swiper" class="highlighted-offer-list swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-if="loading" v-for="n in cardsPerPage">
                        <offer-card-small-loader ref="offer-card-loader" :key="'offer-card-loader-' + n"/>
                    </div>

                    <div class="swiper-slide" v-for="offer in offers">
                        <offer-card-small :key="offer.id"
                                          :offer="offer"
                                          :user="getLoggedUserInfos"
                                          :offer_list_type="offerListType"
                                          :is_prime_enabled="config.get('prime.enabled')"
                                          :is_free_chat_enabled_for_prospect="config.get('chat.free_chat_enabled_for_prospect')"
                                          :user_credits="getCredits"
                        />
                    </div>
                </div>

                <base-infinite-pagination :slider="slider"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.highlighted-offer-list-container {
    @apply w-full overflow-hidden;

    .highlighted-offer-list {
        @apply mx-6 sm:mx-0;

        &.swiper {
            @apply overflow-visible lg:overflow-hidden;
        }

        .swiper-slide {
            @apply py-2 px-2 sm:px-2;
        }
    }
}
</style>

<script>
import Swiper from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import BaseSelectConversationType from "../elements/custom/BaseSelectConversationType.vue";
import OfferCard from "./OfferCard.vue";
import OfferCardLoader from "./OfferCardLoader.vue";
import OfferCardSmall from "./OfferCardSmall.vue";
import OfferCardSmallLoader from "./OfferCardSmallLoader.vue";
import OfferListMixin from "./OfferListMixin";

import {mapGetters} from "vuex";
import {ziggy_route} from "../ZiggyMixin";
import BaseInfinitePagination from "../elements/custom/BaseInfinitePagination.vue";

export default {
    name: "HighlightedOfferList",
    mixins: [OfferListMixin],
    components: {
        BaseInfinitePagination,
        OfferCardSmallLoader, OfferCardLoader, BaseSelectConversationType, OfferCardSmall, OfferCard
    },
    props: {
        categoryId: Number,
    },
    mounted() {
        this.initList();
        this.initSlider();
        this.initStatusChangedListener();
    },
    computed: {
        ...mapGetters('user', ['getLoggedUserInfos']),
        ...mapGetters('payment', ['getCredits']),
        cardsPerPage() {
            return 3;
        },
        displayList() {
            return this.loading || this.offers.length > 0;
        },
    },
    methods: {
        initList() {
            this.loading = true;

            let params = {
                category_id: this.categoryId,
                offer_list_type: this.offerListType,
                page: 1,
                filters: [],
            };

            axios.get(ziggy_route('offer-list.get', params)).then(response => {
                response.data.data.forEach((offer) => {
                    this.createOrUpdateOffer(offer);
                });

                this.loading = false;
                this.offerListReady = true;

                this.$nextTick(() => {
                    if (this.slider !== null) {
                        this.slider.update();
                    }
                });
            }).catch(error => {
                console.error(error);
            });
        },
        initSlider() {
            this.slider = new Swiper(this.$refs.swiper, {
                loop: true,
                slidesPerView: 1,
                centeredSlides: true,
                centerInsufficientSlides: true,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1024: {
                        slidesPerView: 3,
                        centeredSlides: false,
                    },
                },
            });
        },
    },
    data() {
        return {
            conversationType: null,
            loading: true,
            offerListType: "offer_highlight",
            slider: null,
        }
    }
}
</script>
