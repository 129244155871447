<template>
    <development>
        <div>
            <development-heading>Separator</development-heading>

            <base-alert type="info" block>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna
                dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin molestie
                malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Pellentesque in ipsum
                id orci porta dapibus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla porttitor
                accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Proin eget
                tortor risus.
            </base-alert>
            <base-separator>this is a separator</base-separator>
            <base-alert type="info" block>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna
                dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin molestie
                malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Pellentesque in ipsum
                id orci porta dapibus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla porttitor
                accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Proin eget
                tortor risus.
            </base-alert>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseSeparator from "../elements/BaseSeparator";
import BaseAlert from "../elements/BaseAlert";

export default {
    components: {BaseAlert, BaseSeparator, DevelopmentHeading, Development}
}
</script>
