var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _vm.showStartConsultation
        ? [
            _vm.isVoucher
              ? _c("modal-heading", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get(
                          "chat.modals.you_ll_be_put_in_contact_with"
                        )
                      ) +
                      "\n        "
                  ),
                ])
              : _c("modal-heading", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get("chat.modals.you_ll_be_put_in_touch_with")
                      ) +
                      "\n        "
                  ),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "-mx-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "bg-neutral-50 flex items-center mb-5 p-5 w-full",
                },
                [
                  _c("img", {
                    staticClass: "mr-5 rounded-full w-20",
                    attrs: { src: _vm.offer.photo, alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "span",
                        { staticClass: "font-bold text-xl text-primary" },
                        [_vm._v(_vm._s(_vm.offer.name))]
                      ),
                      _vm._v(" "),
                      _vm.prospectCanConsultForFree
                        ? _c("base-list-item", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["fas", "circle-chevron-right"],
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                _vm.isChat
                                  ? {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.trans.get(
                                                  "chat.free_credit_for_the_first_chat"
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.trans.get(
                                                  "call.free_credit_for_the_first_call"
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                              ],
                              null,
                              true
                            ),
                          })
                        : !_vm.isVoucher
                        ? _c("base-list-item", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["fas", "circle-chevron-right"],
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        _vm._s(_vm.trans.get("generic.rate")) +
                                          " :"
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "description",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("formatMoneyPerMinute")(
                                              _vm.costByMinute / 100
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                      _vm.hasUserMobileTax
                                        ? [
                                            _vm._v(
                                              "\n                                + " +
                                                _vm._s(
                                                  _vm._f(
                                                    "formatMoneyPerMinute"
                                                  )(_vm.userMobileTax / 100)
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.trans.get(
                                                    "generic.for_mobile"
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAdditionalCost
                                        ? [
                                            _vm._v(
                                              "\n                                + " +
                                                _vm._s(
                                                  _vm._f("formatMoneyPerCall")(
                                                    _vm.costByCallAdditional /
                                                      100
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2349169184
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "base-list",
              { staticClass: "ml-3" },
              [
                _vm.isCall
                  ? _c("base-list-item", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fas", "circle-chevron-right"],
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "description",
                            fn: function () {
                              return [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans.get(
                                        "generic.i_want_to_be_called_back"
                                      )
                                    ) + " : "
                                  ),
                                ]),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("formatPhoneNumber")(
                                        _vm.userPhoneNumber,
                                        "national"
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "base-link",
                                  {
                                    attrs: { color: "dark" },
                                    on: { click: _vm.updatePhoneNumber },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.trans.get(
                                            "generic.modify_my_number"
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1211256403
                      ),
                    })
                  : _vm.isChat
                  ? _c("base-list-item", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fas", "circle-chevron-right"],
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans.get(
                                      "chat.modals.start_chat_live_infos"
                                    )
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4103158570
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isVoucher
                  ? _c("base-list-item", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fas", "circle-chevron-right"],
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.trans.get("generic.credit")) +
                                    " :\n                "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatMoneyShort")(
                                      _vm.availableCredits / 100
                                    )
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3423673136
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                staticClass: "mt-6",
                attrs: { block: "", loading: this.loading },
                on: { click: _vm.submitCredit },
              },
              [
                _vm.isFastPass
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("generic.use_fast_pass")) +
                          "\n            "
                      ),
                    ]
                  : _vm.isVoucher
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("generic.use_voucher")) +
                          "\n            "
                      ),
                    ]
                  : _vm.isCall
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("call.start_call")) +
                          "\n            "
                      ),
                    ]
                  : _vm.isChat
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trans.get(
                              "chat.modals.start_chat_live_button_label"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans.get("generic.validate_my_choice")) +
                          "\n            "
                      ),
                    ],
              ],
              2
            ),
          ]
        : [
            _c("modal-heading", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get("generic.start_my_consultation_with", {
                      master_name: _vm.masterName,
                    })
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-4 mb-4" },
              [
                _c(
                  "base-list",
                  [
                    _vm.isCall
                      ? _c("base-list-item", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["fas", "circle-chevron-right"],
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "description",
                                fn: function () {
                                  return [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans.get(
                                            "generic.i_want_to_be_called_back"
                                          )
                                        ) + " : "
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("formatPhoneNumber")(
                                            _vm.userPhoneNumber,
                                            "national"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "base-link",
                                      {
                                        attrs: { color: "dark" },
                                        on: { click: _vm.updatePhoneNumber },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.trans.get(
                                                "generic.modify_my_number"
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3661202003
                          ),
                        })
                      : _vm.isChat
                      ? _c("base-list-item", {
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fas", "circle-chevron-right"],
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans.get(
                                        "chat.modals.start_chat_live_infos"
                                      )
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-list-item", {
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "circle-chevron-right"],
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      _vm.insufficientCredit
                                        ? "generic.insufficient_credit"
                                        : "generic.credit"
                                    )
                                  ) +
                                  " :\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "description",
                          fn: function () {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatMoneyShort")(
                                    _vm.availableCredits / 100
                                  )
                                )
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("base-list-item", {
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "circle-chevron-right"],
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                _vm._s(_vm.trans.get("generic.rate")) + " :"
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "description",
                          fn: function () {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatMoneyPerMinute")(
                                    _vm.costByMinute / 100
                                  )
                                ) + "\n                        "
                              ),
                              _vm.hasUserMobileTax
                                ? [
                                    _vm._v(
                                      "\n                            + " +
                                        _vm._s(
                                          _vm._f("formatMoneyPerMinute")(
                                            _vm.userMobileTax / 100
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.trans.get("generic.for_mobile")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAdditionalCost
                                ? [
                                    _vm._v(
                                      "\n                            + " +
                                        _vm._s(
                                          _vm._f("formatMoneyPerCall")(
                                            _vm.costByCallAdditional / 100
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showUseCredit
              ? [
                  _c("modal-heading", { attrs: { level: 2 } }, [
                    _vm._v(_vm._s(_vm.trans.get("generic.use_credit"))),
                  ]),
                  _vm._v(" "),
                  _c("base-radio-input-billing-type", {
                    attrs: {
                      value: "credit",
                      title: this.trans.get("generic.available_credit"),
                      description: _vm._f("formatMoney")(
                        _vm.availableCredits / 100
                      ),
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ]
              : _vm.showBuyPack
              ? [
                  _c(
                    "div",
                    { staticClass: "mb-6" },
                    [
                      _c("modal-heading", { attrs: { level: 2 } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.trans.get(
                                "generic.modals.payment_options.refill_credits"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("modal-paragraph", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.trans.get(
                                "generic.modals.payment_options.when_credits_end_consultation_is_stopped"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-3" },
                    [
                      _c("base-radio-input-pack", {
                        attrs: {
                          value: _vm.packs[0].id,
                          cost: _vm.packs[0].cost,
                          credits: _vm.packs[0].credits,
                          "pack-number": 1,
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                      _vm._v(" "),
                      _c("base-radio-input-pack", {
                        attrs: {
                          value: _vm.packs[1].id,
                          cost: _vm.packs[1].cost,
                          credits: _vm.packs[1].credits,
                          "pack-number": 2,
                          "most-popular": "",
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                      _vm._v(" "),
                      _c("base-radio-input-pack", {
                        attrs: {
                          value: _vm.packs[2].id,
                          cost: _vm.packs[2].cost,
                          credits: _vm.packs[2].credits,
                          "pack-number": 3,
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.showUsePayPerUse
              ? [
                  _c("base-separator", [
                    _vm._v(_vm._s(_vm.trans.get("generic.or"))),
                  ]),
                  _vm._v(" "),
                  _vm.config.get("payment-methods.ppu.paypal") && !this.isChat
                    ? [
                        _c(
                          "div",
                          { staticClass: "mb-6" },
                          [
                            _c("modal-heading", { attrs: { level: 2 } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans.get("generic.pay_per_use_label")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("modal-paragraph", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans.get("generic.pay_per_use_title")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-2 mb-2" },
                          [
                            _c("base-radio-ppu-type", {
                              attrs: {
                                title: _vm.trans.get("payment.credit_card"),
                                cost_by_minute: _vm.costByMinute,
                                value: "cb",
                              },
                              on: { change: _vm.onChangePayPerUseType },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-radio-ppu-type", {
                              attrs: {
                                title: "PayPal",
                                cost_by_minute: _vm.costByMinute,
                                value: "paypal",
                              },
                              on: { change: _vm.onChangePayPerUseType },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "mb-6" },
                          [
                            _c("modal-heading", { attrs: { level: 2 } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans.get("generic.pay_per_use_label")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("base-radio-input-billing-type", {
                          attrs: {
                            name: "billing_type",
                            value: "cb",
                            title: this.trans.get("generic.pay_per_use_title"),
                            description: _vm._f("formatMoneyPerMinute")(
                              _vm.costByMinute / 100
                            ),
                          },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        }),
                      ],
                  _vm._v(" "),
                  _vm.billingTypeIsPayPerUse
                    ? _c(
                        "div",
                        [
                          _c(
                            "base-checkbox",
                            {
                              attrs: {
                                error: _vm.generalConditionsError,
                                block: "",
                              },
                              on: { change: _vm.onChangeGeneralConditions },
                              model: {
                                value: _vm.generalConditionsValue,
                                callback: function ($$v) {
                                  _vm.generalConditionsValue = $$v
                                },
                                expression: "generalConditionsValue",
                              },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.generalConditions),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-checkbox",
                            {
                              attrs: {
                                error: _vm.rightOfWithdrawalError,
                                block: "",
                              },
                              on: { change: _vm.onChangeGeneralConditions },
                              model: {
                                value: _vm.rightOfWithdrawalValue,
                                callback: function ($$v) {
                                  _vm.rightOfWithdrawalValue = $$v
                                },
                                expression: "rightOfWithdrawalValue",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.accept_right_of_withdrawal"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-6" },
              [
                !this.showPayPalButton
                  ? [
                      _c(
                        "base-button",
                        {
                          attrs: { block: "", loading: this.loading },
                          on: { click: _vm.submit },
                        },
                        [
                          this.isFastPass
                            ? [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.trans.get("generic.use_fast_pass")
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.trans.get(
                                        "generic.validate_my_choice"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.showPayPalButton,
                        expression: "this.showPayPalButton",
                      },
                    ],
                  },
                  [
                    _vm.isPaypalComponentLoading
                      ? _c("base-button", {
                          attrs: { disabled: "", loading: "", block: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isPaypalComponentLoading,
                          expression: "!isPaypalComponentLoading",
                        },
                      ],
                      attrs: { id: "paypal-component" },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-center mt-4 ml-2 sm:ml-4 sm:mb-8" },
              _vm._l(
                _vm.config.get("payment-methods.ppu.displayed_brands"),
                function (brand) {
                  return _c("div", { staticClass: "mr-2 sm:mr-4 max-w-12" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.assetManager.getImageAsset(
                          "payment/brands/" + brand + ".png"
                        ),
                        alt: "Brands",
                      },
                    }),
                  ])
                }
              ),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }