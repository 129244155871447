var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Rating")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Rating")]),
          _vm._v(" - Also check it on mobile !"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [_c("base-rating")],
          1
        ),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("value")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("minValue")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("maxValue")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("size")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'xs', 'sm', 'lg'")]),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Value")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("Default "),
          _c("strong", [_vm._v("value")]),
          _vm._v(' - ex: value="3"'),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [
            _c(
              "development-paragraph",
              [_c("base-rating", { attrs: { value: 3 } })],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Using v-model")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [
            _c(
              "development-paragraph",
              [
                _c("base-rating", {
                  model: {
                    value: _vm.value_1,
                    callback: function ($$v) {
                      _vm.value_1 = $$v
                    },
                    expression: "value_1",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.value_1) + " / 5"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Size")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Size value")]),
          _vm._v(": String - "),
          _c("em", [_vm._v("'xs', 'sm', 'lg'")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center md:text-left" }, [
          _c(
            "div",
            { staticClass: "text-center md:text-left" },
            [_c("base-rating", { attrs: { size: "xs" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center md:text-left" },
            [_c("base-rating", { attrs: { size: "sm" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center md:text-left" },
            [_c("base-rating")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center md:text-left" },
            [_c("base-rating", { attrs: { size: "lg" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("development-paragraph", [_vm._v('Ex: disabled & value="3"')]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [_c("base-rating", { attrs: { value: 3, disabled: "" } })],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Min Value")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Minimum value")]),
          _vm._v(' - ex: min-value="2"'),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [
            _c(
              "development-paragraph",
              [
                _c("base-rating", {
                  attrs: { "min-value": 2 },
                  model: {
                    value: _vm.value_2,
                    callback: function ($$v) {
                      _vm.value_2 = $$v
                    },
                    expression: "value_2",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.value_2) + " / 5"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Max Value")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Maximum value")]),
          _vm._v(' - ex: max-value="21"'),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center md:text-left" },
          [
            _c(
              "development-paragraph",
              [
                _c("base-rating", {
                  attrs: { "max-value": 21 },
                  model: {
                    value: _vm.value_3,
                    callback: function ($$v) {
                      _vm.value_3 = $$v
                    },
                    expression: "value_3",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.value_3) + " / 21"),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }