<template>
    <base-modal>
        <img v-if="showGameBanner"
             :src="gameBanner"
             class="w-full mb-4"/>

        <modal-heading>
            {{ trans.get('payment.credit_my_account') }}
        </modal-heading>

        <div class="ml-4 mb-6">
            <base-list>
                <base-list-item>
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>
                        {{ trans.get('generic.credit') }} :
                    </template>
                    <template #description>{{ availableCredits / 100 | formatMoneyShort }}</template>
                </base-list-item>
            </base-list>
        </div>

        <div v-if="showBuyPack" class="grid grid-cols-3">
            <base-radio-input-pack
                v-model="value"
                :value="packs[0].id"
                :cost="packs[0].cost"
                :credits="packs[0].credits"
                :pack-number="1"
            />
            <base-radio-input-pack
                v-model="value"
                :value="packs[1].id"
                :cost="packs[1].cost"
                :credits="packs[1].credits"
                :pack-number="2"
                most-popular
            />
            <base-radio-input-pack
                v-model="value"
                :value="packs[2].id"
                :cost="packs[2].cost"
                :credits="packs[2].credits"
                :pack-number="3"
            />
        </div>

        <base-button class="mt-6" block @click="submit" :loading="this.loading">
            {{ trans.get('generic.validate_my_choice') }}
        </base-button>

        <div class="flex justify-center mt-4 ml-2 sm:ml-4 sm:mb-8">
            <div v-for="brand in config.get('payment-methods.ppu.displayed_brands')"
                 class="mr-2 sm:mr-4 max-w-12">
                <img :src="assetManager.getImageAsset('payment/brands/' + brand + '.png')" alt="Brands"/>
            </div>
        </div>
    </base-modal>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ModalHeading from "../../modals/elements/ModalHeading";
import BaseList from "../../elements/BaseList";
import BaseListItem from "../../elements/BaseListItem";
import BaseRadioInputPack from "../../elements/custom/BaseRadioInputPack";
import BaseTimer from "../../elements/BaseTimer";

export default {
    name: "ModalPackOptions",
    components: {BaseTimer, BaseRadioInputPack, BaseListItem, BaseList, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam', 'getLoading']),
        ...mapGetters('payment', ['getPacksToDisplay', 'getCredits', 'selectFirstPackByDefault']),
        ...mapGetters('game', ['getActiveGameSlug']),
        showBuyPack() {
            return this.packs.length > 0;
        },
        packs() {
            return this.getPacksToDisplay;
        },
        isGameSpecialRefill() {
            return this.packs[0].pack_type === "recharge_speciale_game_client" || this.packs[0].pack_type === "recharge_speciale_game_prospect";
        }
    },
    methods: {
        ...mapActions('modals', ['closeModal', 'openModal', 'setModalIsLoading']),
        ...mapActions('payment', ['loadPaymentInfo']),
        checkRefillIsGameRefill() {
            if (this.isGameSpecialRefill && this.packs[0].hasOwnProperty('end_at') && this.getActiveGameSlug) {
                this.gameBanner = this.assetManager.getImageAsset('game/' + this.getActiveGameSlug + '/website/popup/top_refill.jpg')
                this.showGameBanner = true;
            }
        },
        submit() {
            this.closeModal();

            let oid = this.offer ? this.offer.id : 0;

            ajax_popup('buy', {
                oid: oid,
                p: this.value,
                type: this.consultationType,
                apple_pay_available: window.ApplePaySession ? 'true' : 'false'
            });
        }
    },
    async mounted() {
        this.setModalIsLoading(true);

        this.consultationType = this.getParam['pack-options']['consultation_type'];
        this.offerId = this.getParam['pack-options']['offer_id'];

        this.loadPaymentInfo(() => {
            this.$nextTick(() => {
                this.availableCredits = this.getCredits;
                this.setModalIsLoading(false);
                this.value = (this.selectFirstPackByDefault) ? this.packs[0].id : this.packs[1].id;
                this.checkRefillIsGameRefill();
            });
        });
    },
    data() {
        return {
            availableCredits: '',
            consultationType: '',
            gameBanner: '',
            isPaymentInfoLoading: true,
            loading: false,
            offer: {},
            offerId: null,
            showGameBanner: false,
            value: null,
        };
    },
}
</script>
