var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.inputClass },
      [
        _vm._l(_vm.values, function (v, index) {
          return [
            _c("input", {
              key: `${_vm.id}-${index}`,
              ref: _vm.iRefs[index],
              refInFor: true,
              staticClass: "base-code-input-field",
              attrs: {
                type: "tel",
                pattern: "[0-9]",
                "data-id": index,
                disabled: _vm.disabled,
                maxlength: "1",
                placeholder: _vm.placeholder,
              },
              domProps: { value: v },
              on: {
                input: _vm.onValueChange,
                focus: _vm.onFocus,
                keydown: _vm.onKeyDown,
              },
            }),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", {
          class: _vm.messageClass,
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }