var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-card-medium" }, [
    _c("div", { staticClass: "flex items-center w-full" }, [
      _c(
        "div",
        { staticClass: "flex-none pr-3" },
        [
          _c("base-avatar", {
            attrs: { color: "neutral-secondary", "skeleton-loader": "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "offer-card-actions" },
      [
        _c("base-button", {
          staticClass: "offer-card-action h-9 w-14",
          attrs: {
            color: "neutral-secondary",
            small: "",
            "skeleton-loader": "",
          },
        }),
        _vm._v(" "),
        _c("base-button", {
          staticClass: "offer-card-action h-9 w-14",
          attrs: {
            color: "neutral-secondary",
            small: "",
            "skeleton-loader": "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-grow flex flex-col min-w-0" }, [
      _c("div", { staticClass: "flex-grow flex min-w-0" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-none w-10/12 bg-neutral-300 animate-pulse rounded",
          },
          [_c("p", [_vm._v(" ")])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h-1" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow flex min-w-0" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-none w-4/12 bg-neutral-300 animate-pulse rounded",
          },
          [_c("p", [_vm._v(" ")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }