import {ziggy_route} from '../ZiggyMixin';
import ChatMixin from './ChatMixin';
import {app} from '../../app'
import MessageMixin from "./MessageMixin";


export default {
    getConversations(page, conversation_list = 'all') {
        return axios.get(ziggy_route('chat.getConversations', {
            page: page,
            conversation_list: conversation_list
        })).catch((error) => {
            console.log(error);
        });
    },
    markConversationAsRead(participants) {
        return axios.post(ziggy_route('chat.readConversation'), {participants}).catch((errorResponse) => {
            console.log(errorResponse);
        });
    },
    markConversationAsUnread(participants) {
        return axios.post(ziggy_route('chat.unreadConversation'), {participants}).catch((errorResponse) => {
            console.log(errorResponse);
        });
    },
    loadConversationMessages(participants) {
        return axios.get(ziggy_route('chat.getParticipantMessages', {
            'type_1': ChatMixin.methods.me(participants).type,
            'id_1': ChatMixin.methods.me(participants).id,
            'type_2': ChatMixin.methods.interlocutor(participants).type,
            'id_2': (ChatMixin.methods.interlocutor(participants).id !== undefined) ? ChatMixin.methods.interlocutor(
                participants).id : 0,
        })).catch((error) => {
            console.log(error);
        });
    },
    sendMessage(message, successCallback, errorCallback) {
        let formData = new FormData();
        formData.append('type', message.type);
        formData.append('content', message.content.text);
        formData.append('participants', JSON.stringify({
            from: message.participant,
            to: ChatMixin.methods.interlocutor(),
        }));
        if (message.content.file !== null) {
            formData.append('file', message.content.file);
        }

        return axios.post(ziggy_route('chat.sendMessage'), formData)
            .then((response) => {
                this.sendMessageSucceed = true;
                message.id = response.data.data.id;
                message.content.text = response.data.data.content.text;
                message.created_at = response.data.data.created_at;
                message.updated_at = response.data.data.updated_at;
                message.read = false;

                if (successCallback) {
                    successCallback(message);
                }
                const participants = app.$store.getters['chat/getSelectedParticipants'];
                const conversation = app.$store.getters['chat/getConversation'](participants);
                const messages = app.$store.getters['chat/getConversationMessages'](participants);
                app.$store.dispatch('chat/setConversationMessages', {conversation, messages});
            })
            .catch((errorResponse) => {
                MessageMixin.methods.messageNotSent(message);

                if (errorResponse.response !== undefined && errorResponse.response.data !== undefined) {
                    if (app.$store.getters["chat/isLiveConversation"]) {
                        if (errorResponse.response.data.message === 'No active client chat Session') {
                            app.$store.dispatch('chat/handlingChatStoppedForClientEventMissing');
                        } else if (errorResponse.response.data.message === 'No active master chat Session') {
                            app.$store.dispatch('handlingChatStoppedForMasterEventMissing');
                        }
                    }
                }

                if (errorCallback) {
                    errorCallback(errorResponse);
                }
            });
    },
    sendFollowUpMessage(message) {
        let formData = new FormData();
        formData.append('type', message.type);
        formData.append('content', message.content.text);
        formData.append('participants', JSON.stringify({
            from: message.participant,
            to: ChatMixin.methods.interlocutor(),
        }));
        if (message.content.file !== null) {
            formData.append('file', message.content.file);
        }

        return axios.post(ziggy_route('chat.sendFollowUpMessage'), formData);
    },
    startLiveChat(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.startLiveChat'), data).then(successCallback).catch(errorCallback);
    },
    acceptLiveChat(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.acceptLiveChat'), data).then(successCallback).catch(errorCallback);
    },
    refuseLiveChat(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.refuseLiveChat'), data).then(successCallback).catch(errorCallback);
    },
    stopLiveChat(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.stopLiveChat'), data).then(successCallback).catch(errorCallback);
    },
    getLiveSession(successCallback) {
        return axios.get(ziggy_route('chat.getLiveSession')).then(successCallback).catch();
    },
    sendNotifMasterOnline(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('user.sendNotifMasterOnline'), data).then(successCallback).catch(errorCallback);
    },
    addComment(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.addComment'), data).then(successCallback).catch(errorCallback);
    },
    getClientInformation(participants, successCallback) {
        return axios.get(ziggy_route('chat.getClientInformation', {
            'user_id': (ChatMixin.methods.interlocutor(participants).id !== undefined) ? ChatMixin.methods.interlocutor(
                participants).id : 0,
        })).then(successCallback).catch((error) => {
            console.log(error);
        });
    },
    askChatBotQuestion(participants, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.chatBot.ask'), {participants}).then(successCallback).catch(errorCallback);
    },
    answerChatBotQuestion(message) {
        let formData = new FormData();
        formData.append('type', message.type);
        formData.append('message', message.content.text);
        formData.append('participants', JSON.stringify({
            from: message.participant,
            to: ChatMixin.methods.interlocutor(),
        }));
        if (message.content.file !== null) {
            formData.append('file', message.content.file);
        }

        return axios.post(ziggy_route('chat.chatBot.answer'), formData);
    },
    searchConversations(data, cancelToken, successCallback, errorCallback, alwaysCallback) {
        return axios.post(ziggy_route('chat.conversations.search'), data, cancelToken)
            .then(successCallback)
            .catch(errorCallback)
            .then(alwaysCallback);
    },
    getAvailableOffers(successCallback, errorCallback, alwaysCallback) {
        return axios.get(ziggy_route('chat.getAvailableOffers'))
            .then(successCallback)
            .catch(errorCallback)
            .then(alwaysCallback);
    },
    getFavoriteOffers(successCallback, errorCallback, alwaysCallback) {
        return axios.get(ziggy_route('getFavorites'))
            .then(successCallback)
            .catch(errorCallback)
            .then(alwaysCallback);
    },
    changeMasterStatus(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('master.changeStatus'), data)
            .then(successCallback)
            .catch(errorCallback);
    },
    loadDigitalArtPresaleConversations(data, successCallback, errorCallback) {
        return axios.get(ziggy_route('digital.getPreSaleConversations'))
            .then(successCallback)
            .catch(errorCallback);
    },
    deleteMessage(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.deleteMessage'), data)
            .then(successCallback)
            .catch(errorCallback);
    },
    setStartRecord(data, successCallback, errorCallback) {
        return axios.post(ziggy_route('chat.audio.setStartRecord'), data)
            .then(successCallback)
            .catch(errorCallback);
    },
};
