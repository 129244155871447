<template>
    <div class="comment-card comment-card-loader">
        <div class="comment-card-header">
            <p class="comment-card-title">
                <span class="skeleton-text">&nbsp;</span>
            </p>
            <p class="comment-card-date">
                <span class="skeleton-text">&nbsp;</span>
            </p>
        </div>

        <div class="comment-card-content">
            <p class="comment-card-text">
                <span class="skeleton-text leading-4 max-h-4 mb-1">&nbsp;</span>
                <span class="skeleton-text leading-4 max-h-4 mb-1">&nbsp;</span>
                <span class="skeleton-text leading-4 max-h-4 mb-1">&nbsp;</span>
                <span class="skeleton-text leading-4 max-h-4 w-3/4">&nbsp;</span>
            </p>
        </div>
    </div>
</template>

<style lang="scss">
.comment-card-loader {
    .comment-card-title {
        @apply pr-8;
    }

    .comment-card-date {
        @apply w-1/4;
    }

    .skeleton-text {
        @apply block bg-neutral-300 animate-pulse rounded h-3/4;
    }
}
</style>

<script>
import BaseLink from "../elements/BaseLink.vue";

export default {
    name: "CommentCardLoader",
    components: {BaseLink},
}
</script>
