<template>
    <div class="w-full flex p-3 cursor-pointer hover:bg-neutral-100" @click="$emit('click', $event)">
        <div class="flex-none self-center pr-3">
            <base-avatar
                :name="nickname"
                :photo="avatarUrl"
                :status="status"
                color="primary"
                outlined/>
        </div>
        <div class="flex-grow self-center min-w-0 text-left">
            <p :class="nicknameClass" class="text-lg capitalize">{{ nickname }}</p>

            <div :class="messageClass">
                <span v-if="isAudioMessage"><font-awesome-icon :icon="['fal', 'microphone-lines']"/>&nbsp;</span>
                <span v-else-if="isStickerMessage"><font-awesome-icon :icon="['fas', 'note-sticky']"/>&nbsp;</span>
                <p class="flex-grow whitespace-nowrap overflow-ellipsis overflow-hidden pointer-events-none" v-html="message"></p>
                <p class="flex-none whitespace-nowrap">&nbsp;&middot;&nbsp;{{ writtenSince }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAvatar from '../elements/BaseAvatar';

export default {
    name: 'ConversationItem',
    components: {BaseAvatar},
    props: {
        nickname: {
            type: String,
            default: '',
        },
        avatarUrl: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: null,
        },
        message: {
            type: String | Number,
            default: '',
        },
        read: {
            type: Boolean,
            default: true,
        },
        writtenSince: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    computed: {
        nicknameClass() {
            return {
                'text-neutral-800 font-normal': this.read,
                'text-primary font-bold': !this.read,
            };
        },

        messageClass() {
            return {
                'flex flex-row': true,
                'text-neutral-500 font-normal': this.read,
                'text-primary font-bold': !this.read,
            };
        },
        isAudioMessage() {
            return this.type === 'audio';
        },
        isStickerMessage() {
            return this.type === 'sticker';
        },
    },
};
</script>
