var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.comments.length > 0
      ? _c(
          "h1",
          {
            staticClass:
              "text-center text-primary lg:text-2xl text-xl font-bold mb-1",
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.trans.get("generic.latest_customer_reviews")) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "comment-list-container" }, [
      _c(
        "div",
        {
          ref: "swiper",
          staticClass: "comment-list swiper",
          on: {
            mouseenter: _vm.disableAutoplay,
            mouseleave: _vm.enableAutoplay,
          },
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            [
              _vm._l(_vm.cardsPerPage, function (n) {
                return _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "swiper-slide" },
                      [
                        _c("comment-card-loader", {
                          key: "comment-card-loader-" + n,
                          ref: "comment-card-loader",
                          refInFor: true,
                        }),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.comments, function (comment) {
                return _c(
                  "div",
                  { staticClass: "swiper-slide" },
                  [
                    _c("comment-card", {
                      key: comment.id,
                      attrs: { comment: comment },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("base-infinite-pagination", { attrs: { slider: _vm.slider } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }