<template>
    <div class="conversation-hearts">
        <font-awesome-icon v-for="heart in hearts"
                           :key="heart.id"
                           :icon="['fas', 'heart']"
                           :style="heart.style"
                           class="conversation-heart"
        />
    </div>
</template>

<style lang="scss">
.conversation-hearts {
    @apply absolute w-full h-full bottom-0 left-0 pointer-events-none;

    .conversation-heart {
        @apply text-danger absolute bottom-0 pointer-events-none opacity-0;
        animation: falling-heart #{1000 + random(2000)}ms;
    }
}

@keyframes falling-heart {
    0% {
        @apply bottom-1 opacity-0;
    }

    3% {
        @apply bottom-0 opacity-100;
    }

    100% {
        @apply bottom-1/3 opacity-0;
    }
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'ConversationHearts',
    methods: {
        ...mapGetters('chat', ['getHearts']),
        random(max) {
            return Math.floor(Math.random() * max);
        },
        addHeart() {
            let size = ((100 + this.random(125)) / 100) + 'rem';
            let left = (this.random(350) / 100) + 'rem'
            let heart = {
                id: this.index,
                style: {
                    left: left,
                    width: size,
                    height: size,
                }
            }

            this.hearts.push(heart);
            this.index++;

            setTimeout(() => {
                this.hearts.splice(this.hearts.findIndex(item => item.id === heart.id), 1);
            }, 4000);
        },
    },
    created() {
        this.unwatchHearts = this.$store.watch(this.getHearts, () => {
            this.addHeart();
        });
    },
    beforeDestroy() {
        this.unwatchHearts();
    },
    data: function () {
        return {
            index: 0,
            hearts: [],
        };
    },
}
</script>
