<template>
    <p class="modal-paragraph">
        <slot></slot>
    </p>
</template>

<style lang="scss">
.modal-paragraph {
    @apply text-main mb-4;

    strong {
        @apply text-dark;
    }
}
</style>

<script>
export default {
    name: "ModalParagraph"
};
</script>
