import axios from "axios";
import {ziggy_route} from "../ZiggyMixin";

const OfferMixin = {
    data() {
        return {
            offerCardHighlight: false,
        }
    },
    computed: {
        isOffline() {
            return this.offer.status_details.status === 'offline';
        },
        isBusy() {
            return this.offer.status_details.status === 'busy';
        },
        isOnline() {
            return this.offer.status_details.status === 'online';
        },
        isPrimeOfferList() {
            return this.offer_list_type === 'prime_free_chat'
                || this.offer_list_type === 'prime_free_call'
                || this.offer_list_type === 'prime_free_call_masters_never_consulted';
        },
        canCall() {
            return this.offer.status_details.can_call
                && !this.offer.status_details.has_active_call
                && !this.offer.status_details.has_active_chat
                && this.offer.status_details.status === 'online'
                && !this.canChatFreePrime;
        },
        canCallFreePrime() {
            return this.offer_list_type === 'prime_free_call';
        },
        canCallFreePrimeWithMastersNeverConsulted() {
            return this.offer_list_type === 'prime_free_call_masters_never_consulted';
        },
        canCallWithFastPass() {
            return !this.isPrimeOfferList
                && (this.offer.status_details.has_active_call || this.offer.status_details.has_active_chat)
                && this.is_prime_enabled
                && this.offer.is_in_prime_category
                && this.user.is_prime
                && (this.offer.status_details.waiting_queue === undefined || this.offer.status_details.waiting_queue.length === 0);
        },
        canShowFastPassPresentation() {
            return !this.isPrimeOfferList
                && (this.offer.status_details.has_active_call || this.offer.status_details.has_active_chat)
                && this.is_prime_enabled
                && this.offer.is_in_prime_category
                && !this.user.is_prime
                && this.user.transfo === 'client'
                && (this.offer.status_details.waiting_queue === undefined || this.offer.status_details.waiting_queue.length === 0);
        },
        canPurchaseFastPass() {
            return this.canCallWithFastPass && this.user.can_buy_fast_pass;
        },
        canCallFree() {
            return !this.offer.status_details.no_free_calls
                && (!this.user.id || this.user.can_free_call)
                && this.user_credits >= this.offer.cost_by_minute;
        },
        canCallWithVoucher() {
            return this.user !== undefined
                && this.user.active_vouchers !== undefined
                && this.user.active_vouchers.find((voucher) => voucher.offer_id === this.offer.id) !== undefined;
        },
        canChat() {
            return this.offer_list_type !== 'horoscope'
                && this.offer.status_details.can_chat
                && !this.offer.status_details.has_active_call
                && !this.offer.status_details.has_active_chat
                && this.offer.status_details.status === 'online'
                && !(this.canCallFreePrime || this.canCallFreePrimeWithMastersNeverConsulted);
        },
        canChatFreePrime() {
            return this.offer_list_type === 'prime_free_chat';
        },
        canChatFree() {
            return this.is_free_chat_enabled_for_prospect
                && !this.offer.status_details.no_free_calls
                && (!this.user.id || this.user.can_free_chat)
                && this.user_credits >= this.offer.cost_by_minute;
        },
        canChatWithVoucher() {
            return this.user !== undefined
                && this.user.active_vouchers !== undefined
                && this.user.active_vouchers.find((voucher) => voucher.offer_id === this.offer.id) !== undefined;
        },
        fastPassActivated() {
            return this.offer.status_details.waiting_queue !== undefined
                && this.offer.status_details.waiting_queue.find((fast_pass) => fast_pass.user_id === this.user.id) !== undefined;
        },
        fastPassUnavailable() {
            return this.user.active_waiting_queue !== undefined
                && this.user.active_waiting_queue.length > 0
                && this.user.active_waiting_queue.find((fast_pass) => fast_pass.offer_id === this.offer.id) === undefined;
        },
        callInProgress() {
            return this.offer.status_details.has_active_call;
        },
        chatInProgress() {
            return this.offer.status_details.has_active_chat;
        },
        waitingQueueFull() {
            return this.user.transfo === 'client'
                && this.offer.status_details.waiting_queue !== undefined
                && this.offer.status_details.waiting_queue.length > 0;
        },
        statusColor() {
            if (this.isOffline) {
                return 'offline';
            }

            if (this.isBusy) {
                return 'busy';
            }

            return 'available';
        },
        hasSegment() {
            return ['new', 'gold', 'star', 'top', 'up', 'plus', 'super', 'elite'].indexOf(this.offer.segment) !== -1;
        },
        segmentContent() {
            return this.trans.get('generic.master_segment.' + this.offer.segment);
        },
        segmentColor() {
            switch (this.offer.segment) {
                case "new":
                    return "primary";
                case "gold":
                    return "prime";
                case "star":
                    return "secondary";
                case "top":
                    return "busy";
                case "up":
                    return "horoscope";
                case "plus":
                    return "available";
                case "super":
                    return "primary";
                case "elite":
                    return "primary";
            }
        }
    },
    methods: {
        isInViewport() {
            if (this.$refs['offer-card'] === undefined) return false;

            const rect = this.$refs['offer-card'].getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        highlight() {
            this.offerCardHighlight = true;
            setTimeout(() => {
                this.offerCardHighlight = false;
            }, 2000)
        },
        startCallFreePrime() {
            ajax_popup('prime_free_call_activation_confirm', {
                oid: this.offer.id,
                advantage_type: 'prime_free_call'
            });
        },
        startCallFreePrimeWithMastersNeverConsulted() {
            ajax_popup('prime_free_call_activation_confirm', {
                oid: this.offer.id,
                advantage_type: 'prime_free_call_masters_never_consulted'
            });
        },
        showFastPassPresentation() {
            ajax_popup('prime_presentation_fast_pass');
        },
        purchaseFastPass() {
            ajax_popup('prime_purchase_fast_pass', {oid: this.offer.id});
        },
        startCallWithFastPass() {
            ajax_popup('prime-usage', {
                oid: this.offer.id,
                is_app: 0,
                step: 'before_payment'
            });
        },
        startCallFree() {
            ajax_popup('call-start', {
                oid: this.offer.id,
                is_app: 0,
            });
        },
        startCallWithVoucher() {
            ajax_popup('call-start', {
                oid: this.offer.id,
                is_app: 0,
            });
        },
        startCall() {
            ajax_popup('call-start', {
                oid: this.offer.id,
                is_app: 0,
            });
        },
        startChat() {
            if (window.globalVue.$store.getters['chat/isLiveConversation']) {
                return false;
            }

            popup_close();
            window.globalVue.$store.dispatch('chat/startLiveChatProcess', {
                offerId: this.offer.id
            });
        },
        startChatWithVoucher() {
            if (window.globalVue.$store.getters['chat/isLiveConversation']) {
                return false;
            }

            popup_close();
            window.globalVue.$store.dispatch('chat/startLiveChatProcess', {
                offerId: this.offer.id,
                voucher: true,
            });
        },
        startChatFreePrime() {
            if (window.globalVue.$store.getters['chat/isLiveConversation']) {
                return false;
            }

            popup_close();
            window.globalVue.$store.dispatch('chat/startPrimeFreeLiveChatProcess', this.offer.id);
        },
        startChatFree() {
            if (window.globalVue.$store.getters['chat/isLiveConversation']) {
                return false;
            }

            popup_close();
            window.globalVue.$store.dispatch('chat/startLiveChatProcess', {
                offerId: this.offer.id
            });
        },
        notifyWhenMasterIsOnline() {
            axios.post(ziggy_route('offer.infoForSms'), {
                'user_id': window.globalVue.$store.getters['user/getLoggedUserInfos']['id'],
                'offer_id': this.offer.id
            }).then((data) => {
                let params = {
                    master: {
                        id: this.offer.id,
                        name: this.offer.name,
                    },
                    userFullMobilePhoneNumber: data.data.user_number
                };

                window.globalVue.$store.commit('modals/addParam', {
                    'name': globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE, 'param': params
                });

                window.globalVue.$store.commit('modals/show', globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE);
            }).catch((errorResponse) => {
                showModalV5(globals.GENERIC_ERROR);
            });
        },
    },
}

export default OfferMixin;
