<template>
    <span class="conversation-message-metadata">
        <transition name="flip" appear mode="out-in">
            <font-awesome-icon size="sm" v-if="error" class="text-danger" :icon="['fas', 'exclamation']" key="error"/>
            <font-awesome-icon size="sm" v-else-if="read" :icon="['far', 'check-double']" key="read"/>
            <font-awesome-icon size="sm" v-else-if="sent" :icon="['far', 'check']" key="sent"/>
        </transition>
    </span>
</template>

<style>
.flip-enter-active {
    animation: flip-in .5s;
}

.flip-leave-active {
    animation: flip-in .5s reverse;
}

@keyframes flip-in {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
</style>

<script>
export default {
    name: "ConversationMessageMetadata",
    props: {
        status: String,
    },
    computed: {
        read() {
            return this.status === 'read';
        },
        sent() {
            return this.status === 'sent';
        },
        error() {
            return this.status === 'error';
        }
    },
}
</script>
