var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "baseRating", class: _vm.ratingClass },
    [
      _vm._l(_vm.maxValue, function (starIndex) {
        return [
          _c(
            "div",
            {
              class: _vm.buttonClass(starIndex),
              on: {
                click: function ($event) {
                  return _vm.rate(starIndex)
                },
                mouseup: function ($event) {
                  return _vm.rate(starIndex)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "base-rating-icon--active",
                attrs: { icon: ["fas", "star"], size: _vm.size },
              }),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "base-rating-icon--inactive",
                attrs: { icon: ["fal", "star"], size: _vm.size },
              }),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }