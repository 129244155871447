var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "" } },
    [
      _c("modal-heading", [
        _vm._v(_vm._s(_vm.trans.get("chat.modals.authorize_microphone_title"))),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", {
        domProps: { innerHTML: _vm._s(_vm.description) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _c("base-button", { on: { click: _vm.closeModal } }, [
            _vm._v(_vm._s(_vm.trans.get("generic.i_understood"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }