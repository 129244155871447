<template>
    <development>
        <div>
            <development-heading>Phone input</development-heading>
            <base-phone-input/>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>autofocus</strong>: Boolean</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>error</strong>: String</li>
                <li><strong>hideCountries</strong>: Boolean</li>
                <li><strong>initialCountry</strong>: String</li>
                <li><strong>label</strong>: String</li>
                <li><strong>mobileOnly</strong>: Boolean</li>
                <li><strong>name</strong>: String</li>
                <li><strong>onlyCountries</strong>: Array</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>placeholder</strong>: String</li>
                <li><strong>value</strong>: String | Number</li>
            </ul>

            <development-heading>Placeholder</development-heading>
            <base-phone-input outlined placeholder="Phone number"/>

            <development-heading>Label</development-heading>
            <base-phone-input outlined label="Phone number"/>

            <development-heading>Label + Placeholder</development-heading>
            <base-phone-input outlined label="Phone number" placeholder="Phone number"/>

            <development-heading>Outlined</development-heading>
            <base-phone-input outlined placeholder="Phone number"/>

            <development-heading>Disabled</development-heading>
            <base-phone-input disabled placeholder="Phone number"/>

            <development-heading>Only countries</development-heading>
            <base-phone-input outlined label="Phone number" placeholder="Phone number" :only-countries="['FR', 'BE']"/>

            <development-heading>Initial Country</development-heading>
            <base-phone-input outlined label="Phone number" placeholder="Phone number" initialCountry="FR"
                              :only-countries="['FR', 'BE']"/>

            <development-heading>Hide countries</development-heading>
            <base-phone-input hide-countries outlined label="Phone number" placeholder="Phone number" initialCountry="FR" v-model="value_hidden"
                              :only-countries="['FR']"/>
            <base-list class="pt-4">
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Value : </template>
                    <template #description>{{ value_hidden }}</template>
                </base-list-item>
            </base-list>

            <development-heading>Value</development-heading>
            <development-paragraph>The default country is selected based on the <strong>value</strong>
            </development-paragraph>
            <base-phone-input ref="phone_input" outlined label="Phone number" placeholder="Phone number" v-model="value_strong"
                              :only-countries="['FR', 'BE', 'IT', 'ES', 'DE', 'US']" @input="activeCountry = $refs.phone_input.getActiveCountry()"/>
            <base-list class="pt-4">
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Value : </template>
                    <template #description>{{ value_strong }}</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Iso2 : </template>
                    <template #description>{{ activeCountry.iso2 }}</template>
                </base-list-item>
                <base-list-item>
                    <template #icon><font-awesome-icon :icon="['fas', 'circle-chevron-right']"/></template>
                    <template #title>Dial : </template>
                    <template #description>{{ activeCountry.dialCode }}</template>
                </base-list-item>
            </base-list>

            <development-heading>Error</development-heading>
            <development-paragraph>The <strong>error</strong> is stronger than the validation of the phone number
            </development-paragraph>
            <base-phone-input :error="error"
                              @keydown="error = ''"
                              label="Phone number"
                              placeholder="Phone number"
                              :only-countries="['FR', 'BE']"
                              value="+33123456789"
                              outlined/>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BasePhoneInput from "../elements/BasePhoneInput";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    components: {BaseListItem, BaseList, BasePhoneInput, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            error: "This is an error",
            value_hidden: "",
            value_strong: "+33123232323",
            activeCountry: {
                iso2: 'FR',
                name: 'France',
                dialCode: '33'
            },
        };
    }
}
</script>
