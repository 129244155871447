var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-wheel-background-snowfall" },
    _vm._l(50, function (i) {
      return _c("div", {
        staticClass: "game-wheel-background-snowfall-snowflake",
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }