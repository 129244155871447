var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isChatBotShown(_vm.getSelectedParticipants)
        ? _c("conversation-message", { attrs: { message: _vm.chatBotMessage } })
        : _vm.chatBot.reason === "asked_too_many_masters_recently"
        ? _c(
            "div",
            { staticClass: "chat-bot-alert" },
            [
              _c(
                "base-alert",
                { attrs: { elevated: "", "text-center": "" } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-primary",
                    attrs: { icon: ["fas", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get(
                          "chat.chat_bot.asked_too_many_masters_recently_alert",
                          {
                            hours: this.getHoursBeforeAskingEnabledAgain(
                              this.getSelectedParticipants
                            ),
                          }
                        )
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.chatBot.reason === "asked_master_too_recently" ||
          _vm.chatBot.reason === "asked_master_too_recently_with_answer"
        ? _c(
            "div",
            { staticClass: "chat-bot-alert" },
            [
              _c("base-alert", { attrs: { elevated: "", "text-center": "" } }, [
                _c(
                  "span",
                  { staticClass: "font-bold" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-primary",
                      attrs: { icon: ["fas", "circle-exclamation"] },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.chat_bot.asked_master_too_recently_alert_title"
                          )
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      this.trans.get(
                        "chat.chat_bot.asked_master_too_recently_alert_text"
                      ) +
                        " " +
                        this.trans.get(
                          "chat.chat_bot.asked_master_too_recently_alert_or_write_again_later_text",
                          {
                            hours: this.getHoursBeforeAskingEnabledAgain(
                              this.getSelectedParticipants
                            ),
                          }
                        )
                    ) + "\n            "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm.chatBot.reason ===
          "asked_same_master_without_answer_too_many_times"
        ? _c(
            "div",
            { staticClass: "chat-bot-alert" },
            [
              _c("base-alert", { attrs: { elevated: "", "text-center": "" } }, [
                _c(
                  "span",
                  { staticClass: "font-bold" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-primary",
                      attrs: { icon: ["fas", "circle-exclamation"] },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.chat_bot.asked_master_too_recently_alert_title"
                          )
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      this.trans.get(
                        "chat.chat_bot.asked_master_too_recently_alert_text"
                      )
                    ) + "\n            "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }