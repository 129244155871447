var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Icon")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("All icons are from "),
          _c("strong", [_vm._v("Font Awesome")]),
          _vm._v(" and we are using "),
          _c("strong", [_vm._v("vue-fontawesome")]),
          _vm._v("\n            as a component.\n        "),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Documentation :")]),
        _vm._v(" "),
        _c(
          "development-paragraph",
          [
            _c("strong", [_vm._v("fontawesome")]),
            _vm._v(" :\n            "),
            _c(
              "development-link",
              { attrs: { href: "https://fontawesome.com/", target: "_blank" } },
              [
                _vm._v(
                  "\n                https://fontawesome.com/\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "development-paragraph",
          [
            _c("strong", [_vm._v("vue-fontawesome")]),
            _vm._v(" :\n            "),
            _c(
              "development-link",
              {
                attrs: {
                  href: "https://github.com/FortAwesome/vue-fontawesome",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  " https://github.com/FortAwesome/vue-fontawesome\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("List of icons used :")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full md:w-96 mb-4" },
          [
            _c("base-text-input", {
              ref: "searchInput",
              staticClass: "conversation-search-input",
              attrs: {
                type: "text",
                placeholder: "Search icon ...",
                outlined: "",
                small: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "magnifying-glass"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _vm.hasSearchValue
                        ? _c(
                            "base-button",
                            {
                              attrs: { icon: "", small: "" },
                              on: { click: _vm.clearSearch },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "text-neutral-300",
                                attrs: { icon: ["fas", "circle-xmark"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.filteredList, function (icon) {
            return _c("li", [
              _c(
                "span",
                { staticClass: "inline-block w-4" },
                [_c("font-awesome-icon", { attrs: { icon: icon } })],
                1
              ),
              _vm._v(
                "\n                <font-awesome-icon :icon=\"['" +
                  _vm._s(icon[0]) +
                  "', '" +
                  _vm._s(icon[1]) +
                  "']\"></font-awesome-icon>\n            "
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Color")]),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "text-star",
          attrs: { size: "2x", icon: ["fas", "trophy-star"] },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "text-neutral-400",
          attrs: { size: "2x", icon: ["fas", "trophy"] },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "text-dark",
          attrs: { size: "2x", icon: ["fas", "gem"] },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "text-primary",
          attrs: { size: "2x", icon: ["fas", "user"] },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "text-primary",
          attrs: { size: "2x", icon: ["fas", "face-smile-plus"] },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Spin")]),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fas", "spinner"], size: "2x", spin: "" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Size")]),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "xs" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "lg" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "3x" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "6x" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Rotate")]),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x", rotation: "90" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x", rotation: "180" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x", rotation: "270" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Flip")]),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: {
            icon: ["fal", "paper-plane"],
            size: "2x",
            flip: "horizontal",
          },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x", flip: "vertical" },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "paper-plane"], size: "2x", flip: "both" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }