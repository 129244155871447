var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-card offer-card-loader" }, [
    _c("div", { staticClass: "flex w-full" }, [
      _c(
        "div",
        { staticClass: "offer-card-col-left" },
        [
          _c("base-avatar", {
            attrs: {
              color: "neutral-secondary",
              "skeleton-loader": "",
              "x-large": "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "offer-card-actions" },
      [
        _c("base-button", {
          staticClass: "offer-card-action",
          attrs: {
            color: "neutral-secondary",
            small: "",
            "skeleton-loader": "",
          },
        }),
        _vm._v(" "),
        _c("base-button", {
          staticClass: "offer-card-action",
          attrs: {
            color: "neutral-secondary",
            small: "",
            "skeleton-loader": "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offer-card-col-right" }, [
      _c("div", { staticClass: "offer-card-col-right-header" }, [
        _c("p", { staticClass: "offer-card-name" }, [
          _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "offer-card-consultations-count" }, [
          _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "offer-card-col-right-content" }, [
        _c("p", { staticClass: "offer-card-description" }, [
          _c("span", { staticClass: "skeleton-text leading-4 max-h-4 mb-1" }, [
            _vm._v(" "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "skeleton-text leading-4 max-h-4" }, [
            _vm._v(" "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "offer-card-col-right-footer" }, [
        _c("p", { staticClass: "offer-card-rating" }, [
          _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "offer-card-price" }, [
          _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }