<template>
    <div>
        <div :class="inputClass">
            <template v-for="(v, index) in values">
                <input type="tel"
                       pattern="[0-9]"
                       class="base-code-input-field"
                       :key="`${id}-${index}`"
                       :data-id="index"
                       :value="v"
                       :ref="iRefs[index]"
                       v-on:input="onValueChange"
                       v-on:focus="onFocus"
                       v-on:keydown="onKeyDown"
                       :disabled="disabled"
                       maxlength="1"
                       :placeholder="placeholder"
                />
            </template>
        </div>

        <div v-if="hasError" :class="messageClass" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.base-code-input {
    @apply flex flex-row space-x-2;

    &--error {
        .base-code-input-field {
            @apply border-danger focus:border-danger;
        }
    }

    .base-code-input-field {
        @apply w-12 h-12 p-0 text-center bg-white rounded-md border border-neutral-400 focus:border-primary focus:outline-none focus:placeholder-transparent;

        &:disabled {
            @apply bg-neutral-300 border-disabled;
        }
    }
}
</style>

<script>
const KEY_CODE = {
    backspace: 8,
    left: 37,
    up: 38,
    right: 39,
    down: 40
};

export default {
    name: "BaseCodeInput",
    props: {
        type: {
            type: String,
            default: "number"
        },
        fields: {
            type: Number,
            default: 4
        },
        error: String,
        disabled: false,
        placeholder: String,
        autofocus: Boolean,
    },
    computed: {
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
        inputClass() {
            return {
                'base-code-input': true,
                'base-code-input--error': this.hasError
            };
        },
        messageClass() {
            return {
                'base-text-input-message': true,
                'base-text-input-message--error': this.hasError
            };
        }
    },
    methods: {
        onFocus(e) {
            e.target.select(e);
        },
        onValueChange(e) {
            const index = parseInt(e.target.dataset.id);
            const {type, fields} = this;
            if (type === "number") {
                e.target.value = e.target.value.replace(/[^\d]/gi, "");
            }
            if (
                e.target.value === "" ||
                (type === "number" && !e.target.validity.valid)
            ) {
                return;
            }
            let next;
            const value = e.target.value;
            let {values} = this;
            values = Object.assign([], values);
            if (value.length > 1) {
                let nextIndex = value.length + index - 1;
                if (nextIndex >= fields) {
                    nextIndex = fields - 1;
                }
                next = this.iRefs[nextIndex];
                const split = value.split("");
                split.forEach((item, i) => {
                    const cursor = index + i;
                    if (cursor < fields) {
                        values[cursor] = item;
                    }
                });
                this.values = values;
            } else {
                next = this.iRefs[index + 1];
                values[index] = value;
                this.values = values;
            }
            if (next) {
                const element = this.$refs[next][0];
                element.focus();
                element.select();
            }
            this.triggerChange(values);
        },
        onKeyDown(e) {
            const index = parseInt(e.target.dataset.id);
            const prevIndex = index - 1;
            const nextIndex = index + 1;
            const prev = this.iRefs[prevIndex];
            const next = this.iRefs[nextIndex];
            switch (e.keyCode) {
                case KEY_CODE.backspace: {
                    e.preventDefault();
                    const vals = [...this.values];
                    if (this.values[index]) {
                        vals[index] = "";
                        this.values = vals;
                        this.triggerChange(vals);
                    } else if (prev) {
                        vals[prevIndex] = "";
                        this.$refs[prev][0].focus();
                        this.values = vals;
                        this.triggerChange(vals);
                    }
                    break;
                }
                case KEY_CODE.left:
                    e.preventDefault();
                    if (prev) {
                        this.$refs[prev][0].focus();
                    }
                    break;
                case KEY_CODE.right:
                    e.preventDefault();
                    if (next) {
                        this.$refs[next][0].focus();
                    }
                    break;
                case KEY_CODE.up:
                case KEY_CODE.down:
                    e.preventDefault();
                    break;
                default:
                    break;
            }
        },
        triggerChange(values = this.values) {
            const {fields} = this;
            const val = values.join("");
            this.$emit('input', val);
            this.$emit("change", val);
            if (this.isComplete()) {
                this.$emit("complete", val);
            }
        },
        isComplete() {
            return this.values.join("").length >= this.fields;
        },
        focusInput: function () {
            this.$refs[this.iRefs[0]][0].focus();
        },
    },
    data() {
        const {fields, values} = this;
        let vals;
        if (values && values.length) {
            vals = [];
            for (let i = 0; i < fields; i++) {
                vals.push(values[i] || "");
            }
        } else {
            vals = Array(fields).fill("");
        }
        this.iRefs = [];
        for (let i = 0; i < fields; i++) {
            this.iRefs.push(`input_${i}`);
        }
        this.id = +new Date();
        return {values: vals};
    },
    mounted() {
        if (this.autofocus) {
            this.focusInput();
        }
    }
};
</script>
