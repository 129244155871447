<template>
    <div :class="containerClass" v-click-outside="blurEvent">
        <div class="relative">
            <div :class="selectClass"
                 ref="baseMenu"
                 tabindex="0"
                 role="button"
                 @click="clickEvent">
                <slot/>
            </div>

            <div ref="itemList"
                 :class="itemListClass"
                 :style="itemListStyle"
                 @mouseover="listHover = true"
                 @mouseleave="listHover = false"
                 @click="listHover = false">

                <div v-if="hasHeader" class="base-menu-item-list-header">
                    <slot name="header"/>
                </div>

                <div v-if="loading" class="py-4 px-12 cursor-default">
                    <font-awesome-icon :icon="['fas', 'spinner']" class="text-primary" spin size="2x"/>
                </div>

                <div v-else :class="itemsClass">
                    <slot name="items"/>
                </div>

                <div v-if="hasFooter" class="base-menu-item-list-footer">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.base-menu-container {
    @apply select-none inline-block;
}

.base-menu {
    @apply leading-normal w-full appearance-none focus:outline-none cursor-pointer;
    -webkit-tap-highlight-color: transparent;

    &--disabled {
        @apply cursor-not-allowed pointer-events-none;
    }

    &--open-on-hover:hover ~ .base-menu-item-list {
        @apply inline-block;
    }
}

.base-menu-item-list {
    @apply absolute hidden w-max z-10 bg-white rounded-md shadow-large overflow-hidden;

    &--visible {
        @apply inline-block;
    }
}

.base-menu-items {
    &--scrollable {
        @apply max-h-60 overflow-y-auto;
    }
}

.base-menu-item-list-header {
    @apply cursor-default border-b border-neutral-300;
}

.base-menu-item-list-footer {
    @apply cursor-default border-t border-neutral-300;
}
</style>

<script>
import BaseButton from "./BaseButton";

export default {
    name: "BaseMenu",
    components: {BaseButton},
    props: {
        open: {
            type: Boolean,
            default: null
        },
        disabled: Boolean,
        dropLeft: Boolean,
        dropTop: Boolean,
        loading: Boolean,
        openOnHover: Boolean,
        parentContainerId: String,
        scrollable: Boolean,
    },
    data() {
        return {
            focused: false,
            listHover: false,
            maxWidth: 'auto',
            right: null,
            left: null,
            top: null,
        }
    },
    watch: {
        dropTop: function () {
            this.$nextTick(() => {
                this.updatePosition();
            });
        },
        dropLeft: function () {
            this.$nextTick(() => {
                this.updatePosition();
            });
        }
    },
    computed: {
        containerClass() {
            return {
                'base-menu-container': true,
            }
        },
        selectClass() {
            return {
                'base-menu': true,
                'base-menu--open-on-hover': this.openOnHover,
                'base-menu--disabled': this.disabled,
            };
        },
        itemListClass() {
            return {
                'base-menu-item-list': true,
                'base-menu-item-list--visible': this.isOpen(),
                'base-menu-item-list--drop-right': !this.dropLeft,
                'base-menu-item-list--drop-left': this.dropLeft,
            }
        },
        itemsClass() {
            return {
                'base-menu-items': true,
                'base-menu-items--scrollable': this.scrollable,
            }
        },
        itemListStyle() {
            return {
                maxWidth: this.maxWidth + 'px',
                right: this.right !== null ? this.right + 'px' : null,
                left: this.left !== null ? this.left + 'px' : null,
                top: this.top !== null ? this.top + 'px' : null,
            }
        },
        hasHeader() {
            return !!this.$slots['header'];
        },
        hasFooter() {
            return !!this.$slots['footer'];
        },
    },
    methods: {
        close() {
            this.blurEvent();
        },
        clickEvent() {
            if (!this.disabled) {
                this.toggleFocused();
                this.$emit('click');
            }
        },
        blurEvent() {
            if (!this.disabled) {
                this.toggleFocused(false);
                this.$emit('blur');
            }
        },
        toggleFocused(focused) {
            if (this.open !== false && !this.disabled && !this.openOnHover) {
                if (focused !== undefined) {
                    this.focused = focused;
                } else {
                    this.focused = !this.focused;
                }
            }
            if (this.isOpen() && this.focused) {
                const elementToFocus = this.$refs.baseMenu;
                if (elementToFocus) {
                    elementToFocus.focus();
                }
                this.updateMaxSize();
                this.$emit('open');
            } else {
                this.$emit('close');
            }
        },
        isOpen() {
            if (this.open === false) {
                return false;
            } else {
                return this.open === true || this.focused || this.listHover;
            }
        },
        updateMaxSize() {
            if (this.parentContainerId !== undefined) {
                const screenWidth = document.documentElement.clientWidth;
                const parentWidth = document.getElementById(this.parentContainerId).getBoundingClientRect().width;
                this.maxWidth = Math.min(screenWidth, parentWidth);
            } else {
                this.maxWidth = document.documentElement.clientWidth;
            }
        },
        updatePosition() {
            const screenWidth = document.documentElement.clientWidth;
            const screenHeight = document.documentElement.clientHeight;
            const parentPosition = this.parentContainerId !== undefined ? document.getElementById(this.parentContainerId).getBoundingClientRect() : null;
            const listPosition = this.$refs.itemList.getBoundingClientRect();
            const buttonPosition = this.$refs.baseMenu.getBoundingClientRect();

            if (this.dropTop) {
                if (listPosition.height > buttonPosition.top) {
                    this.top = null;
                } else if (parentPosition !== null && buttonPosition.top - listPosition.height < parentPosition.top) {
                    this.top = null;
                } else {
                    this.top = listPosition.height * -1;
                }
            } else {
                if (listPosition.height + buttonPosition.bottom > screenHeight && listPosition.height < buttonPosition.top) {
                    this.top = listPosition.height * -1;
                } else if (parentPosition !== null && listPosition.height + buttonPosition.bottom > parentPosition.bottom && listPosition.height < buttonPosition.top - parentPosition.top) {
                    this.top = listPosition.height * -1;
                } else {
                    this.top = null;
                }
            }

            if (this.dropLeft) {
                if (listPosition.width >= screenWidth - (screenWidth - buttonPosition.right)) {
                    this.right = (screenWidth - buttonPosition.right) * -1;
                } else {
                    this.right = 0;
                }
                this.left = null;
            } else {
                if (listPosition.width >= screenWidth) {
                    this.left = buttonPosition.left * -1;
                } else if ((screenWidth - buttonPosition.left) < listPosition.width) {
                    this.right = (screenWidth - buttonPosition.right) * -1;
                    this.left = null;
                } else {
                    this.left = 0;
                }
            }
        }
    },
    updated() {
        if (this.isOpen()) {
            this.updatePosition();
        }
    },
    mounted() {
        this.updateMaxSize();
        this.updatePosition();
    },
}
</script>
