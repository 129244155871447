<template>
    <div ref="swiper" class="vertical-comment-list swiper" @mouseenter="disableAutoplay" @mouseleave="enableAutoplay">
        <div ref="swiper-wrapper" class="swiper-wrapper">
            <vertical-comment-card v-for="comment in comments" :key="comment.id" :comment="comment"/>
        </div>
    </div>
</template>

<style lang="scss">
.vertical-comment-list {
    &.swiper {
        @apply w-full;
    }

    .swiper-autoheight, .swiper-autoheight .swiper-slide {
        @apply h-auto;
    }
}
</style>

<script>
import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';

import 'swiper/css';

import BaseRating from "../elements/BaseRating.vue";
import CommentCard from "./CommentCard.vue";
import VerticalCommentCard from "./VerticalCommentCard.vue";

import {ziggy_route} from "../ZiggyMixin";

export default {
    name: "VerticalCommentList",
    components: {CommentCard, VerticalCommentCard, BaseRating},
    props: {
        categoryId: Number,
    },
    mounted() {
        this.initList();
    },
    methods: {
        initList() {
            this.loading = true;

            let params = {
                category_id: this.categoryId,
                page: 1,
            };

            axios.get(ziggy_route('comment.get', params)).then(response => {
                response.data.data.forEach((comment) => {
                    this.createOrUpdateComment(comment);
                });

                this.loading = false;

                this.$nextTick(() => {
                    this.initSlider();
                });
            }).catch(error => {
                console.error(error);
            });
        },
        initSlider() {
            this.slider = new Swiper(this.$refs.swiper, {
                modules: [Autoplay],
                direction: "vertical",
                slidesPerView: 4,
                loop: true,
                speed: 500,
                autoHeight: true,
                allowTouchMove: false,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                on: {
                    beforeInit: (swiper) => {
                        // Override of the function updateSlides
                        swiper.updateSlides = function () {
                            function getDirectionLabel(property) {
                                return {
                                    'width': 'height',
                                    'margin-top': 'margin-left',
                                    'margin-bottom ': 'margin-right',
                                    'margin-left': 'margin-top',
                                    'margin-right': 'margin-bottom',
                                    'padding-left': 'padding-top',
                                    'padding-right': 'padding-bottom',
                                    'marginRight': 'marginBottom',
                                }[property];
                            }

                            const params = swiper.params;

                            const {wrapperEl, slidesEl, size: swiperSize, rtlTranslate: rtl, wrongRTL} = swiper;
                            const isVirtual = swiper.virtual && params.virtual.enabled;
                            const previousSlidesLength = isVirtual ? swiper.virtual.slides.length : swiper.slides.length;
                            const slides = swiper.slides;
                            const slidesLength = isVirtual ? swiper.virtual.slides.length : slides.length;
                            let snapGrid = [];
                            const slidesGrid = [];
                            const slidesSizesGrid = [];

                            let offsetBefore = params.slidesOffsetBefore;
                            if (typeof offsetBefore === 'function') {
                                offsetBefore = params.slidesOffsetBefore.call(swiper);
                            }

                            let offsetAfter = params.slidesOffsetAfter;
                            if (typeof offsetAfter === 'function') {
                                offsetAfter = params.slidesOffsetAfter.call(swiper);
                            }

                            const previousSnapGridLength = swiper.snapGrid.length;
                            const previousSlidesGridLength = swiper.slidesGrid.length;

                            let spaceBetween = params.spaceBetween;
                            let slidePosition = -offsetBefore;
                            let prevSlideSize = 0;
                            let index = 0;
                            if (typeof swiperSize === 'undefined') {
                                return;
                            }
                            if (typeof spaceBetween === 'string' && spaceBetween.indexOf('%') >= 0) {
                                spaceBetween = (parseFloat(spaceBetween.replace('%', '')) / 100) * swiperSize;
                            } else if (typeof spaceBetween === 'string') {
                                spaceBetween = parseFloat(spaceBetween);
                            }

                            swiper.virtualSize = -spaceBetween;

                            // reset margins
                            slides.forEach((slideEl) => {
                                if (rtl) {
                                    slideEl.style.marginLeft = '';
                                } else {
                                    slideEl.style.marginRight = '';
                                }
                                slideEl.style.marginBottom = '';
                                slideEl.style.marginTop = '';
                            });

                            // Calc slides
                            let slideSize;

                            for (let i = 0; i < slidesLength; i += 1) {
                                slideSize = 0;
                                let slide;
                                if (slides[i]) {
                                    slide = slides[i];
                                }

                                slideSize = slide.offsetHeight;

                                if (slides[i]) {
                                    slides[i].swiperSlideSize = slideSize;
                                }

                                slidesSizesGrid.push(slideSize);

                                if ((index - Math.min(swiper.params.slidesPerGroupSkip, index)) % swiper.params.slidesPerGroup === 0) {
                                    snapGrid.push(slidePosition);
                                }
                                slidesGrid.push(slidePosition);
                                slidePosition = slidePosition + slideSize + spaceBetween;

                                swiper.virtualSize += slideSize + spaceBetween;

                                prevSlideSize = slideSize;
                                index += 1;
                            }

                            swiper.virtualSize = Math.max(swiper.virtualSize, swiperSize) + offsetAfter;

                            if (rtl && wrongRTL && (params.effect === 'slide' || params.effect === 'coverflow')) {
                                wrapperEl.style.width = `${swiper.virtualSize + spaceBetween}px`;
                            }
                            if (params.setWrapperSize) {
                                wrapperEl.style[getDirectionLabel('width')] = `${swiper.virtualSize + spaceBetween}px`;
                            }

                            // Remove last grid elements depending on width
                            if (!params.centeredSlides) {
                                const newSlidesGrid = [];
                                for (let i = 0; i < snapGrid.length; i += 1) {
                                    let slidesGridItem = snapGrid[i];
                                    if (params.roundLengths) slidesGridItem = Math.floor(slidesGridItem);
                                    if (snapGrid[i] <= swiper.virtualSize - swiperSize) {
                                        newSlidesGrid.push(slidesGridItem);
                                    }
                                }
                                snapGrid = newSlidesGrid;

                                if (Math.floor(swiper.virtualSize - swiperSize) - Math.floor(snapGrid[snapGrid.length - 1]) > 1) {
                                    snapGrid.push(swiper.virtualSize - swiperSize);
                                }
                            }

                            if (snapGrid.length === 0) snapGrid = [0];

                            if (spaceBetween !== 0) {
                                const key = swiper.isHorizontal() && rtl ? 'marginLeft' : getDirectionLabel('marginRight');
                                slides.filter((_, slideIndex) => {
                                    if (!params.cssMode || params.loop) return true;
                                    if (slideIndex === slides.length - 1) {
                                        return false;
                                    }
                                    return true;
                                }).forEach((slideEl) => {
                                    slideEl.style[key] = `${spaceBetween}px`;
                                });
                            }

                            Object.assign(swiper, {
                                slides,
                                snapGrid,
                                slidesGrid,
                                slidesSizesGrid,
                            });

                            if (slidesLength !== previousSlidesLength) {
                                swiper.emit('slidesLengthChange');
                            }
                            if (snapGrid.length !== previousSnapGridLength) {
                                if (swiper.params.watchOverflow) swiper.checkOverflow();
                                swiper.emit('snapGridLengthChange');
                            }
                            if (slidesGrid.length !== previousSlidesGridLength) {
                                swiper.emit('slidesGridLengthChange');
                            }

                            if (params.watchSlidesProgress) {
                                swiper.updateSlidesOffset();
                            }
                        }

                        // Override of the function updateAutoHeight
                        swiper.updateAutoHeight = function (speed) {
                            const activeSlides = [];
                            const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
                            let newHeight = 0;
                            let i;
                            if (typeof speed === 'number') {
                                swiper.setTransition(speed);
                            } else if (speed === true) {
                                swiper.setTransition(swiper.params.speed);
                            }
                            const getSlideByIndex = index => {
                                if (isVirtual) {
                                    return swiper.slides[swiper.getSlideIndexByData(index)];
                                }
                                return swiper.slides[index];
                            };
                            // Find slides currently in view
                            if (swiper.params.slidesPerView !== 'auto' && swiper.params.slidesPerView > 1) {
                                if (swiper.params.centeredSlides) {
                                    (swiper.visibleSlides || []).forEach(slide => {
                                        activeSlides.push(slide);
                                    });
                                } else {
                                    for (i = 0; i < Math.ceil(swiper.params.slidesPerView); i += 1) {
                                        const index = swiper.activeIndex + i;
                                        if (index > swiper.slides.length && !isVirtual) break;
                                        activeSlides.push(getSlideByIndex(index));
                                    }
                                }
                            } else {
                                activeSlides.push(getSlideByIndex(swiper.activeIndex));
                            }

                            // Find new height from highest slide in view
                            if (swiper.params.direction === 'vertical') {
                                for (i = 0; i < activeSlides.length; i += 1) {
                                    if (typeof activeSlides[i] !== 'undefined') {
                                        const height = activeSlides[i].offsetHeight;
                                        newHeight += height;
                                    }
                                }
                            } else {
                                for (i = 0; i < activeSlides.length; i += 1) {
                                    if (typeof activeSlides[i] !== 'undefined') {
                                        const height = activeSlides[i].offsetHeight;
                                        newHeight = height > newHeight ? height : newHeight;
                                    }
                                }
                            }

                            // Update Height
                            if (newHeight || newHeight === 0) swiper.wrapperEl.style.height = `${newHeight}px`;

                        }
                    },
                },
            })
        },
        enableAutoplay() {
            this.slider.autoplay.start();
        },
        disableAutoplay() {
            this.slider.autoplay.stop();
        },
        getCommentIndex(comment_id) {
            const index = this.comments.findIndex(existingComment => existingComment.id === comment_id);
            return index > -1 ? index : null;
        },
        createComment(comment) {
            this.comments.push(comment);
        },
        updateComment(comment) {
            let index = this.getCommentIndex(comment.id);

            if (index !== null) {
                this.$set(this.comments, index, {...this.comments[index], ...comment});
            }
        },
        createOrUpdateComment(comment) {
            this.getCommentIndex(comment.id) !== null ? this.updateComment(comment) : this.createComment(comment);
        },
    },
    data() {
        return {
            comments: [],
            loading: true,
            slider: null,
        }
    },
}
</script>

