import {ziggy_route} from './components/ZiggyMixin';

export default {
    install(Vue, options) {
        Vue.prototype.assetManager = {
            getImageAsset: function(assetPath) {
                return decodeURIComponent(ziggy_route('asset.getAsset', {file_type: 'image', asset_path: assetPath}));
            },
            getDocumentAsset: function(assetPath) {
                return decodeURIComponent(ziggy_route('asset.getAsset', {file_type: 'document', asset_path: assetPath}));
            },
            getAudioAsset: function(assetPath) {
                return decodeURIComponent(ziggy_route('asset.getAsset', {file_type: 'audio', asset_path: assetPath}));
            },
            getVideoAsset: function(assetPath) {
                return decodeURIComponent(ziggy_route('asset.getAsset', {file_type: 'video', asset_path: assetPath}));
            },
            getAssetFromDisk: function(disk, assetPath) {
                return decodeURIComponent(ziggy_route('asset.getAssetFromDisk', {disk: disk, asset_path: assetPath}));
            }
        };
    }
}
