<template>
    <div class="flex justify-center w-full">
        <div v-if="isUserListeningToAudioDraft" class="flex justify-center w-full">
            <base-button icon large color="secondary" @click="deleteRecord" class="mr-8">
                <font-awesome-icon :icon="['fal', 'trash-can']"/>
            </base-button>

            <div class="flex-grow flex justify-center mr-8">
                <conversation-audio-record-player v-if="hasUrl" :url="audio.url" :duration="audio.duration"/>
            </div>

            <base-button icon large color="primary" @click="sendRecord">
                <font-awesome-icon :icon="['fal', 'paper-plane']"/>
            </base-button>
        </div>

        <div v-else class="flex justify-center w-full">
            <base-button icon large color="secondary" @click="deleteRecord">
                <font-awesome-icon :icon="['fal', 'trash-can']"/>
            </base-button>

            <div class="flex-grow flex justify-center items-center">
                <div class="mr-3 flex">
                    <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-secondary opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-secondary"></span>
                </div>
                <div class="w-10 text-center">
                    <base-timer auto-start v-slot="{minutes, seconds}" class="self-center" ref="audioRecordTimer"
                                :threshold-events="thresholdEvents">
                        {{ minutes }}:{{ seconds }}
                    </base-timer>
                </div>
            </div>

            <base-button icon large color="available" @click="validateRecord">
                <font-awesome-icon :icon="['far', 'circle-check']"/>
            </base-button>
        </div>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import BaseTimer from "../elements/BaseTimer";
import ConversationMessageContentAudio from "./ConversationMessageContentAudio";
import {mapActions, mapGetters} from "vuex";
import requests from "./requests";
import ChatMixin from "./ChatMixin";
import ConversationAudioRecordPlayer from "./ConversationAudioRecordPlayer";
import MessageMixin from "./MessageMixin";

export default {
    name: 'ConversationAudioRecord',
    components: {ConversationAudioRecordPlayer, ConversationMessageContentAudio, BaseTimer, BaseButton},
    mixins: [ChatMixin, MessageMixin],
    data() {
        return {
            isTypingWorker: null,
            audio: {
                url: null,
                file: null,
                duration: 0,
            },
            thresholdEvents: {
                chat_over: {
                    time: this.config.get('chat.chat_audio_message_record_max_duration') * 1000,
                    callback: () => this.validateRecord()
                }
            }
        }
    },
    computed: {
        ...mapGetters('chat', ['getConversation', 'getSelectedParticipants', 'isChatBotAnswerMode']),
        ...mapGetters('media_devices', [
            'getRecordingChunks',
            'isRecordInProgress',
            'isUserListeningToAudioDraft'
        ]),
        hasUrl() {
            return this.audio.url !== null;
        }
    },
    methods: {
        ...mapActions('chat', ['addMessageToConversation', 'sendIsTyping']),
        ...mapActions('media_devices', [
            'deleteAudioRecord',
            'resetRecordTime',
            'setIsUserListeningToAudioDraft',
            'stopAudioRecord'
        ]),
        ...mapGetters('media_devices', ['getRecordFinalBlob']),
        deleteRecord() {
            if (this.isRecordInProgress) {
                this.$refs.audioRecordTimer.end();
                this.stopAudioRecord();
            } else {
                this.setIsUserListeningToAudioDraft(false);
            }
            this.deleteAudioRecord();
        },
        validateRecord() {
            this.audio.duration = this.$refs.audioRecordTimer.totalMilliseconds / 1000;
            this.$refs.audioRecordTimer.end();
            this.stopAudioRecord();
            this.setIsUserListeningToAudioDraft(true);
        },
        sendRecord() {
            this.setIsUserListeningToAudioDraft(false);
            this.resetRecordTime();
            const finalBlob = this.getRecordFinalBlob();
            this.audio.file = new File([finalBlob], 'audio_message.mp3', {type: finalBlob.type});

            let message = this.createMessage();
            message.content.url = this.audio.url;
            message.content.file = this.audio.file;
            message.content.duration = this.audio.duration;
            message.participant = this.me();
            message.type = 'audio';

            if (this.isChatBotAnswerMode) {
                requests.answerChatBotQuestion(message).then((response) => {
                    this.sendMessageSucceed = true;
                    message.id = response.data.data.id;
                }).catch(() => {
                    this.messageNotSent(message);
                });
            } else {
                this.addMessageToConversation({
                    conversation: this.getConversation(this.getSelectedParticipants),
                    message: message,
                });
                requests.sendMessage(message);
            }
        },
        isTyping () {
            this.sendIsTyping('audio');
            this.isTypingWorker = setTimeout(this.isTyping, 3000);
        }
    },
    created() {
        this.unwatchRecordFinalBlob = this.$store.watch(this.getRecordFinalBlob, (blob) => {
            if (blob !== null) {
                this.audio.url = URL.createObjectURL(blob);
            } else {
                this.audio.url = null;
            }
        });
        this.isTyping();
    },
    beforeDestroy() {
        if(this.isTypingWorker) {
            clearTimeout(this.isTypingWorker);
        }
        this.unwatchRecordFinalBlob();
    }
}
</script>
