<template>
    <base-radio-input
        v-model="pack"
        :name="name"
        :value="value"
        :disabled="disabled"
        input-hidden
        class="mr-2 text-center"
    >
        <template>
            <div :class="baseRadioInputPackClass">
                <div v-if="mostPopular" class="base-radio-input-pack-most-popular">
                    <span>{{ trans.get('generic.most_popular_pack') }}</span>
                </div>
                <div class="base-radio-input-pack-title-container">
                    <p class="base-radio-input-pack-title">{{ cost / 100 | formatMoneyShort }}</p>
                    <p v-if="hasBonus" class="base-radio-input-pack-bonus">
                        {{ trans.get('modals.refill.bonus_amount_pack', {bonus_amount: bonus}) }}
                    </p>
                </div>
                <div :class="baseRadioInputPackIconClass" class="text-neutral-400">
                    <font-awesome-icon :icon="['far', 'circle']" size="lg"/>
                </div>
            </div>
        </template>
        <template #checked>
            <div :class="baseRadioInputPackClass" class="base-radio-input-pack--checked" :style="cssVars">
                <div v-if="mostPopular"
                     class="base-radio-input-pack-most-popular base-radio-input-pack-most-popular--checked">
                    <span>{{ trans.get('generic.most_popular_pack') }}</span>
                </div>
                <div class="base-radio-input-pack-title-container">
                    <p class="base-radio-input-pack-title">{{ cost / 100 | formatMoneyShort }}</p>
                    <p v-if="hasBonus" class="base-radio-input-pack-bonus">
                        {{ trans.get('modals.refill.bonus_amount_pack', {bonus_amount: bonus}) }}
                    </p>
                </div>
                <div :class="baseRadioInputPackIconClass" class="base-radio-input-pack-icon--checked">
                    <font-awesome-icon :icon="['far', 'circle-check']" size="lg"/>
                </div>
            </div>
        </template>
    </base-radio-input>
</template>

<style lang="scss">

.base-radio-input-pack {
    @apply relative w-full mb-4 border border-neutral-400 text-neutral-500 rounded-lg cursor-pointer;

    &--checked {
        @apply text-white bg-no-repeat bg-center bg-cover;
        &.base-radio-input-pack--pack1 {
            @apply border-pack1-from;
            background-image: var(--bg-pack1);
        }

        &.base-radio-input-pack--pack2 {
            @apply border-pack2-from;
            background-image: var(--bg-pack2);
        }

        &.base-radio-input-pack--pack3 {
            @apply border-pack3-from;
            background-image: var(--bg-pack3);
        }
    }

    &--disabled {
        @apply bg-disabled text-neutral-400 pointer-events-none;
    }
}

.base-radio-input-pack-most-popular {
    @apply absolute top-0 left-2/4 p-1 w-5/6 bg-white text-xs select-none md:text-sm border border-neutral-400 rounded-full transform -translate-y-1/2 -translate-x-1/2;

    &--checked {
        @apply border-pack2-from text-pack2-from;
    }
}

.base-radio-input-pack-icon {
    @apply absolute top-full left-2/4 bg-white rounded-full transform -translate-y-1/2 -translate-x-1/2;

    &--disabled {
        @apply bg-disabled;
    }

    &--checked {
        &.base-radio-input-pack-icon--pack1 {
            @apply text-pack1-from;
        }

        &.base-radio-input-pack-icon--pack2 {
            @apply text-pack2-from;
        }

        &.base-radio-input-pack-icon--pack3 {
            @apply text-pack3-from;
        }
    }

}

.base-radio-input-pack-title-container {
    @apply w-full flex flex-wrap content-center select-none min-h-24;
}

.base-radio-input-pack-title {
    @apply w-full font-bold text-lg xs:text-2xl;
}

.base-radio-input-pack-bonus {
    @apply w-full font-bold text-sm xs:text-base;
}

</style>

<script>
import BaseRadioInput from "../BaseRadioInput";
import {ziggy_route} from "../../ZiggyMixin";

export default {
    name: "BaseRadioInputPack",
    components: {BaseRadioInput},
    model: {
        prop: 'selectedPack',
        event: 'change'
    },
    props: {
        cost: Number,
        credits: Number,
        description: String,
        disabled: Boolean,
        displayedPack: Object,
        name: String,
        mostPopular: Boolean,
        packNumber: Number,
        selectedPack: Number|String,
        title: String,
        value: Number|String
    },
    computed: {
        pack: {
            get() {
                return this.selectedPack;
            },

            set(value) {
                this.$emit('change', value);
            }
        },
        hasBonus() {
            return this.credits - this.cost > 0;
        },
        bonus() {
            return this.$options.filters.formatMoneyShort((this.credits - this.cost) / 100);
        },
        cssVars() {
            return {
                '--bg-pack1': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack1-from), var(--color-pack1-to))",
                '--bg-pack2': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack2-from), var(--color-pack2-to))",
                '--bg-pack3': "url('" + this.bgImageUrl + "'), linear-gradient(to right, var(--color-pack3-from), var(--color-pack3-to))"
            }
        },
        baseRadioInputPackClass() {
            return {
                'base-radio-input-pack': true,
                'base-radio-input-pack--pack1': this.packNumber === 1,
                'base-radio-input-pack--pack2': this.packNumber === 2,
                'base-radio-input-pack--pack3': this.packNumber === 3
            }
        },
        baseRadioInputPackIconClass() {
            return {
                'base-radio-input-pack-icon': true,
                'base-radio-input-pack-icon--pack1': this.packNumber === 1,
                'base-radio-input-pack-icon--pack2': this.packNumber === 2,
                'base-radio-input-pack-icon--pack3': this.packNumber === 3
            }
        }
    },
    data() {
        return {
            bgImageUrl: this.assetManager.getImageAsset('bg_recharge_pack.png')
        }
    }
}
</script>
