<template>
    <div :class="messageClass">
        <div class="conversation-message-container">
            <base-avatar v-if="!isMe"
                         class="conversation-message-avatar"
                         :name="this.getParticipantName(this.message.participant)"
                         :photo="this.message.participant.photo"
                         color="light"
                         small/>

            <div :class="contentClass">
                <conversation-message-content-text v-if="type === 'text'"
                                                   :message-status="status"
                                                   :text="content.text"/>

                <conversation-message-content-image v-else-if="type === 'image'"
                                                    :url="content.url"
                                                    :url_thumbnail="content.thumbnail"
                                                    :message-status="status"
                                                    :text="content.text"/>

                <conversation-message-content-audio v-else-if="type === 'audio'"
                                                    :message="this.message"
                                                    :message-status="status"
                                                    @error="setError"/>

                <conversation-message-content-sticker v-else-if="type === 'sticker'"
                                                      :message-status="status"
                                                      :message="this.message"/>

                <conversation-message-content-is-typing v-else-if="type === 'is-typing'"/>

                <conversation-message-content-chat-bot-ask-question v-else-if="type === 'chat_bot_ask_question'"
                                                                    :nickname="this.me().name"/>
            </div>

            <div class="conversation-message-menu">
                <base-menu v-if="isDeletable" parent-container-id="conversation-messages-list-container" drop-top>
                    <div class="w-6 text-center text-xl">
                        <font-awesome-icon class="text-main" :icon="['far', 'ellipsis-vertical']"/>
                    </div>
                    <template #items>
                        <base-menu-item class="text-dark" @click="deleteMessage">
                            <font-awesome-icon :icon="['fal', 'trash-can']"/>
                            {{ trans.get('chat.delete_this_message') }}
                        </base-menu-item>
                    </template>
                </base-menu>
            </div>
        </div>

        <div v-if="hasError" class="conversation-message-error">
            <template v-if="isMessageNotSent(message)">
                {{ trans.get('chat.message_not_sent') }}
            </template>
            <template v-else-if="isMessageNotLoaded(message)">
                {{ trans.get('chat.audio_message_not_loaded') }}
            </template>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-message {
    @apply w-full pt-1 px-3;

    .conversation-message-menu {
        @apply flex items-end w-6;
    }

    .conversation-message-error {
        @apply mt-0.5 mb-2 text-danger;
    }

    &--right {
        .conversation-message-container {
            @apply flex flex-row-reverse;

            .conversation-message-content:not(.conversation-message-content--sticker) {
                @apply bg-primary text-white;
            }

            .conversation-message-content-text {
                a, a:visited {
                    @apply text-white hover:text-neutral-100 underline;
                }
            }

            .conversation-message-menu {
                @apply ml-10 md:ml-20;
            }
        }

        & + .conversation-message--left {
            @apply pb-5;
        }

        .conversation-message-error {
            @apply text-right;
        }
    }

    &--left {
        .conversation-message-container {
            @apply flex flex-row;

            & + .conversation-message--left .conversation-message-avatar {
                @apply invisible;
            }

            .conversation-message-content:not(.conversation-message-content--sticker) {
                @apply bg-white text-dark;
            }

            .conversation-message-content-text {
                a, a:visited {
                    @apply text-primary hover:text-primary-hover underline;
                }
            }

            .conversation-message-menu {
                @apply mr-2 md:mr-6;
            }
        }

        & + .conversation-message--right {
            @apply pb-5;
        }

        .conversation-message-error {
            @apply ml-12;
        }
    }

    &--danger {
        .conversation-message-content {
            @apply bg-danger text-white;
        }
    }

    .conversation-message-avatar {
        @apply flex-none self-end mr-3 mb-0.5;
    }

    .conversation-message-content {
        @apply flex-shrink rounded-lg overflow-ellipsis overflow-hidden leading-normal;

        &--image {
            @apply max-w-xs;
        }
    }
}
</style>

<script>
import ConversationMessageContentText from './ConversationMessageContentText';
import ConversationMessageContentImage from './ConversationMessageContentImage';
import ConversationMessageContentAudio from './ConversationMessageContentAudio';
import ConversationMessageContentIsTyping from './ConversationMessageContentIsTyping';
import BaseAvatar from '../elements/BaseAvatar';
import ChatMixin from './ChatMixin';
import ConversationMessageContentChatBotAskQuestion from './ConversationMessageContentChatBotAskQuestion';
import BaseMenu from "../elements/BaseMenu";
import BaseButton from "../elements/BaseButton";
import BaseMenuItem from "../elements/BaseMenuItem";
import requests from './requests';
import {mapActions, mapGetters} from "vuex";
import MessageMixin from "./MessageMixin";
import ConversationMessageContentSticker from "./ConversationMessageContentSticker.vue";

export default {
    name: 'ConversationMessage',
    mixins: [ChatMixin, MessageMixin],
    components: {
        ConversationMessageContentSticker,
        BaseMenuItem,
        BaseButton,
        BaseMenu,
        BaseAvatar,
        ConversationMessageContentAudio,
        ConversationMessageContentImage,
        ConversationMessageContentText,
        ConversationMessageContentIsTyping,
        ConversationMessageContentChatBotAskQuestion,
    },
    props: {
        message: {
            type: Object
        },
    },
    computed: {
        ...mapGetters('chat', ['getSelectedConversation']),
        type() {
            return this.message.type !== undefined && this.message.type;
        },
        content() {
            return this.message.content !== undefined && this.message.content;
        },
        status() {
            if (this.hasError) {
                return 'error';
            }

            if (this.isMe && this.message.read !== undefined && this.message.read === false) {
                return 'sent';
            }

            if (this.isMe && this.message.read !== undefined && this.message.read === true) {
                return 'read';
            }

            return null;
        },
        isMe() {
            return this.message.participant !== undefined && this.message.participant.id === this.me().id;
        },
        hasError() {
            return this.hasMessageError(this.message);
        },
        isDeletable() {
            return this.message.participant.type === 'master_care';
        },
        messageClass() {
            return {
                "conversation-message": true,
                "conversation-message--left": this.message.participant !== undefined && !this.isMe,
                "conversation-message--right": this.message.participant !== undefined && this.isMe,
                "conversation-message--danger": this.hasError
            };
        },
        contentClass() {
            return {
                "conversation-message-content": true,
                "conversation-message-content--image": this.type === 'image',
                "conversation-message-content--sticker": this.type === 'sticker',
            };
        },
    },
    methods: {
        ...mapActions('chat', ['removeMessageFromConversation']),
        deleteMessage() {
            const chat_message_id = this.message.id;

            requests.deleteMessage({chat_message_id: chat_message_id}, () => {
                const conversation = this.getSelectedConversation;
                this.removeMessageFromConversation({conversation, chat_message_id});
            }, () => {
            });
        },
        setError(errorMessage) {
            this.error = errorMessage;
        }
    },
    mounted() {
        this.scrollMessagesToBottom();
    },
    data() {
        return {
            error: null,
        }
    }
}
</script>
