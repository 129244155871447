var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "", error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("chat.modals.master_refused_chat_title", {
                master_name: _vm.name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.trans.get("chat.modals.master_refused_chat_text"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _c(
            "base-button",
            { attrs: { autofocus: "" }, on: { click: _vm.cancel } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.i_understood")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }