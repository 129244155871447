<template>
    <base-modal dense :error="loadingError">
        <div class="px-6 pt-6 pb-3">
            <modal-heading>
                {{ trans.get('chat.modals.customer_end_chat_title', {'master_name': master.name}) }}
            </modal-heading>
        </div>

        <div class="flex items-center bg-neutral-100 p-4">
            <div class="flex-none justify-center mr-4">
                <base-avatar large outlined :name="master.name" :photo="master.photo"/>
            </div>
            <div class="flex-grow">
                <base-list>
                    <base-list-item><strong class="text-primary text-base leading-4">{{ master.name }}</strong>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('chat.chat_duration') }} :</template>
                        <template #description>
                            <base-timer ref="timerDuration" v-slot="{hours, minutes, seconds}">
                                <template v-if="hours > 0">{{ hours + 'h' }}</template>
                                <template v-if="minutes > 0">{{ minutes + 'min' }}</template>
                                <template v-if="seconds > 0">{{ seconds + 's' }}</template>
                                <template v-if="seconds == 0">0s</template>
                            </base-timer>
                        </template>
                    </base-list-item>
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.cost') }} :</template>
                        <template #description>{{ cost | formatMoney }} ({{ trans.get('generic.stickers_included') }})</template>
                    </base-list-item>
                </base-list>
            </div>
        </div>

        <div class="p-6">
            <div v-if= "isFreeChat">
             <modal-heading :level="2" class="tracking-widest mb-0.5">
                <font-awesome-icon :icon="['far', 'messages']" class="text-star text-4xl align-top"/>
                {{ trans.get('chat.modals.prime_advantage_free_chat_used') }}
            </modal-heading>
            <modal-paragraph class="grid grid-cols-1 ml-12">
                {{ trans.get('chat.modals.prime_advantage_free_chat_description') }}
            </modal-paragraph>

            </div>
            <p v-else class="text-sm">{{ trans.get('chat.modals.customer_end_chat_read_messages') }}</p>

            <div class="grid grid-cols-1 gap-4 mt-5">
                <base-button v-if="canLeaveComment" @click="displayCommentModal">
                    {{ trans.get('chat.modals.leave_a_comment') }}
                </base-button>
                <base-button v-else autofocus @click="closeModal">
                    {{ trans.get('chat.modals.continue_on_kang') }}
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import {mapActions, mapGetters} from "vuex";
import BaseTimer from "../elements/BaseTimer";
import ModalParagraph from "./elements/ModalParagraph";
import BaseAvatar from "../elements/BaseAvatar";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    name: "ModalChatCustomerEndChat",
    components: {BaseList, BaseListItem, BaseAvatar, ModalParagraph, BaseTimer, BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
        ...mapGetters('user', ['getLoggedUserInfos']),
        canLeaveComment() {
            let minTimeToAllowComment = this.config.get('chat.min_chat_duration_before_user_can_comment.prospect');
            if( this.getLoggedUserInfos['transfo'] === 'client' ) {
                minTimeToAllowComment = this.config.get('chat.min_chat_duration_before_user_can_comment.client');
            }
            return this.duration > minTimeToAllowComment;
        },
        isFreeChat() {
            let freeAdavantages =  this.billingAdvantages.filter((advantage) => {
                if (advantage.advantage === 'free_seconds') return true;
            });
            return Array.isArray(freeAdavantages) && freeAdavantages.length;
        }
    },
    methods: {
        ...mapActions('modals', ['closeModal', 'openModal']),
        displayCommentModal() {
            this.openModal({
                name: globals.CHAT_ADD_COMMENT,
                param: {
                    master_name: this.master.name,
                    client_nick_name: this.client.name,
                    sessionId: this.sessionId
                }
            });
        }
    },
    data() {
        return {
            loadingError: '',
            master: '',
            client: '',
            duration: 0,
            cost: 0,
            sessionId: 0,
            billingAdvantages: [],
        };
    },
    mounted() {
        this.master = this.getParam['chat-customer-end-chat']['master'] ?? '';
        this.client = this.getParam['chat-customer-end-chat']['client'];
        this.duration = this.getParam['chat-customer-end-chat']['duration'];
        this.cost = this.getParam['chat-customer-end-chat']['cost'];
        this.sessionId = this.getParam['chat-customer-end-chat']['sessionId'];
        this.$refs.timerDuration.setTime(this.duration);
        this.billingAdvantages = this.getParam['chat-customer-end-chat']['billing_advantages'] ?? [];
    },
}
</script>

<style scoped>

</style>
