<template>
    <base-text-input
        ref="text_input"
        :id="id"
        :name="name"
        :label="label"
        :outlined="outlined"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        v-model="displayValue"
        :error="displayError"
        inputmode="tel"
        @keydown="$emit('keydown', $event)"
        @blur="checkValue"
        @focus="isInputActive = true">

        <template #prepend v-if="!hideCountries">
            <base-select :items="countryItems"
                         :disabled="disabled"
                         v-model="activeCountry"
                         @input="selectCountry"
                         transparent>

                <template #placeholder>
                    <span class="vti__flag inline-block"/>
                    <span>+</span>
                </template>

                <template #selected>
                    <span :class="activeCountry.iso2.toLowerCase()" class="vti__flag inline-block"/>
                    <span>{{ `+${activeCountry.dialCode}` }}</span>
                </template>

                <template v-slot:item="data">
                    <span :class="data.item.iso2.toLowerCase()" class="vti__flag inline-block"/>
                    <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
                </template>

            </base-select>
        </template>

    </base-text-input>
</template>

<style src="./flags.css"></style>

<script>
import BaseTextInput from "./BaseTextInput";

const importAllCountries = () => import('../../all-countries');
const importPhoneNumber = () => import('awesome-phonenumber');
import BaseSelect from "./BaseSelect";

export default {
    name: "BasePhoneInput",
    components: {BaseTextInput, BaseSelect},
    props: {
        id: String,
        autofocus: Boolean,
        disabled: Boolean,
        error: String,
        hideCountries: Boolean,
        initialCountry: String,
        label: String,
        mobileOnly: Boolean,
        name: String,
        onlyCountries: Array,
        outlined: Boolean,
        placeholder: String,
        value: String | Number,
    },

    computed: {
        countryItems() {
            if (this.onlyCountries) {
                return this.onlyCountries
                    .map(countryCode => this.findCountry(countryCode))
                    .filter(Boolean);
            }
            return this.allCountries;
        },
        displayValue: {
            get: function () {
                let value = this.phoneValue;

                if (this.phoneValue) {
                    if (this.isInputActive) {
                        value = this.phoneValue.toString();
                    } else {
                        let phoneNumber = new this.PhoneNumber(this.phoneValue.toString(), this.activeCountry.iso2.toUpperCase());
                        if (phoneNumber.isValid()) {
                            value = phoneNumber.getNumber('national');
                        }
                    }
                }

                return value;
            },
            set: function (value) {
                this.phoneValue = value;
                this.updateValue();
            }
        },
        displayError() {
            if (this.error) {
                return this.error;
            } else if (this.errorState) {
                return this.errorState;
            }
            return '';
        }
    },

    methods: {
        getValueAsString() {
            if (this.value === '') {
                return '';
            }

            return '+' + this.value.toString();
        },
        updateValue: function () {
            let value = this.phoneValue;

            let phoneNumber = new this.PhoneNumber(this.phoneValue.toString(), this.activeCountry.iso2.toUpperCase());
            if (phoneNumber.isValid() && phoneNumber.getRegionCode() === this.activeCountry.iso2.toUpperCase()) {
                value = phoneNumber.getNumber();
            }

            this.$emit('input', value);
        },
        findCountry(iso = '') {
            return this.allCountries.find(
                country => country.iso2 === iso.toUpperCase(),
            );
        },
        checkValue() {
            let valid = true;
            this.errorState = '';
            if (this.phoneValue) {
                this.isInputActive = false;
                let phoneNumber = new this.PhoneNumber(this.phoneValue.toString(), this.activeCountry.iso2.toUpperCase());

                if (phoneNumber.isValid()) {
                    if (this.activeCountry.iso2.length === 0) {
                        this.activeCountry = {
                            iso2: phoneNumber.getRegionCode(),
                            dialCode: phoneNumber.getCountryCode()
                        }
                    }
                }

                if (!phoneNumber.isValid()) {
                    this.errorState = this.trans.get('validation.phone_number_invalid');
                    valid = false;
                } else if (phoneNumber.getRegionCode() !== this.activeCountry.iso2.toUpperCase()) {
                    this.errorState = this.trans.get('validation.phone_number_country_invalid');
                    valid = false;
                } else if (this.mobileOnly && !phoneNumber.isMobile()) {
                    this.errorState = this.trans.get('validation.mobile_phone');
                    valid = false;
                }
            } else {
                valid = false;
            }

            return valid;
        },
        selectCountry() {
            this.checkValue();
            this.updateValue();
        },
        getActiveCountry() {
            return this.activeCountry;
        },
        focusInput: function () {
            this.$refs.text_input.focusInput();
        },
    },

    data() {
        return {
            activeCountry: {
                iso2: '',
                name: '',
                dialCode: ''
            },
            allCountries: [],
            PhoneNumber: null,
            phoneValue: '',
            errorState: '',
            isInputActive: false,
        }
    },

    mounted() {
        importAllCountries().then((countries) => {
            this.allCountries = countries.default;
        });

        importPhoneNumber().then((PhoneNumber) => {
            this.PhoneNumber = PhoneNumber.default;
            if (this.value) {
                let phoneNumber = new this.PhoneNumber(this.getValueAsString());
                this.activeCountry = {
                    iso2: phoneNumber.getRegionCode(),
                    dialCode: phoneNumber.getCountryCode()
                }
                this.phoneValue = phoneNumber.getNumber('significant');
                this.checkValue();
            } else if (this.initialCountry) {
                let phoneNumber = new this.PhoneNumber('', this.initialCountry);
                this.activeCountry = {
                    iso2: phoneNumber.getRegionCode(),
                    dialCode: phoneNumber.getCountryCode()
                }
            }
        });

        if (this.autofocus) {
            this.focusInput();
        }
    }
};
</script>
