<template>
    <div id="conversation-messages" class="conversation-messages">
        <conversation-alert :key="interlocutor().id"/>

        <conversation-messages-suggestion-box v-if="showConversationMessagesSuggestionBox"/>

        <div id="conversation-messages-list-container" :class="containerClass">
            <div id="conversation-messages-list">
                <conversation-messages-tooltip/>

                <chat-bot/>

                <conversation-message v-if="isTyping(getSelectedParticipants)"
                                      :message="{participant: this.interlocutor(), type: 'text', content: {text: this.isTypingText}}"/>

                <template v-for="message in this.conversationsMessages">
                    <conversation-message :key="message.id + message.created_at" :message="message"/>
                </template>
            </div>
        </div>

        <conversation-hearts v-if="canSendHearts"/>

        <conversation-sticker-animations/>
    </div>
</template>

<style lang="scss">
.conversation-messages {
    @apply flex-1 flex relative overflow-hidden;
}

.conversation-messages-list-container {
    @apply flex-1 overflow-y-scroll bg-neutral-100;

    &--background {
        @apply bg-conversation-background-image;
    }
}

#conversation-messages-list {
    @apply flex flex-col-reverse overflow-hidden relative px-0 min-h-full md:px-3 lg:px-6 xl:px-9 2xl:px-12 pb-3;
}
</style>

<script>
import BaseAlert from '../elements/BaseAlert';
import BaseButton from '../elements/BaseButton';
import ChatBot from './ChatBot';
import ChatMixin from './ChatMixin';
import ConversationAlert from './ConversationAlert';
import ConversationHearts from "./ConversationHearts.vue";
import ConversationMessage from './ConversationMessage';
import ConversationMessagesSuggestionBox from './ConversationMessagesSuggestionBox';
import ConversationMessagesTooltip from './ConversationMessagesTooltip';
import ConversationStickerAnimations from "./ConversationStickerAnimations.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ConversationMessages',
    components: {
        ConversationStickerAnimations,
        ConversationHearts,
        ConversationMessagesSuggestionBox,
        ConversationMessagesTooltip,
        ConversationAlert,
        BaseAlert,
        BaseButton,
        ConversationMessage,
        ChatBot
    },
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', [
            'canSendHearts',
            'getChatBot',
            'getChatBotMastersAsked',
            'getConversationMessages',
            'getHoursBeforeAskingEnabledAgain',
            'getSelectedParticipants',
            'hasChatBotReasonToShow',
            'hasLiveSessionBillingStarted',
            'iAmMaster',
            'isChatBotShown',
            'isChatSessionAlmostOver',
            'isConversationsLoading',
            'isLiveConversationInProgress',
            'isLiveConversationWaiting',
            'isTyping',
            'isTypingType',
            'refillFailed',
            'refillSuccess',
        ]),
        chatBotMessage: function () {
            return {
                content: {},
                type: 'chat_bot_ask_question',
                error: false,
                participant: this.interlocutor(),
            };
        },
        conversationsMessages() {
            return this.getConversationMessages(this.getSelectedParticipants).slice().reverse();
        },
        containerClass() {
            return {
                'conversation-messages-list-container': true,
                'conversation-messages-list-container--background': this.iAmMaster || this.conversationsMessages.length > 0,
            };
        },
        showConversationMessagesSuggestionBox() {
            return !this.isLiveConversationWaiting && !this.isLiveConversationInProgress && this.conversationsMessages.length === 0;
        },
        isTypingText() {
            let name = this.interlocutor(this.getSelectedParticipants).name;

            if (this.isTypingType(this.getSelectedParticipants) === 'audio') {
                return this.trans.get('chat.is_typing_audio', {name});
            }

            return this.trans.get('chat.is_typing_text', {name});
        }
    },
    methods: {
        ...mapActions('chat', ['askChatBotQuestion']),
    },
    mounted() {
        const conversationMessages = document.getElementById("conversation-messages");
        new ResizeObserver(this.scrollMessagesToBottom).observe(conversationMessages);
    },
    updated() {
        this.scrollMessagesToBottom();
    },
};
</script>
