var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hidden lg:block text-header-top text-base",
      staticStyle: { "line-height": "55px" },
    },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.ziggy_route("account.favorites", { page: 0 }, true),
          },
        },
        [
          _c("font-awesome-icon", {
            attrs: { size: "lg", icon: ["fas", "circle-heart"] },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }