var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex py-2 pr-2 md:px-4" }, [
    _c(
      "div",
      { staticClass: "flex-none self-center md:hidden" },
      [
        _c(
          "base-button",
          { attrs: { icon: "" }, on: { click: _vm.back } },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "chevron-left"], size: "lg" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-none pr-4 self-center" },
      [
        _c("base-avatar", {
          attrs: {
            name: this.getParticipantName(_vm.care),
            photo: _vm.care.photo,
            outlined: "",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex-grow self-center min-w-10 gap-2" }, [
      _c("p", { staticClass: "text-sm font-bold text-status-available" }, [
        _vm._v(_vm._s(_vm.name)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-none self-center" },
      [
        _c(
          "base-menu",
          {
            attrs: { icon: "", "drop-left": "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.markConversationAsUnread(
                              _vm.getSelectedParticipants
                            )
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "envelope"] },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.set_unread")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "base-button",
              { attrs: { icon: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "ellipsis-vertical"], size: "lg" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }