var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-full flex p-3 cursor-pointer hover:bg-neutral-100",
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-none self-center pr-3" },
        [
          _c("base-avatar", {
            attrs: {
              name: _vm.nickname,
              photo: _vm.avatarUrl,
              status: _vm.status,
              color: "primary",
              outlined: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow self-center min-w-0 text-left" }, [
        _c(
          "p",
          { staticClass: "text-lg capitalize", class: _vm.nicknameClass },
          [_vm._v(_vm._s(_vm.nickname))]
        ),
        _vm._v(" "),
        _c("div", { class: _vm.messageClass }, [
          _vm.isAudioMessage
            ? _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "microphone-lines"] },
                  }),
                  _vm._v(" "),
                ],
                1
              )
            : _vm.isStickerMessage
            ? _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "note-sticky"] },
                  }),
                  _vm._v(" "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("p", {
            staticClass:
              "flex-grow whitespace-nowrap overflow-ellipsis overflow-hidden pointer-events-none",
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "flex-none whitespace-nowrap" }, [
            _vm._v(" · " + _vm._s(_vm.writtenSince)),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }