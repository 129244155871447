var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-infinite-pagination" },
    [
      _vm.paginationEnabled
        ? _c(
            "transition-group",
            { attrs: { name: "pagination", tag: "p" } },
            _vm._l(_vm.items, function (item) {
              return _c("span", {
                key: item,
                staticClass: "pagination-item",
                on: {
                  click: function ($event) {
                    return _vm.click(item)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }