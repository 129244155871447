export default class ValidationLang {

    getMessageTranslated(errorResponse) {
        var errorsTranslated = new Map();
        for (const [field, errorsForField] of Object.entries(errorResponse)) {
            errorsTranslated.set(field, new Array());
            errorsForField.forEach((error, index) => {
                errorsTranslated.get(field).push(
                    error['message']
                );
            });
        }
        return errorsTranslated;
    }

}
