var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "customer-ad-card",
      style: { backgroundImage: "url(" + _vm.backgroundImage + ")" },
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-col justify-around h-full w-9/12" },
        [
          _vm.isProspect
            ? [
                _c("p", { staticClass: "font-bold text-xl" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans.get("offer-list.customer_ad.prospect.title")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "leading-6 mb-2" }, [
                  _c("span", {
                    staticClass: "leading-6 mb-2",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.trans.get("offer-list.customer_ad.prospect.text")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "shadow-large mb-2",
                        attrs: { color: "white", block: "", small: "" },
                        on: { click: _vm.openRefillModal },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "text-pack1-from" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-plus"] },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.trans.get(
                                    "offer-list.customer_ad.prospect.btn"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm.isCustomer
            ? [
                _c("p", { staticClass: "font-bold text-xl" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans.get("offer-list.customer_ad.customer.title")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "leading-6 mb-2" }, [
                  _c("span", {
                    staticClass: "leading-6 mb-2",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.trans.get("offer-list.customer_ad.customer.text")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "shadow-large mb-2 text-center",
                        attrs: {
                          color: "white",
                          block: "",
                          small: "",
                          href: _vm.ziggy_route("account.sponsorship"),
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "text-pack1-from" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-plus"] },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.trans.get(
                                    "offer-list.customer_ad.customer.btn"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c("p", { staticClass: "font-bold text-xl" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans.get("offer-list.customer_ad.hot_prospect.title")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "leading-6 mb-2" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "phone-volume"] },
                    }),
                    _vm._v(" "),
                    _vm.isHotProspect
                      ? [
                          _c("span", {
                            staticClass: "leading-6 mb-2",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.trans.get(
                                  "offer-list.customer_ad.hot_prospect.text"
                                )
                              ),
                            },
                          }),
                        ]
                      : [
                          _c("span", {
                            staticClass: "leading-6 mb-2",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.trans.get(
                                  "offer-list.customer_ad.signout.text"
                                )
                              ),
                            },
                          }),
                        ],
                  ],
                  2
                ),
              ],
          _vm._v(" "),
          _c("p", [
            _c(
              "a",
              {
                staticClass: "text-white font-bold",
                attrs: { href: _vm.ziggy_route("footer.faq") },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "circle-question"] },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "underline" }, [
                  _vm._v(_vm._s(_vm.trans.get("offer-list.customer_ad.faq"))),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }