var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.itemClass, on: { click: _vm.selectConversation } },
    [
      _c(
        "div",
        { staticClass: "flex-none self-center pr-3" },
        [
          _c("base-avatar", {
            attrs: {
              name: _vm.name,
              photo: _vm.photo,
              status: _vm.status,
              color: _vm.selected ? "neutral" : "primary",
              outlined: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow self-center min-w-0" }, [
        _c("p", { staticClass: "conversation-list-item-name" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _vm.isTyping(this.participants)
          ? _c("p", [_vm._v(_vm._s(_vm.trans.get("chat.is_typing")))])
          : _vm.hasLastMessage
          ? _c("p", { staticClass: "conversation-list-item-last-message" }, [
              _vm.isTextMessage
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.lastMessage.content.text),
                    },
                  })
                : _vm.isImageMessage
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "image"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans.get("generic.chat_message_image")) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm.isAudioMessage
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "microphone-lines"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans.get("generic.chat_message_audio")) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm.isStickerMessage
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "note-sticky"] },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.trans.get("generic.chat_message_sticker")
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.isMasterStatusOffline
        ? _c(
            "div",
            { staticClass: "flex-none self-center" },
            [
              _c(
                "base-button",
                {
                  staticClass: "min-w-30",
                  attrs: { color: "neutral", outlined: "", small: "" },
                  on: { click: _vm.displayModalNotifMasterOnline },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "bell"], size: "1x" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get("generic.alert_notif_master_online")
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.isMasterStatusBusy
        ? _c(
            "div",
            { staticClass: "flex-none self-center" },
            [
              _c(
                "base-button",
                {
                  staticClass: "min-w-30",
                  attrs: { color: "busy", outlined: "", small: "" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.trans.get("generic.status_busy")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm.isChatLiveEnabled
        ? _c(
            "div",
            { staticClass: "flex-none self-center" },
            [
              _c(
                "base-button",
                {
                  staticClass: "min-w-30",
                  attrs: { outlined: "", small: "" },
                  on: { click: _vm.startLiveChat },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "message-lines"] },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.trans.get("generic.chat_live")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.hasLastMessage
        ? _c(
            "div",
            { staticClass: "flex-none self-center whitespace-nowrap" },
            [
              _c("p", { staticClass: "whitespace-nowrap" }, [
                _vm._v(" · " + _vm._s(_vm.lastMessageWrittenSince)),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }