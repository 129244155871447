var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Wheel")]),
        _vm._v(" "),
        _c("game-wheel", {
          ref: "wheel",
          attrs: {
            "wheel-image": "game/example_development/wheel.png",
            "button-play-image": "game/example_development/button_play.png",
            "button-played-image": "game/example_development/button_played.png",
            "prize-image": _vm.prizeImage,
            "prize-action": _vm.prizeAction,
          },
          on: { play: _vm.play },
        }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("GameWheel - props"),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("maxValue")]),
            _vm._v(": Number - "),
            _c("em", [_vm._v("Number of prizes (8, 10 ...)")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("buttonPlayImage")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("buttonPlayedImage")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("prizeAction")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("prizeImage")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("wheelImage")]), _vm._v(": String")]),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("GameWheelBackground - props"),
        ]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("The background can be a video, an image or a color."),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("color")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("fullscreen")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("image")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("order")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("scale")]),
            _vm._v(": Number - "),
            _c("em", [_vm._v("Size in % (125, 150, 200 ...)")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("spin")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("video")]),
            _vm._v(": Object - "),
            _c("em", [
              _vm._v(
                "Example : {mp4: '/wheel_background.mp4', webm: '/wheel_background.webm'}"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("Game builder"),
        ]),
        _vm._v(" "),
        _c(
          "development-paragraph",
          [
            _c(
              "base-link",
              { attrs: { href: _vm.ziggy_route("development.wheel.builder") } },
              [_vm._v("game-builder")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }