var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conversation-message-content-sticker" }, [
    _c(
      "div",
      { staticClass: "conversation-message-content-sticker-thumbnail" },
      [
        _c("img", { attrs: { src: _vm.message.content.thumbnail } }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "conversation-message-content-sticker-status" },
          [
            _c("conversation-message-metadata", {
              attrs: { status: _vm.messageStatus },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }