var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-card-small offer-card-small-loader" },
    [
      _c("div", { staticClass: "flex items-center w-full" }, [
        _c(
          "div",
          { staticClass: "offer-card-small-col-left" },
          [
            _c("base-avatar", {
              attrs: {
                color: "neutral-secondary",
                "skeleton-loader": "",
                "x-large": "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "offer-card-small-col-right" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offer-card-actions" },
            [
              _c("base-button", {
                staticClass: "offer-card-action w-14",
                attrs: {
                  color: "neutral-secondary",
                  small: "",
                  "skeleton-loader": "",
                },
              }),
              _vm._v(" "),
              _c("base-button", {
                staticClass: "offer-card-action w-14",
                attrs: {
                  color: "neutral-secondary",
                  small: "",
                  "skeleton-loader": "",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offer-card-small-col-right-header" }, [
      _c("p", { staticClass: "offer-card-small-name" }, [
        _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "offer-card-small-price" }, [
        _c("span", { staticClass: "skeleton-text" }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-grow flex min-w-0" }, [
      _c("div", { staticClass: "flex-grow min-w-0" }, [
        _c("p", { staticClass: "offer-card-small-description" }, [
          _c("span", { staticClass: "skeleton-text leading-4 max-h-4" }, [
            _vm._v(" "),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }