var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: _vm.inputClass }, [
      _vm.hasPrepend
        ? _c(
            "div",
            { staticClass: "flex-none self-center" },
            [_vm._t("prepend")],
            2
          )
        : _vm.hasIcon
        ? _c(
            "div",
            { class: _vm.iconClass, on: { click: _vm.focusInput } },
            [_vm._t("icon")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "relative flex-grow" }, [
        _c("input", {
          class: _vm.fieldClass,
          attrs: {
            id: _vm.id,
            name: _vm.name,
            type: _vm.type,
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            autocomplete: _vm.autocomplete,
            inputmode: _vm.inputmode,
            readonly: _vm.readonly,
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.updateValue($event.target.value)
            },
            keydown: function ($event) {
              return _vm.$emit("keydown", $event)
            },
            blur: function ($event) {
              return _vm.$emit("blur", $event)
            },
            focus: function ($event) {
              return _vm.$emit("focus", $event)
            },
          },
        }),
        _vm._v(" "),
        _vm.hasLabel
          ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
              _vm._v(_vm._s(_vm.label)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.hasIconAppend
        ? _c(
            "div",
            { class: _vm.iconClass, on: { click: _vm.focusInput } },
            [_vm._t("icon-append")],
            2
          )
        : _vm.hasAppend
        ? _c(
            "div",
            { staticClass: "flex-none self-center" },
            [_vm._t("append")],
            2
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", {
          class: _vm.messageClass,
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }