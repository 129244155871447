var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("chat.modals.add_comment_title", {
                master_name: _vm.master_name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", [
        _c("p", { staticClass: "font-smaller" }, [
          _vm._v(_vm._s(_vm.trans.get("chat.modals.add_comment_text"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "modal-paragraph",
        [
          _c("base-text-input", {
            attrs: {
              autofocus: "",
              outlined: "",
              error: _vm.errorClientNickName,
              label: this.trans.get("chat.modals.add_comment_input_label"),
              placeholder: this.trans.get(
                "chat.modals.add_comment_input_label"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "circle-user"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.client_nick_name,
              callback: function ($$v) {
                _vm.client_nick_name = $$v
              },
              expression: "client_nick_name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-paragraph",
        [
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.trans.get("generic.your_rating")) + " : "),
          ]),
          _vm._v(" "),
          _c("base-rating", {
            model: {
              value: _vm.comment_rating,
              callback: function ($$v) {
                _vm.comment_rating = $$v
              },
              expression: "comment_rating",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-paragraph",
        [
          _c("base-textarea", {
            attrs: {
              outlined: "",
              error: _vm.errorCommentText,
              label: this.trans.get("generic.your_comment"),
              placeholder: this.trans.get("generic.your_comment"),
              rows: 5,
              "icon-top": "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "message-lines"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.comment_text,
              callback: function ($$v) {
                _vm.comment_text = $$v
              },
              expression: "comment_text",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-5" },
        [
          _c(
            "base-button",
            { attrs: { outlined: "" }, on: { click: _vm.closeModal } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.cancel")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("base-button", { on: { click: _vm.postComment } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans.get("generic.validate")) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }