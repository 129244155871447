<template>
    <div :class="classes">
        <slot/>

        <base-timer auto-start v-if="hasTime" countdown :time="delay" @end="close"></base-timer>
    </div>
</template>

<style lang="scss">
.base-bar {
    @apply text-center font-bold uppercase;

    &--color {
        &-neutral {
            @apply bg-neutral-500 text-white;
        }

        &-info {
            @apply bg-primary text-white;
        }

        &-success {
            @apply bg-success text-white;
        }

        &-warning {
            @apply bg-warning text-white;
        }

        &-danger {
            @apply bg-danger text-white;
        }

        &-pack-1 {
            @apply text-white;
            background: linear-gradient(to right, var(--color-pack1-from), var(--color-pack1-to));
        }
    }

    &--block {
        @apply block;
    }

    &--small {
        @apply p-1 text-xs;
    }

    &--medium {
        @apply p-2 text-sm;
    }

    &--large {
        @apply p-3 text-base;
    }

    &--elevated {
        @apply shadow-large;
    }

    &--pill {
        @apply rounded-full;
    }

    svg {
        @apply mr-1;
    }
}

</style>

<script>
import BaseButton from "./BaseButton";
import BaseTimer from "./BaseTimer";

export default {
    name: "BaseBar",
    components: {BaseTimer, BaseButton},
    props: {
        color: {
            type: String,
            validator: function (value) {
                return ['info', 'success', 'warning', 'danger', 'neutral', 'pack-1'].indexOf(value) !== -1
            }
        },
        delay: Number,
        elevated: Boolean,
        large: Boolean,
        pill: Boolean,
        small: Boolean,
    },
    computed: {
        hasTime() {
            return this.delay !== undefined && Number.isInteger(this.delay) && this.delay > 0;
        },
        classes() {
            return {
                'base-bar': true,
                'base-bar--color-info': this.color === 'info',
                'base-bar--color-success': this.color === 'success',
                'base-bar--color-warning': this.color === 'warning',
                'base-bar--color-danger': this.color === 'danger',
                'base-bar--color-neutral': this.color === 'neutral',
                'base-bar--color-pack-1': this.color === 'pack-1',
                'base-bar--elevated': this.elevated,
                'base-bar--small': this.small,
                'base-bar--medium': !this.small && !this.large,
                'base-bar--large': this.large,
                'base-bar--pill': this.pill,
            }
        },
    },
    methods: {
        close() {
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        }
    },
}
</script>
