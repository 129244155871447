<template>
    <div class="w-full flex py-2 pr-2 md:px-4">
        <div class="flex-none self-center md:hidden">
            <base-button icon @click="back">
                <font-awesome-icon :icon="['fal', 'chevron-left']" size="lg"/>
            </base-button>
        </div>
        <div class="flex-none pr-4 self-center">
            <base-avatar
                :name="this.getParticipantName(care)"
                :photo="care.photo"
                outlined />
        </div>
        <div class="flex-grow self-center min-w-10 gap-2">
            <p class="text-sm font-bold text-status-available">{{ name }}</p>
        </div>
        <div class="flex-none self-center">
            <base-menu icon drop-left>
                <base-button icon>
                    <font-awesome-icon :icon="['far', 'ellipsis-vertical']" size="lg"/>
                </base-button>
                <template #items>
                    <base-menu-item @click="markConversationAsUnread(getSelectedParticipants)">
                        <font-awesome-icon :icon="['fal', 'envelope']"/>
                        {{ trans.get('generic.set_unread') }}
                    </base-menu-item>
                </template>
            </base-menu>
        </div>

    </div>
</template>

<style>

</style>

<script>
import BaseButton from '../elements/BaseButton';
import {mapGetters, mapActions} from 'vuex';
import BaseAvatar from '../elements/BaseAvatar';
import ChatMixin from './ChatMixin';
import BaseMenu from "../elements/BaseMenu";
import BaseMenuItem from "../elements/BaseMenuItem";

export default {
    name: 'ConversationHeaderCustomerMasterCareInformationDisplay',
    components: {BaseMenuItem, BaseMenu, BaseAvatar, BaseButton},
    mixins: [ChatMixin],
    props: {
        careType: {
            type: String,
        },
    },
    computed: {
        ...mapGetters('chat', ['getSelectedParticipants']),
        care: {
            get()
            {
                return this.interlocutor();
            },
        },
        name()
        {
            switch (this.careType) {
                case 'customer_care':
                    return this.trans.get('generic.chat_customer_care_name');
                case 'master_care':
                    return this.trans.get('generic.chat_master_care_name');
            }
        },
    },
    methods: {
        ...mapActions('chat', ['markConversationAsUnread', 'closeConversation']),
        unreadConversation()
        {
            this.markConversationAsUnread(this.getSelectedParticipants);
        },
        back() {
            this.closeConversation();
            history.pushState(null, "chat_v2", "chat_v2");
        },
    },
};
</script>
