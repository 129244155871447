<template>
    <div class="swiper-slide pb-4 text-main text-xs">
        <a :href="comment.offer.url">
            <base-rating v-if="hasRating" :value="rating" disabled/>
            <span class="font-bold" v-html="trans.get('generic.customer_has_consulted_the_master', {
                        date: comment.created_at,
                        user: comment.user.name,
                        master: masterLink
                    })">
            </span>
            <p class="text-xs">{{ comment.comment }}</p>
        </a>
    </div>
</template>

<script>
import BaseRating from "../elements/BaseRating.vue";
import BaseLink from "../elements/BaseLink";

export default {
    name: "VerticalCommentCard",
    components: {BaseLink, BaseRating},
    props: {
        comment: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    methods: {
        goToMasterPage() {
            return window.location.href = this.master.url;
        }
    },
    computed: {
        masterLink() {
            return this.compileHtml('<base-link href="' + this.comment.offer.url + '" :underline="false" class="whitespace-nowrap">' + this.comment.offer.name + '</base-link>')
        },
        rating() {
            return this.comment.mark / 10;
        },
        hasRating() {
            return this.comment.mark !== null && this.comment.mark !== undefined;
        }
    }
}
</script>
