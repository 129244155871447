<template>
    <span class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 bg-danger text-white rounded-full py-0.5 px-2">{{ amount }}</span>
</template>

<script>
export default {
    name: 'BasePin',
    props: {
        amount: {
            type: Number,
            default: 0
        }
    }
};
</script>
