<template>
    <base-modal small>
        <modal-heading>
            {{ trans.get('chat.modals.master_is_connected', {master_name: master.name}) }}
        </modal-heading>
        <modal-paragraph class="flex justify-center">
            <div class="master-infos-container">
                <div class="flex-none pr-4 self-center">
                    <base-avatar :name="master.name"
                                 :photo="master.photo"
                                 :status="master.status"/>
                </div>
                <div class="flex-grow self-center min-w-10 overflow-hidden">
                    <p class="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {{ master.name }}
                    </p>
                    <p class="text-sm text-neutral-400 whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {{ trans.get('generic.status_' + master.status) }}
                    </p>
                </div>
            </div>
        </modal-paragraph>
        <div v-if="master.chatLiveEnabled" class="grid grid-cols-1 gap-4 mt-5">
            <base-button autofocus @click="startLiveChat">
                {{ trans.get('chat.modals.start_chat_live_button_label')}}
            </base-button>
        </div>
        <div v-else class="grid grid-cols-1 gap-4 mt-5">
            <base-link :href="master.url">
                <base-button autofocus class="w-full">
                    <font-awesome-icon :icon="['fas', 'user']" size="1x" />
                    {{ trans.get('generic.see_the_master_profile') }}
                </base-button>
            </base-link>
        </div>
    </base-modal>
</template>

<style lang="scss">
.master-infos-container {
    @apply flex w-3/4 px-4 py-2 text-primary rounded-lg shadow-large;
}
</style>

<script>
import ModalHeading from "../../modals/elements/ModalHeading";
import BaseButton from "../../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";
import BaseAvatar from "../../elements/BaseAvatar";
import ModalParagraph from "../../modals/elements/ModalParagraph";
import BaseLink from "../../elements/BaseLink";
export default {
    name: "ModalChatMasterConnected",
    components: {ModalParagraph, BaseAvatar, BaseButton, ModalHeading, BaseLink},
    computed: {
        ...mapGetters('modals', ['getParam']),
        ...mapGetters('chat', ['getSelectedParticipants', 'getOfferIdFromParticipants'])
    },
    methods: {
        ...mapActions('chat', ['startLiveChatProcess']),
        startLiveChat(event) {
            event.stopPropagation();

            this.startLiveChatProcess({offerId:this.getOfferIdFromParticipants(this.getSelectedParticipants)});
        }
    },
    data() {
        return {
            master: {name: ''}
        }
    },
    mounted() {
        if (this.getParam['chat-master-connected']['master'] !== undefined) {
            this.master = this.getParam['chat-master-connected']['master'];
        }
    }
}
</script>
