import Vue from "vue";

const ERROR_MESSAGE_NOT_SENT = 'message_not_sent';
const ERROR_MESSAGE_NOT_LOADED = 'message_not_loaded';

const MessageMixin = {
    methods: {
        createMessage: function () {
            let today = new Date();
            let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
            let dateTime = date + ' ' + time;

            return {
                id: null,
                content: {
                    text: '',
                    url: '',
                    file: '',
                },
                participant: {},
                type: '',
                created_at: dateTime,
                error: null,
            };
        },
        removeErrorFromMessage: function (message) {
            Vue.set(message, 'error', null);
        },
        messageNotSent: function (message) {
            Vue.set(message, 'error', ERROR_MESSAGE_NOT_SENT);
        },
        messageNotLoaded: function (message) {
            Vue.set(message, 'error', ERROR_MESSAGE_NOT_LOADED);
        },
        hasMessageError: function (message) {
            return message.error !== undefined && message.error !== null && message.error.length > 0;
        },
        isMessageNotSent: function (message) {
            return message.error === ERROR_MESSAGE_NOT_SENT;
        },
        isMessageNotLoaded: function (message) {
            return message.error === ERROR_MESSAGE_NOT_LOADED;
        },
    }
}

export default MessageMixin;
