var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "li",
        [
          _c(
            "development-link",
            { attrs: { href: _vm.ziggy_route(_vm.route) } },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }