var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("modal-heading", { attrs: { level: 2 } }, [
        _vm._v(_vm._s(_vm.title) + " :"),
      ]),
      _vm._v(" "),
      _c("base-textarea", {
        attrs: {
          rows: _vm.rows,
          placeholder: _vm.placeholder,
          disabled: _vm.status === "loading",
          readonly: _vm.readonly,
          outlined: "",
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _vm.showStatus
        ? _c("div", { staticClass: "mt-1 text-sm text-right" }, [
            _vm.status === "loading"
              ? _c(
                  "div",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "spinner"], spin: "" },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.trans.get("generic.loading")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm.status === "saving"
              ? _c(
                  "div",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "spinner"], spin: "" },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.trans.get("generic.saving_changes")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm.status === "saved"
              ? _c(
                  "div",
                  { staticClass: "text-success" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "check"] },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.trans.get("generic.changes_saved")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _c("div", [_vm._v(" ")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }