var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Bar")]),
        _vm._v(" "),
        _c("base-bar", [_vm._v("Bar")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [
              _vm._v("'info', 'success', 'warning', 'danger', 'neutral'"),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("elevated")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Elevated")]),
        _vm._v(" "),
        _c(
          "base-bar",
          { attrs: { elevated: "" } },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "message-lines"] },
            }),
            _vm._v(" Bar elevated"),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Type")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : info / success / warning / danger / neutral\n        "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-bar",
              { attrs: { elevated: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Bar default"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "info" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Info"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "success" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Success"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "warning" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Warning"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "danger" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Danger"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "neutral" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Neutral"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { color: "pack-1" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Pack 1"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-bar",
              { attrs: { elevated: "", small: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Bar default small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "info" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Info small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "success" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Success small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "warning" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Warning small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "danger" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Danger small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "neutral" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Neutral small"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { small: "", color: "pack-1" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Pack 1 small"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-bar",
              { attrs: { elevated: "", large: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Bar default Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "info" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Info Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "success" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Success Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "warning" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Warning Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "danger" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Danger Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "neutral" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Neutral Large"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { large: "", color: "pack-1" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Pack 1 Large"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Pill")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-bar",
              { attrs: { elevated: "", pill: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Bar default Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "info" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Info Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "success" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Success Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "warning" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Warning Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "danger" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Danger Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "neutral" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Neutral Pill"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { pill: "", color: "pack-1" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Pack 1 Pill"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Delay")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("delay")]),
          _vm._v(" before remove this bar"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-bar",
              { attrs: { delay: 5000, elevated: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Bar (5 sec)"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { delay: 10000, color: "info" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Info (10 sec)"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { delay: 15000, color: "success" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Success (15 sec)"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { delay: 20000, color: "warning" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Warning (20 sec)"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { delay: 25000, color: "danger" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Danger (25 sec)"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-bar",
              { attrs: { delay: 30000, color: "neutral" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "message-lines"] },
                }),
                _vm._v(" Neutral (30 sec)"),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }