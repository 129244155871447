<template>
    <base-menu ref="base-select-conversation-type" class="base-select-conversation-type" drop-left>
        <base-button small pill class="base-select-conversation-type-button">
            <template v-if="isCallSelected">
                <font-awesome-icon :icon="['fas', 'phone']" class="mr-2"/>
                {{ trans.get('generic.call') }}
            </template>
            <template v-else-if="isChatSelected">
                <font-awesome-icon :icon="['fas', 'message-lines']" class="mr-2"/>
                {{ trans.get('generic.chat') }}
            </template>
            <template v-else-if="isCallAndChatSelected">
                <font-awesome-icon :icon="['fas', 'phone']" class="mr-1"/>
                <font-awesome-icon :icon="['fas', 'message-lines']" class="mr-2"/>
                {{ trans.get('generic.call_and_chat') }}
            </template>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="ml-2"/>
        </base-button>
        <template #items>
            <base-menu-item :selected="isCallSelected" @click="selectItem('only_call')">
                <font-awesome-icon :icon="['far', 'phone']"/>
                <span>{{ trans.get('generic.call') }}</span>
                <font-awesome-icon v-show="isCallSelected" :icon="['far', 'check']"/>
            </base-menu-item>
            <base-menu-item :selected="isChatSelected" @click="selectItem('only_chat')">
                <font-awesome-icon :icon="['far', 'message-lines']"/>
                <span>{{ trans.get('generic.chat') }}</span>
                <font-awesome-icon v-show="isChatSelected" :icon="['far', 'check']"/>
            </base-menu-item>
            <base-menu-item :selected="isCallAndChatSelected" @click="selectItem(null)">
                <font-awesome-icon :icon="['far', 'phone']"/>
                <font-awesome-icon :icon="['far', 'message-lines']"/>
                <span>{{ trans.get('generic.call_and_chat') }}</span>
                <font-awesome-icon v-show="isCallAndChatSelected" :icon="['far', 'check']"/>
            </base-menu-item>
        </template>
    </base-menu>
</template>

<style lang="scss">
.base-select-conversation-type {
    .base-select-conversation-type-button {
        @apply flex items-center my-2;
    }

    .base-menu-item {
        @apply flex min-w-64 bg-white text-dark;

        span {
            @apply flex-grow;
        }

        svg {
            @apply mr-2;
        }

        &--selected {
            @apply text-primary;
        }
    }
}
</style>

<script>
import BaseMenu from "../BaseMenu";
import BaseMenuItem from "../BaseMenuItem";

export default {
    name: "BaseSelectConversationType",
    components: {BaseMenuItem, BaseMenu},
    methods: {
        selectItem(value) {
            this.selectedItem = value;
            this.$emit('input', value);
            this.$emit('change');
            this.$refs["base-select-conversation-type"].close();
        },
    },
    computed: {
        isCallSelected() {
            return this.selectedItem === 'only_call';
        },
        isChatSelected() {
            return this.selectedItem === 'only_chat';
        },
        isCallAndChatSelected() {
            return this.selectedItem === null;
        },
    },
    data() {
        return {
            selectedItem: null,
        }
    },
}
</script>
