<template>
    <div class="py-2 px-0.5">
        <div :class="conversationStickerClass" ref="conversation-sticker" tabindex="0">
            <img class="conversation-sticker-image" :src="sticker.thumbnail" :alt="sticker.title">
            <div class="conversation-sticker-price" v-if="sticker.price > 0">{{
                    sticker.price / 100 | formatMoney
                }}
            </div>
            <div class="conversation-sticker-price" v-else>&nbsp;</div>
            <div class="conversation-sticker-title">{{ sticker.title }}</div>
            <base-button class="conversation-sticker-button" :disabled="disabled" :loading="loading"
                         small @click="sendSticker">
                {{ trans.get('generic.send') }}
            </base-button>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-sticker {
    @apply flex flex-col cursor-pointer text-center rounded-lg overflow-hidden transition ease-in-out duration-100;

    &-image {
        @apply text-primary mx-auto transition ease-in-out duration-100;
    }

    &-price {
        @apply h-5 leading-5 text-primary pointer-events-none select-none font-medium;
    }

    &-title {
        @apply px-0.5 text-xs h-7 leading-7 text-main whitespace-nowrap overflow-ellipsis overflow-hidden pointer-events-none select-none;
    }

    .conversation-sticker-button {
        @apply h-7 rounded-none px-0.5;
    }

    &--disabled {
        @apply pointer-events-none bg-neutral-100;

        .conversation-sticker {
            &-price, &-image {
                @apply text-main;
            }

            &-image {
                @apply filter grayscale;

            }
        }
    }

    &:not(.conversation-sticker--disabled) {
        &:focus, &:focus-within {
            @apply bg-neutral-100 shadow-small;

            .conversation-sticker {
                &-image {
                    @apply transform scale-110;
                }

                &-title {
                    @apply hidden;
                }
            }
        }
    }

    &.conversation-sticker--disabled, &:not(.conversation-sticker:focus):not(.conversation-sticker:focus-within) {
        .conversation-sticker-button {
            @apply hidden;
        }
    }
}
</style>

<script>
import BaseButton from "../elements/BaseButton.vue";
import ChatMixin from "./ChatMixin";
import MessageMixin from "./MessageMixin";
import {mapActions, mapGetters} from "vuex";
import {ziggy_route} from "../ZiggyMixin";

export default {
    components: {BaseButton},
    name: 'ConversationSticker',
    props: {
        sticker: {
            type: Object,
        },
    },
    mixins: [ChatMixin, MessageMixin],
    computed: {
        ...mapGetters('chat', [
            'getConversation',
            'getSelectedParticipants'
        ]),
        conversationStickerClass() {
            return {
                'conversation-sticker': true,
                'conversation-sticker--disabled': this.disabled,
            }
        }
    },
    methods: {
        ...mapActions('chat', ['addMessageToConversation', 'addStickerToQueue', 'toggleStickersMenu']),
        toggle(enabled) {
            if (enabled === true) {
                this.disabled = false;
            } else if (enabled === false) {
                this.disabled = true;
            } else {
                this.disabled = !this.disabled;
            }
        },
        sendSticker() {
            this.$refs['conversation-sticker'].focus();

            this.loading = true;

            let formData = new FormData();
            formData.append('participants', JSON.stringify({
                from: ChatMixin.methods.me(),
                to: ChatMixin.methods.interlocutor(),
            }));
            formData.append('chat_session_id', ChatMixin.methods.getChatLiveSessionId());
            formData.append('sticker_id', this.sticker.id);

            return axios.post(ziggy_route('chat.send-sticker'), formData).then((response) => {
                let message = response.data.data;

                this.addMessageToConversation({
                    conversation: this.getConversation(this.getSelectedParticipants),
                    message: message,
                });

                this.addStickerToQueue(message);

                this.loading = false;

                this.toggleStickersMenu(false);
            }).catch((errorResponse) => {
                this.loading = false;
                console.error(errorResponse);
            });
        }
    },
    data() {
        return {
            loading: false,
            disabled: false,
        }
    }
}
</script>
