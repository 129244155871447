var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Console")]),
        _vm._v(" "),
        _c("base-console"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-button",
              { attrs: { color: "primary" }, on: { click: _vm.info } },
              [_vm._v("Info")]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { color: "neutral" }, on: { click: _vm.log } },
              [_vm._v("Log")]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { color: "busy" }, on: { click: _vm.warn } },
              [_vm._v("Warn")]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { color: "danger" }, on: { click: _vm.error } },
              [_vm._v("Error")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Utilisation")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v(
            "Il est possible d'activer la console en ajoutant la balise dans un fichier v4 ou v5 :"
          ),
        ]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("code", [_vm._v("<base-console></base-console>")]),
        ]),
        _vm._v(" "),
        _c("development-paragraph", { staticClass: "text-danger" }, [
          _c("strong", [_vm._v("Attention : ")]),
          _vm._v(
            " Ne pas oublier de retirer la balise avant toute mise en production !"
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }