var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "development",
    [
      _c(
        "div",
        [_c("development-heading", [_vm._v("Text and Typography")])],
        1
      ),
      _vm._v(" "),
      _c("development-heading", [_vm._v("Text color")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-primary" }, [_vm._v("text-primary")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-secondary" }, [_vm._v("text-secondary")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-black" }, [_vm._v("text-black")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-darkest" }, [_vm._v("text-darkest")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-dark" }, [_vm._v("text-dark")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-main" }, [_vm._v("text-main")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-light" }, [_vm._v("text-light")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-lightest" }, [_vm._v("text-lightest")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }