var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conversation-informations" }, [
    _c(
      "div",
      {
        staticClass: "h-16 flex w-full items-center cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.toggleConversationInformations(false)
          },
        },
      },
      [
        _c(
          "base-button",
          {
            staticClass: "ml-4",
            attrs: { link: "", "font-normal": "", "normal-case": "" },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-2",
              attrs: { icon: ["fal", "xmark"], size: "2x" },
            }),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.trans.get("generic.chat_close_conversation_informations")
                ) +
                "\n        "
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "py-4 px-7 overflow-y-auto h-full md:pb-4 pb-20" },
      [
        _c("h1", { staticClass: "text-primary text-xl font-bold mb-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get("chat.information_about_customer", {
                  name: _vm.client.name,
                })
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "p-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans.get("generic.user_segment.type")) +
                          "\n                "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm.getSegment === "gold"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get("generic.user_segment.gold")
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-star",
                                attrs: { icon: ["fas", "trophy-star"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "silver"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get("generic.user_segment.silver")
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-neutral-400",
                                attrs: { icon: ["fas", "trophy"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "diamond"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.diamond"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-dark",
                                attrs: { icon: ["fas", "gem"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "new_paying_customer"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.new_paying_customer"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-primary",
                                attrs: { icon: ["fas", "face-smile-plus"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "free_customer"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.free_customer"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-primary",
                                attrs: { icon: ["fas", "face-smile-plus"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "free_customer"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.free_customer"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-main",
                                attrs: { icon: ["far", "face-meh"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "customer_to_retain"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.customer_to_retain"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-success",
                                attrs: { icon: ["far", "face-smile-plus"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "loyal_customer"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.loyal_customer"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-primary",
                                attrs: { icon: ["far", "face-smile"] },
                              }),
                            ],
                            1
                          )
                        : _vm.getSegment === "customer_to_reloyalty"
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.customer_to_reloyalty"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-warning",
                                attrs: { icon: ["far", "face-sleeping"] },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.trans.get(
                                      "generic.user_segment.customer"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "text-primary",
                                attrs: { icon: ["fas", "user"] },
                              }),
                            ],
                            1
                          ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.trans.get(
                            "chat.information_about_customer_last_consultation"
                          )
                        )
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(_vm.getDateLastConsultation)
                        )
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _vm.cost_by_minute > 0
              ? _c("base-list-item", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-chevron-right"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              _vm._s(_vm.trans.get("generic.rate")) + " :"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      this.hasPrimeFreeChatAdvantage()
                        ? {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("formatMoneyPerMinute")(0))
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatMoneyPerMinute")(
                                      _vm.cost_by_minute
                                    )
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          },
                    ],
                    null,
                    true
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            this.hasPrimeFreeChatAdvantage()
              ? _c("base-list-item", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-chevron-right"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              _vm._s(_vm.trans.get("chat.consultation_type"))
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "description",
                        fn: function () {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.trans.get("chat.modals.free_chat_no_payoff")
                              )
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3970311961
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        this.config.get("call.user_notes_enabled")
          ? _c("master-user-notes-editor", {
              attrs: { "user-id": _vm.client.id },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "text-center mt-6 underline text-danger" }, [
          this.getChatLiveSessionId()
            ? _c(
                "a",
                { on: { click: _vm.reportProblem } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.trans.get("chat.signal_problem_with_client")) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }