var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "conversation-messages",
      attrs: { id: "conversation-messages" },
    },
    [
      _c("conversation-alert", { key: _vm.interlocutor().id }),
      _vm._v(" "),
      _vm.showConversationMessagesSuggestionBox
        ? _c("conversation-messages-suggestion-box")
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.containerClass,
          attrs: { id: "conversation-messages-list-container" },
        },
        [
          _c(
            "div",
            { attrs: { id: "conversation-messages-list" } },
            [
              _c("conversation-messages-tooltip"),
              _vm._v(" "),
              _c("chat-bot"),
              _vm._v(" "),
              _vm.isTyping(_vm.getSelectedParticipants)
                ? _c("conversation-message", {
                    attrs: {
                      message: {
                        participant: this.interlocutor(),
                        type: "text",
                        content: { text: this.isTypingText },
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(this.conversationsMessages, function (message) {
                return [
                  _c("conversation-message", {
                    key: message.id + message.created_at,
                    attrs: { message: message },
                  }),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.canSendHearts ? _c("conversation-hearts") : _vm._e(),
      _vm._v(" "),
      _c("conversation-sticker-animations"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }