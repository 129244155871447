<template>
    <div v-if="hasItems" class="space-y-1">
        <template v-for="item in items">
            <base-list-item v-if="isObjectItem(item)">
                <template v-if="itemHasIcon(item)" #icon><font-awesome-icon :icon="item.icon"/></template>
                <template v-if="itemHasTitle(item)" #title>{{ item.title }}</template>
                <template v-if="itemHasDescription(item)" #description>{{ item.description }}</template>
            </base-list-item>
            <base-list-item v-else>
                {{ item }}
            </base-list-item>
        </template>
    </div>
    <div v-else class="space-y-1">
        <slot/>
    </div>
</template>

<script>
import BaseListItem from "./BaseListItem";

export default {
    name: "BaseList",
    components: {BaseListItem},
    props: {
        items: Array
    },
    computed: {
        hasItems() {
            return this.items !== undefined && this.items.length > 0;
        },
        hasIcon() {
            return !!this.$slots.icon;
        },
    },
    methods: {
        isObjectItem(item) {
            return typeof item === 'object' && item !== null && this.items.length > 0;
        },
        itemHasIcon(item) {
            return typeof item === 'object' && item !== null && 'icon' in item;
        },
        itemHasTitle(item) {
            return typeof item === 'object' && item !== null && 'title' in item;
        },
        itemHasDescription(item) {
            return typeof item === 'object' && item !== null && 'description' in item;
        }
    }
}
</script>
