var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "development" }, [
    _c("div", { staticClass: "lg:flex" }, [
      _c("div", { staticClass: "lg:flex-none p-4" }, [
        _c(
          "div",
          { staticClass: "mb-4 lg:mt-6 border-b border-neutral-100" },
          [
            _c("base-text-input", {
              ref: "searchInput",
              attrs: { placeholder: "Rechercher ...", small: "" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "magnifying-glass"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _vm.hasSearchValue
                        ? _c(
                            "base-button",
                            {
                              attrs: { icon: "", small: "" },
                              on: { click: _vm.clearSearch },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "text-neutral-300",
                                attrs: { icon: ["fas", "circle-xmark"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("base-title", { attrs: { color: "neutral", level: 2 } }, [
              _vm._v("Style"),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _c("development-menu-item", {
                  attrs: {
                    route: "development.style.text-and-typography",
                    label: "Text and typography",
                    filter: _vm.filter,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("base-title", { attrs: { color: "neutral", level: 2 } }, [
              _vm._v("Base components"),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _c("development-menu-item", {
                  attrs: {
                    route: "development.alert",
                    label: "Alert",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.audio-player",
                    label: "Audio Player",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.avatar",
                    label: "Avatar",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.badge",
                    label: "Badge",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.bar",
                    label: "Bar",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.button",
                    label: "Button",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.checkbox",
                    label: "Checkbox",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.code-input",
                    label: "Code Input",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.console",
                    label: "Console",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.icon",
                    label: "Icon",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.link",
                    label: "Link",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.list",
                    label: "List",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.lottie-file-player",
                    label: "Lottie file Player",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.menu",
                    label: "Menu",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.modal",
                    label: "Modal",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.phone-input",
                    label: "Phone Input",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.radio-input",
                    label: "Radio Input",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.rating",
                    label: "Rating",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.select",
                    label: "Select",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.separator",
                    label: "Separator",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.slider",
                    label: "Slider",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.text-input",
                    label: "Text Input",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.textarea",
                    label: "Textarea",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.timer",
                    label: "Timer",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.title",
                    label: "Title",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.tooltip",
                    label: "Tooltip",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.webm-player",
                    label: "Webm Player",
                    filter: _vm.filter,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("base-title", { attrs: { color: "neutral", level: 2 } }, [
              _vm._v("Game"),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _c("development-menu-item", {
                  attrs: {
                    route: "development.wheel",
                    label: "Wheel",
                    filter: _vm.filter,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("base-title", { attrs: { color: "neutral", level: 2 } }, [
              _vm._v("List"),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _c("development-menu-item", {
                  attrs: {
                    route: "development.offer-list.banner",
                    label: "Banner",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.offer-list.offer-card",
                    label: "Offer Card",
                    filter: _vm.filter,
                  },
                }),
                _vm._v(" "),
                _c("development-menu-item", {
                  attrs: {
                    route: "development.offer-list.offer-card-small",
                    label: "Offer Card Small",
                    filter: _vm.filter,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "lg:flex-grow p-4" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }