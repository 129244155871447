<template>
    <div class="offer-card-small">
        <div class="flex items-center w-full">
            <div class="offer-card-small-col-left">
                <a :href="offer.url">
                    <base-badge v-if="hasSegment"
                                :content="segmentContent"
                                :color="segmentColor"
                                outlined bottom center square block>
                        <base-avatar :photo="offer.photo" color="neutral-secondary" x-large/>
                    </base-badge>
                    <base-avatar v-else :photo="offer.photo" color="neutral-secondary" x-large/>
                </a>
            </div>

            <div class="offer-card-small-col-right">
                <a :href="offer.url">
                    <div class="offer-card-small-col-right-header">
                        <p class="offer-card-small-name">{{ offer.name }}</p>
                        <p class="offer-card-small-price">{{ offer.cost_by_minute / 100 | formatMoneyPerMinute }}</p>
                    </div>

                    <div class="flex-grow flex min-w-0">
                        <div class="flex-grow min-w-0">
                            <p class="offer-card-small-description">{{ offer.short_description }}</p>
                        </div>
                    </div>
                </a>

                <offer-card-actions :offer="offer"
                                    :user="user"
                                    :offer_list_type="offer_list_type"
                                    :is_prime_enabled="is_prime_enabled"
                                    :is_free_chat_enabled_for_prospect="is_free_chat_enabled_for_prospect"
                                    :user_credits="user_credits"
                                    small
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.offer-card-small {
    @apply shadow-small bg-white rounded-2xl p-3;

    &-col {
        &-left {
            @apply flex-none pr-3;
        }

        &-right {
            @apply flex-grow flex flex-col min-w-0;

            &-header {
                @apply flex-grow flex min-w-0;
            }

            &-content {
                @apply flex-grow min-w-0 h-15;
            }

            &-footer {
                @apply flex items-end justify-between align-middle my-1;
            }
        }
    }

    &-name {
        @apply flex-grow min-w-0 font-bold text-base text-primary whitespace-nowrap overflow-ellipsis overflow-hidden;
    }

    &-description {
        @apply text-main text-sm line-clamp-2;
    }

    &-price {
        @apply flex-none text-base text-primary font-bold whitespace-nowrap;
    }

    .offer-card-actions {
        @apply flex mt-2 gap-x-2 h-8;

        .offer-card-action {
            @apply w-full flex-1 min-w-0;
        }
    }
}
</style>

<script>
import BaseAvatar from "../elements/BaseAvatar";
import BaseBadge from "../elements/BaseBadge";
import BaseButton from "../elements/BaseButton";
import OfferMixin from "./OfferMixin";
import OfferCardActions from "./OfferCardActions.vue";

export default {
    name: 'OfferCardSmall',
    mixins: [OfferMixin],
    components: {OfferCardActions, BaseBadge, BaseButton, BaseAvatar},
    props: {
        offer: Object,
        user: Object | Array,
        offer_list_type: String,
        is_prime_enabled: Boolean,
        is_free_chat_enabled_for_prospect: Boolean,
        user_credits: Number,
    },
}
</script>
