<template>
    <div :class="containerClass">
        <label :class="labelClass" :for="id">
            <div class="px-2">
                <input type="checkbox"
                       :checked="checked"
                       :disabled="disabled"
                       class="base-checkbox-input"
                       :id="id"
                       :name="name"
                       @change="updateValue($event.target.checked)"/>
                <div :class="checkboxClass">
                    <font-awesome-icon :icon="['far', 'check']" class="base-checkbox-checkmark"/>
                </div>
            </div>

            <div :class="labelContentClass">
                <slot/>
            </div>
        </label>

        <div v-if="hasError" :class="messageClass" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.base-checkbox-label {
    @apply flex flex-row cursor-pointer pt-2 -ml-2;

    &--disabled {
        @apply cursor-default;
    }
}

.base-checkbox-input {
    @apply hidden;

    &:checked ~ .base-checkbox {
        &--color {
            &-primary {
                @apply bg-primary;
            }

            &-secondary {
                @apply bg-secondary;
            }

            &-available {
                @apply bg-status-available;
            }

            &-busy {
                @apply bg-status-busy;
            }

            &-neutral {
                @apply bg-neutral-500;
            }

            &-offline {
                @apply bg-status-offline;
            }

            &-danger {
                @apply bg-danger;
            }

            &-prime {
                @apply bg-prime;
            }

            &-facebook {
                @apply bg-facebook;
            }

            &-whatsapp {
                @apply bg-whatsapp;
            }
        }

        @apply border-none;

        &--disabled {
            @apply bg-neutral-300;
        }

        .base-checkbox-checkmark {
            @apply visible;
        }
    }
}

.base-checkbox {
    @apply flex-none border border-neutral-500 bg-white h-5 w-5 p-0.5 flex items-center justify-center;

    &--disabled {
        @apply border-neutral-300;
    }
}

.base-checkbox-checkmark {
    @apply text-white invisible;
}

.base-checkbox-label-content {
    @apply pt-0.5 pl-4;

    &--disabled {
    }
}

.base-checkbox-message {
    @apply ml-11 text-sm;

    &--error {
        @apply text-danger;
    }
}
</style>

<script>
export default {
    name: 'BaseCheckbox',
    props: {
        id: {
            type: String,
            default() {
                return `checkbox-${this._uid}`;
            }
        },
        color: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'available', 'busy', 'neutral', 'danger', 'prime', 'facebook', 'whatsapp'].indexOf(value) !== -1;
            },
        },
        value: Boolean,
        disabled: Boolean,
        error: String,
        large: Boolean,
        name: String,
        block: Boolean,
        small: Boolean,
    },
    computed: {
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
        containerClass() {
            return {
                'mb-4': true,
                'inline-block pb-2': !this.block,
            }
        },
        checkboxClass() {
            return {
                'base-checkbox': true,
                'base-checkbox--color-primary': this.color === 'primary',
                'base-checkbox--color-secondary': this.color === 'secondary',
                'base-checkbox--color-available': this.color === 'available',
                'base-checkbox--color-busy': this.color === 'busy',
                'base-checkbox--color-offline': this.color === 'offline',
                'base-checkbox--color-neutral': this.color === 'neutral',
                'base-checkbox--color-danger': this.color === 'danger',
                'base-checkbox--color-prime': this.color === 'prime',
                'base-checkbox--color-facebook': this.color === 'facebook',
                'base-checkbox--color-whatsapp': this.color === 'whatsapp',
                'base-checkbox--small': this.small,
                'base-checkbox--medium': !this.small && !this.large,
                'base-checkbox--large': this.large,
                'base-checkbox--disabled': this.disabled,
            };
        },
        labelClass() {
            return {
                'base-checkbox-label': true,
                'base-checkbox-label--disabled': this.disabled,
            };
        },
        labelContentClass() {
            return {
                'base-checkbox-label-content': true,
                'base-checkbox-label-content--disabled': this.disabled,
            };
        },
        messageClass() {
            return {
                'base-checkbox-message': true,
                'base-checkbox-message--error': this.hasError
            };
        }
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },
    },
    data() {
        return {
            checked: !!this.value,
        }
    }
}
</script>
