<template>
    <development>
        <div>
            <development-heading>Avatar</development-heading>
            <base-avatar/>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>color</strong>: String - <em>'primary', 'secondary', 'neutral', 'light'</em></li>
                <li><strong>status</strong>: String - <em>'online', 'busy', 'offline'</em></li>
                <li><strong>name</strong>: String</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>photo</strong>: String</li>
                <li><strong>tile</strong>: Boolean</li>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>xLarge</strong>: Boolean</li>
            </ul>

            <development-heading>Colors</development-heading>
            <development-paragraph><strong>color</strong> : primary <small>(default)</small> / secondary / neutral
            </development-paragraph>
            <div class="flex space-x-2">
                <base-avatar color="primary"/>
                <base-avatar color="secondary"/>
                <base-avatar color="neutral"/>
                <base-avatar color="light"/>
            </div>

            <development-heading>Status</development-heading>
            <development-paragraph><strong>status</strong> : online / busy / offline</development-paragraph>
            <div class="flex space-x-2">
                <base-avatar status="online"/>
                <base-avatar status="busy"/>
                <base-avatar status="offline"/>
            </div>

            <development-heading>Name</development-heading>
            <div class="flex space-x-2">
                <base-avatar name="A"/>
                <base-avatar name="B"/>
                <base-avatar name="C"/>
            </div>

            <development-heading>Outlined</development-heading>
            <div class="flex space-x-2">
                <base-avatar outlined color="primary"/>
                <base-avatar outlined color="secondary" status="online"/>
                <base-avatar tile outlined color="neutral"/>
                <base-avatar tile outlined color="light"/>
            </div>

            <development-heading>Photo</development-heading>
            <development-paragraph><strong>photo</strong> (not working with outlined)</development-paragraph>
            <div class="flex space-x-2">
                <base-avatar photo="https://via.placeholder.com/150" status="online"/>
                <base-avatar photo="https://via.placeholder.com/150" status="busy"/>
                <base-avatar photo="https://via.placeholder.com/150" status="offline"/>
            </div>

            <development-heading>Tile</development-heading>
            <development-paragraph><strong>tile</strong> prop removes the border radius from v-avatar leaving you with a
                simple square avatar
            </development-paragraph>
            <div class="flex space-x-2">
                <base-avatar photo="https://via.placeholder.com/150" tile/>
                <base-avatar photo="https://via.placeholder.com/150" status="online" tile/>
            </div>

            <development-heading>Small</development-heading>
            <div class="flex space-x-2">
                <base-avatar small/>
                <base-avatar small photo="https://via.placeholder.com/150"/>
                <base-avatar small photo="https://via.placeholder.com/150" status="online"/>
                <base-avatar small photo="https://via.placeholder.com/150" tile/>
                <base-avatar small photo="https://via.placeholder.com/150" tile status="online"/>
            </div>

            <development-heading>Large</development-heading>
            <div class="flex space-x-2">
                <base-avatar large/>
                <base-avatar large photo="https://via.placeholder.com/150"/>
                <base-avatar large photo="https://via.placeholder.com/150" status="online"/>
                <base-avatar large photo="https://via.placeholder.com/150" tile/>
                <base-avatar large photo="https://via.placeholder.com/150" tile status="online"/>
            </div>

            <development-heading>xLarge</development-heading>
            <div class="flex space-x-2">
                <base-avatar x-large/>
                <base-avatar x-large photo="https://via.placeholder.com/150"/>
                <base-avatar x-large photo="https://via.placeholder.com/150" status="online"/>
                <base-avatar x-large photo="https://via.placeholder.com/150" tile/>
                <base-avatar x-large photo="https://via.placeholder.com/150" tile status="online"/>
            </div>

            <development-heading>Skeleton-loader</development-heading>
            <div class="flex space-x-2">
                <base-avatar skeleton-loader small/>
                <base-avatar skeleton-loader color="secondary"/>
                <base-avatar skeleton-loader color="neutral" large/>
                <base-avatar skeleton-loader color="neutral-secondary" x-large/>
            </div>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import BaseAvatar from "../elements/BaseAvatar";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";

export default {
    components: {DevelopmentParagraph, DevelopmentHeading, BaseAvatar, Development}
}
</script>
