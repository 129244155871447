var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.conversationClass },
    [
      this.isConversationOpen
        ? _c(
            "div",
            { staticClass: "flex flex-row flex-none h-16" },
            [
              this.speakingWithClient
                ? _c("conversation-header-client-information-display")
                : this.speakingWithMaster
                ? _c("conversation-header-master-information-display")
                : this.speakingWithServiceClient ||
                  this.speakingWithServiceMaster
                ? _c(
                    "conversation-header-customer-master-care-information-display",
                    { attrs: { "care-type": this.interlocutor().type } }
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.connectionStateConnecting
        ? _c(
            "base-bar",
            { attrs: { color: "warning", small: "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "spinner"], spin: "" },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.trans.get("chat.reconnection_attempt")) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm.connectionStateUnavailable
        ? _c(
            "base-bar",
            { attrs: { color: "danger", small: "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.trans.get("chat.connection_interrupted")) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm.offlineChat
        ? _c(
            "base-bar",
            { attrs: { color: "neutral", small: "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "message-lines"] },
              }),
              _vm._v(
                " " + _vm._s(_vm.trans.get("chat.offline_chat")) + "\n    "
              ),
            ],
            1
          )
        : _vm.individualFollowUp
        ? _c(
            "base-bar",
            { attrs: { color: "info", small: "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "message-lines"] },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.trans.get("chat.individual_follow_up")) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("conversation-messages"),
      _vm._v(" "),
      _vm.showConversationAudioRecord
        ? _c(
            "div",
            { staticClass: "flex-none" },
            [_c("conversation-audio-record")],
            1
          )
        : _vm.showConversationStickersMenu
        ? _c("div", [_c("conversation-stickers-menu")], 1)
        : _vm.showConversationInput
        ? _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c("conversation-input", {
                attrs: {
                  "photo-enabled": "",
                  "audio-enabled": "",
                  disabled: _vm.disableConversationInput,
                  "min-length": _vm.minLength,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }