var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", [
    _c(
      "form",
      { on: { submit: _vm.postForm } },
      [
        _c("base-title", { attrs: { color: "whatsapp", center: "" } }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get("whatsapp.catcher.we_are_coming_to_whatsapp")
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("base-paragraph", { staticClass: "text-center" }, [
          _c("strong", [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.trans.get("whatsapp.catcher.join_our_whatsappfamily")
                ) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-full text-center mb-4" }, [
          _c("img", {
            staticClass: "inline-block max-h-48",
            attrs: {
              src: _vm.assetManager.getImageAsset(
                "whatsapp/whatsapp-catcher.gif"
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "base-checkbox",
          {
            ref: "checkbox_right_of_withdrawal",
            attrs: {
              color: "whatsapp",
              block: "",
              error: _vm.whatsapp_notifications_error,
            },
            on: {
              change: function ($event) {
                _vm.whatsapp_notifications_error = ""
              },
            },
            model: {
              value: _vm.whatsapp_notifications_value,
              callback: function ($$v) {
                _vm.whatsapp_notifications_value = $$v
              },
              expression: "whatsapp_notifications_value",
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "text-whatsapp",
              attrs: { icon: ["fas", "circle-chevron-right"] },
            }),
            _vm._v(" "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  this.trans.get("whatsapp.catcher.i_agree_to_receive_whatsapp")
                ),
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          {
            attrs: {
              type: "submit",
              color: "whatsapp",
              loading: _vm.formLoading,
              block: "",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans.get("whatsapp.catcher.lets_go")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }