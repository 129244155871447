<template>
    <div>
        <label :for="id" :class="labelClass">
            <input
                type="radio"
                ref="input_radio"
                v-model="checkedValue"
                :id="id"
                :value="value"
                :name="name"
                :disabled="disabled"
                :class="inputClass"/>
            <slot v-if="displayCheckedSlot" name="checked" />
            <slot v-else-if="displayDisabledSlot" name="disabled" />
            <slot v-else />
        </label>
    </div>

</template>

<style lang="scss">

.base-radio-input-label {
    @apply text-neutral-800 cursor-pointer;
}

.base-radio-input-input--hidden {
    @apply hidden;
}
</style>

<script>
export default {
    name: "BaseRadioInput",
    model: {
        prop: 'selectedValue',
        event: 'change'
    },
    props: {
        id: {
            type: String,
            default() {
                return `radio-${this._uid}`;
            }
        },
        disabled: Boolean,
        inputHidden: Boolean,
        name: String,
        selectedValue: String|Number,
        value: String|Number
    },
    computed: {
        displayCheckedSlot() {
            return this.checked && !!this.$slots.checked;
        },
        displayDisabledSlot() {
            return this.disabled && !!this.$slots.disabled;
        },
        checked() {
            return this.value === this.selectedValue;
        },
        checkedValue: {
            get () {
                return this.selectedValue;
            },

            set (value) {
                this.$emit('change', value);
            }
        },
        labelClass() {
            return {
                'base-radio-input-label': true,
                'base-radio-input-label--checked': this.checked
            }
        },
        inputClass() {
            return {
                'base-radio-input-input--hidden': this.inputHidden,
            }
        }
    },
    mounted() {
        this.$refs.input_radio.checked = this.checked;
    }
}
</script>
