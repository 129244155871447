var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Title")]),
        _vm._v(" "),
        _c("base-title", [_vm._v("Base title")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("level")]),
            _vm._v(": integer - "),
            _c("em", [_vm._v("1 (default) / 2 / 3 / 4 / 5 / 6")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [
              _vm._v(
                "primary (default) / secondary / available / busy / neutral /\n                light / white / danger / prime / whatsapp"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("center")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Level")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("level")]),
          _vm._v(" : 1 (default) / 2 / 3 / 4 / 5 / 6"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-title", { attrs: { level: 1 } }, [
              _vm._v("Title level 1"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { level: 2 } }, [
              _vm._v("Title level 2"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { level: 3 } }, [
              _vm._v("Title level 3"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { level: 4 } }, [
              _vm._v("Title level 4"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { level: 5 } }, [
              _vm._v("Title level 5"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { level: 6 } }, [
              _vm._v("Title level 6"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Colors")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("color")]),
          _vm._v(
            " : primary (default) / secondary / available / busy / neutral /\n            light / white / danger / prime / whatsapp\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-title", { attrs: { color: "primary" } }, [
              _vm._v("Title primary"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "secondary" } }, [
              _vm._v("Title secondary"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "available" } }, [
              _vm._v("Title available"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "busy" } }, [
              _vm._v("Title busy"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "neutral" } }, [
              _vm._v("Title neutral"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "light" } }, [
              _vm._v("Title light"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "white" } }, [
              _vm._v("Title white"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "danger" } }, [
              _vm._v("Title danger"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "prime" } }, [
              _vm._v("Title prime"),
            ]),
            _vm._v(" "),
            _c("base-title", { attrs: { color: "whatsapp" } }, [
              _vm._v("Title whatsapp"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Center")]),
        _vm._v(" "),
        _c("base-title", { attrs: { center: "" } }, [_vm._v("Title centered")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }