<template>
    <development>
        <div>
            <development-heading>Button</development-heading>
            <base-button>button</base-button>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>type</strong>: String - <em>'button', 'submit'</em></li>
                <li><strong>color</strong>: String - <em>'primary', 'secondary', 'available', 'busy', 'offline',
                    'neutral', 'neutral-secondary', 'light', 'dark'</em></li>
                <li><strong>block</strong>: Boolean</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>fab</strong>: Boolean</li>
                <li><strong>Pill</strong>: Boolean</li>
                <li><strong>icon</strong>: Boolean</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>loading</strong>: Boolean</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>small</strong>: Boolean</li>
            </ul>

            <development-heading>Colors</development-heading>
            <development-paragraph><strong>color</strong> : primary <small>(default)</small> / secondary / busy /
                neutral / light / dark
            </development-paragraph>
            <div class="space-y-2 mb-4">
                <base-button color="primary">primary</base-button>
                <base-button color="secondary">secondary</base-button>
                <base-button color="available">available</base-button>
                <base-button color="busy">busy</base-button>
                <base-button color="neutral">neutral</base-button>
                <base-button color="neutral-secondary">neutral-secondary</base-button>
                <base-button color="light">light</base-button>
                <base-button color="white">white</base-button>
                <base-button color="danger">danger</base-button>
                <base-button color="prime">prime</base-button>
                <base-button color="facebook">facebook</base-button>
                <base-button color="whatsapp">whatsapp</base-button>
                <base-button color="dark">dark</base-button>
            </div>
            <development-paragraph><small><strong class="text-danger">Attention :</strong> <strong>neutral</strong> is
                for the default/system button and <strong>offline</strong> is for a status (ex: master offline). Same
                color, different uses.</small></development-paragraph>

            <development-heading>Outlined</development-heading>
            <development-paragraph><strong>outlined</strong> buttons inherit their borders from the current color
                applied.
            </development-paragraph>
            <div class="space-y-2">
                <base-button outlined color="primary">primary</base-button>
                <base-button outlined color="secondary">secondary</base-button>
                <base-button outlined color="available">available</base-button>
                <base-button outlined color="busy">busy</base-button>
                <base-button outlined color="neutral">neutral</base-button>
                <base-button outlined color="neutral-secondary">neutral-secondary</base-button>
                <base-button outlined color="light">light</base-button>
                <base-button outlined color="danger">danger</base-button>
                <base-button outlined color="prime">prime</base-button>
                <base-button outlined color="facebook">facebook</base-button>
                <base-button outlined color="whatsapp">whatsapp</base-button>
                <base-button outlined color="dark">dark</base-button>
            </div>
            <div class="space-y-2 bg-primary p-4 mt-4">
                <base-button outlined color="white">white</base-button>
                <base-button outlined loading color="white">white</base-button>
            </div>

            <development-heading>Link</development-heading>
            <div class="space-y-2">
                <base-button link color="primary">primary</base-button>
                <base-button link font-normal color="secondary">secondary (font-normal)</base-button>
                <base-button link normal-case color="available">Available (Normal-case)</base-button>
                <base-button link lowercase color="busy">BUSY (LOWERCASE)</base-button>
                <base-button link color="offline">offline</base-button>
                <base-button link color="neutral">neutral</base-button>
                <base-button link color="light">light</base-button>
            </div>
            <div class="space-y-2 bg-primary p-4 mt-4">
                <base-button link color="white"><font-awesome-icon :icon="['fal', 'magnifying-glass']" size="lg"/> white with icon</base-button>
            </div>

            <development-heading>Block</development-heading>
            <development-paragraph><strong>block</strong> buttons extend the full available width.
            </development-paragraph>
            <base-button block>block button</base-button>


            <development-heading>Disabled</development-heading>
            <base-button disabled>button</base-button>
            <base-button fab disabled>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button icon disabled>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>

            <development-heading>Loading</development-heading>
            <development-paragraph><strong>loading</strong> state</development-paragraph>
            <base-button loading>button</base-button>
            <base-button outlined loading>button</base-button>

            <development-heading>Skeleton-loader</development-heading>
            <development-paragraph><strong>Skeleton-loader</strong> state</development-paragraph>
            <base-button skeleton-loader>button</base-button>

            <development-heading>Floating</development-heading>
            <development-paragraph>Floating buttons are rounded and usually contain an icon.</development-paragraph>
            <base-button fab>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button fab color="secondary">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button fab outlined>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button fab disabled>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button fab loading>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button fab outlined loading>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>


            <development-heading>Pill</development-heading>
            <development-paragraph>Pill buttons are full border-radius keeping their size</development-paragraph>
            <base-button small pill>
                Small Pill Button
            </base-button>
            <base-button pill>
                medium Pill Button
            </base-button>
            <base-button large pill>
                large Pill Button
            </base-button>
            <base-button small pill disabled>
                small Pill Button Disabled
            </base-button>


            <development-heading>Icon</development-heading>
            <base-button icon>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button icon color="secondary">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button icon color="neutral">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button icon color="light">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>


            <development-heading>Text</development-heading>
            <div class="space-y-2">
                <base-button text>J'ai compris !</base-button>
                <p class="text-secondary">It's a button inside a paragraph
                    <base-button text>J'ai compris !</base-button>
                </p>
            </div>

            <development-heading>Small</development-heading>
            <base-button small>small button</base-button>
            <base-button small outlined color="primary">primary</base-button>
            <base-button small fab>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button small disabled>button</base-button>


            <development-heading>Large</development-heading>
            <base-button large>large button</base-button>
            <base-button large outlined color="primary">primary</base-button>
            <base-button large fab>
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
            <base-button large disabled>button</base-button>
        </div>
    </development>
</template>
<script>
import BaseButton from "../elements/BaseButton";
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";

export default {
    components: {DevelopmentParagraph, DevelopmentHeading, Development, BaseButton}
}
</script>
