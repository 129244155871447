<template>
    <div class="py-4">
        <div class="base-separator">
            <div class="base-separator-content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.base-separator {
    @apply relative border-b border-neutral-400;
}

.base-separator-content {
    @apply absolute top-full left-2/4 bg-white transform -translate-y-1/2 -translate-x-1/2 px-4 text-neutral-500;
}
</style>

<script>
export default {
    name: "BaseSeparator",
}
</script>
