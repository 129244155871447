<template>
    <div :class="itemClass" @click="itemClicked">
        <slot/>
    </div>
</template>

<style lang="scss">
.base-menu-item {
    &:not(.base-menu-item--separator) {
        @apply p-3 cursor-pointer text-left;
        -webkit-tap-highlight-color: transparent;
    }

    &:not(.base-menu-item--separator):not(.base-menu-item--background-color-dark) {
        @apply hover:bg-neutral-100;
    }

    &--dense:not(.base-menu-item--separator) {
        @apply p-0;
    }

    &--color-info {
        @apply text-primary;
    }

    &--color-success {
        @apply text-success;
    }

    &--color-warning {
        @apply text-warning;
    }

    &--color-danger {
        @apply text-danger;
    }

    &--color-white {
        @apply text-white;
    }

    &--background-color-dark {
        @apply bg-neutral-500;
    }

    &--separator {
        @apply border-b border-neutral-400;
    }
}
</style>

<script>
export default {
    name: "BaseMenuItem",
    props: {
        color: {
            type: String,
            validator: function (value) {
                return ['info', 'success', 'warning', 'danger', 'white'].indexOf(value) !== -1
            }
        },
        backgroundColor: {
            type: String,
            validator: function (value) {
                return ['dark'].indexOf(value) !== -1
            }
        },
        dense: Boolean,
        selected: Boolean,
        separator: Boolean,
    },
    computed: {
        itemClass() {
            return {
                'base-menu-item': true,
                'base-menu-item--separator': this.separator,
                'base-menu-item--dense': this.dense,
                'base-menu-item--selected': this.selected,
                'base-menu-item--color-info': this.color === 'info',
                'base-menu-item--color-success': this.color === 'success',
                'base-menu-item--color-warning': this.color === 'warning',
                'base-menu-item--color-danger': this.color === 'danger',
                'base-menu-item--color-white': this.color === 'white',
                'base-menu-item--background-color-dark': this.backgroundColor === 'dark',
            }
        }
    },
    methods: {
        itemClicked() {
            if (!this.separator) {
                this.$emit('click');
            }
        }
    }
}
</script>
