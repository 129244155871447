<template>
    <base-modal>
        <modal-heading>{{ trans.get('generic.well_done') }}</modal-heading>

        <modal-paragraph
            v-html="this.trans.get('generic.promo_code.you_benefit_from_several_minutes_of_free_clairvoyance')">
        </modal-paragraph>

        <div class="px-4">
            <modal-heading :level="2">{{ trans.get('generic.promo_code.i_choose_my_master') }}</modal-heading>
            <div class="w-full grid grid-cols-1 place-items-center mb-2">
                <img class="max-h-20"
                     :src="assetManager.getImageAsset('promo-code/masters.png')"
                     :alt="trans.get('generic.site_name')"/>
            </div>

            <modal-heading :level="2">{{ trans.get('generic.promo_code.i_click_on') }}</modal-heading>
            <div class="w-full grid grid-cols-1 place-items-center my-4">
                <img class="max-h-8"
                     :src="assetManager.getImageAsset('promo-code/call_and_chat_buttons.png')"
                     :alt="trans.get('generic.site_name')"/>
            </div>

            <modal-heading :level="2">{{ trans.get('generic.promo_code.im_online_with_my_master') }}</modal-heading>
            <div class="w-full grid grid-cols-1 place-items-center mb-2">
                <img class="max-h-16"
                     :src="assetManager.getImageAsset('promo-code/master_in_consultation.png')"
                     :alt="trans.get('generic.site_name')"/>
            </div>

        </div>

        <div class="grid grid-cols-1 mt-8">
            <a :href="config.get('app.promo_code_redirect_button_url')">
                <base-button block>{{ trans.get('generic.i_take_advantage_of_it') }}</base-button>
            </a>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import BaseButton from '../elements/BaseButton';
import ModalParagraph from './elements/ModalParagraph';
import BaseLink from '../elements/BaseLink';

export default {
    components: {BaseLink, ModalParagraph, BaseButton, ModalHeading},
};
</script>
