var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.level === 1
    ? _c(
        "h1",
        { staticClass: "text-primary text-xl font-bold mb-4 leading-6" },
        [_vm._t("default")],
        2
      )
    : _vm.level === 2
    ? _c(
        "h2",
        { staticClass: "text-primary text-lg font-bold mb-2" },
        [_vm._t("default")],
        2
      )
    : _vm.level === 3
    ? _c("h3", [_vm._t("default")], 2)
    : _vm.level === 4
    ? _c("h4", [_vm._t("default")], 2)
    : _vm.level === 5
    ? _c("h5", [_vm._t("default")], 2)
    : _vm.level === 6
    ? _c("h6", [_vm._t("default")], 2)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }