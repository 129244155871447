var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isImage && _vm.repeat
    ? _c("div", { class: _vm.backgroundClass, style: _vm.backgroundStyle })
    : _vm.isImage
    ? _c("img", {
        class: _vm.backgroundClass,
        style: _vm.backgroundStyle,
        attrs: { src: _vm.imageSrc },
      })
    : _vm.isVideo
    ? _c(
        "video",
        {
          class: _vm.backgroundClass,
          style: _vm.backgroundStyle,
          attrs: { autoplay: "", muted: "", loop: "" },
          domProps: { muted: true },
        },
        [
          _vm.hasWebm
            ? _c("source", { attrs: { src: _vm.webmSrc, type: "video/webm" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasMp4
            ? _c("source", { attrs: { src: _vm.mp4Src, type: "video/mp4" } })
            : _vm._e(),
        ]
      )
    : _vm.isColor
    ? _c("div", { class: _vm.backgroundClass, style: _vm.backgroundStyle })
    : _vm.isSnowfall
    ? _c("game-wheel-background-snowfall", {
        class: _vm.backgroundClass,
        style: _vm.backgroundStyle,
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }