<template>
    <base-modal small :error="loadingError">
        <modal-heading>
            {{ trans.get('generic.modals.notif_master_online_title', {'mastername': master.name}) }}
        </modal-heading>

        <div>
            <div v-if="this.canModifyPhoneNumber">
                <base-list class="ml-3 mb-4">
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.modals.notif_master_online_text') }} :</template>
                    </base-list-item>
                </base-list>

                <p>
                    <base-phone-input outlined
                                      autofocus
                                      ref="input_phone_number"
                                      v-model="userFullMobilePhoneNumber"
                                      :only-countries="config.get('sms.phone_number_available_countries')"
                                      :initial-country="config.get('sms.default_phone_number_country_code')"
                                      :label="this.trans.get('generic.phone')"
                                      :placeholder="this.trans.get('generic.phone')"/>
                </p>

            </div>
            <div v-else>
                <base-list class="ml-3 mb-4">
                    <base-list-item>
                        <template #icon>
                            <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                        </template>
                        <template #title>{{ trans.get('generic.modals.notif_master_online_text_without_phone') }} :
                        </template>
                    </base-list-item>
                </base-list>
                <p class="pl-6">
                    {{ userFullMobilePhoneNumber | formatPhoneNumber('national') }}
                    <span class="underline cursor-pointer" @click="displayInputPhoneNumber">
                    {{ trans.get('generic.modals.notif_master_online_change_phone_number') }}
                </span>
                </p>
            </div>
        </div>

        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button @click="sendNotifMasterOnline">
                {{ trans.get('generic.validate') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseButton from "../elements/BaseButton";
import ModalHeading from "./elements/ModalHeading";
import BaseTextInput from "../elements/BaseTextInput";
import requests from "../chat/requests";
import PhoneNumber from 'awesome-phonenumber';
import BasePhoneInput from "../elements/BasePhoneInput";
import BaseList from "../elements/BaseList";
import BaseListItem from "../elements/BaseListItem";

export default {
    name: "ModalChatCustomerNotifMasterOnline",
    components: {BaseListItem, BaseList, BasePhoneInput, ModalHeading, BaseTextInput, BaseButton},
    computed: {
        ...mapGetters('modals', ['getParam']),
        hasPhoneNumber() {
            return this.userFullMobilePhoneNumber !== null && this.userFullMobilePhoneNumber !== '';
        },
        canModifyPhoneNumber() {
            return this.hasPhoneNumber || this.isModifyingPhoneNumber;
        }
    },
    methods: {
        ...mapActions('modals', ['openModalMessage', 'closeModal']),
        displayInputPhoneNumber() {
            this.isModifyingPhoneNumber = true;
        },
        sendNotifMasterOnline() {
            let data = {offer_id: this.master.id};
            if (this.isModifyingPhoneNumber) {
                data.full_number = this.userFullMobilePhoneNumber;
                data.region_code = this.$refs.input_phone_number.getActiveCountry().iso2;
            }

            requests.sendNotifMasterOnline(
                data,
                () => {
                    this.openModalMessage({
                        small: true,
                        title: this.trans.get('generic.modals.notif_master_online_title', {'mastername': this.master.name}),
                        description: this.trans.get('chat.modals.notif_master_online_confirmation_message'),
                        buttonText: this.trans.get('chat.modals.continue_on_kang')
                    });
                },
                (errorResponse) => {
                    if (errorResponse.response !== undefined) {
                        this.$refs.input_phone_number.error = errorResponse.response.data.errors;
                    } else {
                        this.$refs.input_phone_number.error = errorResponse.toString();
                    }
                }
            );
        }
    },
    data() {
        return {
            loadingError: '',
            master: {name: ''},
            userFullMobilePhoneNumber: '',
            isModifyingPhoneNumber: false
        };
    },
    mounted() {
        this.master = this.getParam['chat-customer-notif-master-online']['master'];
        if (this.getParam['chat-customer-notif-master-online']['userFullMobilePhoneNumber']) {
            this.userFullMobilePhoneNumber = '+' + this.getParam['chat-customer-notif-master-online']['userFullMobilePhoneNumber'];
        }
    },
}
</script>
