var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-screen h-screen overflow-x-hidden" }, [
    _c(
      "div",
      {
        staticClass:
          "max-w-screen-sm lg:max-w-screen-md mx-auto text-center px-4",
      },
      [
        _c("img", {
          staticClass: "mx-auto w-72 md:w-96 select-none",
          attrs: {
            src: this.assetManager.getImageAsset(
              "game/kang-summer-wheel-2024/game/wheel/title.png"
            ),
          },
        }),
        _vm._v(" "),
        _c("game-wheel", {
          ref: "wheel",
          attrs: {
            "wheel-image": "game/kang-summer-wheel-2024/game/wheel/wheel.png",
            "button-play-image":
              "game/kang-summer-wheel-2024/game/wheel/button_play.gif",
            "button-played-image":
              "game/kang-summer-wheel-2024/game/wheel/button_played.png",
            "prize-image": _vm.prizeImage,
            "prize-action": _vm.prizeAction,
            "after-show-prize-action": _vm.afterShowPrizeAction,
          },
          on: { play: _vm.run },
          scopedSlots: _vm._u([
            {
              key: "backgrounds",
              fn: function () {
                return [
                  _c("game-wheel-background", {
                    attrs: { color: "#A5DCE4", fullscreen: "" },
                  }),
                  _vm._v(" "),
                  _c("game-wheel-background", {
                    attrs: {
                      image:
                        "game/kang-summer-wheel-2024/game/wheel/background_1.png",
                      spin: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("game-wheel-background", {
                    attrs: {
                      image:
                        "game/kang-summer-wheel-2024/game/wheel/background_2.png",
                      scale: 200,
                    },
                  }),
                  _vm._v(" "),
                  _c("game-wheel-background", {
                    attrs: {
                      image:
                        "game/kang-summer-wheel-2024/game/wheel/background_3.png",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("base-link", { attrs: { href: "/" } }, [
              _c("img", {
                staticClass: "h-12 md:h-20 mx-auto select-none",
                attrs: {
                  src: this.assetManager.getImageAsset(
                    "game/kang-summer-wheel-2024/game/wheel/button_back.png"
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "base-link",
              {
                attrs: {
                  href: this.assetManager.getDocumentAsset(
                    "game/reglement-kang-summer-wheel-2024.pdf"
                  ),
                  color: "dark",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("game.consult_regulation")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }