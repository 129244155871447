var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-2 px-0.5" }, [
    _c(
      "div",
      {
        ref: "conversation-sticker",
        class: _vm.conversationStickerClass,
        attrs: { tabindex: "0" },
      },
      [
        _c("img", {
          staticClass: "conversation-sticker-image",
          attrs: { src: _vm.sticker.thumbnail, alt: _vm.sticker.title },
        }),
        _vm._v(" "),
        _vm.sticker.price > 0
          ? _c("div", { staticClass: "conversation-sticker-price" }, [
              _vm._v(
                _vm._s(_vm._f("formatMoney")(_vm.sticker.price / 100)) +
                  "\n        "
              ),
            ])
          : _c("div", { staticClass: "conversation-sticker-price" }, [
              _vm._v(" "),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "conversation-sticker-title" }, [
          _vm._v(_vm._s(_vm.sticker.title)),
        ]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "conversation-sticker-button",
            attrs: { disabled: _vm.disabled, loading: _vm.loading, small: "" },
            on: { click: _vm.sendSticker },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans.get("generic.send")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }