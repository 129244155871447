var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "swiper-slide pb-4 text-main text-xs" }, [
    _c(
      "a",
      { attrs: { href: _vm.comment.offer.url } },
      [
        _vm.hasRating
          ? _c("base-rating", { attrs: { value: _vm.rating, disabled: "" } })
          : _vm._e(),
        _vm._v(" "),
        _c("span", {
          staticClass: "font-bold",
          domProps: {
            innerHTML: _vm._s(
              _vm.trans.get("generic.customer_has_consulted_the_master", {
                date: _vm.comment.created_at,
                user: _vm.comment.user.name,
                master: _vm.masterLink,
              })
            ),
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "text-xs" }, [
          _vm._v(_vm._s(_vm.comment.comment)),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }