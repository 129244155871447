var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "flex" }, [
      _c("div", {
        class: _vm.inputClass,
        attrs: {
          "data-sitekey": _vm.siteKey,
          "data-callback": "baseRecaptchaCallback",
          error: _vm.captchaError,
        },
        on: {
          validate: function ($event) {
            return this.$emit("recaptchavalidate", $event)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", {
          class: _vm.messageClass,
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }