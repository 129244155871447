var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reset-this h-full" },
    [
      _c(
        "div",
        { class: _vm.chatClass },
        [
          _vm.displayConversationList
            ? _c("conversation-list", { staticClass: "chat-conversation-list" })
            : _vm.displayConversationSearch
            ? _c("conversation-search", {
                staticClass: "chat-conversation-search",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("conversation", { staticClass: "chat-conversation" }),
          _vm._v(" "),
          _vm.isConversationInformationsOpen
            ? _c("conversation-informations", {
                staticClass: "chat-conversation-informations",
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.openMasterConfirmationToStartLiveChatModal
        ? _c("modal-chat-master-confirmation-to-start-live-chat")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.CHAT_CUSTOMER_CONFIRMATION_END_CHAT_SESSION)
        ? _c("modal-chat-customer-confirmation-end-chat-session")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.CHAT_REPORT_PROBLEM)
        ? _c("modal-chat-report-problem")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.FOLLOW_UP_TYPE_CHOICE)
        ? _c("modal-follow-up-type-choice")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.FOLLOW_UP_TO_ONE)
        ? _c("modal-follow-up-to-one")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.CHAT_MASTER_CONNECTED)
        ? _c("modal-chat-master-connected")
        : _vm._e(),
      _vm._v(" "),
      _vm.isShown(_vm.globals.CHAT_RECHARGE_LIVE)
        ? _c("modal-chat-recharge-live")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }