var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "offer-card",
      class: {
        "offer-card": true,
        "offer-card-highlight": _vm.offerCardHighlight,
      },
    },
    [
      _c("div", { staticClass: "flex w-full" }, [
        _c("div", { staticClass: "offer-card-col-left" }, [
          _c(
            "a",
            { attrs: { href: _vm.offer.url } },
            [
              _c(
                "base-badge",
                {
                  attrs: {
                    content: _vm.number,
                    distance: -0.75,
                    outlined: "",
                    left: "",
                  },
                },
                [
                  _vm.hasSegment
                    ? _c(
                        "base-badge",
                        {
                          attrs: {
                            content: _vm.segmentContent,
                            color: _vm.segmentColor,
                            outlined: "",
                            bottom: "",
                            center: "",
                            square: "",
                          },
                        },
                        [
                          _c("base-avatar", {
                            attrs: {
                              photo: _vm.offer.photo,
                              color: "neutral-secondary",
                              "x-large": "",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("base-avatar", {
                        attrs: {
                          photo: _vm.offer.photo,
                          color: "neutral-secondary",
                          "x-large": "",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "offer-card-col-right" }, [
          _c("a", { attrs: { href: _vm.offer.url } }, [
            _c("div", { staticClass: "offer-card-col-right-header" }, [
              _c("p", { staticClass: "offer-card-name" }, [
                _vm._v(_vm._s(_vm.offer.name)),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "offer-card-consultations-count" },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.offer.consultations_count) +
                      " "
                  ),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "circle-phone-flip"] },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "offer-card-col-right-content" }, [
              _c("p", { staticClass: "offer-card-description" }, [
                _vm._v(_vm._s(_vm.offer.short_description)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "offer-card-col-right-footer" }, [
              _c("p", { staticClass: "offer-card-rating" }, [
                _vm.offer.display_rating
                  ? _c(
                      "span",
                      [
                        _vm._v(_vm._s(_vm.offer.average_mark / 10)),
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "star"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "offer-card-price" }, [
                this.offer.cost_by_minute_without_advantage !==
                this.offer.cost_by_minute
                  ? _c("span", { staticClass: "previous-price" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatMoneyPerMinute")(
                            _vm.offer.cost_by_minute_without_advantage / 100
                          )
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "text-primary font-bold whitespace-nowrap" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatMoneyPerMinute")(
                          _vm.offer.cost_by_minute / 100
                        )
                      )
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("offer-card-actions", {
        attrs: {
          offer: _vm.offer,
          user: _vm.user,
          offer_list_type: _vm.offer_list_type,
          is_prime_enabled: _vm.is_prime_enabled,
          is_free_chat_enabled_for_prospect:
            _vm.is_free_chat_enabled_for_prospect,
          user_credits: _vm.user_credits,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }