import Vue from 'vue';
import Vuex from 'vuex';
import {ziggy_route} from './components/ZiggyMixin';
import Config from './config';
import CompileHtml from './compile-html';
import AssetManager from './asset-manager';
import UrlManager from './url-manager';
import VueGtm from '@gtm-support/vue2-gtm';


const gtmId = process.env.MIX_GTM_ID;
let app;

export {app};

// When login via homepage, a URL with no_cache_frz is called with the ajax_iframe function,
// which causes the creation of another Vue instance (since this file is imported again)
// mounted on the #app DOM element, which causes the Vue components on the page to not react anymore.
// This condition is here to make sure this does not happen...
if (window.location.href.indexOf('no_cache_frz') === -1) {
    require('./font-awesome');
    require('./bootstrap');

    window.Vue = Vue;
    Vue.use(Vuex);
    Vue.use(Config);
    Vue.use(CompileHtml);
    Vue.use(AssetManager);
    Vue.use(UrlManager);

    if (gtmId) {
        Vue.use(VueGtm, {
            id: gtmId,
            defer: false,
            enabled: true,
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        });
    }

    Vue.mixin({
        methods: {
            ziggy_route: ziggy_route,
        },
    });

    require('./lang');
    require('./config');
    require('./compile-html');
    require('./format');
    require('./directives');
    require('./errors');

    require('./components/modals/global.modal');
    require('./components/modals/imports');
    require('./components/chat/imports');
    require('./components/development/imports');
    require('./components/game/imports');
    require('./components/header/imports');
    require('./components/imports');
    require('./components/sponsorship/imports');
    require('./components/contact/imports');

    Vue.config.devtools = true;

    const store = new Vuex.Store({
        modules: {
            modals: require('./components/modals/store').default,
            chat: require('./components/chat/store').default,
            user: require('./components/user/store').default,
            payment: require('./components/payment/store').default,
            offer: require('./components/offer/store').default,
            login: require('./components/login/store').default,
            game: require('./components/game/store').default,
            media_devices: require('./components/media-devices/store').default,
            coupons: require('./components/coupons/store').default,
            digital: require('./components/digital/store').default,
            special_operation: require('./components/special-operation/store').default,
        },
    });

    app = new Vue({
        el: '#app',
        store: store,
        data() {
            return {
                audioAutoplayUnlocked: false,
                chatAskedSound: null,
            };
        },
        created() {
            this.$store.dispatch('login/connect', {
                user_infos: window.user_infos,
                chat_live_session: window.chat_live_session,
                chat_selected_conversation: window.chat_selected_conversation,
                chat_follow_up_mode: window.chat_follow_up_mode,
            });
            this.$store.dispatch('game/fetchGameInfos');
            this.$store.dispatch('game/flashUnauthorizeSession');

            this.$store.dispatch('special_operation/fetchActiveSpecialOperation');

            this.$store.dispatch('coupons/fetchCoupon');

            this.chatAskedSound = new Audio(this.assetManager.getAudioAsset('chat/demande_chat.wav'));
            this.chatAskedSound.loop = true;

            this.$store.dispatch('modals/forceDisplayPopup', window.display_popup);
        },
    });

    window.globalVue = app;
} else {
    // ...but since the user logged in, we have to update the parent window store with the infos loaded in this base.html
    // and listen to the events on the right channel (since the user id is now set)
    if (parent.window.globalVue) {
        parent.window.globalVue.$store.dispatch('login/connect', {
            user_infos: window.user_infos,
            chat_live_session: window.chat_live_session,
            chat_selected_conversation: window.chat_selected_conversation,
            chat_follow_up_mode: window.chat_follow_up_mode,
        });
    }

    app = parent.window.globalVue;
}

if (typeof jQuery !== 'undefined') {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': window.csrf_token,
        },
    });
}
