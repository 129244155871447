var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Webm player")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "conversation-messages-list-container conversation-messages-list-container--background h-100",
          },
          [
            _c("base-video-player", {
              ref: "player",
              staticClass: "h-full",
              attrs: {
                "src-webm": _vm.assetManager.getVideoAsset(
                  "example/" + _vm.filename + ".webm"
                ),
                "src-mov": _vm.assetManager.getVideoAsset(
                  "example/" + _vm.filename + ".mov"
                ),
              },
              on: {
                play: function ($event) {
                  return _vm.addLog("play")
                },
                pause: function ($event) {
                  return _vm.addLog("pause")
                },
                end: function ($event) {
                  return _vm.addLog("end")
                },
                loadstart: function ($event) {
                  return _vm.addLog("loadstart")
                },
                stop: function ($event) {
                  return _vm.addLog("stop")
                },
                canplaythrough: function ($event) {
                  return _vm.addLog("canplaythrough")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex justify-between gap-2 mt-2" }, [
          _c(
            "div",
            { staticClass: "flex-1 flex flex-col gap-2" },
            [
              _c("base-button", { on: { click: _vm.play } }, [_vm._v("Play")]),
              _vm._v(" "),
              _c("base-button", { on: { click: _vm.pause } }, [
                _vm._v("Pause"),
              ]),
              _vm._v(" "),
              _c("base-button", { on: { click: _vm.stop } }, [_vm._v("Stop")]),
              _vm._v(" "),
              _c("base-select", {
                attrs: { outlined: "", label: "Video", items: _vm.videos },
                on: { input: _vm.changeVideo },
                model: {
                  value: _vm.filename,
                  callback: function ($$v) {
                    _vm.filename = $$v
                  },
                  expression: "filename",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-1" },
            [
              _c("base-textarea", {
                staticClass: "w-full",
                attrs: {
                  label: "Logs",
                  placeholder: "Logs",
                  rows: 8,
                  disabled: "",
                  outlined: "",
                },
                model: {
                  value: _vm.logs,
                  callback: function ($$v) {
                    _vm.logs = $$v
                  },
                  expression: "logs",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("src")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("loop")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autoplay")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("Events :"),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("canplaythrough")]),
            _vm._v(
              ": The browser estimates it can play the media up to its end without\n                stopping for content buffering.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("play")]),
            _vm._v(": Playback has begun."),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("pause")]),
            _vm._v(": Playback has been paused."),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("end")]),
            _vm._v(
              ": Playback has stopped because the end of the media was reached."
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("Loop & autoplay"),
        ]),
        _vm._v(" "),
        _c("base-video-player", {
          attrs: {
            "src-webm": _vm.assetManager.getVideoAsset(
              "example/video-example.webm"
            ),
            "src-mov": _vm.assetManager.getVideoAsset(
              "example/video-example.mov"
            ),
            loop: "",
            autoplay: "",
          },
        }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("Animation player example"),
        ]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("The player will play the video once and self-destruct."),
        ]),
        _vm._v(" "),
        _c("base-video-player", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAnimationPlayer,
              expression: "showAnimationPlayer",
            },
          ],
          ref: "animation-player",
          attrs: {
            "src-webm": _vm.assetManager.getVideoAsset(
              "example/video-example.webm"
            ),
            "src-mov": _vm.assetManager.getVideoAsset(
              "example/video-example.mov"
            ),
          },
          on: {
            canplaythrough: _vm.animationPlayerCanplaythrough,
            end: _vm.animationPlayerEnd,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }