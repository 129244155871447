import {ziggy_route} from '../ZiggyMixin';

export default {
    getPaymentStatusByReference(data, successCallback, errorCallback, alwaysCallback) {
        return axios.post(ziggy_route('payment.getPaymentStatusByReference'), data)
            .then(successCallback)
            .catch(errorCallback)
            .then(alwaysCallback);
    },
};
