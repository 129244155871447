<template>
    <development>
        <div>
            <development-heading>Rating</development-heading>
            <development-paragraph><strong>Rating</strong> - Also check it on mobile !</development-paragraph>
            <div class="text-center md:text-left">
                <base-rating/>
            </div>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>value</strong>: Number</li>
                <li><strong>minValue</strong>: Number</li>
                <li><strong>maxValue</strong>: Number</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>size</strong>: String - <em>'xs', 'sm', 'lg'</em></li>
            </ul>

            <development-heading>Value</development-heading>
            <development-paragraph>Default <strong>value</strong> - ex: value="3"</development-paragraph>
            <div class="text-center md:text-left">
                <development-paragraph><base-rating :value="3"/></development-paragraph>
            </div>

            <development-heading>Using v-model</development-heading>
            <div class="text-center md:text-left">
                <development-paragraph><base-rating v-model="value_1"/> {{ value_1 }} / 5</development-paragraph>
            </div>

            <development-heading>Size</development-heading>
            <development-paragraph><strong>Size value</strong>: String - <em>'xs', 'sm', 'lg'</em></development-paragraph>
            <div class="text-center md:text-left">
                <div class="text-center md:text-left"><base-rating size="xs"/></div>
                <div class="text-center md:text-left"><base-rating size="sm"/></div>
                <div class="text-center md:text-left"><base-rating/></div>
                <div class="text-center md:text-left"><base-rating size="lg"/></div>
            </div>

            <development-heading>Disabled</development-heading>
            <development-paragraph>Ex: disabled & value="3"</development-paragraph>
            <div class="text-center md:text-left">
                <base-rating :value="3" disabled/>
            </div>

            <development-heading>Min Value</development-heading>
            <development-paragraph><strong>Minimum value</strong> - ex: min-value="2"</development-paragraph>
            <div class="text-center md:text-left">
                <development-paragraph><base-rating v-model="value_2" :min-value="2"/> {{ value_2 }} / 5</development-paragraph>
            </div>

            <development-heading>Max Value</development-heading>
            <development-paragraph><strong>Maximum value</strong> - ex: max-value="21"</development-paragraph>
            <div class="text-center md:text-left">
                <development-paragraph><base-rating v-model="value_3" :max-value="21"/> {{ value_3 }} / 21</development-paragraph>
            </div>
        </div>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseRating from "../elements/BaseRating";

export default {
    components: {BaseRating, DevelopmentParagraph, DevelopmentHeading, Development},
    data() {
        return {
            value_1:3,
            value_2:2,
            value_3:12,
        }
    }
}
</script>
