var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "swiper",
      staticClass: "vertical-comment-list swiper",
      on: { mouseenter: _vm.disableAutoplay, mouseleave: _vm.enableAutoplay },
    },
    [
      _c(
        "div",
        { ref: "swiper-wrapper", staticClass: "swiper-wrapper" },
        _vm._l(_vm.comments, function (comment) {
          return _c("vertical-comment-card", {
            key: comment.id,
            attrs: { comment: comment },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }