<template>
    <div class="w-80 max-w-full">
        <base-audio-player ref="audio-player"
                           :src="message.content.url"
                           :initial-duration="message.content.duration"
                           @update="update"
                           @error="error"/>
        <div class="flex items-stretch">
            <div class="flex-none pr-2">
                <base-button icon @click="buttonClick">
                    <font-awesome-icon class="playerButton" :icon="buttonIcon" :spin="buttonIconSpin"/>
                </base-button>
            </div>
            <template v-if="isMessageNotSent(message)">
                <div class="flex-grow flex items-center">
                    {{ trans.get('generic.error_click_to_try_again') }}
                </div>
            </template>
            <template v-else-if="isMessageNotLoaded(message)">
                <div class="flex-grow flex items-center">
                    {{ trans.get('generic.error_click_to_try_again') }}
                </div>
            </template>
            <template v-else>
                <div class="flex-grow flex">
                    <input type="range" class="progressBar" min="0" :max="100" step="0.1" :value="playingProgression"
                           @input="setCurrentTime"/>
                </div>
                <div class="flex-none flex items-center ml-2 w-10 text-center">
                    <span v-if="displayCurrentTime">{{ currentTime | formatSecondsToTimer }}</span>
                    <span v-else>{{ duration | formatSecondsToTimer }}</span>
                </div>
            </template>
            <span class="conversation-message-content-audio--status">
            <conversation-message-metadata :status="messageStatus"/>
        </span>
        </div>
    </div>
</template>

<style lang="scss">

.progressBar {
    @apply w-full h-full self-center cursor-pointer inline-block;
    -webkit-appearance: none;
    margin: 0 -5px;
    background: none;

    &:focus {
        @apply outline-none;
    }
}

.conversation-message--left {
    .playerButton {
        @apply text-dark;
    }

    .progressBar {
        &::-webkit-slider-runnable-track {
            height: 2px;
            @apply cursor-pointer bg-neutral-800;
        }

        &::-moz-range-track {
            height: 2px;
            @apply cursor-pointer bg-neutral-800;
        }

        &::-ms-track {
            height: 2px;
            @apply cursor-pointer bg-neutral-800;
        }

        &::-webkit-slider-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
            -webkit-appearance: none;
            margin-top: 1px;
            transform: translateY(-50%);
        }

        &::-moz-range-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
            -webkit-appearance: none;
        }

        &::-ms-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-neutral-800;
            transform: translateY(0%);
        }
    }
}

.conversation-message--right {
    .playerButton {
        @apply text-white;
    }

    .progressBar {
        &::-webkit-slider-runnable-track {
            height: 2px;
            @apply cursor-pointer bg-white;
        }

        &::-moz-range-track {
            height: 2px;
            @apply cursor-pointer bg-white;
        }

        &::-ms-track {
            height: 2px;
            @apply cursor-pointer bg-white;
        }

        &::-webkit-slider-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-white;
            -webkit-appearance: none;
            margin-top: 1px;
            transform: translateY(-50%);
        }

        &::-moz-range-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-white;
            -webkit-appearance: none;
        }

        &::-ms-thumb {
            @apply cursor-pointer rounded-full h-4 w-4 z-3 relative bg-white;
            transform: translateY(0%);
        }
    }
}

.conversation-message-content-audio--status {
    @apply self-end flex-none inline-block pl-0.5 pr-2 pb-1;
}
</style>

<script>
import BaseAudioPlayer from "../elements/BaseAudioPlayer";
import BaseButton from "../elements/BaseButton";
import requests from "./requests";
import MessageMixin from "./MessageMixin";
import ConversationMessageMetadata from "./ConversationMessageMetadata";

export default {
    name: "ConversationMessageContentAudio",
    mixins: [MessageMixin],
    components: {ConversationMessageMetadata, BaseButton, BaseAudioPlayer},
    props: {
        message: {
            type: Object
        },
        messageStatus: String,
    },
    computed: {
        hasUploadError() {
            return this.isMessageNotSent(this.message) && this.status !== 'loading';
        },
        hasLoadingError() {
            return this.isMessageNotLoaded(this.message);
        },
        hasError() {
            return this.hasLoadingError || this.hasUploadError;
        },
        isLoading() {
            return this.status === 'loading';
        },
        isPlaying() {
            return this.status === 'playing';
        },
        displayCurrentTime() {
            return this.status === 'playing' || this.status === 'changingTime';
        },
        buttonIcon() {
            if (this.hasUploadError) {
                return ['fas', 'rotate-right'];
            } else if (this.hasLoadingError) {
                return ['fas', 'rotate-right'];
            } else if (this.isLoading) {
                return ['fas', 'spinner'];
            } else if (this.isPlaying) {
                return ['fas', 'pause'];
            } else {
                return ['fas', 'play'];
            }
        },
        buttonIconSpin() {
            return this.isLoading;
        },
    },
    methods: {
        buttonClick() {
            if (this.hasUploadError) {
                this.upload();
            } else if (this.hasLoadingError) {
                this.reload();
            } else if (this.isLoading) {

            } else if (this.isPlaying) {
                this.pause();
            } else {
                this.play();
            }
        },
        play() {
            this.$refs["audio-player"].play();
        },
        pause() {
            this.$refs["audio-player"].pause();
        },
        reload() {
            this.removeErrorFromMessage(this.message);
            this.$refs["audio-player"].init();
            this.$refs["audio-player"].play();
        },
        upload() {
            this.status = 'loading';
            requests.sendMessage(this.message, this.removeErrorFromMessage)
                .then(() => this.status = '');
        },
        setCurrentTime(element) {
            this.$refs["audio-player"].setPlayingProgression(element.target.value);
        },
        update() {
            this.playingProgression = this.$refs["audio-player"].playingProgression;
            this.currentTime = this.$refs["audio-player"].currentTime;
            this.duration = this.$refs["audio-player"].duration;
            this.status = this.$refs["audio-player"].status;
        },
        error() {
            this.messageNotLoaded(this.message);
            this.$emit('error');
        },
    },
    data() {
        return {
            playingProgression: 0,
            currentTime: 0,
            duration: 0,
            status: null,
            errorMessage: null,
        }
    }
}
</script>
