var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", [
    this.hasBirthdayCouponActive
      ? _c(
          "div",
          [
            _c("modal-heading", { staticClass: "text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get("modals.birthday_coupon.happy_birthday")
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", { staticClass: "text-center" }, [
              _c("p", { staticClass: "text-black font-bold" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.trans.get(
                        "modals.birthday_coupon.your_gift_is_waiting"
                      )
                    )
                ),
                _c("br"),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.trans.get("modals.birthday_coupon.enjoy_your_gift", {
                        amount: this.getCouponAmount,
                      })
                    ) +
                    "\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full text-center" }, [
              _c("img", {
                staticClass: "inline-block max-h-48",
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    _vm.config.get(
                      "assets.popup_birthday_coupon_active_image_path"
                    )
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 gap-4 mt-5" },
              [
                _c(
                  "base-button",
                  { attrs: { autofocus: "" }, on: { click: _vm.closeModal } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.trans.get(
                            "modals.birthday_coupon.i_use_my_coupon"
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("modal-heading", { staticClass: "text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get(
                      "modals.birthday_coupon.sorry_coupon_unavailable"
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", { staticClass: "text-center" }, [
              _c("p", { staticClass: "text-black font-bold" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("modals.birthday_coupon.dont_worry")) +
                    "\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full text-center" }, [
              _c("img", {
                staticClass: "inline-block max-h-48",
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    _vm.config.get(
                      "assets.popup_birthday_coupon_unavailable_image_path"
                    )
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 gap-4 mt-5" },
              [
                _c(
                  "base-button",
                  { attrs: { autofocus: "" }, on: { click: _vm.closeModal } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.ok")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }