<template>
    <div>
        <conversation-message-content-text :text="trans.get('chat.chat_bot.offer_answer_text', {nickname})"/>
        <div class="pb-3 px-3">
            <base-button small @click="askDisponibilities">
                {{ trans.get('chat.chat_bot.knowing_the_availability') }}
            </base-button>
        </div>
    </div>
</template>

<script>
import ChatMixin from './ChatMixin';
import BaseButton from '../elements/BaseButton';
import {mapActions} from 'vuex';
import ConversationMessageContentText from "./ConversationMessageContentText";

export default {
    name: 'ConversationMessageContentChatBotAskQuestion',
    components: {ConversationMessageContentText, BaseButton},
    mixins: [ChatMixin],
    props: {
        nickname: {
            type: String,
        },
    },
    methods: {
        ...mapActions('chat', ['askChatBotQuestion']),
        askDisponibilities() {
            this.askChatBotQuestion();
        }
    },
};
</script>
