var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "base-console" }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "base-console-header",
        on: { mousedown: _vm.dragMouseDown, touchmove: _vm.dragTouchMove },
      },
      [_c("base-bar", { attrs: { color: "info" } }, [_vm._v("Console")])],
      1
    ),
    _vm._v(" "),
    _vm.visible
      ? _c(
          "div",
          { ref: "console", staticClass: "base-console-logs" },
          [
            _vm._l(_vm.logs, function (log) {
              return [
                log.type === "info"
                  ? _c("p", { staticClass: "base-console-info" }, [
                      _vm._v(_vm._s(log.message)),
                    ])
                  : log.type === "warn"
                  ? _c("p", { staticClass: "base-console-warn" }, [
                      _vm._v(_vm._s(log.message)),
                    ])
                  : log.type === "error"
                  ? _c("p", { staticClass: "base-console-error" }, [
                      _vm._v(_vm._s(log.message)),
                    ])
                  : _c("p", [_vm._v(_vm._s(log.message))]),
              ]
            }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "base-console-info" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "chevron-right"] },
                }),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }