<template>
    <div>
        <h1 v-if="comments.length > 0" class="text-center text-primary lg:text-2xl text-xl font-bold mb-1">
            {{ trans.get('generic.latest_customer_reviews') }}
        </h1>

        <div class="comment-list-container">
            <div ref="swiper" class="comment-list swiper" @mouseenter="disableAutoplay" @mouseleave="enableAutoplay">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-if="loading" v-for="n in cardsPerPage">
                        <comment-card-loader ref="comment-card-loader" :key="'comment-card-loader-' + n"/>
                    </div>

                    <div class="swiper-slide" v-for="comment in comments">
                        <comment-card :key="comment.id" :comment="comment"/>
                    </div>
                </div>

                <base-infinite-pagination :slider="slider"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.comment-list-container {
    @apply w-full overflow-hidden;

    .comment-list {
        @apply mx-6 sm:mx-0 pb-2;

        &.swiper {
            @apply overflow-visible lg:overflow-hidden;
        }

        .swiper-slide {
            @apply py-2 px-2 sm:px-2;
        }
    }
}
</style>

<script>
import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';

import 'swiper/css';

import BaseInfinitePagination from "../elements/custom/BaseInfinitePagination.vue";
import CommentCard from "./CommentCard.vue";
import CommentCardLoader from "./CommentCardLoader.vue";

import {ziggy_route} from "../ZiggyMixin";

export default {
    name: "CommentList",
    components: {BaseInfinitePagination, CommentCardLoader, CommentCard},
    props: {
        categoryId: Number,
    },
    mounted() {
        this.initSlider();
        this.initList();
    },
    computed: {
        cardsPerPage() {
            return 3;
        },
    },
    methods: {
        initList() {
            this.loading = true;

            let params = {
                category_id: this.categoryId,
                page: 1,
            };

            axios.get(ziggy_route('comment.get', params)).then(response => {
                response.data.data.forEach((comment) => {
                    this.createOrUpdateComment(comment);
                });

                this.loading = false;

                this.$nextTick(() => {
                    if (this.slider !== null) {
                        this.slider.update();
                    }
                });
            }).catch(error => {
                console.error(error);
            });
        },
        initSlider() {
            this.slider = new Swiper(this.$refs.swiper, {
                modules: [Autoplay],
                loop: true,
                slidesPerView: 1,
                normalizeSlideIndex: false,
                centeredSlides: true,
                centerInsufficientSlides: true,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1024: {
                        slidesPerView: 3,
                        centeredSlides: false,
                    },
                },
            });
        },
        enableAutoplay() {
            if (this.slider !== null) {
                this.slider.autoplay.start();
            }
        },
        disableAutoplay() {
            if (this.slider !== null) {
                this.slider.autoplay.stop();
            }
        },
        getCommentIndex(comment_id) {
            const index = this.comments.findIndex(existingComment => existingComment.id === comment_id);
            return index > -1 ? index : null;
        },
        createComment(comment) {
            this.comments.push(comment);
        },
        updateComment(comment) {
            let index = this.getCommentIndex(comment.id);

            if (index !== null) {
                this.$set(this.comments, index, {...this.comments[index], ...comment});
            }
        },
        createOrUpdateComment(comment) {
            this.getCommentIndex(comment.id) !== null ? this.updateComment(comment) : this.createComment(comment);
        },
    },
    data() {
        return {
            comments: [],
            loading: true,
            slider: null,
        }
    }
}
</script>
