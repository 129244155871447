var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "" } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get(
                "generic.modals.user_phone_verification_already_used.phone_number_already_used"
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get(
                "generic.modals.user_phone_verification_already_used.this_number_is_already_used_on_the_following_account",
                {
                  email: _vm.email,
                  phone_number_customer_care: _vm.config.get(
                    "app.phone_number_customer_care"
                  ),
                }
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-4 mb-6" },
        [
          _c(
            "base-button",
            { attrs: { autofocus: "" }, on: { click: _vm.closeModal } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.i_understood")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-paragraph", { staticClass: "text-center" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get(
                "generic.modals.user_phone_verification_already_used.did_you_use_the_wrong_account"
              )
            ) +
            "\n        "
        ),
        _c(
          "div",
          { on: { click: _vm.openLoginModal } },
          [
            _c("base-link", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.trans.get(
                      "generic.modals.user_phone_verification_already_used.connect_with_another_account"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }