var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Phone input")]),
        _vm._v(" "),
        _c("base-phone-input"),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autofocus")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("error")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("hideCountries")]),
            _vm._v(": Boolean"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("initialCountry")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("label")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("mobileOnly")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("onlyCountries")]),
            _vm._v(": Array"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("placeholder")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("value")]),
            _vm._v(": String | Number"),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Placeholder")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: { outlined: "", placeholder: "Phone number" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: { outlined: "", label: "Phone number" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label + Placeholder")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: {
            outlined: "",
            label: "Phone number",
            placeholder: "Phone number",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: { outlined: "", placeholder: "Phone number" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: { disabled: "", placeholder: "Phone number" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Only countries")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: {
            outlined: "",
            label: "Phone number",
            placeholder: "Phone number",
            "only-countries": ["FR", "BE"],
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Initial Country")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: {
            outlined: "",
            label: "Phone number",
            placeholder: "Phone number",
            initialCountry: "FR",
            "only-countries": ["FR", "BE"],
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Hide countries")]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: {
            "hide-countries": "",
            outlined: "",
            label: "Phone number",
            placeholder: "Phone number",
            initialCountry: "FR",
            "only-countries": ["FR"],
          },
          model: {
            value: _vm.value_hidden,
            callback: function ($$v) {
              _vm.value_hidden = $$v
            },
            expression: "value_hidden",
          },
        }),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "pt-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Value : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.value_hidden))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Value")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("The default country is selected based on the "),
          _c("strong", [_vm._v("value")]),
        ]),
        _vm._v(" "),
        _c("base-phone-input", {
          ref: "phone_input",
          attrs: {
            outlined: "",
            label: "Phone number",
            placeholder: "Phone number",
            "only-countries": ["FR", "BE", "IT", "ES", "DE", "US"],
          },
          on: {
            input: function ($event) {
              _vm.activeCountry = _vm.$refs.phone_input.getActiveCountry()
            },
          },
          model: {
            value: _vm.value_strong,
            callback: function ($$v) {
              _vm.value_strong = $$v
            },
            expression: "value_strong",
          },
        }),
        _vm._v(" "),
        _c(
          "base-list",
          { staticClass: "pt-4" },
          [
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Value : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.value_strong))]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Iso2 : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeCountry.iso2))]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("base-list-item", {
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "circle-chevron-right"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Dial : ")]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.activeCountry.dialCode))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Error")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("The "),
          _c("strong", [_vm._v("error")]),
          _vm._v(
            " is stronger than the validation of the phone number\n        "
          ),
        ]),
        _vm._v(" "),
        _c("base-phone-input", {
          attrs: {
            error: _vm.error,
            label: "Phone number",
            placeholder: "Phone number",
            "only-countries": ["FR", "BE"],
            value: "+33123456789",
            outlined: "",
          },
          on: {
            keydown: function ($event) {
              _vm.error = ""
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }