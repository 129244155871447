var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col sponsorship-share" },
    [
      _vm.copy_success
        ? _c(
            "base-alert",
            {
              ref: "alert",
              staticClass: "top-2 right-0 absolute z-10",
              attrs: {
                dismissible: "",
                delay: 5000,
                small: "",
                type: "success",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.trans.get("sponsorship.sponsor_link_was_copied"))
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("base-text-input", {
        staticClass: "pb-3",
        attrs: { value: _vm.link, readonly: "readonly", outlined: "" },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [
                _c("font-awesome-icon", { attrs: { icon: ["fal", "link"] } }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "base-button",
        { attrs: { block: "" }, on: { click: _vm.copy_link } },
        [_vm._v(_vm._s(_vm.trans.get("sponsorship.copy_link")))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex items-center my-4" }, [
        _c("hr", { staticClass: "flex-1 my-0" }),
        _c("span", { staticClass: "px-4" }, [
          _vm._v(_vm._s(_vm.trans.get("sponsorship.or"))),
        ]),
        _c("hr", { staticClass: "flex-1 my-0" }),
      ]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          attrs: { block: "", color: "facebook" },
          on: {
            click: function ($event) {
              return _vm.fbshome_click()
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "mr-2",
            attrs: { icon: ["fab", "facebook-f"], size: "lg" },
          }),
          _vm._v(_vm._s(_vm.trans.get("sponsorship.share_by_facebook"))),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }