var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-menu",
    {
      ref: "base-select-conversation-type",
      staticClass: "base-select-conversation-type",
      attrs: { "drop-left": "" },
      scopedSlots: _vm._u([
        {
          key: "items",
          fn: function () {
            return [
              _c(
                "base-menu-item",
                {
                  attrs: { selected: _vm.isCallSelected },
                  on: {
                    click: function ($event) {
                      return _vm.selectItem("only_call")
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "phone"] },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.trans.get("generic.call")))]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCallSelected,
                        expression: "isCallSelected",
                      },
                    ],
                    attrs: { icon: ["far", "check"] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-menu-item",
                {
                  attrs: { selected: _vm.isChatSelected },
                  on: {
                    click: function ($event) {
                      return _vm.selectItem("only_chat")
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "message-lines"] },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.trans.get("generic.chat")))]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isChatSelected,
                        expression: "isChatSelected",
                      },
                    ],
                    attrs: { icon: ["far", "check"] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-menu-item",
                {
                  attrs: { selected: _vm.isCallAndChatSelected },
                  on: {
                    click: function ($event) {
                      return _vm.selectItem(null)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "phone"] },
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "message-lines"] },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.trans.get("generic.call_and_chat"))),
                  ]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCallAndChatSelected,
                        expression: "isCallAndChatSelected",
                      },
                    ],
                    attrs: { icon: ["far", "check"] },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "base-button",
        {
          staticClass: "base-select-conversation-type-button",
          attrs: { small: "", pill: "" },
        },
        [
          _vm.isCallSelected
            ? [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: ["fas", "phone"] },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("generic.call")) +
                    "\n        "
                ),
              ]
            : _vm.isChatSelected
            ? [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: ["fas", "message-lines"] },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("generic.chat")) +
                    "\n        "
                ),
              ]
            : _vm.isCallAndChatSelected
            ? [
                _c("font-awesome-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: ["fas", "phone"] },
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: ["fas", "message-lines"] },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("generic.call_and_chat")) +
                    "\n        "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("font-awesome-icon", {
            staticClass: "ml-2",
            attrs: { icon: ["fas", "chevron-down"] },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }