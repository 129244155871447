var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Separator")]),
        _vm._v(" "),
        _c("base-alert", { attrs: { type: "info", block: "" } }, [
          _vm._v(
            "Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna\n            dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin molestie\n            malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Pellentesque in ipsum\n            id orci porta dapibus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla porttitor\n            accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Proin eget\n            tortor risus.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("base-separator", [_vm._v("this is a separator")]),
        _vm._v(" "),
        _c("base-alert", { attrs: { type: "info", block: "" } }, [
          _vm._v(
            "Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna\n            dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin molestie\n            malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Pellentesque in ipsum\n            id orci porta dapibus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla porttitor\n            accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Proin eget\n            tortor risus.\n        "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }