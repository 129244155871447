var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Link")]),
        _vm._v(" "),
        _c("base-link", { attrs: { href: "#" } }, [_vm._v("Link")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'primary', 'secondary'")]),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Color")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : primary / secondary"),
        ]),
        _vm._v(" "),
        _c(
          "development-paragraph",
          [
            _c("base-link", { attrs: { href: "#", color: "primary" } }, [
              _vm._v("Link primary"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "development-paragraph",
          [
            _c("base-link", { attrs: { href: "#", color: "secondary" } }, [
              _vm._v("Link secondary"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }