<template>
    <development>
        <div>
            <development-heading>Timer</development-heading>
            <base-timer auto-start v-slot="{minutes, seconds}">{{ minutes }} : {{ seconds }}</base-timer>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>tag</strong>: String - <em>'span', 'p', 'div', ...</em></li>
                <li><strong>autoStart</strong>: Boolean</li>
                <li><strong>time</strong>: Number - <em>in milliseconds</em></li>
                <li><strong>countdown</strong>: Boolean</li>
            </ul>

            <development-heading>Time</development-heading>
            <development-paragraph><strong>time</strong> in milliseconds</development-paragraph>
            <base-timer auto-start v-slot="{minutes, seconds}" :time="60000">
                Start at 1min/60000ms - {{ minutes }} : {{ seconds }}
            </base-timer>

            <development-heading>Countdown</development-heading>
            <development-paragraph><strong>countdown</strong> to reverse time !</development-paragraph>
            <base-timer auto-start countdown :time="10000" v-slot="{seconds}">{{ seconds }}</base-timer>

            <development-heading>Exemple :</development-heading>
            <development-paragraph><strong>Pluralization</strong> test</development-paragraph>
            <base-timer auto-start :time="57000" v-slot="{minutes,seconds}">
                <template v-if="minutes > 0">{{ minutes + (minutes > 1 ? ' minutes' : ' minute') }}</template>
                <template v-if="seconds > 0">{{ seconds + (seconds > 1 ? ' secondes' : ' seconde') }}</template>
            </base-timer>

            <development-heading>Exemple :</development-heading>
            <development-paragraph><strong>Countdown</strong> with days, hours, minutes and seconds</development-paragraph>
            <base-timer :time="3 * 24 * 60 * 60 * 1000"
                        v-slot="{days,hours,minutes,seconds}"
                        auto-start
                        countdown>
                {{ days }} jours {{ hours }} heures {{ minutes }} min {{ seconds }} sec rest.
            </base-timer>

            <development-heading>Exemple :</development-heading>
            <development-paragraph><strong>Play</strong> with time</development-paragraph>
            <base-button @click="startTimer">Play</base-button>
            <base-button @click="pauseTimer">Pause</base-button>
            <base-button @click="stopTimer">Stop</base-button>
            <base-timer ref="timerplay" :auto-start="false" v-slot="{minutes, seconds}">
                {{ minutes }}:{{ seconds }}
            </base-timer>

            <development-heading>Exemple :</development-heading>
            <development-paragraph><strong>Buttons and events</strong> at start, pause, stop or end</development-paragraph>
            <base-button @click="startCountdown">Play</base-button>
            <base-button @click="pauseCountdown">Pause</base-button>
            <base-button @click="stopCountdown">Stop</base-button>
            <base-timer
                ref="timercountdown"
                :time="3000"
                v-slot="{seconds}"
                countdown
                @start="addEvent('Start', 'success')"
                @pause="addEvent('Pause','warning')"
                @stop="addEvent('Stop','danger')"
                @end="addEvent('End','danger')">
                {{ seconds }}
            </base-timer>

            <div class="notifications">
                <template v-for="(timerEvent, index) in timerEvents">
                    <base-alert :type="timerEvent.type" dismissible elevated class="mb-2" :delay="5000">{{ timerEvent.message }}</base-alert>
                </template>
            </div>

            <development-heading>Exemple :</development-heading>
            <development-paragraph><strong>Threshold event</strong> launches an event when timer reaches a given time (3 seconds in this example)</development-paragraph>
            <base-timer
                auto-start
                v-slot="{seconds}"
                :threshold-events="thresholdEventGivenTime">
                {{ seconds }}
            </base-timer>

            <development-paragraph><strong>Threshold event</strong> launches an event when countdown timer reaches a given time left (4 seconds left in this example)</development-paragraph>
            <base-timer
                auto-start
                countdown
                :time="10000"
                v-slot="{seconds}"
                :threshold-events="thresholdEventCountdown">
                {{ seconds }}
            </base-timer>

            <development-paragraph><strong>Multiple threshold events</strong> launches multiple events when timer reaches each event given time (respectively 5, 10 and 20 seconds in this example)</development-paragraph>
            <base-timer
                auto-start
                v-slot="{seconds}"
                :threshold-events="thresholdMultipleEvents">
                {{ seconds }}
            </base-timer>
        </div>
    </development>
</template>

<style>
.notifications {
    @apply fixed top-0 right-0 flex flex-col-reverse p-2;
}
</style>

<script>
import Development from "./Development";
import BaseTimer from "../elements/BaseTimer";
import BaseButton from "../elements/BaseButton";
import BaseAlert from "../elements/BaseAlert";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";

export default {
    components: {DevelopmentParagraph, DevelopmentHeading, BaseAlert, BaseButton, BaseTimer, Development},
    data() {
        return {
            counting: false,
            timerEvents: [],
            thresholdEventGivenTime: {given_time: {time: 3000, callback: () => this.addEvent('Threshold 3 seconds', 'warning')}},
            thresholdEventCountdown: {given_time: {time: 4000, callback: () => this.addEvent('Threshold countdown 4s. left', 'danger')}},
            thresholdMultipleEvents: {
                first_event: {time: 5000, callback: () => this.addEvent('First Event 5 seconds', 'success')},
                second_event: {time: 10000, callback: () => this.addEvent('Second Event 10 seconds', 'success')},
                third_event: {time: 20000, callback: () => this.addEvent('Third Event 20 seconds', 'success')},
            }
        };
    },
    methods: {
        startCountdown: function () {
            this.$refs.timercountdown.start();
        },
        pauseCountdown: function () {
            this.$refs.timercountdown.pause();
        },
        stopCountdown: function () {
            this.$refs.timercountdown.stop();
        },
        addEvent: function (message, type) {
            this.timerEvents.push({message: message, type: type});
        },
        startTimer: function () {
            this.$refs.timerplay.start();
        },
        pauseTimer: function () {
            this.$refs.timerplay.pause();
        },
        stopTimer: function () {
            this.$refs.timerplay.stop();
        }
    }
}
</script>
