var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex text-dark space-x-1 items-start" },
    [
      _vm.hasIcon ? _vm._t("icon") : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _vm.hasTitle
          ? _c("div", [
              _c("div", { staticClass: "float-left pr-1" }, [
                _c("strong", [_vm._t("title")], 2),
              ]),
              _vm._v(" "),
              _vm.hasDescription
                ? _c(
                    "div",
                    { staticClass: "float-left" },
                    [_vm._t("description")],
                    2
                  )
                : _vm._e(),
            ])
          : _vm.hasDescription
          ? _c("div", [_vm._t("description")], 2)
          : _c("div", { staticClass: "float-left" }, [_vm._t("default")], 2),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }