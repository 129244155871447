var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    {
      attrs: { dense: "", "scrollable-content": "" },
      on: { scroll: _vm.onScroll },
      scopedSlots: _vm._u([
        {
          key: "content-header",
          fn: function () {
            return [
              _c("follow-up-to-one-search-bar", {
                on: {
                  back: _vm.backToFollowUpChoiceModal,
                  input: _vm.initialLoad,
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-follow-up-to-one-list" },
        [
          _vm._l(_vm.conversations, function (conversation) {
            return [
              _c("follow-up-to-one-search-list-item", {
                attrs: { conversation: conversation },
                on: { selectItem: _vm.followUpWithConversation },
              }),
            ]
          }),
          _vm._v(" "),
          _vm.loading || this.page < this.lastPage
            ? _c(
                "div",
                {
                  staticClass: "text-center p-4",
                  attrs: { id: "follow-up-list-items-loader" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-primary",
                    attrs: { icon: ["fas", "spinner"], spin: "", size: "3x" },
                  }),
                ],
                1
              )
            : !_vm.loading && _vm.conversations.length === 0
            ? _c(
                "div",
                { staticClass: "flex items-center justify-center p-5" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-primary",
                    attrs: { icon: ["far", "face-frown"], size: "5x" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }