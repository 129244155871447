var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Modal")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Small")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("To display a "),
          _c("strong", [_vm._v("small")]),
          _vm._v(
            " modal, the prop small must be added into the\n            base-modal tag like this :"
          ),
          _c("br"),
          _vm._v("<base-modal small></base-modal>\n        "),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("List of modals in production"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-4 space-y-2" },
          [
            _vm._l(_vm.modals, function (modalName) {
              return [
                _c(
                  "base-button",
                  {
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.modal(modalName)
                      },
                    },
                  },
                  [_vm._v(_vm._s(modalName))]
                ),
                _vm._v(" "),
                _vm.modalsIsShown(modalName)
                  ? _c("modal-" + modalName, { tag: "component" })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Component Base Modal")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_1 = true
              },
            },
          },
          [_vm._v("Base modal")]
        ),
        _vm._v(" "),
        _vm.modal_1
          ? _c(
              "base-modal",
              {
                on: {
                  close: function ($event) {
                    _vm.modal_1 = false
                  },
                },
              },
              [
                _c("p", [
                  _vm._v(
                    "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet\n                quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum\n                id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.\n                Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero\n                malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit\n                aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("dense")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("scrollableContent")]),
            _vm._v(": Boolean"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Dense")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_2 = true
              },
            },
          },
          [_vm._v("Base modal dense")]
        ),
        _vm._v(" "),
        _vm.modal_2
          ? _c(
              "base-modal",
              {
                attrs: { dense: "" },
                on: {
                  close: function ($event) {
                    _vm.modal_2 = false
                  },
                },
              },
              [
                _c("p", [
                  _vm._v(
                    "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet\n                quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum\n                id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.\n                Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero\n                malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit\n                aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Scrollable content")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_3 = true
              },
            },
          },
          [_vm._v("Base modal dense")]
        ),
        _vm._v(" "),
        _vm.modal_3
          ? _c(
              "base-modal",
              {
                attrs: { scrollableContent: "" },
                on: {
                  close: function ($event) {
                    _vm.modal_3 = false
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "space-y-4" },
                  _vm._l(10, function (n) {
                    return _c("p", [
                      _vm._v(
                        "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum\n                    ac diam sit amet\n                    quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in\n                    ipsum\n                    id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.\n                    Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut\n                    libero\n                    malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit\n                    aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt."
                      ),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_4 = true
              },
            },
          },
          [_vm._v("Base modal")]
        ),
        _vm._v(" "),
        _vm.modal_4
          ? _c(
              "base-modal",
              {
                attrs: { small: "" },
                on: {
                  close: function ($event) {
                    _vm.modal_4 = false
                  },
                },
              },
              [
                _c("p", [
                  _vm._v(
                    "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet\n                quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum\n                id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.\n                Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero\n                malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit\n                aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Header and footer")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_5 = true
              },
            },
          },
          [_vm._v("Base modal")]
        ),
        _vm._v(" "),
        _vm.modal_5
          ? _c(
              "base-modal",
              {
                attrs: { scrollableContent: "" },
                on: {
                  close: function ($event) {
                    _vm.modal_5 = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content-header",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-6" }, [
                            _vm._v("Content Header"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content-footer",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-6" }, [
                            _vm._v("Content Footer"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  743174217
                ),
              },
              [
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac diam sit amet\n                quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum\n                id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.\n                Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla quis lorem ut libero\n                malesuada feugiat. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit\n                aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Full option modal")]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            on: {
              click: function ($event) {
                _vm.modal_6 = true
              },
            },
          },
          [_vm._v("Base modal")]
        ),
        _vm._v(" "),
        _vm.modal_6
          ? _c(
              "base-modal",
              {
                attrs: { dense: "", scrollableContent: "" },
                on: {
                  close: function ($event) {
                    _vm.modal_6 = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content-header",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-6" }, [
                            _vm._v("Content Header"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content-footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "p-3 text-right" },
                            [
                              _c(
                                "base-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.modal_6 = false
                                    },
                                  },
                                },
                                [_vm._v("Fermer")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3357157683
                ),
              },
              [
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "divide-y divide-neutral-300" },
                  _vm._l(100, function (n) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "p-4 cursor-pointer outline-none hover:bg-neutral-100 focus:bg-neutral-100",
                        attrs: { tabindex: "0" },
                      },
                      [
                        _vm._v(
                          "\n                    Item " +
                            _vm._s(n) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Modal Message")]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.openMessageNoIcon } }, [
          _vm._v("Without icon"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.openMessageSmile } }, [
          _vm._v("With smile icon"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.openMessageCheck } }, [
          _vm._v("Small with check icon"),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [
          _vm._v("Params :"),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("title")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("description")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("icon (optional)")]),
            _vm._v(": Array for font awesome icon (ex: ['fal', 'check'])"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("buttonText")]), _vm._v(": String")]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }