var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: "" } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("chat.modals.master_is_connected", {
                master_name: _vm.master.name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", { staticClass: "flex justify-center" }, [
        _c("div", { staticClass: "master-infos-container" }, [
          _c(
            "div",
            { staticClass: "flex-none pr-4 self-center" },
            [
              _c("base-avatar", {
                attrs: {
                  name: _vm.master.name,
                  photo: _vm.master.photo,
                  status: _vm.master.status,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-grow self-center min-w-10 overflow-hidden" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-bold whitespace-nowrap overflow-ellipsis overflow-hidden",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.master.name) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm text-neutral-400 whitespace-nowrap overflow-ellipsis overflow-hidden",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.trans.get("generic.status_" + _vm.master.status)
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.master.chatLiveEnabled
        ? _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 mt-5" },
            [
              _c(
                "base-button",
                { attrs: { autofocus: "" }, on: { click: _vm.startLiveChat } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get(
                          "chat.modals.start_chat_live_button_label"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 mt-5" },
            [
              _c(
                "base-link",
                { attrs: { href: _vm.master.url } },
                [
                  _c(
                    "base-button",
                    { staticClass: "w-full", attrs: { autofocus: "" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "user"], size: "1x" },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trans.get("generic.see_the_master_profile")
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }