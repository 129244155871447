var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-radio-input",
    {
      attrs: {
        name: _vm.name,
        value: _vm.value,
        disabled: _vm.disabled,
        "input-hidden": "",
      },
      scopedSlots: _vm._u([
        {
          key: "checked",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "base-radio-input base-radio-input--checked" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-2 w-full items-center min-h-10",
                    },
                    [
                      _c("span", { staticClass: "base-radio-input-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-right font-bold text-2xl" },
                        [_vm._v(_vm._s(_vm.description))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "base-radio-input-icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-check"], size: "lg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "disabled",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "base-radio-input base-radio-input--disabled" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-2 w-full items-center min-h-10",
                    },
                    [
                      _c("span", { staticClass: "base-radio-input-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-right font-bold text-2xl" },
                        [_vm._v(_vm._s(_vm.description))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "base-radio-input-icon base-radio-input-icon--disabled",
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle"], size: "lg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.billingType,
        callback: function ($$v) {
          _vm.billingType = $$v
        },
        expression: "billingType",
      },
    },
    [
      [
        _c("div", { staticClass: "base-radio-input" }, [
          _c(
            "div",
            { staticClass: "grid grid-cols-2 w-full items-center min-h-10" },
            [
              _c("span", { staticClass: "base-radio-input-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-right font-bold text-2xl" }, [
                _vm._v(_vm._s(_vm.description)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "base-radio-input-icon text-neutral-400" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle"], size: "lg" },
              }),
            ],
            1
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }