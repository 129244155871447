<template>
    <div :class="conversationClass">
        <div class="flex flex-row flex-none h-16" v-if="this.isConversationOpen">
            <conversation-header-client-information-display v-if="this.speakingWithClient"/>
            <conversation-header-master-information-display v-else-if="this.speakingWithMaster"/>
            <conversation-header-customer-master-care-information-display
                v-else-if="this.speakingWithServiceClient || this.speakingWithServiceMaster"
                :care-type="this.interlocutor().type"/>
        </div>

        <base-bar v-if="connectionStateConnecting" color="warning" small>
            <font-awesome-icon :icon="['fas', 'spinner']" spin/> {{ trans.get('chat.reconnection_attempt') }}
        </base-bar>

        <base-bar v-else-if="connectionStateUnavailable" color="danger" small>
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/> {{ trans.get('chat.connection_interrupted') }}
        </base-bar>

        <base-bar v-else-if="offlineChat" color="neutral" small>
            <font-awesome-icon :icon="['far', 'message-lines']"/> {{ trans.get('chat.offline_chat') }}
        </base-bar>

        <base-bar v-else-if="individualFollowUp" color="info" small>
            <font-awesome-icon :icon="['far', 'message-lines']"/> {{ trans.get('chat.individual_follow_up') }}
        </base-bar>

        <conversation-messages/>

        <div v-if="showConversationAudioRecord" class="flex-none">
            <conversation-audio-record/>
        </div>

        <div v-else-if="showConversationStickersMenu">
            <conversation-stickers-menu/>
        </div>

        <div v-else-if="showConversationInput" class="flex-none">
            <conversation-input photo-enabled audio-enabled :disabled="disableConversationInput" :min-length="minLength"/>
        </div>
    </div>
</template>

<style lang="scss">
.conversation {
    @apply flex flex-col bg-white;
}
</style>

<script>
import ConversationHeaderClientInformationDisplay from "./ConversationHeaderClientInformationDisplay";
import ConversationHeaderMasterInformationDisplay from "./ConversationHeaderMasterInformationDisplay";
import ConversationMessages from "./ConversationMessages";
import ConversationInput from "./ConversationInput";

import {mapGetters} from 'vuex';
import ConversationHeaderCustomerMasterCareInformationDisplay
    from "./ConversationHeaderCustomerMasterCareInformationDisplay";
import ChatMixin from "./ChatMixin";
import BaseBar from "../elements/BaseBar";
import ConversationAudioRecord from "./ConversationAudioRecord";
import ConversationStickersMenu from "./ConversationStickersMenu.vue";

export default {
    name: "Conversation",
    mixins: [ChatMixin],
    components: {
        ConversationStickersMenu,
        ConversationAudioRecord,
        BaseBar,
        ConversationHeaderClientInformationDisplay,
        ConversationHeaderCustomerMasterCareInformationDisplay,
        ConversationHeaderMasterInformationDisplay,
        ConversationInput,
        ConversationMessages,
    },
    computed: {
        ...mapGetters('chat', [
            'canFollowUp',
            'getConnectionState',
            'iAmMaster',
            'isChatBotAnswerMode',
            'isConversationOpen',
            'isConversationStickersMenuOpen',
            'isFollowUpMode',
            'isLiveConversation',
            'isLiveConversationInProgress',
            'isLiveConversationWaiting',
            'speakingWithClient',
            'speakingWithMaster',
            'speakingWithServiceClient',
            'speakingWithServiceMaster',
        ]),
        ...mapGetters('media_devices', ['isRecordInProgress', 'isUserListeningToAudioDraft']),
        conversationClass() {
            return {
                "conversation": true,
                "conversation--opened": this.isConversationOpen
            };
        },
        showConversationInput() {
            let show = true;

            if (this.isConversationWithCare()) {
                show = false;
            } else if (!this.iAmMaster && !this.isMasterWithChatLiveEnabled(this.interlocutor())) {
                show = false;
            }

            return show;
        },
        showConversationAudioRecord() {
            return this.isRecordInProgress || this.isUserListeningToAudioDraft;
        },
        showConversationStickersMenu() {
            return this.isConversationStickersMenuOpen;
        },
        disableConversationInput() {
            let disable = false;

            if (this.isConversationWithCare()) {
                disable = true;
            } else if (this.iAmMaster) {
                if (!this.isLiveConversationInProgress && !this.isFollowUpMode && !this.isChatBotAnswerMode) {
                    disable = true;
                }
            } else if (!this.isLiveConversationInProgress && !this.isMasterAvailableForChat(this.interlocutor())) {
                disable = true;
            }

            return disable;
        },
        minLength() {
            if (this.isChatBotAnswerMode) {
                return this.config.get('chat.chat_min_length_chat_bot_answer_message');
            }
            if (this.isFollowUpMode) {
                return this.config.get('chat.chat_min_length_individual_follow_up_message');
            }
            return 1;
        },
        offlineChat() {
            return this.iAmMaster && !this.isConversationWithCare() && !this.isLiveConversationInProgress && this.canFollowUp && !this.isFollowUpMode;
        },
        individualFollowUp() {
            return this.iAmMaster && !this.isConversationWithCare() && this.isFollowUpMode;
        },
        connectionStateConnecting() {
            return this.getConnectionState === 'connecting';
        },
        connectionStateUnavailable() {
            return this.getConnectionState === 'unavailable';
        }
    },
}
</script>
