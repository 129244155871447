var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-card-actions" },
    [
      _vm.isOffline
        ? [
            _c("offer-card-action", {
              attrs: {
                label: _vm.trans.get("generic.button.alert_me_to_his_return"),
                icon: ["fas", "bell"],
                small: _vm.small,
                color: "offline",
                outlined: "",
              },
              on: { click: _vm.notifyWhenMasterIsOnline },
            }),
          ]
        : _vm.isBusy
        ? [
            _vm.fastPassActivated
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.fast_pass_active"),
                    icon: ["fas", "phone"],
                    small: _vm.small,
                    color: "prime",
                    outlined: "",
                  },
                })
              : _vm.waitingQueueFull
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get(
                      "generic.button.already_reserved_prime"
                    ),
                    icon: ["fas", "stars"],
                    small: _vm.small,
                    color: "busy",
                    outlined: "",
                  },
                })
              : _vm.fastPassUnavailable
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get(
                      "generic.button.fast_pass_unavailable"
                    ),
                    icon: ["fas", "phone-slash"],
                    small: _vm.small,
                    color: "prime",
                    outlined: "",
                  },
                })
              : _vm.canPurchaseFastPass
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.purchase_fast_pass"),
                    icon: ["fas", "phone"],
                    small: _vm.small,
                    color: "prime",
                  },
                  on: { click: _vm.purchaseFastPass },
                })
              : _vm.canCallWithFastPass
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.use_fast_pass"),
                    icon: ["fas", "phone"],
                    small: _vm.small,
                    color: "prime",
                  },
                  on: { click: _vm.startCallWithFastPass },
                })
              : _vm.canShowFastPassPresentation
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.use_fast_pass"),
                    icon: ["fas", "phone"],
                    small: _vm.small,
                    color: "prime",
                  },
                  on: { click: _vm.showFastPassPresentation },
                })
              : _vm.callInProgress
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.in_consultation"),
                    icon: ["fas", "phone-slash"],
                    small: _vm.small,
                    color: "busy",
                    outlined: "",
                  },
                })
              : _vm.chatInProgress
              ? _c("offer-card-action", {
                  attrs: {
                    label: _vm.trans.get("generic.button.in_consultation"),
                    icon: ["fas", "message-slash"],
                    small: _vm.small,
                    color: "busy",
                    outlined: "",
                  },
                })
              : _c("offer-card-action", {
                  attrs: {
                    color: "busy",
                    label: _vm.trans.get("generic.button.busy"),
                    icon: ["fas", "user-clock"],
                    small: _vm.small,
                    outlined: "",
                  },
                }),
          ]
        : !_vm.canChat && !_vm.canCall
        ? [
            _c("offer-card-action", {
              attrs: {
                label: _vm.trans.get("generic.button.busy"),
                icon: ["fas", "user-clock"],
                small: _vm.small,
                color: "busy",
                outlined: "",
              },
            }),
          ]
        : [
            _vm.canChat
              ? [
                  _vm.canChatFreePrime
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.free_chat"),
                          icon: ["fas", "message-lines"],
                          small: _vm.small,
                          color: "available",
                        },
                        on: { click: _vm.startChatFreePrime },
                      })
                    : _vm.canChatFree
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.free_chat"),
                          icon: ["fas", "message-lines"],
                          small: _vm.small,
                          color: "available",
                        },
                        on: { click: _vm.startChatFree },
                      })
                    : _vm.canChatWithVoucher
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.minutes_gift", {
                            minutes: 10,
                          }),
                          icon: ["fas", "message-lines"],
                          small: _vm.small,
                          color: "available",
                        },
                        on: { click: _vm.startChatWithVoucher },
                      })
                    : _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.chat"),
                          icon: ["fas", "message-lines"],
                          small: _vm.small,
                          color: "available",
                        },
                        on: { click: _vm.startChat },
                      }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.canCall
              ? [
                  _vm.canCallFreePrime
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.free_call"),
                          icon: ["fas", "phone"],
                          small: _vm.small,
                        },
                        on: { click: _vm.startCallFreePrime },
                      })
                    : _vm.canCallFreePrimeWithMastersNeverConsulted
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.free_call"),
                          icon: ["fas", "phone"],
                          small: _vm.small,
                        },
                        on: {
                          click:
                            _vm.startCallFreePrimeWithMastersNeverConsulted,
                        },
                      })
                    : _vm.canCallFree
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.free_call"),
                          icon: ["fas", "phone"],
                          small: _vm.small,
                        },
                        on: { click: _vm.startCallFree },
                      })
                    : _vm.canCallWithVoucher
                    ? _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.minutes_gift", {
                            minutes: 10,
                          }),
                          icon: ["fas", "phone"],
                          small: _vm.small,
                        },
                        on: { click: _vm.startCallWithVoucher },
                      })
                    : _c("offer-card-action", {
                        attrs: {
                          label: _vm.trans.get("generic.button.call"),
                          icon: ["fas", "phone"],
                          small: _vm.small,
                        },
                        on: { click: _vm.startCall },
                      }),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }