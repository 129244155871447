var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Alert")]),
        _vm._v(" "),
        _c("base-alert", { attrs: { type: "success" } }, [
          _vm._v("I'm an Alert Usage Example"),
        ]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("type")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'info', 'success', 'warning', 'danger'")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'info', 'success', 'warning', 'danger'")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("block")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("text")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("loading")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("dismissible")]),
            _vm._v(": Boolean"),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Type")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : info / success / warning / danger"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { type: "info" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { type: "pack-1" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Colors")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("color")]),
          _vm._v(" : info / success / warning / danger"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { color: "info" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { color: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { color: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { color: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { color: "pack-1" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Icons")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-alert",
              {
                attrs: { color: "info" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "message-lines"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                attrs: { color: "success" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "envelope"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                attrs: { color: "warning" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "cake-candles"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                attrs: { color: "danger" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "paper-plane"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Elevated")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : info / success / warning / danger"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-alert",
              {
                attrs: { elevated: "" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          staticClass: "text-primary",
                          attrs: { icon: ["fal", "envelope"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("base-alert", { attrs: { elevated: "", type: "info" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { elevated: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { elevated: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { elevated: "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Action")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-alert",
              {
                ref: "alertInfoAction",
                attrs: { type: "info" },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.alertInfoAction.close()
                              },
                            },
                          },
                          [_vm._v("J'ai compris !")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris\n                blandit aliquet elit, eget tincidunt nibh pulvinar.\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                ref: "warningInfoAction",
                attrs: { type: "warning" },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.warningInfoAction.close()
                              },
                            },
                          },
                          [_vm._v("Recharger mon crédit")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris\n                blandit aliquet elit, eget tincidunt nibh pulvinar.\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                ref: "successInfoAction",
                attrs: { type: "success" },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.successInfoAction.close()
                              },
                            },
                          },
                          [_vm._v("Fermer")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                I'm an Alert Usage Example\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                attrs: { "text-center": "", elevated: "" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "spinner"], spin: "" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          { attrs: { disabled: "" } },
                          [
                            _vm._v("Envoyer dans\n                        "),
                            _c("base-timer", {
                              attrs: { countdown: "", time: 60000 },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ seconds }) {
                                    return [_vm._v(_vm._s(seconds))]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit\n                neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.\n                Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat. Vivamus suscipit\n                tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Proin eget tortor risus. Cras\n                ultricies ligula sed magna dictum porta. Sed porttitor lectus nibh.\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Block")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("block")]),
          _vm._v(" alerts extend the full available width.\n        "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { block: "", type: "info" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { block: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { block: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { block: "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Dismissible")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("An alert "),
          _c("strong", [_vm._v("dismissible")]),
          _vm._v(" can be close."),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { dismissible: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { dismissible: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { dismissible: "", type: "danger" } }, [
              _vm._v(
                "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit\n                neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.\n                Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat. Vivamus suscipit\n                tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Proin eget tortor risus. Cras\n                ultricies ligula sed magna dictum porta. Sed porttitor lectus nibh.\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "base-alert",
              { attrs: { dismissible: "", type: "success", block: "" } },
              [_vm._v("I'm a Block Alert Usage Example")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Text center")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-alert",
              { attrs: { "text-center": "", type: "success" } },
              [
                _vm._v(
                  "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices\n                posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.\n                Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit\n                amet ligula. Nulla porttitor accumsan tincidunt.\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                attrs: { dismissible: "", elevated: "", "text-center": "" },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          staticClass: "text-primary",
                          attrs: { icon: ["fal", "envelope"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices\n                posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.\n                Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit\n                amet ligula. Nulla porttitor accumsan tincidunt.\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              {
                ref: "centerTextInfoInfoAction",
                attrs: {
                  type: "info",
                  elevated: "",
                  "text-center": "",
                  large: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "spinner"], spin: "" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "action",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.centerTextInfoInfoAction.close()
                              },
                            },
                          },
                          [_vm._v("Fermer")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(
                  "\n                Info elevated text-center large dismissible with icon and action. Vestibulum ante ipsum primis in\n                faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam\n                vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt. Donec velit neque, auctor sit\n                amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor accumsan tincidunt.\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("XSmall")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { "x-small": "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { "x-small": "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { "x-small": "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { small: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { small: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { small: "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { large: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { large: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { large: "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Pill")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-alert", { attrs: { pill: "", type: "success" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { pill: "", type: "warning" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
            _vm._v(" "),
            _c("base-alert", { attrs: { pill: "", type: "danger" } }, [
              _vm._v("I'm an Alert Usage Example"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Delay")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("delay")]),
          _vm._v(" before remove this alert"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c(
              "base-alert",
              { attrs: { large: "", type: "info", delay: 5000 } },
              [_vm._v("Remove after 5 sec")]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              { attrs: { large: "", type: "success", delay: 10000 } },
              [_vm._v("Remove after 10 sec")]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              { attrs: { large: "", type: "warning", delay: 15000 } },
              [_vm._v("Remove after 15 sec")]
            ),
            _vm._v(" "),
            _c(
              "base-alert",
              { attrs: { large: "", type: "danger", delay: 20000 } },
              [_vm._v("Remove after 20 sec")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }