<template>
    <div class="conversation-informations">
        <div class="h-16 flex w-full items-center cursor-pointer" @click="toggleConversationInformations(false)">
            <base-button link font-normal normal-case class="ml-4">
                <font-awesome-icon :icon="['fal', 'xmark']" size="2x" class="mr-2"/>
                {{ trans.get('generic.chat_close_conversation_informations') }}
            </base-button>
        </div>

        <div class="py-4 px-7 overflow-y-auto h-full md:pb-4 pb-20">
            <h1 class="text-primary text-xl font-bold mb-3">
                {{ trans.get('chat.information_about_customer', {name: client.name}) }}
            </h1>

            <base-list class="p-4">
                <base-list-item>
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>
                        {{ trans.get('generic.user_segment.type') }}
                    </template>
                    <template #description>
                        <span v-if="getSegment === 'gold'">
                            {{ trans.get('generic.user_segment.gold') }}
                            <font-awesome-icon :icon="['fas', 'trophy-star']" class="text-star"/>
                        </span>
                        <span v-else-if="getSegment === 'silver'">
                            {{ trans.get('generic.user_segment.silver') }}
                            <font-awesome-icon :icon="['fas', 'trophy']" class="text-neutral-400"/>
                        </span>
                        <span v-else-if="getSegment === 'diamond'">
                            {{ trans.get('generic.user_segment.diamond') }}
                            <font-awesome-icon :icon="['fas', 'gem']" class="text-dark"/>
                        </span>
                        <span v-else-if="getSegment === 'new_paying_customer'">
                            {{ trans.get('generic.user_segment.new_paying_customer') }}
                            <font-awesome-icon :icon="['fas', 'face-smile-plus']" class="text-primary"/>
                        </span>
                        <span v-else-if="getSegment === 'free_customer'">
                            {{ trans.get('generic.user_segment.free_customer') }}
                            <font-awesome-icon :icon="['fas', 'face-smile-plus']" class="text-primary"/>
                        </span>

                        <span v-else-if="getSegment === 'free_customer'">
                            {{ trans.get('generic.user_segment.free_customer') }}
                            <font-awesome-icon :icon="['far', 'face-meh']" class="text-main"/>
                        </span>
                        <span v-else-if="getSegment === 'customer_to_retain'">
                            {{ trans.get('generic.user_segment.customer_to_retain') }}
                            <font-awesome-icon :icon="['far', 'face-smile-plus']" class="text-success"/>
                        </span>
                        <span v-else-if="getSegment === 'loyal_customer'">
                            {{ trans.get('generic.user_segment.loyal_customer') }}
                            <font-awesome-icon :icon="['far', 'face-smile']" class="text-primary"/>
                        </span>
                        <span v-else-if="getSegment === 'customer_to_reloyalty'">
                            {{ trans.get('generic.user_segment.customer_to_reloyalty') }}
                            <font-awesome-icon :icon="['far', 'face-sleeping']" class="text-warning"/>
                        </span>
                        <span v-else>
                            {{ trans.get('generic.user_segment.customer') }}
                            <font-awesome-icon :icon="['fas', 'user']" class="text-primary"/>
                        </span>
                    </template>
                </base-list-item>
                <base-list-item>
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>{{ trans.get('chat.information_about_customer_last_consultation') }}</template>
                    <template #description>{{ getDateLastConsultation | formatDate }}</template>
                </base-list-item>
                <base-list-item v-if="cost_by_minute > 0">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>{{ trans.get('generic.rate') }} :</template>
                    <template #description v-if="this.hasPrimeFreeChatAdvantage()">{{ 0 | formatMoneyPerMinute }}</template>
                    <template #description v-else>{{ cost_by_minute | formatMoneyPerMinute }}</template>
                </base-list-item>
                <base-list-item v-if="this.hasPrimeFreeChatAdvantage()">
                    <template #icon>
                        <font-awesome-icon :icon="['fas', 'circle-chevron-right']"/>
                    </template>
                    <template #title>{{ trans.get('chat.consultation_type') }}</template>
                    <template #description>{{ trans.get('chat.modals.free_chat_no_payoff') }}</template>
                </base-list-item>
            </base-list>

            <master-user-notes-editor v-if="this.config.get('call.user_notes_enabled')" :user-id="client.id"/>
            <p class="text-center mt-6 underline text-danger">
                <a v-if="this.getChatLiveSessionId()" @click="reportProblem">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"></font-awesome-icon> {{ trans.get('chat.signal_problem_with_client') }}
                </a>
            </p>
        </div>
    </div>
</template>

<style>
.conversation-informations {
    @apply bg-white;
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters} from 'vuex';
import BaseList from '../elements/BaseList';
import BaseListItem from '../elements/BaseListItem';
import requests from '../chat/requests';
import ChatMixin from './ChatMixin';

export default {
    name: 'ConversationInformations',
    components: {BaseListItem, BaseList, BaseButton},
    mixins: [ChatMixin],
    data() {
        return {
            cost_by_minute: 0,
        };
    },
    computed: {
        ...mapGetters('chat', ['getClientInformationData', 'getChatLiveSession']),
        client: {
            get() {
                return this.interlocutor();
            },
        },
        getDateLastConsultation: {
            get() {
                return (this.getClientInformationData['date_last_consultation'])
                    ? this.getClientInformationData['date_last_consultation']
                    : this.trans.get('chat.information_about_customer_first_consultation');
            }
        },
        getRegisterDate: {
            get() {
                return this.getClientInformationData['register_date'];
            }
        },
        getSegment: {
            get() {
                return this.getClientInformationData['segment'];
            }
        }
    },
    methods: {
        ...mapActions('chat', ['toggleConversationInformations', 'loadClientInformation']),
        ...mapActions('modals', ['openModal']),
        reportProblem() {
            this.openModal({
                name: globals.CONVERSATION_REPORT,
                param: {
                    conversation_id: this.getChatLiveSessionId(),
                    conversation_type: 'chat',
                }
            });
        },
    },
    created() {
        this.loadClientInformation();
    },
    updated() {
        this.loadClientInformation();
    },
    mounted() {
        let session = this.getChatLiveSession;

        if (session && session.hasOwnProperty('cost_by_minute')) {
            this.cost_by_minute = session.cost_by_minute / 100;
        }
    }
};
</script>
