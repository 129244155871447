var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.audioError
    ? _c("button", {
        staticClass: "base-audio-player-pause",
        on: { click: _vm.pause },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }