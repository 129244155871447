var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", [
    _c("div", [
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("master-user-note-editor", {
            ref: "general",
            attrs: {
              title: _vm.trans.get("master.customer_notes"),
              placeholder: _vm.trans.get("master.write_customer_notes"),
              "user-id": _vm.userId,
              "note-type": "general",
              readonly: "",
            },
            on: { status: _vm.changeStatus },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("master-user-note-editor", {
            ref: "consultation",
            attrs: {
              title: _vm.trans.get("master.reading_notes"),
              placeholder: _vm.trans.get("master.write_reading_notes"),
              "user-id": _vm.userId,
              "note-type": "consultation",
              "show-status": "",
              readonly: "",
            },
            on: { status: _vm.changeStatus },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "base-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.closeModal } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(this.trans.get("generic.close")) +
                  "\n            "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }