<template>
    <development>
        <div>
            <development-heading>Text and Typography</development-heading>
        </div>

        <development-heading>Text color</development-heading>
        <p class="text-primary">text-primary</p>
        <p class="text-secondary">text-secondary</p>
        <p class="text-black">text-black</p>
        <p class="text-darkest">text-darkest</p>
        <p class="text-dark">text-dark</p>
        <p class="text-main">text-main</p>
        <p class="text-light">text-light</p>
        <p class="text-lightest">text-lightest</p>
    </development>
</template>


<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";

export default {
    components: {DevelopmentHeading, Development}
}
</script>
