<template>
    <a class="text-primary underline">
        <slot></slot>
    </a>
</template>

<script>
export default {
    name: "DevelopmentLink"
};
</script>
