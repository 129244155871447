var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-list-banner" },
    [
      _vm.isGameBanner || _vm.isSpecialOperationBanner
        ? [
            _vm.isGameBanner
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "offer-list-banner-image-container cursor-pointer",
                      attrs: { onclick: "showGameV5();" },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            type: "image/jpeg",
                            srcset: _vm.assetManager.getImageAsset(
                              "game/" +
                                _vm.getActiveGameSlug +
                                "/website/banner/offer-list-banner-desktop.jpg"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "offer-list-banner-image",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(
                              "game/" +
                                _vm.getActiveGameSlug +
                                "/website/banner/offer-list-banner-mobile.jpg"
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isSpecialOperationBanner
              ? [
                  _c(
                    "div",
                    { staticClass: "offer-list-banner-image-container" },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            type: "image/jpeg",
                            srcset: _vm.assetManager.getAssetFromDisk(
                              "img_promos",
                              _vm.getBannerPromoPath(
                                _vm.getActiveSpecialOperation.encart_name
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "offer-list-banner-image",
                          attrs: {
                            src: _vm.assetManager.getAssetFromDisk(
                              "img_promos",
                              _vm.getBannerPromoPath(
                                _vm.getActiveSpecialOperation
                                  .list_banner_img_name
                              )
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm.isVoyanceByChatBanner
        ? [
            _c("div", { staticClass: "offer-list-banner-voyance-by-chat" }, [
              _c("div", { staticClass: "banner-col-left" }, [
                _c("img", {
                  attrs: {
                    src: _vm.assetManager.getImageAsset(
                      "offer-list/voyance_by_chat_banner.png"
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "banner-col-right" }, [
                _c("p", {
                  staticClass: "banner-text",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.trans.get("offer-list.voyance_by_chat_banner_title")
                    ),
                  },
                }),
              ]),
            ]),
          ]
        : _vm.isDefaultBanner
        ? [
            _c(
              "div",
              {
                staticClass: "offer-list-banner-default",
                style: {
                  "background-image":
                    "url(" +
                    _vm.assetManager.getImageAsset(
                      "offer-list/default_banner.jpg"
                    ) +
                    ")",
                },
              },
              [
                _c("div", {
                  staticClass: "banner-col-left",
                  domProps: { innerHTML: _vm._s(_vm.defaultBannerTitle) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "banner-col-right",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.trans.get("offer-list.default-banner-subtitle")
                    ),
                  },
                }),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isPrimeOfferList
        ? [
            _c("div", { staticClass: "offer-list-banner-prime-list" }, [
              _c("div", { staticClass: "banner-col-left" }, [
                _c("img", {
                  staticClass: "max-w-8 mx-1",
                  attrs: {
                    src: _vm.assetManager.getImageAsset("prime/gift.png"),
                    alt: _vm.primeAdvantageTitle,
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "banner-col-right" },
                [
                  _c("h4", { staticClass: "banner-title" }, [
                    _vm._v(_vm._s(_vm.primeAdvantageTitle)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "banner-description" }, [
                    _vm._v(_vm._s(_vm.primeAdvantageDescription)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "base-link",
                    {
                      staticClass: "banner-link",
                      attrs: { href: _vm.ziggy_route("astro") },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans.get("prime.banner.back_link.text")) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }