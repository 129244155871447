<template>
    <div :class="itemClass" @click="selectConversation">
        <div class="flex-none self-center pr-3">
            <base-avatar
                :name="name"
                :photo="photo"
                :status="status"
                :color="selected ? 'neutral' : 'primary'"
                outlined/>
        </div>
        <div class="flex-grow self-center min-w-0">
            <p class="conversation-list-item-name">{{ name }}</p>
            <p v-if="isTyping(this.participants)">{{ trans.get('chat.is_typing') }}</p>
            <p v-else-if="hasLastMessage" class="conversation-list-item-last-message">
                <span v-if="isTextMessage" v-html="lastMessage.content.text">
                </span>
                <span v-else-if="isImageMessage">
                    <font-awesome-icon :icon="['far', 'image']"/> {{ trans.get('generic.chat_message_image') }}
                </span>
                <span v-else-if="isAudioMessage">
                    <font-awesome-icon :icon="['fal', 'microphone-lines']" /> {{ trans.get('generic.chat_message_audio') }}
                </span>
                <span v-else-if="isStickerMessage">
                    <font-awesome-icon :icon="['fas', 'note-sticky']"/> {{ trans.get('generic.chat_message_sticker') }}
                </span>
            </p>
        </div>
        <div v-if="isMasterStatusOffline" class="flex-none self-center">
            <base-button class="min-w-30" color="neutral" outlined small @click="displayModalNotifMasterOnline">
                <font-awesome-icon :icon="['far', 'bell']" size="1x"/>
                {{ trans.get('generic.alert_notif_master_online') }}
            </base-button>
        </div>
        <div v-else-if="isMasterStatusBusy" class="flex-none self-center">
            <base-button class="min-w-30" color="busy" outlined small>
                {{ trans.get('generic.status_busy') }}
            </base-button>
        </div>
        <div v-else-if="isChatLiveEnabled" class="flex-none self-center">
            <base-button class="min-w-30" outlined small @click="startLiveChat">
                <font-awesome-icon :icon="['far', 'message-lines']"/>
                {{ trans.get('generic.chat_live') }}
            </base-button>
        </div>
        <div v-else-if="hasLastMessage" class="flex-none self-center whitespace-nowrap">
            <p class="whitespace-nowrap">&nbsp;&middot;&nbsp;{{ lastMessageWrittenSince }}</p>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-list-item {
    @apply w-full flex p-3 cursor-pointer hover:bg-neutral-100;

    & .conversation-list-item-name {
        @apply text-lg text-primary font-bold capitalize;
    }

    & .conversation-list-item-last-message {
        @apply text-sm text-primary font-semibold pointer-events-none;
    }

    &--selected {
        @apply bg-neutral-100;

        .conversation-list-item-name {
            @apply text-neutral-800 font-normal;
        }

        .conversation-list-item-last-message {
            @apply text-neutral-500 font-normal;
        }
    }

    &--read {
        .conversation-list-item-name {
            @apply text-neutral-800 font-normal;
        }

        .conversation-list-item-last-message {
            @apply text-neutral-500 font-normal;
        }
    }

    & p {
        @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
    }
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters} from 'vuex';
import BaseAvatar from '../elements/BaseAvatar';
import ChatMixin from './ChatMixin';
import moment from "moment";

export default {
    name: 'ConversationListItem',
    components: {BaseAvatar, BaseButton},
    mixins: [ChatMixin],
    props: {
        lastMessage: {
            type: Object,
        },
        participants: {
            type: Array
        },
        read: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('chat', ['getSelectedParticipants', 'iAmMaster', 'isTyping', 'getOfferIdFromParticipants']),
        name() {
            return this.getParticipantName(this.interlocutor(this.participants));
        },
        photo() {
            return this.interlocutor(this.participants).photo;
        },
        status() {
            return this.interlocutor(this.participants).status;
        },
        selected() {
            return this.participants === this.getSelectedParticipants;
        },
        hasLastMessage() {
            return this.lastMessage !== undefined && this.lastMessage !== null;
        },
        isChatLiveEnabled() {
            return this.isMasterWithChatLiveEnabled(this.interlocutor(this.participants));
        },
        isLastMessageFromMe() {
            return this.hasLastMessage && this.lastMessage.participant.id === this.me(this.participants).id;
        },
        isDisplayedAsRead() {
            return this.read;
        },
        itemClass() {
            return {
                "conversation-list-item": true,
                "conversation-list-item--read": this.isDisplayedAsRead,
                "conversation-list-item--selected": this.selected === true,
            };
        },
        isMasterStatusOffline() {
            return this.status === 'offline';
        },
        isMasterStatusBusy() {
            return this.status === 'busy';
        },
        lastMessageWrittenSince() {
            return moment(this.lastMessage.created_at, 'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        isImageMessage() {
            return this.lastMessage.type === 'image';
        },
        isStickerMessage() {
            return this.lastMessage.type === 'sticker';
        },
        isAudioMessage() {
            return this.lastMessage.type === 'audio';
        },
        isTextMessage() {
            return this.lastMessage.type === 'text';
        }
    },
    methods: {
        ...mapActions('chat', ['displayMasterConnectedModal', 'selectParticipants', 'setConversationUrl', 'startLiveChatProcess']),
        ...mapActions('modals', ['openModal']),
        selectConversation: function () {
            this.selectParticipants(this.participants);
            this.setConversationUrl(this.participants);
        },
        startLiveChat: function (event) {
            event.stopPropagation();
            this.startLiveChatProcess({offerId:this.getOfferIdFromParticipants(this.participants)});
        },
        displayModalNotifMasterOnline() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE,
                param: {
                    master: this.interlocutor(this.participants),
                    userFullMobilePhoneNumber: this.meFromUserStore().mobile_phone
                }
            });
        }
    },
}
</script>
