var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "reset-this" }, [
      _c(
        "div",
        { class: [_vm.containerClass, _vm.zIndex], attrs: { id: "BaseModal" } },
        [
          _c("div", { staticClass: "fixed", on: { click: _vm.closeModal } }, [
            _c("div", { staticClass: "base-modal-overlay" }),
          ]),
          _vm._v(" "),
          _vm.getLoading
            ? _c("div", { staticClass: "base-modal-loading" }, [
                _c(
                  "div",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "spinner"], spin: "", size: "3x" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "my-3 font-extrabold" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.trans.get("generic.loading")) +
                      "...\n                "
                  ),
                ]),
              ])
            : _vm.error
            ? _c("div", { staticClass: "error" }, [
                _c("p", [_vm._v(_vm._s(_vm.error))]),
              ])
            : _c("div", { class: _vm.modalClass }, [
                _vm.small
                  ? _c(
                      "div",
                      {
                        staticClass: "base-modal-header-small",
                        on: { click: _vm.closeModal },
                      },
                      [
                        !_vm.disableClosing
                          ? _c(
                              "base-button",
                              {
                                attrs: { small: "", color: "light", icon: "" },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["fal", "circle-xmark"],
                                    size: "lg",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.fullscreen
                  ? _c("div")
                  : _c(
                      "div",
                      { staticClass: "base-modal-header" },
                      [
                        _c("img", {
                          staticClass: "h-10",
                          attrs: {
                            src: _vm.assetManager.getImageAsset(_vm.logo),
                            alt: _vm.trans.get("generic.site_name"),
                          },
                        }),
                        _vm._v(" "),
                        !_vm.disableClosing
                          ? _c(
                              "base-button",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass:
                                    "text-modal-header-button hover:text-modal-header-button",
                                  attrs: {
                                    icon: ["far", "circle-xmark"],
                                    size: "lg",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                _vm._v(" "),
                _vm.hasContentHeader
                  ? _c(
                      "div",
                      { staticClass: "base-modal-content-header" },
                      [_vm._t("content-header")],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "modalContent",
                    class: _vm.contentClass,
                    on: {
                      scroll: function ($event) {
                        return _vm.$emit("scroll", $event)
                      },
                    },
                  },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _vm.hasContentFooter
                  ? _c(
                      "div",
                      { staticClass: "base-modal-content-footer" },
                      [_vm._t("content-footer")],
                      2
                    )
                  : _vm._e(),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }