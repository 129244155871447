var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isConversationOpen
    ? _c(
        "div",
        [
          _c("base-menu", {
            ref: "sticker-tooltip",
            class: {
              "h-0 w-0": true,
              "float-left": !this.isConversationInputFocused,
              "float-right": this.isConversationInputFocused,
            },
            attrs: {
              open: _vm.showStickerTooltip,
              "drop-left": this.isConversationInputFocused,
              "drop-top": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function () {
                    return [
                      _c(
                        "base-alert",
                        {
                          attrs: { dismissible: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      staticClass: "text-primary",
                                      attrs: {
                                        icon: ["fas", "gift"],
                                        size: "lg",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            677207517
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "text-primary w-48",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.trans.get(
                                  _vm.meFromUserStore().is_master
                                    ? "chat.you_can_send_stickers.master"
                                    : "chat.you_can_send_stickers.client"
                                )
                              ),
                            },
                            on: { click: _vm.closeStickerTooltip },
                          }),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              15741
            ),
          }),
          _vm._v(" "),
          _c("div", { staticClass: "conversation-input" }, [
            !this.isConversationInputFocused &&
            !_vm.image.url &&
            _vm.canSendHearts
              ? _c(
                  "div",
                  { staticClass: "conversation-input-icon" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { icon: "", large: "", color: "danger" },
                        on: { click: _vm.sendHeart },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "pointer-events-none",
                          attrs: { icon: ["fas", "heart"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !this.isConversationInputFocused && _vm.canSendStickers
              ? _c(
                  "div",
                  { staticClass: "conversation-input-icon" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { icon: "", large: "" },
                        on: { click: _vm.openStickerMenu },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "pointer-events-none",
                          attrs: { icon: ["fas", "gift"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.conversationInputInputContainerClass },
              [
                _vm.image.url
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "conversation-input-image-preview-container",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative inline-block" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass:
                                  "conversation-input-image-preview-delete-icon",
                                attrs: { fab: "", small: "", color: "light" },
                                on: { click: _vm.deleteImage },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "xmark"], size: "lg" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "conversation-input-image-preview",
                              attrs: { src: _vm.image.url },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("base-textarea", {
                  ref: "basetextarea",
                  attrs: {
                    outlined: "",
                    "error-top": "",
                    "max-length": _vm.maxLength,
                    rows: _vm.rows,
                    "max-rows": 3,
                    disabled: _vm.disabled,
                    "icon-right": this.gifEnabled,
                    error: _vm.errorSendMessage,
                    placeholder: _vm.trans.get(
                      _vm.disabled
                        ? "generic.conversation_input_placeholder_disabled"
                        : "generic.conversation_input_placeholder"
                    ),
                  },
                  on: {
                    keydown: _vm.keydown,
                    input: _vm.isTyping,
                    focus: _vm.focus,
                    blur: _vm.blur,
                  },
                  scopedSlots: _vm._u(
                    [
                      this.gifEnabled
                        ? {
                            key: "icon",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "conversation-input-gif-icon",
                                  },
                                  [_vm._v("GIF")]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-2" }, [
              _vm.photoEnabled
                ? _c(
                    "div",
                    { staticClass: "conversation-input-icon" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: !_vm.canSendFiles,
                            color: "neutral",
                          },
                          on: { mousedown: _vm.openInputFile },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pointer-events-none",
                            attrs: { icon: ["fal", "camera"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "image_file_input",
                        staticClass: "conversation-input-image-input",
                        attrs: {
                          type: "file",
                          accept: "image/png, image/jpeg, image/jpg, image/gif",
                        },
                        on: { change: _vm.onPhotoSelect },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "conversation-input-icon" },
                [
                  _vm.displayAudioIcon
                    ? _c(
                        "base-button",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            color: "primary",
                            disabled: !_vm.canSendFiles,
                          },
                          on: { mousedown: _vm.sendAudioMessage },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pointer-events-none",
                            attrs: { icon: ["fal", "microphone-lines"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displaySendIcon
                    ? _c(
                        "base-button",
                        {
                          attrs: {
                            disabled: !_vm.hasContent,
                            icon: "",
                            large: "",
                            color: "neutral",
                          },
                          on: { mousedown: _vm.sendMessage },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pointer-events-none",
                            attrs: { icon: ["fal", "paper-plane"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        this.isConversationInputFocused &&
                        !_vm.image.url &&
                        _vm.canSendHearts,
                      expression:
                        "this.isConversationInputFocused && !image.url && canSendHearts",
                    },
                  ],
                  staticClass: "conversation-input-icon",
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { icon: "", large: "", color: "danger" },
                      on: { mousedown: _vm.sendHeartThenFocusInput },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pointer-events-none",
                        attrs: { icon: ["fas", "heart"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        this.isConversationInputFocused && _vm.canSendStickers,
                      expression:
                        "this.isConversationInputFocused && canSendStickers",
                    },
                  ],
                  staticClass: "conversation-input-icon",
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { icon: "", large: "" },
                      on: { mousedown: _vm.openStickerMenu },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pointer-events-none",
                        attrs: { icon: ["fas", "gift"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }