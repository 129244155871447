<template>
    <video ref="video" width="100%" height="100%"
           :autoplay="autoplay"
           :loop="loop"
           muted playsinline>
        <source v-if="hasWebm" :src="srcWebm" type="video/webm">
        <source v-if="hasMov" :src="srcMov" type='video/mp4; codecs="hvc1"'>
    </video>
</template>

<script>
export default {
    name: "BaseVideoPlayer",
    props: {
        srcWebm: String,
        srcMov: String,
        loop: Boolean,
        autoplay: Boolean,
    },
    mounted() {
        this.init();
    },
    computed: {
        hasWebm() {
            return this.srcWebm !== undefined && this.srcWebm !== null;
        },
        hasMov() {
            return this.srcMov !== undefined && this.srcMov !== null;
        }
    },
    methods: {
        init() {
            this.video = this.$refs.video;

            this.video.addEventListener('canplaythrough', () => {
                this.$emit('canplaythrough');
            }, false);

            this.video.addEventListener('play', () => {
                this.$emit('play');
            }, false);

            this.video.addEventListener('pause', () => {
                this.$emit('pause');
            }, false);

            this.video.addEventListener('loadstart', () => {
                this.$emit('loadstart');
            }, false);

            this.video.addEventListener('ended', () => {
                this.$emit('end');
            }, false);
        },
        play() {
            this.video.play();
        },
        load() {
            this.video.load();
        },
        pause() {
            this.video.pause();
        },
        stop() {
            this.$emit('stop');
            this.video.currentTime = 0;
            this.video.pause();
        },
    },
    data() {
        return {
            video: null,
        }
    },

}
</script>
