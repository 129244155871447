<template>
    <div class="w-full flex py-2 pr-4 md:pl-2">
        <div :class="backButtonClass">
            <base-button icon @click="back">
                <font-awesome-icon :icon="['fal', 'chevron-left']" size="lg"/>
            </base-button>
        </div>
        <div :class="masterAvatarClass">
            <base-link :href="this.interlocutor().url" :inactive="this.isLiveConversation" :underline="false">
                <base-avatar :name="this.getParticipantName(master)"
                             :photo="master.photo"
                             :status="masterAvatarStatus"/>
            </base-link>
        </div>
        <div class="flex-grow self-center min-w-10 overflow-hidden">
            <p class="text-sm font-bold whitespace-nowrap overflow-ellipsis overflow-hidden"
               :class="masterTextColorByStatus">
                <base-link :href="this.interlocutor().url" :inactive="this.isLiveConversation" :underline="false">
                    {{ master.name }}
                </base-link>
            </p>
            <p class="text-xs text-neutral-500 whitespace-nowrap overflow-ellipsis overflow-hidden">
                {{ masterTextByStatus }}
            </p>
        </div>

        <template v-if="!this.hasLiveSessionBillingStarted()">
            <div class="flex-none self-center">
                <template v-if="isMasterOffline(this.master)">
                    <base-button small color="neutral" outlined @click="displayModalNotifMasterOnline" class="ml-2">
                        <font-awesome-icon :icon="['far', 'bell']" size="1x"/>
                        {{ trans.get('generic.alert_notif_master_online') }}
                    </base-button>
                </template>
                <template v-else-if="isMasterStatusBusy">
                </template>
                <template v-else-if="isChatLiveEnabled">
                    <base-button outlined small @click="startLiveChat" class="ml-2">
                        <font-awesome-icon :icon="['far', 'message-lines']"/>
                        {{ trans.get('generic.chat_live') }}
                    </base-button>
                </template>
            </div>
        </template>
        <template v-else>
            <p class="text-primary text-sm font-bold self-center mr-5">
                <base-timer ref="billing_timer" :threshold-events="thresholdEvents"
                            v-slot="{hours, minutes, seconds}">
                    {{ hours }}:{{ minutes }}:{{ seconds }}
                </base-timer>
            </p>
            <div v-if="isAddFundButtonDisplayed" class="flex-none self-center mr-5">
                <base-button small @click="addFundAlertToggle">
                    <font-awesome-icon :icon="['fas', 'circle-plus']"/>
                    {{ trans.get('generic.add_fund') }}
                    <font-awesome-icon v-if="isAddFundAlertDisplayed" :icon="['fas', 'caret-up']" />
                    <font-awesome-icon v-else :icon="['fas', 'caret-down']" />
                </base-button>
            </div>
            <div class="flex-none self-center">
                <base-button fab outlined small color="danger" @click="displayModalCloseChat">
                    <font-awesome-icon :icon="['fal', 'xmark']"/>
                </base-button>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.conversation-header-master-informations-back-button {
    @apply flex-none self-center md:hidden;

    &--hidden {
        @apply hidden;
    }
}

.conversation-header-master-informations-master-avatar {
    @apply flex-none pr-4 self-center;

    &--back-button-hidden {
        @apply pl-2 md:pl-0;
    }
}

</style>
<script>
import {mapActions, mapGetters} from 'vuex';
import BaseButton from '../elements/BaseButton';
import BaseAvatar from '../elements/BaseAvatar';
import BaseTimer from '../elements/BaseTimer';
import ChatMixin from './ChatMixin';
import BaseLink from "../elements/BaseLink";

export default {
    name: 'ConversationHeaderMasterInformationDisplay',
    components: {BaseLink, BaseAvatar, BaseButton, BaseTimer},
    mixins: [ChatMixin],
    data() {
        return {
            thresholdEvents: {
                recharge_live: {
                    time: this.config.get('chat.time_left_to_refill_chat_session_almost_over'),
                    callback: () => this.chatSessionAlmostOver()
                }
            }
        };
    },
    computed: {
        ...mapGetters('chat', [
            'getLiveSessionBillingTime',
            'getLiveSessionBillingTimeLeft',
            'getOfferIdFromParticipants',
            'getSelectedParticipants',
            'iAmMaster',
            'isAddFundAlertDisplayed',
            'isAddFundButtonDisplayed',
            'isLiveConversation',
            'isLiveConversationInProgress',
            'isLiveConversationWaiting'
        ]),
        ...mapGetters('payment', ['hasEnabledCreditCards']),
        master: {
            get() {
                return this.interlocutor();
            },
        },
        isChatLiveEnabled() {
            return this.isMasterWithChatLiveEnabled(this.master);
        },
        isMasterStatusBusy() {
            return this.master.status === 'busy';
        },
        hasChatLiveSessionBegun() {
            return this.isLiveConversationWaiting || this.isLiveConversationInProgress;
        },
        isLiveConversationWaitingWithClient() {
            return this.hasChatLiveSessionBegun && !this.iAmMaster;
        },
        masterAvatarStatus() {
            if (!this.isLiveConversationWaitingWithClient) {
                return this.master.status;
            } else {
                return "online";
            }
        },
        masterTextColorByStatus() {
            switch (this.master.status) {
                case "online":
                    return "text-primary";
                case "busy":
                    return !this.isLiveConversationWaitingWithClient ? "text-status-busy" : "text-primary";
                case "offline":
                    return "text-status-offline";
                default:
                    break;
            }
        },
        masterTextByStatus() {
            if (!this.isLiveConversationWaitingWithClient) {
                return this.trans.get('generic.status_' + this.master.status);
            } else {
                return this.trans.get('chat.online_with_you');
            }
        },
        backButtonClass() {
            return {
                'conversation-header-master-informations-back-button': true,
                'conversation-header-master-informations-back-button--hidden': this.hasChatLiveSessionBegun
            }
        },
        masterAvatarClass() {
            return {
                'conversation-header-master-informations-master-avatar': true,
                'conversation-header-master-informations-master-avatar--back-button-hidden': this.hasChatLiveSessionBegun
            }
        }

    },
    methods: {
        ...mapGetters('chat', ['hasLiveSessionBillingStarted', 'getLiveSessionMaxBillingTime']),
        ...mapActions('chat', ['chatSessionAlmostOver', 'closeConversation', 'startLiveChatProcess', 'toggleAddFundAlert']),
        ...mapActions('modals', ['openModal']),
        displayModalCloseChat() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_CONFIRMATION_END_CHAT_SESSION
            });
        },
        displayModalNotifMasterOnline() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE,
                param: {
                    master: this.interlocutor(),
                    userFullMobilePhoneNumber: this.meFromUserStore().mobile_phone
                }
            });
        },
        back() {
            this.closeConversation();
            history.pushState(null, "chat_v2", "chat_v2");
        },
        startLiveChat: function () {
            this.startLiveChatProcess({offerId: this.getOfferIdFromParticipants(this.getSelectedParticipants)});
        },
        addFundAlertToggle: function() {
            this.toggleAddFundAlert();
        },
    },
    created() {
        this.unwatchLiveSessionBillingStarted = this.$store.watch(this.hasLiveSessionBillingStarted, () => {
            if (this.hasLiveSessionBillingStarted()) {
                this.$nextTick(() => {
                    this.$refs.billing_timer.setTime(this.getLiveSessionBillingTime);
                    this.$refs.billing_timer.start();
                });
            }
        }, {immediate: true});

        this.unwatchLiveSessionMaxBillingTime = this.$store.watch(this.getLiveSessionMaxBillingTime, () => {
            if (this.hasLiveSessionBillingStarted()) {
                this.$nextTick(() => {
                    if (this.getLiveSessionBillingTimeLeft <= this.config.get('chat.time_left_to_refill_chat_session_almost_over')) {
                        this.thresholdEvents.recharge_live.time = 1;
                    } else {
                        this.thresholdEvents.recharge_live.time = this.getLiveSessionMaxBillingTime() - this.config.get('chat.time_left_to_refill_chat_session_almost_over');
                    }
                });
            }
        }, {immediate: true});
    },
    beforeDestroy() {
        this.unwatchLiveSessionBillingStarted();
        this.unwatchLiveSessionMaxBillingTime();
    }
}
</script>


