var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _c("modal-heading", [
        _vm._v(
          _vm._s(_vm.trans.get("generic.modals.follow_up_type_choice_title"))
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", {
        domProps: {
          innerHTML: _vm._s(
            this.trans.get("generic.modals.follow_up_type_choice_text")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1" },
        [
          _c(
            "base-button",
            {
              attrs: {
                disabled: !_vm.getLoggedUserInfos["can_send_follow_up_to_all"],
              },
              on: { click: _vm.openFollowUpToAllModal },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fas", "users"] } }),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get(
                      "generic.modals.follow_up_type_choice_all_clients"
                    )
                  ) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-separator", [_vm._v(_vm._s(_vm.trans.get("generic.or")))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1" },
        [
          _c(
            "base-button",
            { on: { click: _vm.openFollowUpToOneModal } },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fas", "user"] } }),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get(
                      "generic.modals.follow_up_type_choice_one_client"
                    )
                  ) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }