var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _vm.send_success
        ? _c(
            "base-alert",
            {
              staticClass: "top-5 right-0 fixed z-50",
              attrs: { dismissible: "", small: "", type: "success" },
            },
            [_vm._v(_vm._s(_vm.trans.get("sponsorship.sponsor_sent")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c(
            "base-alert",
            {
              staticClass: "top-5 right-0 fixed z-50",
              attrs: {
                dismissible: "",
                delay: 5000,
                small: "",
                type: "danger",
              },
            },
            [_vm._v(_vm._s(_vm.trans.get("generic.an_error_has_occurred")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.noEmail
        ? _c(
            "base-alert",
            {
              staticClass: "top-5 right-0 fixed z-50",
              attrs: {
                dismissible: "",
                delay: 5000,
                small: "",
                type: "warning",
              },
            },
            [_vm._v(_vm._s(_vm.trans.get("sponsorship.empty_input")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "py-0",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.sendEmail.apply(null, arguments)
            },
          },
        },
        [
          _vm._l(_vm.emails.keys(), function (i) {
            return _c("base-text-input", {
              key: i,
              staticClass: "pb-3",
              attrs: {
                error: _vm.errors[i],
                placeholder: _vm.getLabelInput,
                label: _vm.getLabelInput,
                type: "email",
                outlined: "",
                autocomplete: "off",
              },
              on: {
                input: function ($event) {
                  _vm.errors[i] = ""
                },
                blur: function ($event) {
                  return _vm.isValidEmail(i)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "envelope"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.emails[i],
                callback: function ($$v) {
                  _vm.$set(_vm.emails, i, $$v)
                },
                expression: "emails[i]",
              },
            })
          }),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "mx-auto my-2 underline",
              attrs: {
                link: "",
                color: "dark",
                "font-normal": "",
                "normal-case": "",
                disabled: _vm.canAddSponsored,
              },
              on: { click: _vm.addInput },
            },
            [_vm._v(_vm._s(this.trans.get("sponsorship.add_sponsored")))]
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "mt-5",
              attrs: { type: "submit", loading: _vm.loading, block: "" },
            },
            [_vm._v(_vm._s(_vm.trans.get("sponsorship.send_invitation")))]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }