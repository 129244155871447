var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-text-input", {
    ref: "text_input",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      label: _vm.label,
      outlined: _vm.outlined,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      value: _vm.value,
      error: _vm.displayError,
      inputmode: "tel",
    },
    on: {
      keydown: function ($event) {
        return _vm.$emit("keydown", $event)
      },
      blur: _vm.checkValue,
      focus: function ($event) {
        _vm.isInputActive = true
      },
    },
    scopedSlots: _vm._u(
      [
        !_vm.hideCountries
          ? {
              key: "prepend",
              fn: function () {
                return [
                  _c("base-select", {
                    attrs: {
                      items: _vm.countryItems,
                      disabled: _vm.disabled,
                      transparent: "",
                    },
                    on: { input: _vm.selectCountry },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _c("span", {
                                staticClass: "vti__flag inline-block",
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("+")]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "selected",
                          fn: function () {
                            return [
                              _c("span", {
                                staticClass: "vti__flag inline-block",
                                class: _vm.activeCountry.iso2.toLowerCase(),
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(`+${_vm.activeCountry.dialCode}`)
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c("span", {
                                staticClass: "vti__flag inline-block",
                                class: data.item.iso2.toLowerCase(),
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(data.item.name) +
                                    " " +
                                    _vm._s(`+${data.item.dialCode}`)
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      96708916
                    ),
                    model: {
                      value: _vm.activeCountry,
                      callback: function ($$v) {
                        _vm.activeCountry = $$v
                      },
                      expression: "activeCountry",
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.displayValue,
      callback: function ($$v) {
        _vm.displayValue = $$v
      },
      expression: "displayValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }