<template>
    <base-modal>
        <modal-heading>
            {{ trans.get('generic.an_error_has_occurred') }}
        </modal-heading>

        <modal-paragraph>{{ trans.get('generic.please_retry_later') }}</modal-paragraph>

        <div class="text-center my-8">
            <font-awesome-icon :icon="['fal', 'face-sad-tear']" class="text-primary" size="4x"/>
        </div>

        <div class="grid grid-cols-1 mt-5">
            <base-button autofocus @click="closeModal">
                {{ trans.get('generic.back_to_site') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import {mapActions} from "vuex";

export default {
    name: "ModalGenericError",
    components: {BaseButton, ModalParagraph, ModalHeading},
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
}
</script>
