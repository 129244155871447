import {makeEchoInstance} from '../../utilities/echo-factory';
import axios from 'axios';
import {ziggy_route} from '../ZiggyMixin';
import listeners from '../chat/listeners';

export default {
    namespaced: true,

    state: {
        loggedUserInfos: {},
    },

    mutations: {
        setUserInfos: (state, userInfos) => {
            state.loggedUserInfos = userInfos;
        },
    },
    getters: {
        getLoggedUserInfos: (state) => {
            return state.loggedUserInfos;
        },
        getUserPrivateChannel: (state, getters) => {
            if (getters.getLoggedUserInfos.id) {
                const echo = makeEchoInstance('App.Events.User');
                return echo.private(`user.${getters.getLoggedUserInfos.id}`);
            }
            return null;
        },
        getMasterPrivateChannel: (state, getters) => {
            if (getters.getLoggedUserInfos.id) {
                const echo = makeEchoInstance('App.Events.Master');
                return echo.private(`user.${getters.getLoggedUserInfos.id}`);
            }
            return null;
        },
    },
    actions: {
        loadUserInfos({commit}, userInfos) {
            commit('setUserInfos', userInfos);
        },
        fetchUserInfos({commit}) {
            return axios.get(ziggy_route('getLoggedUserInfos')).then(response => {
                commit('setUserInfos', response.data.user);
            }).catch(error => error);
        },
        disconnect({commit}) {
            commit('setUserInfos', {});
        },
        listenForUserUpdatedEvent({commit, getters}) {
            listeners.listen(getters.getUserPrivateChannel, 'UserUpdated', e => {
                commit('setUserInfos', e.userResource);
            });
        },
        listenForMasterMissedCallEvent({dispatch, getters}) {
            listeners.listen(getters.getMasterPrivateChannel, 'MasterMissedCall', e => {
                dispatch(
                    'modals/openModal',
                    {
                        name: globals.RECALL_CUSTOMER,
                        param: {
                            customer: {
                                nick_name: e.resource.customer_nick_name,
                                user_id: e.resource.client_id,
                                segment: e.resource.segment,
                            },
                            seconds: e.resource.seconds_left,
                            error: '',
                        },
                    },
                    {root: true},
                );
            });
        },
        updateUserCredits() {
            refresh_main_header_v4();
        }
    },
};
