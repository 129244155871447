<template>
    <component :is="tag">
        <slot :formattedTimer="formattedTimer"
              :years="years"
              :months="months"
              :days="days"
              :hours="hours"
              :minutes="minutes"
              :seconds="seconds"/>
    </component>
</template>

<script>
const MILLISECONDS_SECOND = 1000;
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND;
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE;
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR;
const MILLISECONDS_YEAR = 365 * MILLISECONDS_DAY;
const MILLISECONDS_MONTH = MILLISECONDS_YEAR / 12;

const EVENT_START = 'start';
const EVENT_PAUSE = 'pause';
const EVENT_STOP = 'stop';
const EVENT_END = 'end';
const EVENT_THRESHOLD = "threshold"

export default {
    name: "BaseTimer",
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        autoStart: {
            type: Boolean,
            default: false,
        },
        now: {
            type: Function,
            default: () => Date.now(),
        },
        time: {
            type: Number,
            default: 0
        },
        thresholdEvents: {
            type: Object,
            default() {
                return {}
            }
        },
        countdown: Boolean
    },
    data() {
        return {
            worker: null,
            start_at: null,
            initialTime: 0,
            currentTime: 0,
            totalMilliseconds: 0,
            hasSentThresholdEvent: [],
        };
    },
    emits: [
        EVENT_END,
        EVENT_PAUSE,
        EVENT_STOP,
        EVENT_START,
        EVENT_THRESHOLD,
    ],
    methods: {
        start() {
            clearInterval(this.worker);
            this.$emit(EVENT_START);

            this.totalMilliseconds = this.currentTime;
            this.start_at = this.now();

            this.worker = setInterval(() => {
                let delay = this.countdown ? this.start_at - this.now() : this.now() - this.start_at;

                if (this.currentTime + delay > 0) {
                    Object.entries(this.thresholdEvents).forEach(([eventKey, thresholdEvent]) => {
                        if (this.canSendThresholdEvent(eventKey)
                            && ((this.countdown && this.currentTime + delay <= thresholdEvent.time)
                                || (!this.countdown && this.currentTime + delay >= thresholdEvent.time))
                        ) {
                            this.sendThresholdEvent(eventKey);
                        }
                    });
                    this.totalMilliseconds = this.currentTime + delay;
                } else {
                    this.end();
                }
            }, 100);
        },
        pause() {
            clearInterval(this.worker);
            this.currentTime = this.totalMilliseconds;
            this.$emit(EVENT_PAUSE);
        },
        stop() {
            clearInterval(this.worker);
            this.currentTime = this.initialTime;
            this.totalMilliseconds = this.initialTime;
            this.$emit(EVENT_STOP);
        },
        end() {
            clearInterval(this.worker);
            this.totalMilliseconds = 0;
            this.$emit(EVENT_END);
        },
        sendThresholdEvent(eventKey) {
            this.hasSentThresholdEvent[eventKey] = true;
            this.$emit(EVENT_THRESHOLD, eventKey);
            this.thresholdEvents[eventKey].callback();
        },
        updateTimerValues(time) {
            this.initialTime = time;
            this.currentTime = time;
            this.totalMilliseconds = time;
            this.start_at = this.now();

            if (this.autoStart) {
                this.start();
            }
        },
        setTime(time) {
            this.updateTimerValues(time);
        },
        getFormattedTime() {
            return this.hours + ':' + this.minutes + ':' + this.seconds;
        },
        canSendThresholdEvent(eventKey) {
            return this.thresholdEvents[eventKey].time > 0 && !this.hasSentThresholdEvent[eventKey];
        },
        resetThresholdEvents() {
            this.hasSentThresholdEvent = [];
        },
    },
    computed: {
        formattedTimer() {
            const units = [
                {value: this.days, label: " " + this.trans.get('generic.days')},
                {value: this.hours, label: this.trans.get('generic.hours_short')},
                {value: this.minutes, label: this.trans.get('generic.minutes_short')},
                {value: this.seconds, label: this.trans.get('generic.seconds_short')}
            ];

            let formattedTimer = '';
            let hasPreviousValue = false;

            for (const {value, label} of units) {
                if (value > 0 || hasPreviousValue) {
                    formattedTimer += value + label + ' ';
                    hasPreviousValue = true;
                }
            }

            return formattedTimer.trim();
        },
        years() {
            return Math.floor(this.totalMilliseconds / MILLISECONDS_YEAR);
        },
        months() {
            return Math.floor((this.totalMilliseconds % MILLISECONDS_YEAR) / MILLISECONDS_MONTH);
        },
        days() {
            return Math.floor(this.totalMilliseconds / MILLISECONDS_DAY);
        },
        hours() {
            return Math.floor((this.totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR);
        },
        minutes() {
            let minutes = Math.floor((this.totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE);
            return minutes < 10 ? '0' + minutes : minutes;
        },
        seconds() {
            let seconds = Math.floor((this.totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND);
            return seconds < 10 ? '0' + seconds : seconds;
        },
    },
    created() {
        this.updateTimerValues(this.time);

        this.unwatchThresholdEventTime = [];
        Object.entries(this.thresholdEvents).forEach(([eventKey, thresholdEvent]) => {
            this.unwatchThresholdEventTime[eventKey] = this.$watch(
                function () {
                    return thresholdEvent.time;
                },
                function (newVal, oldVal) {
                    let delay = this.countdown ? this.start_at - this.now() : this.now() - this.start_at;
                    if (!((this.countdown && this.currentTime + delay <= thresholdEvent.time)
                        || (!this.countdown && this.currentTime + delay >= thresholdEvent.time))) {
                        this.hasSentThresholdEvent[eventKey] = false;
                    }
                }
            );

            this.hasSentThresholdEvent[eventKey] = false;
        });
    },
    beforeUnmount() {
        this.stop();
    },
    beforeDestroy() {
        this.unwatchThresholdEventTime.forEach((unwatch) => {
            unwatch();
        });
    }
}
</script>
