var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.displayList,
          expression: "displayList",
        },
      ],
    },
    [
      _c(
        "h1",
        {
          staticClass:
            "text-center text-primary lg:text-2xl text-xl font-bold mb-1",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.trans.get("generic.highlighted_masters")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "highlighted-offer-list-container" }, [
        _c(
          "div",
          { ref: "swiper", staticClass: "highlighted-offer-list swiper" },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              [
                _vm._l(_vm.cardsPerPage, function (n) {
                  return _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "swiper-slide" },
                        [
                          _c("offer-card-small-loader", {
                            key: "offer-card-loader-" + n,
                            ref: "offer-card-loader",
                            refInFor: true,
                          }),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.offers, function (offer) {
                  return _c(
                    "div",
                    { staticClass: "swiper-slide" },
                    [
                      _c("offer-card-small", {
                        key: offer.id,
                        attrs: {
                          offer: offer,
                          user: _vm.getLoggedUserInfos,
                          offer_list_type: _vm.offerListType,
                          is_prime_enabled: _vm.config.get("prime.enabled"),
                          is_free_chat_enabled_for_prospect: _vm.config.get(
                            "chat.free_chat_enabled_for_prospect"
                          ),
                          user_credits: _vm.getCredits,
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("base-infinite-pagination", { attrs: { slider: _vm.slider } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }