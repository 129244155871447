var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-center w-full" }, [
    _vm.isUserListeningToAudioDraft
      ? _c(
          "div",
          { staticClass: "flex justify-center w-full" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-8",
                attrs: { icon: "", large: "", color: "secondary" },
                on: { click: _vm.deleteRecord },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "trash-can"] },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow flex justify-center mr-8" },
              [
                _vm.hasUrl
                  ? _c("conversation-audio-record-player", {
                      attrs: {
                        url: _vm.audio.url,
                        duration: _vm.audio.duration,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: { icon: "", large: "", color: "primary" },
                on: { click: _vm.sendRecord },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "paper-plane"] },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "flex justify-center w-full" },
          [
            _c(
              "base-button",
              {
                attrs: { icon: "", large: "", color: "secondary" },
                on: { click: _vm.deleteRecord },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "trash-can"] },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow flex justify-center items-center" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-10 text-center" },
                  [
                    _c("base-timer", {
                      ref: "audioRecordTimer",
                      staticClass: "self-center",
                      attrs: {
                        "auto-start": "",
                        "threshold-events": _vm.thresholdEvents,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ minutes, seconds }) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(minutes) +
                                  ":" +
                                  _vm._s(seconds) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: { icon: "", large: "", color: "available" },
                on: { click: _vm.validateRecord },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-check"] },
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mr-3 flex" }, [
      _c("span", {
        staticClass:
          "animate-ping absolute inline-flex h-3 w-3 rounded-full bg-secondary opacity-75",
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "relative inline-flex rounded-full h-3 w-3 bg-secondary",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }