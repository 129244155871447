var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasItems
    ? _c(
        "div",
        { staticClass: "space-y-1" },
        [
          _vm._l(_vm.items, function (item) {
            return [
              _vm.isObjectItem(item)
                ? _c("base-list-item", {
                    scopedSlots: _vm._u(
                      [
                        _vm.itemHasIcon(item)
                          ? {
                              key: "icon",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: item.icon },
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        _vm.itemHasTitle(item)
                          ? {
                              key: "title",
                              fn: function () {
                                return [_vm._v(_vm._s(item.title))]
                              },
                              proxy: true,
                            }
                          : null,
                        _vm.itemHasDescription(item)
                          ? {
                              key: "description",
                              fn: function () {
                                return [_vm._v(_vm._s(item.description))]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  })
                : _c("base-list-item", [
                    _vm._v("\n            " + _vm._s(item) + "\n        "),
                  ]),
            ]
          }),
        ],
        2
      )
    : _c("div", { staticClass: "space-y-1" }, [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }