var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex py-2 pr-2 md:pl-4" }, [
    !this.hasLiveSessionBillingStarted()
      ? _c(
          "div",
          { staticClass: "flex-none self-center md:hidden" },
          [
            _c(
              "base-button",
              { attrs: { icon: "" }, on: { click: _vm.back } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "chevron-left"], size: "lg" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "flex-none self-center ml-2 mr-2" },
          [
            _c(
              "base-button",
              {
                attrs: { fab: "", outlined: "", small: "" },
                on: { click: _vm.displayModalCloseChat },
              },
              [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
              1
            ),
          ],
          1
        ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex-grow self-center min-w-10 cursor-pointer overflow-hidden",
        on: { click: _vm.toggleConversationInformations },
      },
      [
        _c(
          "p",
          {
            staticClass:
              "text-sm font-bold text-primary whitespace-nowrap overflow-ellipsis overflow-hidden",
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.client.name) + "\n            "
            ),
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "circle-info"] },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "text-sm text-neutral-500 whitespace-nowrap overflow-ellipsis overflow-hidden",
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["far", "cake-candles"], size: "1x" },
            }),
            _vm._v(
              " " +
                _vm._s(_vm._f("formatDate")(_vm.client.dt_birth)) +
                "\n        "
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    this.hasLiveSessionBillingStarted()
      ? _c("div", { staticClass: "flex-none self-center text-right" }, [
          _c(
            "p",
            { staticClass: "text-primary font-bold" },
            [
              _c("base-timer", {
                ref: "billing_timer",
                attrs: {
                  time: this.getLiveSessionBillingTime,
                  "auto-start": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ hours, minutes, seconds }) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(hours) +
                              ":" +
                              _vm._s(minutes) +
                              ":" +
                              _vm._s(seconds) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1240156881
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "text-neutral-400" },
            [
              _c("base-timer", {
                ref: "billing_timer_left",
                attrs: { countdown: "", "auto-start": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ formattedTimer }) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.trans.get("chat.billing_timer_left", {
                                  timer: formattedTimer,
                                })
                              ) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1647858434
                ),
              }),
            ],
            1
          ),
        ])
      : _c(
          "div",
          { staticClass: "flex-none self-center" },
          [
            _c(
              "base-menu",
              {
                attrs: { icon: "", "drop-left": "" },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _vm.canOfferMinutes
                          ? _c(
                              "base-menu-item",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "gift"] },
                                }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.trans.get("chat.offer_minutes", {
                                        minutes: 10,
                                      })
                                    ) +
                                    "\n                "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "base-menu-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.markConversationAsUnread(
                                  _vm.getSelectedParticipants
                                )
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fal", "envelope"] },
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.trans.get("generic.set_unread")) +
                                "\n                "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.canReportUser
                          ? _c(
                              "base-menu-item",
                              {
                                attrs: { color: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reportProblem()
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.trans.get("chat.report_user", {
                                        name: _vm.client.name,
                                      })
                                    ) +
                                    "\n                "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "base-button",
                  { attrs: { icon: "" } },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "ellipsis-vertical"], size: "lg" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }