<template>
    <development>
        <div>
            <development-heading>Textarea</development-heading>
            <base-textarea></base-textarea>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>id</strong>: String</li>
                <li><strong>rows</strong>: Number</li>
                <li><strong>maxRows</strong>: Number</li>
                <li><strong>rowHeight</strong>: Number</li>
                <li><strong>autocomplete</strong>: String</li>
                <li><strong>autofocus</strong>: Boolean</li>
                <li><strong>autoGrow</strong>: Boolean</li>
                <li><strong>disabled</strong>: Boolean</li>
                <li><strong>error</strong>: String</li>
                <li><strong>label</strong>: String</li>
                <li><strong>iconRight</strong>: Boolean</li>
                <li><strong>iconTop</strong>: Boolean</li>
                <li><strong>large</strong>: Boolean</li>
                <li><strong>name</strong>: String</li>
                <li><strong>outlined</strong>: Boolean</li>
                <li><strong>placeholder</strong>: String</li>
                <li><strong>readonly</strong>: Boolean</li>
                <li><strong>small</strong>: Boolean</li>
                <li><strong>value</strong>: String|Number</li>
                <li><strong>maxLength</strong>: Number</li>
            </ul>

            <development-heading>Placeholder</development-heading>
            <base-textarea outlined placeholder="Textarea with placeholder"></base-textarea>

            <development-heading>Label</development-heading>
            <base-textarea outlined label="Label"></base-textarea>

            <development-heading>Label + Placeholder</development-heading>
            <base-textarea outlined label="Focus me" placeholder="Placeholder"></base-textarea>

            <development-heading>Rows</development-heading>
            <base-textarea outlined :rows="10" label="10 rows" placeholder="10 rows"></base-textarea>

            <development-heading>AutoGrow</development-heading>
            <base-textarea outlined auto-grow placeholder="Textarea with AutoGrow"></base-textarea>

            <development-heading>RowHeight</development-heading>
            <base-textarea outlined :row-height="25" label="Row height 25" placeholder="Row height 25"></base-textarea>

            <development-heading>Outlined</development-heading>
            <base-textarea outlined placeholder="Outlined textarea"></base-textarea>

            <development-heading>Readonly</development-heading>
            <base-textarea readonly value="I am a readonly textarea"></base-textarea>

            <development-heading>Readonly + outlined</development-heading>
            <base-textarea readonly outlined value="I am an outlined readonly textarea"></base-textarea>

            <development-heading>Disabled</development-heading>
            <base-textarea disabled placeholder="Disabled textarea"></base-textarea>

            <development-heading>Large</development-heading>
            <base-textarea large placeholder="Large" outlined></base-textarea>

            <development-heading>Small</development-heading>
            <base-textarea small placeholder="Small input" outlined></base-textarea>

            <development-heading>With icon</development-heading>
            <base-textarea label="Textarea with icon" placeholder="Textarea with icon" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-textarea>

            <development-heading>With right icon</development-heading>
            <base-textarea label="Textarea with icon" icon-right placeholder="Textarea with icon" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-textarea>

            <development-heading>With top icon</development-heading>
            <base-textarea label="Textarea with icon" icon-top placeholder="Textarea with icon" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-textarea>

            <development-heading>With right + top icon</development-heading>
            <base-textarea label="Textarea with icon" icon-right icon-top placeholder="Textarea with icon" outlined>
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-textarea>

            <development-heading>Error</development-heading>
            <base-textarea error="This is an error" outlined label="Focus me" placeholder="Placeholder">
                <template #icon>
                    <font-awesome-icon :icon="['fal', 'envelope']"/>
                </template>
            </base-textarea>

            <development-heading>Conversation Input (Chat)</development-heading>
            <base-textarea ref="basetextarea"
                           outlined
                           :max-length="100"
                           :rows="1"
                           :max-rows="3"
                           auto-grow
                           :icon-right="true"
                           :placeholder="trans.get('generic.conversation_input_placeholder')">
                <template #icon><span class="conversation-input-gif-icon">GIF</span></template>
            </base-textarea>
        </div>
    </development>
</template>
<script>
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import BaseTextarea from "../elements/BaseTextarea";

export default {
    components: {BaseTextarea, DevelopmentHeading, Development}
}
</script>
