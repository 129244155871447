var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "video",
    {
      ref: "video",
      attrs: {
        width: "100%",
        height: "100%",
        autoplay: _vm.autoplay,
        loop: _vm.loop,
        muted: "",
        playsinline: "",
      },
      domProps: { muted: true },
    },
    [
      _vm.hasWebm
        ? _c("source", { attrs: { src: _vm.srcWebm, type: "video/webm" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMov
        ? _c("source", {
            attrs: { src: _vm.srcMov, type: 'video/mp4; codecs="hvc1"' },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }