var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasTime
        ? _c("base-timer", {
            attrs: { "auto-start": "", countdown: "", time: _vm.delay },
            on: { end: _vm.close },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }