var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conversation-hearts" },
    _vm._l(_vm.hearts, function (heart) {
      return _c("font-awesome-icon", {
        key: heart.id,
        staticClass: "conversation-heart",
        style: heart.style,
        attrs: { icon: ["fas", "heart"] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }