var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass:
        "absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 bg-danger text-white rounded-full py-0.5 px-2",
    },
    [_vm._v(_vm._s(_vm.amount))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }