<template>
    <div class="offer-card-medium">
        <div class="flex items-center w-full">
            <div class="flex-none pr-3">
                <a :href="offer.url">
                    <base-badge :color="statusColor" :distance="-0.4" outlined left dot block>
                        <base-avatar :photo="offer.photo"/>
                    </base-badge>
                </a>
            </div>
            <div class="flex-grow flex flex-col min-w-0">
                <a :href="offer.url">
                    <div class="flex-grow flex min-w-0">
                        <div class="flex-grow min-w-0">
                            <p class="offer-card-small-name">{{ offer.name }}</p>
                        </div>
                    </div>

                    <div class="flex-grow flex min-w-0">
                        <div class="flex-grow min-w-0">
                            <p class="offer-card-small-price">{{
                                    offer.cost_by_minute / 100 | formatMoneyPerMinute
                                }}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <offer-card-actions :offer="offer"
                            :user="user"
                            :offer_list_type="offer_list_type"
                            :is_prime_enabled="is_prime_enabled"
                            :is_free_chat_enabled_for_prospect="is_free_chat_enabled_for_prospect"
                            :user_credits="user_credits"
                            small/>
    </div>
</template>

<style lang="scss">
.offer-card-medium {
    @apply shadow-small bg-white rounded-2xl p-3;

    &-name {
        @apply font-bold text-primary whitespace-nowrap overflow-ellipsis overflow-hidden mb-1;
    }

    &-price {
        @apply text-primary whitespace-nowrap overflow-ellipsis overflow-hidden;
    }

    &-consultations-count {
        @apply flex-none text-main font-bold whitespace-nowrap;
    }

    .offer-card-actions {
        @apply flex mt-2 mb-1 gap-x-2 h-8;

        .offer-card-action {
            @apply w-full flex-1 min-w-0;
        }
    }
}
</style>

<script>
import BaseAvatar from "../elements/BaseAvatar";
import BaseBadge from "../elements/BaseBadge";
import BaseButton from "../elements/BaseButton";
import OfferMixin from "./OfferMixin";
import OfferCardActions from "./OfferCardActions.vue";

export default {
    name: 'OfferCardMedium',
    mixins: [OfferMixin],
    components: {OfferCardActions, BaseBadge, BaseButton, BaseAvatar},
    props: {
        offer: Object,
        user: Object | Array,
        offer_list_type: String,
        is_prime_enabled: Boolean,
        is_free_chat_enabled_for_prospect: Boolean,
        user_credits: Number,
    },
}
</script>
