var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "hidden lg:block",
      attrs: { href: _vm.ziggy_route("prime.page") },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-col justify-center text-left",
          staticStyle: { height: "55px" },
        },
        [
          _c("span", { staticClass: "prime-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "prime-subtitle" }, [
            _vm._v(_vm._s(_vm.subtitle)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }