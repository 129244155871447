import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment';

const default_currency = window.default_currency;
const default_exchange_rate = window.default_exchange_rate;
const default_locale = window.js_currency_lang + '-' + window.js_currency_country_code;

Vue.filter('formatMoney', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    if (typeof default_exchange_rate === "number") {
        value = value * default_exchange_rate;
    }

    let formatter = new Intl.NumberFormat(default_locale, {
        style: 'currency',
        currency: default_currency
    });

    return formatter.format(value);
});

Vue.filter('formatMoneyShort', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    if (typeof default_exchange_rate === "number") {
        value = value * default_exchange_rate;
    }

    let formatter = new Intl.NumberFormat(default_locale, {
        style: 'currency',
        currency: default_currency,
        maximumFractionDigits: (value % 1 !== 0) ? 2 : 0,
    });

    return formatter.format(value).replace(/\s€/, '€').replace(/US\$/, 'US $');
});

Vue.filter('formatMoneyPerMinute', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    if (typeof default_exchange_rate === "number") {
        value = value * default_exchange_rate;
    }

    let formatter = new Intl.NumberFormat(default_locale, {
        style: 'currency',
        currency: default_currency
    });

    return formatter.format(value).replace(/\s€/, '€') + "/min";
});

Vue.filter('formatMoneyPerCall', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    if (typeof default_exchange_rate === "number") {
        value = value * default_exchange_rate;
    }

    let formatter = new Intl.NumberFormat(default_locale, {
        style: 'currency',
        currency: default_currency
    });

    return formatter.format(value) + "/" + Vue.prototype.trans.get('call.call').toLowerCase();
});

Vue.filter('formatDate', function (value) {
    moment.locale(default_locale);

    if (!moment(value).isValid()) {
        return value;
    }
    if (value === null) {
        return null;
    }
    return moment(value).format('L');
});

Vue.filter('diffFromNow', function (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").fromNow();
});

Vue.filter('formatPhoneNumber', function (value, displayType = 'international', locale = default_locale) {
    if (value) {
        let formatter = new PhoneNumber(value.toString(), locale);
        return formatter.getNumber(displayType);
    }
})

Vue.filter('formatSecondsToTimer', function (value, format) {
    if (isNaN(value)) {
        value = 0;
    }

    let minutes = Math.floor(value / 60);
    let seconds = Math.floor(value - minutes * 60);
    return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
});
