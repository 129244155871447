var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4" }, [
    _c("div", { staticClass: "base-separator" }, [
      _c(
        "div",
        { staticClass: "base-separator-content" },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }