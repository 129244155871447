var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.componentClass }, [
    _c(
      "div",
      { class: _vm.wrapperClass },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("span", { class: _vm.badgeClass, style: _vm.badgeStyles }, [
          _vm._v(_vm._s(_vm.content)),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }