<template>
    <div :class="componentClass">
        <div :class="wrapperClass">
            <slot/>
            <span :class="badgeClass" :style="badgeStyles">{{ content }}</span>
        </div>
    </div>
</template>

<style lang="scss">
.base-badge {
    @apply inline-block leading-none;

    &--block {
        @apply block;
    }

    &-wrapper {
        @apply flex relative;

        &--inline {
            @apply justify-center items-center inline-flex;
        }
    }

    &-badge {
        @apply h-6 leading-6 min-w-6 p-1 absolute bg-primary text-white text-center text-sm justify-center font-bold inline-flex items-center whitespace-nowrap rounded-full uppercase pointer-events-none;

        &--color {
            &-primary {
                @apply bg-primary text-white;
            }

            &-secondary {
                @apply bg-secondary text-white;
            }

            &-available {
                @apply bg-status-available text-white;
            }

            &-busy {
                @apply bg-status-busy text-white;
            }

            &-offline {
                @apply bg-status-offline text-white;
            }

            &-neutral {
                @apply bg-neutral-500 text-white;
            }

            &-light {
                @apply bg-white text-primary;
            }

            &-white {
                @apply bg-white text-neutral-500;
            }

            &-danger {
                @apply bg-danger text-white;
            }

            &-prime {
                @apply bg-prime text-white;
            }

            &-facebook {
                @apply bg-facebook text-white;
            }

            &-whatsapp {
                @apply bg-whatsapp text-white;
            }

            &-horoscope {
                @apply bg-horoscope text-white;
            }
        }

        &--dot {
            @apply h-3 min-w-3 p-0;
        }

        &--square {
            @apply rounded-md;

            &.base-badge-badge--dot {
                @apply rounded-sm;
            }
        }

        &--outlined {
            @apply border-2 border-white;
        }

        &--inline {
            @apply relative align-middle;
        }
    }
}
</style>

<script>
export default {
    name: 'BaseBadge',
    props: {
        color: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'available', 'busy', 'offline', 'neutral', 'light', 'white', 'danger', 'prime', 'facebook', 'whatsapp', 'horoscope'].indexOf(value) !== -1;
            },
        },
        distance: {
            type: String | Number,
            default: 0
        },
        block: Boolean,
        bottom: Boolean,
        center: Boolean,
        content: String | Number,
        dot: Boolean,
        floating: Boolean,
        inline: Boolean,
        left: Boolean,
        outlined: Boolean,
        square: Boolean,
    },
    computed: {
        componentClass() {
            return {
                'base-badge': true,
                'base-badge--block': this.block,
            };
        },
        wrapperClass() {
            return {
                'base-badge-wrapper': true,
                'base-badge-wrapper--inline': this.inline,
            };
        },
        badgeStyles() {
            let styles = {}

            let distance = !isNaN(parseFloat(this.distance)) ? parseFloat(this.distance) : 0;

            if (this.inline) {
                styles['margin-left'] = distance + 'rem';
                return styles;
            }

            if (this.left) {
                styles['right'] = 'calc(100% - ' + ((this.dot ? 0.375 : 0.75) - distance) + 'rem)';
            } else if (this.center) {
                styles['left'] = '50%';
            } else {
                styles['left'] = 'calc(100% - ' + ((this.dot ? 0.375 : 0.75) - distance) + 'rem)';
            }

            if (this.bottom) {
                styles['bottom'] = (0 - distance) + 'rem';
            } else {
                styles['bottom'] = 'calc(100% - ' + ((this.dot ? 0.375 : 0.75) - distance) + 'rem)';
            }

            if (this.center && this.bottom) {
                styles['transform'] = 'translate(-50%, 50%)';
            } else if (this.center) {
                styles['transform'] = 'translateX(-50%)';
            } else if (this.bottom) {
                styles['transform'] = 'translateY(50%)';
            }

            return styles;
        },
        badgeClass() {
            return {
                'base-badge-badge': true,
                'base-badge-badge--color-primary': this.color === 'primary',
                'base-badge-badge--color-secondary': this.color === 'secondary',
                'base-badge-badge--color-available': this.color === 'available',
                'base-badge-badge--color-busy': this.color === 'busy',
                'base-badge-badge--color-offline': this.color === 'offline',
                'base-badge-badge--color-neutral': this.color === 'neutral',
                'base-badge-badge--color-light': this.color === 'light',
                'base-badge-badge--color-white': this.color === 'white',
                'base-badge-badge--color-danger': this.color === 'danger',
                'base-badge-badge--color-prime': this.color === 'prime',
                'base-badge-badge--color-facebook': this.color === 'facebook',
                'base-badge-badge--color-whatsapp': this.color === 'whatsapp',
                'base-badge-badge--color-horoscope': this.color === 'horoscope',
                'base-badge-badge--dot': this.dot,
                'base-badge-badge--square': this.square,
                'base-badge-badge--outlined': this.outlined,
                'base-badge-badge--right': !this.left && !this.inline,
                'base-badge-badge--left': this.left && !this.inline,
                'base-badge-badge--inline': this.inline,
            };
        }
    }
    ,
}
;
</script>
