var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { small: "" } }, [
    _c(
      "form",
      { on: { submit: _vm.submitCode } },
      [
        _c("modal-heading", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get(
                  "generic.modals.user_phone_verification_code.confirm_your_number_to_start_the_chat"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "modal-paragraph",
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.trans.get(
                    "generic.modals.user_phone_verification_code.enter_the_code_received_on_your_mobile_phone",
                    { phone_number: _vm.userPhoneNumber }
                  )
                ) +
                "\n            "
            ),
            _c(
              "base-button",
              {
                staticClass: "underline text-dark hover:text-dark",
                attrs: { "font-normal": "", text: "", lowercase: "" },
                on: { click: _vm.editPhoneNumber },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("generic.edit")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "modal-paragraph",
          [
            _c("base-code-input", {
              ref: "codeInput",
              attrs: { autofocus: "", placeholder: "X", error: _vm.codeError },
              on: { change: _vm.checkCodeComplete },
              model: {
                value: _vm.codeValue,
                callback: function ($$v) {
                  _vm.codeValue = $$v
                },
                expression: "codeValue",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "modal-paragraph",
          [
            _c(
              "base-button",
              {
                staticClass: "underline text-dark hover:text-dark",
                attrs: { "font-normal": "", text: "", "normal-case": "" },
                on: { click: _vm.resendCode },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.trans.get(
                        "generic.modals.user_phone_verification_code.resend_a_code"
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("modal-paragraph", [_vm._v(_vm._s(_vm.anotherCodeWasSent))]),
        _vm._v(" "),
        _c("modal-paragraph", { staticClass: "mt-4" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get(
                  "generic.modals.user_phone_verification_code.contact_customer_service_if_there_is_a_problem",
                  {
                    phone_number_customer_care: _vm.config.get(
                      "app.phone_number_customer_care"
                    ),
                  }
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6" },
          [
            _c(
              "base-button",
              {
                attrs: {
                  type: "submit",
                  disabled: !_vm.codeComplete,
                  loading: _vm.loading,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("generic.validate")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }