<template>
    <base-modal>
        <modal-heading>{{ trans.get('generic.modals.follow_up_to_all_title') }}</modal-heading>

        <modal-paragraph>{{ trans.get('generic.modals.follow_up_to_all_text') }}</modal-paragraph>

        <base-textarea
            v-model="messageText"
            :error="errorMessageText"
            :label="this.trans.get('generic.your_message')"
            :placeholder="this.trans.get('generic.your_message')"
            :rows="5"
            autofocus
            outlined
            icon-top
        >
            <template #icon>
                <font-awesome-icon :icon="['fal', 'message-lines']" />
            </template>
        </base-textarea>

        <div class="grid grid-cols-1 mt-5">
            <base-button :disabled="disabled" @click="sendFollowUpToAll">
                {{ trans.get('generic.modals.follow_up_to_all_button_text', {nb_clients: this.nbClients}) }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BaseTextarea from '../elements/BaseTextarea';
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters} from 'vuex';
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: 'ModalFollowUpToAll',
    components: {BaseButton, BaseTextarea, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('chat', ['mainChatComponentIsMounted']),
        disabled()
        {
            return this.messageText.length < this.minLength || this.hasClicked;
        },
    },
    methods: {
        ...mapActions('modals', ['openModalMessage']),
        ...mapActions('user', ['fetchUserInfos']),
        async sendFollowUpToAll()
        {
            this.hasClicked = true;

            axios.post(ziggy_route('chat.follow-up.sendToAllCustomers'), {
                content: this.messageText,
            }).then(r => this.openModalMessage({
                title: this.trans.get('generic.modals.follow_up_to_all_title'),
                description: this.trans.get('generic.modals.follow_up_to_all_sent_text'),
                icon: ['far', 'circle-check'],
                buttonText: this.trans.get('chat.back_to_chat_list'),
            })).catch(r => this.openModalMessage({
                title: this.trans.get('generic.an_error_has_occurred'),
                description: this.trans.get('generic.modals.follow_up_could_not_be_sent'),
                icon: ['far', 'face-frown'],
                buttonText: this.trans.get('chat.back_to_chat_list'),
            }));

            await this.fetchUserInfos();
        },
    },
    data()
    {
        return {
            nbClients: 0,
            messageText: '',
            errorMessageText: '',
            minLength: this.config.get('app.min_length_follow_up_to_all_message'),
            hasClicked: false,
        };
    },
    mounted()
    {
        axios.get(ziggy_route('chat.follow-up.getEligibleCustomersCount')).
              then(response => this.nbClients = response.data.data).catch(error => console.log(error));
    },
};
</script>
