<template>
    <div class="conversation-message-content-sticker">
        <div class="conversation-message-content-sticker-thumbnail">
            <img :src="message.content.thumbnail"/>
            <span class="conversation-message-content-sticker-status">
                <conversation-message-metadata :status="messageStatus"/>
            </span>
        </div>
    </div>
</template>

<style lang="scss">
.conversation-message-content-sticker {
    &-thumbnail {
        @apply relative h-32 w-32;
    }

    &-status {
        @apply absolute pl-1 bottom-0.5 right-2;
    }
}
</style>

<script>
import ConversationMessageMetadata from "./ConversationMessageMetadata.vue";

export default {
    name: "ConversationMessageContentSticker",
    components: {ConversationMessageMetadata},
    props: {
        message: {
            type: Object
        },
        messageStatus: String,
    },
}
</script>
