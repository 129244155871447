var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conversation-search" }, [
    _c(
      "div",
      { staticClass: "conversation-search-header" },
      [
        _c(
          "base-button",
          {
            staticClass: "conversation-search-close-button",
            attrs: { icon: "" },
            on: {
              click: function ($event) {
                return _vm.toggleConversationSearch(false)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "chevron-left"] },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("base-text-input", {
          ref: "searchInput",
          staticClass: "conversation-search-input",
          attrs: {
            type: "text",
            placeholder: this.trans.get("generic.search_for_a_master"),
            outlined: "",
            small: "",
          },
          on: { input: _vm.search },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "magnifying-glass"] },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  _vm.hasSearchValue
                    ? _c(
                        "base-button",
                        {
                          attrs: { icon: "", small: "" },
                          on: { click: _vm.clearSearch },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "text-neutral-300",
                            attrs: { icon: ["fas", "circle-xmark"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.searchValue,
            callback: function ($$v) {
              _vm.searchValue = $$v
            },
            expression: "searchValue",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "conversation-search-items",
        on: { scroll: _vm.blurInput },
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "text-center p-4" },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-primary",
                  attrs: { icon: ["fas", "spinner"], spin: "", size: "3x" },
                }),
              ],
              1
            )
          : _vm.hasSearchValue
          ? [
              _vm.hasSearchOffers
                ? [
                    _c("h2", { staticClass: "conversation-search-heading" }, [
                      _vm._v(_vm._s(_vm.trans.get("generic.results"))),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.searchOffers, function (conversation) {
                      return [
                        _c("conversation-search-item", {
                          staticClass: "mb-2",
                          attrs: { conversation: conversation },
                        }),
                      ]
                    }),
                  ]
                : [
                    _c("h2", { staticClass: "conversation-search-heading" }, [
                      _vm._v(_vm._s(_vm.trans.get("generic.no_results"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.trans.get(
                            "chat.search.no_results_for_the_search_for_masters"
                          )
                        )
                      ),
                    ]),
                  ],
            ]
          : [
              _vm.hasFavoriteOffers
                ? [
                    _c("h2", { staticClass: "conversation-search-heading" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans.get("generic.favorite_masters")) +
                          " "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.favoriteOffers, function (conversation) {
                      return [
                        _c("conversation-search-item", {
                          staticClass: "mb-2",
                          attrs: { conversation: conversation },
                        }),
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAvailableOffers
                ? [
                    _c("h2", { staticClass: "conversation-search-heading" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.trans.get("generic.masters_online")) +
                          " "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableOffers, function (conversation) {
                      return [
                        _c("conversation-search-item", {
                          staticClass: "mb-2",
                          attrs: { conversation: conversation },
                        }),
                      ]
                    }),
                  ]
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }