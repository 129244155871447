var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reset-this home-top-sellers-list-container" },
    [
      _c(
        "div",
        { ref: "home-top-sellers-list", staticClass: "home-top-sellers-list" },
        [
          _vm._l(_vm.cardsPerPage, function (n) {
            return _vm.loading
              ? _c("offer-card-loader", {
                  key: "offer-card-loader-" + n,
                  ref: "offer-card-loader",
                  refInFor: true,
                  staticClass: "home-top-sellers-list-card",
                })
              : _vm._e()
          }),
          _vm._v(" "),
          _vm._l(_vm.offers, function (offer, index) {
            return _c("offer-card", {
              key: offer.id,
              staticClass: "home-top-sellers-list-card",
              attrs: {
                number: index + 1,
                offer: offer,
                user: _vm.getLoggedUserInfos,
                user_credits: _vm.getCredits,
                is_prime_enabled: _vm.config.get("prime.enabled"),
                is_free_chat_enabled_for_prospect: _vm.config.get(
                  "chat.free_chat_enabled_for_prospect"
                ),
                offer_list_type: "top_sellers",
              },
            })
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }