<template>
    <development>
        <div>
            <development-heading>Webm player</development-heading>

            <div class="conversation-messages-list-container conversation-messages-list-container--background h-100">
                <base-video-player :src-webm="assetManager.getVideoAsset('example/' + filename + '.webm')"
                                   :src-mov="assetManager.getVideoAsset('example/' + filename + '.mov')"
                                   ref="player"
                                   class="h-full"
                                   @play="addLog('play')"
                                   @pause="addLog('pause')"
                                   @end="addLog('end')"
                                   @loadstart="addLog('loadstart')"
                                   @stop="addLog('stop')"
                                   @canplaythrough="addLog('canplaythrough')"
                />
            </div>

            <div class="flex justify-between gap-2 mt-2">
                <div class="flex-1 flex flex-col gap-2">
                    <base-button @click="play">Play</base-button>
                    <base-button @click="pause">Pause</base-button>
                    <base-button @click="stop">Stop</base-button>
                    <base-select v-model="filename" outlined label="Video" :items="videos" @input="changeVideo"/>
                </div>

                <div class="flex-1">
                    <base-textarea v-model="logs" label="Logs" placeholder="Logs" class="w-full"
                                   :rows="8" disabled outlined/>
                </div>
            </div>

            <development-heading :level="2">Props :</development-heading>
            <ul>
                <li><strong>src</strong>: String</li>
                <li><strong>loop</strong>: Boolean</li>
                <li><strong>autoplay</strong>: Boolean</li>
            </ul>

            <development-heading :level="2">Events :</development-heading>
            <ul>
                <li><strong>canplaythrough</strong>: The browser estimates it can play the media up to its end without
                    stopping for content buffering.
                </li>
                <li><strong>play</strong>: Playback has begun.</li>
                <li><strong>pause</strong>: Playback has been paused.</li>
                <li><strong>end</strong>: Playback has stopped because the end of the media was reached.</li>
            </ul>

            <development-heading :level="2">Loop & autoplay</development-heading>
            <base-video-player :src-webm="assetManager.getVideoAsset('example/video-example.webm')"
                               :src-mov="assetManager.getVideoAsset('example/video-example.mov')"
                               loop autoplay/>

            <development-heading :level="2">Animation player example</development-heading>
            <development-paragraph>The player will play the video once and self-destruct.</development-paragraph>
            <base-video-player :src-webm="assetManager.getVideoAsset('example/video-example.webm')"
                               :src-mov="assetManager.getVideoAsset('example/video-example.mov')"
                               v-show="showAnimationPlayer"
                               ref="animation-player"
                               @canplaythrough="animationPlayerCanplaythrough"
                               @end="animationPlayerEnd"
            />
        </div>
    </development>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import BaseVideoPlayer from "../elements/BaseVideoPlayer";
import Development from "./Development";
import DevelopmentHeading from "./elements/DevelopmentHeading";
import DevelopmentParagraph from "./elements/DevelopmentParagraph";
import BaseAudioPlayer from "../elements/BaseAudioPlayer.vue";
import BaseSelect from "../elements/BaseSelect.vue";
import BaseTextarea from "../elements/BaseTextarea.vue";

export default {
    components: {
        BaseTextarea,
        BaseSelect,
        BaseAudioPlayer, BaseButton, BaseVideoPlayer, DevelopmentParagraph, DevelopmentHeading, Development
    },
    methods: {
        play() {
            this.$refs.player.play();
        },
        pause() {
            this.$refs.player.pause();
        },
        stop() {
            this.$refs.player.stop();
        },
        changeVideo() {
            this.$refs.player.load();
        },
        animationPlayerCanplaythrough() {
            this.showAnimationPlayer = true;
            this.$refs['animation-player'].play();
        },
        animationPlayerEnd() {
            this.showAnimationPlayer = false;
        },
        addLog(log) {
            this.logs = log + "\n" + this.logs;
        },
    },
    mounted() {
    },
    data() {
        return {
            showAnimationPlayer: false,
            videos: ['video-example', 'aquarius-sign', 'good-luck', 'evil-eye'],
            filename: 'video-example',
            logs: "",
        }
    }
}
</script>
