<template>
    <div class="w-full flex py-2 pr-2 md:pl-4">
        <div v-if="!this.hasLiveSessionBillingStarted()" class="flex-none self-center md:hidden">
            <base-button icon @click="back">
                <font-awesome-icon :icon="['fal', 'chevron-left']" size="lg"/>
            </base-button>
        </div>
        <div v-else class="flex-none self-center ml-2 mr-2">
            <base-button fab outlined small @click="displayModalCloseChat">
                <font-awesome-icon :icon="['fal', 'xmark']"/>
            </base-button>
        </div>

        <div class="flex-grow self-center min-w-10 cursor-pointer overflow-hidden"
             @click="toggleConversationInformations">
            <p class="text-sm font-bold text-primary whitespace-nowrap overflow-ellipsis overflow-hidden">
                {{ client.name }}
                <font-awesome-icon :icon="['fas', 'circle-info']"/>
            </p>
            <p class="text-sm text-neutral-500 whitespace-nowrap overflow-ellipsis overflow-hidden">
                <font-awesome-icon :icon="['far', 'cake-candles']" size="1x"/> {{ client.dt_birth | formatDate }}
            </p>
        </div>

        <div v-if="this.hasLiveSessionBillingStarted()" class="flex-none self-center text-right">
            <p class="text-primary font-bold">
                <base-timer ref="billing_timer" :time="this.getLiveSessionBillingTime" auto-start
                            v-slot="{hours, minutes, seconds}">
                    {{ hours }}:{{ minutes }}:{{ seconds }}
                </base-timer>
            </p>
            <p class="text-neutral-400">
                <base-timer ref="billing_timer_left" countdown auto-start v-slot="{formattedTimer}">
                    {{ trans.get('chat.billing_timer_left', {timer: formattedTimer}) }}
                </base-timer>
            </p>
        </div>
        <div v-else class="flex-none self-center">
            <base-menu icon drop-left>
                <base-button icon>
                    <font-awesome-icon :icon="['far', 'ellipsis-vertical']" size="lg"/>
                </base-button>
                <template #items>
                    <base-menu-item v-if="canOfferMinutes">
                        <font-awesome-icon :icon="['far', 'gift']"/>
                        {{ trans.get('chat.offer_minutes', {minutes: 10}) }}
                    </base-menu-item>
                    <base-menu-item @click="markConversationAsUnread(getSelectedParticipants)">
                        <font-awesome-icon :icon="['fal', 'envelope']"/>
                        {{ trans.get('generic.set_unread') }}
                    </base-menu-item>
                    <base-menu-item v-if="canReportUser" @click="reportProblem()" color="danger">
                        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                        {{ trans.get('chat.report_user', {name: client.name}) }}
                    </base-menu-item>
                </template>
            </base-menu>
        </div>
    </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import BaseButton from "../elements/BaseButton";
import BaseTimer from "../elements/BaseTimer";
import ChatMixin from "./ChatMixin";
import BaseMenu from "../elements/BaseMenu";
import BaseMenuItem from "../elements/BaseMenuItem";
import requests from "./requests";

export default {
    name: "ConversationHeaderClientInformationDisplay",
    components: {BaseMenuItem, BaseMenu, BaseButton, BaseTimer},
    mixins: [ChatMixin],
    computed: {
        ...mapGetters('chat', [
            'getLiveSessionBillingTime',
            'getLiveSessionBillingTimeLeft',
            'getSelectedParticipants',
            'iAmMaster',
            'isLiveConversation',
        ]),
        client: {
            get() {
                return this.interlocutor();
            },
        },
        canOfferMinutes() {
            return false;
        },
        canReportUser() {
            return this.getChatLiveSessionId();
        },
    },
    methods: {
        ...mapGetters('chat', ['hasLiveSessionBillingStarted', 'getLiveSessionMaxBillingTime']),
        ...mapActions('chat', ['closeConversation', 'markConversationAsUnread', 'toggleConversationInformations']),
        ...mapActions('modals', ['openModal']),
        back() {
            this.closeConversation();
            history.pushState(null, "chat_v2", "chat_v2");
        },
        displayModalCloseChat() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_CONFIRMATION_END_CHAT_SESSION
            });
        },
        reportProblem() {
            this.openModal({
                name: globals.CONVERSATION_REPORT,
                param: {
                    conversation_id: this.getChatLiveSessionId(),
                    conversation_type: 'chat',
                }
            });
        },
        reportUser() {
            requests.reportProblem({sessionId: this.getChatLiveSessionId()},
                () => {
                    this.openModal({
                        name: globals.CHAT_REPORT_PROBLEM
                    });
                },
                (errorCallback) => {
                    console.log('fail to report watch Network');
                    console.log(errorCallback);
                }
            );
        }
    },
    created() {
        this.unwatchChatSessionLive = this.$store.watch(this.hasLiveSessionBillingStarted, () => {
            if (this.hasLiveSessionBillingStarted()) {
                this.$nextTick(() => {
                    this.$refs.billing_timer_left.setTime(this.getLiveSessionBillingTimeLeft);
                    this.$refs.billing_timer.setTime(this.getLiveSessionBillingTime);
                    this.$refs.billing_timer.start();
                });
            }
        }, {immediate: true});

        this.unwatchMaxBillingTime = this.$store.watch(this.getLiveSessionMaxBillingTime, () => {
            if (this.hasLiveSessionBillingStarted()) {
                this.$nextTick(() => {
                    this.$refs.billing_timer_left.setTime(this.getLiveSessionBillingTimeLeft);
                });
            }
        });
    },
    beforeDestroy() {
        this.unwatchChatSessionLive();
        this.unwatchMaxBillingTime();
    }
}
</script>
