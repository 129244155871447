var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(_vm._s(_vm.trans.get("generic.password_lost_title"))),
      ]),
      _vm._v(" "),
      _vm.postFormSucceed
        ? _c(
            "div",
            [
              _c("modal-paragraph", [
                _vm._v(_vm._s(_vm.trans.get("generic.email_sent"))),
              ]),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: { autofocus: "", block: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          )
        : _c("div", [
            _c(
              "form",
              { on: { submit: _vm.postForm } },
              [
                _c("modal-paragraph", [
                  _vm._v(_vm._s(_vm.trans.get("generic.enter_email_address"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("base-text-input", {
                      attrs: {
                        type: "email",
                        autofocus: "",
                        outlined: "",
                        label: _vm.trans.get("generic.email"),
                        placeholder: _vm.trans.get("generic.email"),
                        error: _vm.errorEmail,
                      },
                      on: {
                        keydown: function ($event) {
                          _vm.errorEmail = ""
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "envelope"] },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid grid-cols-2 gap-4" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { type: "button", outlined: "" },
                        on: { click: _vm.cancel },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.cancel")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      { attrs: { type: "submit", loading: _vm.formLoading } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.validate")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }