var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { small: _vm.small } },
    [
      _vm.hasTitle
        ? _c("modal-heading", [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.src, alt: _vm.alt } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _c("base-button", { on: { click: _vm.closeModal } }, [
            _vm._v(_vm._s(_vm.buttonText)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }