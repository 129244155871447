<template>
    <component
        :is="componentType"
        :class="classes"
        :disabled="disabled || loading || skeletonLoader"
        :href="href"
        :type="buttonType"
        ref="button"
        v-on="$listeners">
        <font-awesome-icon v-if="loading" :icon="['fas', 'spinner']" spin class="border-none"/>
        <template v-else-if="skeletonLoader">&nbsp;</template>
        <slot v-else/>
    </component>
</template>

<style lang="scss">
a.base-button, button.base-button {
    @apply justify-center font-bold focus:outline-none rounded uppercase;

    &--normal-case {
        @apply normal-case;
    }

    &--lowercase {
        @apply lowercase;
    }

    &--font-normal {
        @apply font-normal;
    }

    &--outlined {
        @apply border bg-transparent hover:bg-outlined-hover;
    }

    &:not(.base-button--outlined):not(.base-button--text):not(.base-button--icon):not(.base-button--link) {
        @apply border-none hover:text-white;
    }

    &--link {
        @apply border-none bg-transparent hover:bg-transparent flex items-center;
    }

    &--block {
        @apply flex flex-1 min-w-full;
    }

    &--color {
        &-primary:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--icon):not(.base-button--link):not(.base-button--loading) {
                @apply bg-primary text-white hover:bg-primary-hover focus:bg-primary;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-primary text-primary hover:text-primary;
            }

            &.base-button--link {
                @apply p-0 text-primary hover:text-primary-hover;
            }
        }

        &-secondary:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-secondary text-white hover:bg-secondary-hover focus:bg-secondary;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-secondary text-secondary hover:text-secondary;
            }

            &.base-button--link {
                @apply p-0 text-secondary hover:text-secondary-hover;
            }
        }

        &-available:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-status-available text-white hover:bg-status-available-hover focus:bg-status-available;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-status-available text-status-available hover:text-status-available;
            }

            &.base-button--link {
                @apply p-0 text-status-available hover:text-status-available-hover;
            }
        }

        &-busy:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-status-busy text-white hover:bg-status-busy focus:bg-status-busy;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-status-busy text-status-busy hover:text-status-busy;
            }

            &.base-button--link {
                @apply p-0 text-status-busy hover:text-status-busy;
            }
        }

        &-offline:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-status-offline text-white hover:bg-status-offline focus:bg-status-offline;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-status-offline text-status-offline hover:text-status-offline;
            }

            &.base-button--link {
                @apply p-0 text-status-offline hover:text-status-offline;
            }
        }

        &-neutral:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-neutral-500 text-white hover:bg-neutral-800 focus:bg-neutral-500;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-neutral-500 text-neutral-500 hover:text-neutral-500;
            }

            &.base-button--link {
                @apply p-0 text-neutral-500 hover:text-neutral-800;
            }
        }

        &-neutral-secondary:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-neutral-300 text-white hover:bg-neutral-500 focus:bg-neutral-500;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-neutral-300 text-neutral-300 hover:text-neutral-300;
            }

            &.base-button--link {
                @apply p-0 text-neutral-300 hover:text-neutral-500;
            }
        }

        &-light:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-white text-primary hover:bg-neutral-100 focus:bg-white;
            }

            &.base-button--outlined {
                @apply border-primary text-primary hover:text-primary;
            }

            &.base-button--icon {
                @apply border-white text-white hover:text-white;
            }

            &.base-button--link {
                @apply p-0 text-primary hover:text-primary;
            }
        }

        &-white:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-white text-neutral-500 hover:bg-neutral-100 focus:bg-white;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-white text-white hover:text-white;
            }

            &.base-button--link {
                @apply p-0 text-white hover:text-neutral-100;
            }
        }

        &-danger:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-danger text-white hover:bg-danger focus:bg-danger;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-danger text-danger hover:text-danger;
            }

            &.base-button--link {
                @apply p-0 text-danger hover:text-danger;
            }
        }

        &-prime:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-prime text-white hover:bg-prime-hover focus:bg-prime;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-prime text-prime hover:text-prime;
            }

            &.base-button--link {
                @apply p-0 text-prime hover:text-prime-hover;
            }
        }

        &-facebook:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-facebook text-white hover:bg-primary-hover focus:bg-facebook;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-facebook text-facebook hover:text-primary-hover;
            }

            &.base-button--link {
                @apply p-0 text-facebook hover:text-primary-hover;
            }
        }

        &-whatsapp:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-whatsapp text-white hover:bg-whatsapp focus:bg-whatsapp;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-whatsapp text-whatsapp hover:text-whatsapp;
            }

            &.base-button--link {
                @apply p-0 text-whatsapp hover:text-whatsapp;
            }
        }

        &-horoscope:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-horoscope text-white hover:bg-horoscope focus:bg-horoscope;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-horoscope text-horoscope hover:text-horoscope;
            }

            &.base-button--link {
                @apply p-0 text-horoscope hover:text-horoscope;
            }
        }

        &-dark:not(.base-button--disabled) {
            &:not(.base-button--outlined):not(.base-button--icon):not(.base-button--text):not(.base-button--link):not(.base-button--loading) {
                @apply bg-dark text-white hover:bg-dark-hover focus:bg-dark;
            }

            &.base-button--outlined, &.base-button--icon {
                @apply border-dark text-dark hover:text-dark;
            }

            &.base-button--link {
                @apply p-0 text-dark hover:text-dark-hover;
            }
        }
    }

    &--pill {
        @apply rounded-full;
    }

    &--small {
        @apply px-4 py-2 text-xs leading-3;
        &.base-button--pill {
            @apply rounded-full;
        }

        &.base-button--fab, &.base-button--icon {
            @apply h-8 w-8 p-0 text-base leading-4 rounded-full;
        }
    }

    &--medium {
        @apply px-6 py-3 text-sm leading-3.5;
        &.base-button--fab, &.base-button--icon {
            @apply h-12 w-12 p-0 text-lg rounded-full;
        }
    }

    &--large {
        @apply px-10 py-4 text-sm leading-3.5;
        &.base-button--fab, &.base-button--icon {
            @apply h-16 w-16 p-0 text-2xl rounded-full;
        }
    }

    &--text {
        @apply bg-transparent p-0 border-0;
        &:hover {
            @apply bg-transparent;
        }
    }

    &:disabled, &--disabled, &--loading:not(.base-button--outlined) {
        @apply bg-disabled text-neutral-400 pointer-events-none;

        &.base-button--link {
            @apply p-0 bg-transparent
        }
    }

    &--skeleton-loader {
        @apply animate-pulse pointer-events-none;
    }

    &--loading {
        &.base-button--outlined {
            @apply bg-transparent;
        }
    }

    &--icon {
        @apply bg-transparent border-none;

        &:hover, &:disabled, &:focus, &:active {
            @apply bg-transparent;
        }
    }

    &--href {
        @apply inline-block;
    }
}
</style>

<script>
import BasePin from './BasePin';

export default {
    name: 'BaseButton',
    components: {BasePin},
    props: {
        type: {
            type: String,
            default: 'button',
            validator: function (value) {
                return ['button', 'submit', 'reset'].indexOf(value) !== -1;
            },
        },
        color: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'available', 'busy', 'offline', 'neutral', 'neutral-secondary', 'light', 'white', 'danger', 'prime', 'facebook', 'whatsapp', 'horoscope', 'dark'].indexOf(value) !== -1;
            },
        },
        autofocus: Boolean,
        block: Boolean,
        disabled: Boolean,
        fab: Boolean,
        fontNormal: Boolean,
        href: String,
        icon: Boolean,
        large: Boolean,
        link: Boolean,
        loading: Boolean,
        lowercase: Boolean,
        normalCase: Boolean,
        outlined: Boolean,
        pill: Boolean,
        small: Boolean,
        skeletonLoader: Boolean,
        text: Boolean,
    },

    computed: {
        classes() {
            return {
                'base-button': true,
                'base-button--color-primary': this.color === 'primary',
                'base-button--color-secondary': this.color === 'secondary',
                'base-button--color-available': this.color === 'available',
                'base-button--color-busy': this.color === 'busy',
                'base-button--color-offline': this.color === 'offline',
                'base-button--color-neutral': this.color === 'neutral',
                'base-button--color-neutral-secondary': this.color === 'neutral-secondary',
                'base-button--color-light': this.color === 'light',
                'base-button--color-white': this.color === 'white',
                'base-button--color-danger': this.color === 'danger',
                'base-button--color-prime': this.color === 'prime',
                'base-button--color-facebook': this.color === 'facebook',
                'base-button--color-whatsapp': this.color === 'whatsapp',
                'base-button--color-horoscope': this.color === 'horoscope',
                'base-button--color-dark': this.color === 'dark',
                'base-button--outlined': this.outlined,
                'base-button--block': this.block,
                'base-button--fab': this.fab,
                'base-button--pill': this.pill,
                'base-button--small': this.small,
                'base-button--medium': !this.small && !this.large,
                'base-button--large': this.large,
                'base-button--text': this.text,
                'base-button--link': this.link,
                'base-button--normal-case': this.normalCase,
                'base-button--lowercase': this.lowercase,
                'base-button--font-normal': this.fontNormal,
                'base-button--disabled': this.disabled,
                'base-button--skeleton-loader': this.skeletonLoader,
                'base-button--loading': this.loading,
                'base-button--icon': this.icon,
                'base-button--href': this.hasHref,
            };
        },
        hasHref() {
            return this.href !== undefined && this.href.length > 0;
        },
        componentType() {
            return !this.hasHref ? 'button' : 'a';
        },
        buttonType() {
            return !this.hasHref ? this.type : null;
        }
    },

    methods: {
        focusButton: function () {
            this.$refs.button.focus()
        },
    },

    mounted() {
        if (this.autofocus) {
            this.focusButton();
        }
    }
};
</script>
