var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex py-2 pr-4 md:pl-2" },
    [
      _c(
        "div",
        { class: _vm.backButtonClass },
        [
          _c(
            "base-button",
            { attrs: { icon: "" }, on: { click: _vm.back } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fal", "chevron-left"], size: "lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.masterAvatarClass },
        [
          _c(
            "base-link",
            {
              attrs: {
                href: this.interlocutor().url,
                inactive: this.isLiveConversation,
                underline: false,
              },
            },
            [
              _c("base-avatar", {
                attrs: {
                  name: this.getParticipantName(_vm.master),
                  photo: _vm.master.photo,
                  status: _vm.masterAvatarStatus,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow self-center min-w-10 overflow-hidden" },
        [
          _c(
            "p",
            {
              staticClass:
                "text-sm font-bold whitespace-nowrap overflow-ellipsis overflow-hidden",
              class: _vm.masterTextColorByStatus,
            },
            [
              _c(
                "base-link",
                {
                  attrs: {
                    href: this.interlocutor().url,
                    inactive: this.isLiveConversation,
                    underline: false,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.master.name) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "text-xs text-neutral-500 whitespace-nowrap overflow-ellipsis overflow-hidden",
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.masterTextByStatus) + "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !this.hasLiveSessionBillingStarted()
        ? [
            _c(
              "div",
              { staticClass: "flex-none self-center" },
              [
                _vm.isMasterOffline(this.master)
                  ? [
                      _c(
                        "base-button",
                        {
                          staticClass: "ml-2",
                          attrs: { small: "", color: "neutral", outlined: "" },
                          on: { click: _vm.displayModalNotifMasterOnline },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "bell"], size: "1x" },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.trans.get(
                                  "generic.alert_notif_master_online"
                                )
                              ) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm.isMasterStatusBusy
                  ? void 0
                  : _vm.isChatLiveEnabled
                  ? [
                      _c(
                        "base-button",
                        {
                          staticClass: "ml-2",
                          attrs: { outlined: "", small: "" },
                          on: { click: _vm.startLiveChat },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "message-lines"] },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.trans.get("generic.chat_live")) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : [
            _c(
              "p",
              {
                staticClass: "text-primary text-sm font-bold self-center mr-5",
              },
              [
                _c("base-timer", {
                  ref: "billing_timer",
                  attrs: { "threshold-events": _vm.thresholdEvents },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ hours, minutes, seconds }) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(hours) +
                              ":" +
                              _vm._s(minutes) +
                              ":" +
                              _vm._s(seconds) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isAddFundButtonDisplayed
              ? _c(
                  "div",
                  { staticClass: "flex-none self-center mr-5" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { small: "" },
                        on: { click: _vm.addFundAlertToggle },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-plus"] },
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.trans.get("generic.add_fund")) +
                            "\n                "
                        ),
                        _vm.isAddFundAlertDisplayed
                          ? _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "caret-up"] },
                            })
                          : _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "caret-down"] },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-none self-center" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      fab: "",
                      outlined: "",
                      small: "",
                      color: "danger",
                    },
                    on: { click: _vm.displayModalCloseChat },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "xmark"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }