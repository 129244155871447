var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.inactive
    ? _c(
        "a",
        {
          class: _vm.linkClass,
          attrs: { href: _vm.href },
          on: { click: _vm.linkClicked },
        },
        [_vm._t("default")],
        2
      )
    : _c("span", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }