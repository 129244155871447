var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-80 max-w-full" },
    [
      _c("base-audio-player", {
        ref: "audio-player",
        attrs: {
          src: _vm.message.content.url,
          "initial-duration": _vm.message.content.duration,
        },
        on: { update: _vm.update, error: _vm.error },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex items-stretch" },
        [
          _c(
            "div",
            { staticClass: "flex-none pr-2" },
            [
              _c(
                "base-button",
                { attrs: { icon: "" }, on: { click: _vm.buttonClick } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "playerButton",
                    attrs: { icon: _vm.buttonIcon, spin: _vm.buttonIconSpin },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMessageNotSent(_vm.message)
            ? [
                _c("div", { staticClass: "flex-grow flex items-center" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.trans.get("generic.error_click_to_try_again")
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            : _vm.isMessageNotLoaded(_vm.message)
            ? [
                _c("div", { staticClass: "flex-grow flex items-center" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.trans.get("generic.error_click_to_try_again")
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            : [
                _c("div", { staticClass: "flex-grow flex" }, [
                  _c("input", {
                    staticClass: "progressBar",
                    attrs: { type: "range", min: "0", max: 100, step: "0.1" },
                    domProps: { value: _vm.playingProgression },
                    on: { input: _vm.setCurrentTime },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-none flex items-center ml-2 w-10 text-center",
                  },
                  [
                    _vm.displayCurrentTime
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatSecondsToTimer")(_vm.currentTime)
                            )
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatSecondsToTimer")(_vm.duration))
                          ),
                        ]),
                  ]
                ),
              ],
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "conversation-message-content-audio--status" },
            [
              _c("conversation-message-metadata", {
                attrs: { status: _vm.messageStatus },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }