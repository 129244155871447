var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.messageClass }, [
    _c(
      "div",
      { staticClass: "conversation-message-container" },
      [
        !_vm.isMe
          ? _c("base-avatar", {
              staticClass: "conversation-message-avatar",
              attrs: {
                name: this.getParticipantName(this.message.participant),
                photo: this.message.participant.photo,
                color: "light",
                small: "",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.contentClass },
          [
            _vm.type === "text"
              ? _c("conversation-message-content-text", {
                  attrs: {
                    "message-status": _vm.status,
                    text: _vm.content.text,
                  },
                })
              : _vm.type === "image"
              ? _c("conversation-message-content-image", {
                  attrs: {
                    url: _vm.content.url,
                    url_thumbnail: _vm.content.thumbnail,
                    "message-status": _vm.status,
                    text: _vm.content.text,
                  },
                })
              : _vm.type === "audio"
              ? _c("conversation-message-content-audio", {
                  attrs: {
                    message: this.message,
                    "message-status": _vm.status,
                  },
                  on: { error: _vm.setError },
                })
              : _vm.type === "sticker"
              ? _c("conversation-message-content-sticker", {
                  attrs: {
                    "message-status": _vm.status,
                    message: this.message,
                  },
                })
              : _vm.type === "is-typing"
              ? _c("conversation-message-content-is-typing")
              : _vm.type === "chat_bot_ask_question"
              ? _c("conversation-message-content-chat-bot-ask-question", {
                  attrs: { nickname: this.me().name },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "conversation-message-menu" },
          [
            _vm.isDeletable
              ? _c(
                  "base-menu",
                  {
                    attrs: {
                      "parent-container-id":
                        "conversation-messages-list-container",
                      "drop-top": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function () {
                            return [
                              _c(
                                "base-menu-item",
                                {
                                  staticClass: "text-dark",
                                  on: { click: _vm.deleteMessage },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fal", "trash-can"] },
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "chat.delete_this_message"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3990618176
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-6 text-center text-xl" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-main",
                          attrs: { icon: ["far", "ellipsis-vertical"] },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.hasError
      ? _c(
          "div",
          { staticClass: "conversation-message-error" },
          [
            _vm.isMessageNotSent(_vm.message)
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.trans.get("chat.message_not_sent")) +
                      "\n        "
                  ),
                ]
              : _vm.isMessageNotLoaded(_vm.message)
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.trans.get("chat.audio_message_not_loaded")) +
                      "\n        "
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }