var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("chat.modals.master_end_chat_title", {
                client_name: _vm.name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-4" },
        [
          _c(
            "base-list",
            [
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(_vm._s(_vm.trans.get("generic.pseudo")) + " : "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [_vm._v(_vm._s(_vm.name))]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(_vm.trans.get("generic.duration")) + " : "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _c("base-timer", {
                          ref: "timerDuration",
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ hours, minutes, seconds }) {
                                return [
                                  hours > 0
                                    ? [_vm._v(_vm._s(hours + "h"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  minutes > 0
                                    ? [_vm._v(_vm._s(minutes + "min"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  seconds > 0
                                    ? [_vm._v(_vm._s(seconds + "s"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  seconds == 0 ? [_vm._v("0s")] : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(_vm._s(_vm.trans.get("generic.gain")) + " : "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("formatMoney")(_vm.gain)) +
                            " (" +
                            _vm._s(_vm.trans.get("generic.stickers_included")) +
                            ")"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _c(
            "base-button",
            { attrs: { autofocus: "" }, on: { click: _vm.closeModal } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.ok")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }