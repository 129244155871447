var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    {
      attrs: {
        "disable-closing": "",
        dense: "",
        zIndex: "z-40",
        error: _vm.loadingError,
      },
    },
    [
      _c(
        "div",
        { staticClass: "px-6 pt-6 pb-2" },
        [
          _c("modal-heading", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.trans.get(
                    "generic.chat_user_wants_to_start_live_chat_with_you",
                    { username: _vm.name }
                  )
                ) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex items-center bg-neutral-100 p-4" }, [
        _c(
          "div",
          { staticClass: "flex-none justify-center mr-4" },
          [
            _c("base-avatar", {
              attrs: {
                name: _vm.name,
                photo: _vm.getSegmentPhoto(_vm.getSegment),
                large: "",
                outlined: "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c(
              "base-list",
              [
                _c("base-list-item", [
                  _c(
                    "strong",
                    { staticClass: "text-primary text-base leading-4" },
                    [_vm._v(_vm._s(_vm.name))]
                  ),
                ]),
                _vm._v(" "),
                _c("base-list-item", {
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "circle-chevron-right"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.trans.get("generic.user_segment.type")
                              ) +
                              "\n                    "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "description",
                      fn: function () {
                        return [
                          _vm.getSegment === "gold"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.gold"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-star",
                                    attrs: { icon: ["fas", "trophy-star"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "silver"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.silver"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-neutral-400",
                                    attrs: { icon: ["fas", "trophy"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "diamond"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.diamond"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-dark",
                                    attrs: { icon: ["fas", "gem"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "customer_to_retain"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.customer_to_retain"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-success",
                                    attrs: { icon: ["far", "face-smile-plus"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "loyal_customer"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.loyal_customer"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-primary",
                                    attrs: { icon: ["far", "face-smile"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "customer_to_reloyalty"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.customer_to_reloyalty"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-warning",
                                    attrs: { icon: ["far", "face-sleeping"] },
                                  }),
                                ],
                                1
                              )
                            : _vm.getSegment === "free_customer"
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.free_customer"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-main",
                                    attrs: { icon: ["far", "face-meh"] },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.trans.get(
                                          "generic.user_segment.customer"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-primary",
                                    attrs: { icon: ["fas", "user"] },
                                  }),
                                ],
                                1
                              ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("base-list-item", {
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "circle-chevron-right"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(_vm.trans.get("generic.birth_date")) + " :"
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "description",
                      fn: function () {
                        return [
                          _vm._v(_vm._s(_vm._f("formatDate")(_vm.dt_birth))),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("base-list-item", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-chevron-right"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              _vm._s(_vm.trans.get("generic.rate")) + " :"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      _vm.free_chat
                        ? {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans.get(
                                      "chat.modals.free_chat_no_payoff"
                                    )
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatMoneyPerMinute")(
                                      _vm.cost_by_minute
                                    )
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-6 mt-2" }, [
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-4" },
          [
            _c(
              "base-button",
              {
                attrs: { loading: this.loadingRefuse, outlined: "" },
                on: { click: _vm.refuse },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("generic.refuse")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: { autofocus: "", loading: this.loadingAccept },
                on: { click: _vm.accept },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("generic.accept")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }