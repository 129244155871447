var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.level === 1
    ? _c(
        "h1",
        {
          staticClass:
            "text-2xl mt-8 mb-2 font-bold border-b border-neutral-100",
        },
        [_vm._t("default")],
        2
      )
    : _vm.level === 2
    ? _c(
        "h2",
        {
          staticClass:
            "text-xl mt-8 mb-2 font-bold border-b border-neutral-100",
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }