<template>
    <div class="comment-card">
        <a :href="comment.offer.url">
            <div class="comment-card-header">
                <p class="comment-card-title">
                    <span class="mr-0.5">{{ comment.user.name }}</span>
                    <span v-if="hasRating" class="text-star mr-0.5">
                        <span>{{ comment.mark / 10 }}</span>
                        <font-awesome-icon :icon="['fas', 'star']"/>
                    </span>
                    <span class="mr-0.5">{{ trans.get('generic.to') }}</span>
                    <base-link :href="comment.offer.url" :underline="false">
                        <span class="font-bold">{{ comment.offer.name }}</span>
                    </base-link>
                </p>
                <p class="comment-card-date">{{ comment.created_since }}</p>
            </div>

            <div class="comment-card-content">
                <p class="comment-card-text">{{ comment.comment }}</p>
            </div>
        </a>
    </div>
</template>

<style lang="scss">
.comment-card {
    @apply bg-white shadow-medium rounded-2xl p-3;

    &-header {
        @apply flex-grow flex min-w-0 pb-1;
    }

    &-content {
        @apply flex-grow min-w-0 h-25;
    }

    &-text {
        @apply text-main text-sm break-words line-clamp-5;
    }

    &-title {
        @apply flex-grow min-w-0 font-bold text-base text-main whitespace-nowrap overflow-ellipsis overflow-hidden;
    }

    &-date {
        @apply flex-none text-main text-base whitespace-nowrap;
    }
}
</style>

<script>

import BaseLink from "../elements/BaseLink.vue";

export default {
    name: "CommentCard",
    components: {BaseLink},
    props: {
        comment: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        hasRating() {
            return this.comment.mark !== null && this.comment.mark !== undefined;
        }
    }
}
</script>
