var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white" },
    [
      _c("base-timer", {
        ref: "billing_timer_left",
        attrs: {
          time: _vm.getTimeLeft(),
          "threshold-events": _vm.thresholdEvents,
          countdown: "",
          "auto-start": "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shadow" },
        [
          _c(
            "div",
            { staticClass: "flex w-full items-center" },
            [
              _c(
                "base-button",
                {
                  staticClass: "h-12 ml-4",
                  attrs: { link: "", "font-normal": "", "normal-case": "" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleStickersMenu(false)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: ["fal", "xmark"], size: "2x" },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.trans.get(
                          "generic.chat_close_conversation_informations"
                        )
                      ) +
                      "\n            "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !this.meFromUserStore().is_master
            ? [
                !_vm.stickersDisabled
                  ? _c(
                      "base-bar",
                      { attrs: { color: "pack-1", "x-small": "" } },
                      [
                        _c("span", {
                          staticClass: "font-semibold uppercase",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.trans.get(
                                "chat.surprise_your_advisor_with_a_gift"
                              )
                            ),
                          },
                        }),
                      ]
                    )
                  : _c(
                      "base-bar",
                      { attrs: { color: "warning", "x-small": "" } },
                      [
                        _c("span", {
                          staticClass: "font-semibold uppercase",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.trans.get("chat.top_up_to_offer_gift_again")
                            ),
                          },
                        }),
                      ]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "sticker-list-container" }, [
          _c(
            "div",
            { staticClass: "sticker-list" },
            [
              _vm._l(_vm.getStickers, function (sticker) {
                return [
                  _c("conversation-sticker", {
                    key: sticker.id,
                    ref: "sticker-" + sticker.id,
                    refInFor: true,
                    attrs: { sticker: sticker },
                  }),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }