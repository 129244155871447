<template>
    <div ref="container" class="base-console">
        <div ref="header" @mousedown="dragMouseDown" @touchmove="dragTouchMove" class="base-console-header">
            <base-bar color="info">Console</base-bar>
        </div>
        <div v-if="visible" class="base-console-logs" ref="console">
            <template v-for="log in logs">
                <p class="base-console-info" v-if="log.type === 'info'">{{ log.message }}</p>
                <p class="base-console-warn" v-else-if="log.type === 'warn'">{{ log.message }}</p>
                <p class="base-console-error" v-else-if="log.type === 'error'">{{ log.message }}</p>
                <p v-else>{{ log.message }}</p>
            </template>
            <p class="base-console-info"><font-awesome-icon :icon="['fal', 'chevron-right']"></font-awesome-icon></p>
        </div>
    </div>
</template>

<style lang="scss">
.base-console {
    @apply fixed bottom-0 left-0 w-full z-100;

    &-header {
        @apply cursor-ns-resize;
    }

    &-logs {
        @apply bg-white overflow-auto break-words w-full h-24;

        p {
            @apply border-t border-neutral-100 p-2 ;

            &.base-console-info {
                @apply text-primary;
            }

            &.base-console-warn {
                @apply text-warning;
            }

            &.base-console-error {
                @apply text-danger;
            }
        }
    }
}
</style>

<script>
import BaseBar from "./BaseBar";

export default {
    name: "BaseConsole",
    components: {BaseBar},
    methods: {
        toggleConsole() {
            this.visible = !this.visible;
        },
        info(message) {
            this.createLog(message, 'info');
        },
        log(message) {
            this.createLog(message, 'log');
        },
        warn(message) {
            this.createLog(message, 'warn');
        },
        error(message) {
            this.createLog(message, 'error');
        },
        createLog(message, type) {
            let logMessage = '';

            if (typeof message == 'object') {
                logMessage = (JSON && JSON.stringify ? JSON.stringify(message) : message);
            } else {
                logMessage = message;
            }

            this.logs.push({message: logMessage, type: type});
        },
        scrollLogsToBottom() {
            if (this.visible) {
                let console = this.$refs.console;
                if (console !== undefined) {
                    console.scrollTop = console.scrollHeight;
                }
            }
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        touchStart: function (event) {
            event.preventDefault()
            document.ontouchmove = this.elementDrag
            document.ontouchcancel = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            let height = window.innerHeight - event.clientY;
            this.setConsoleHeight(height);
        },
        dragTouchMove: function (event) {
            event.preventDefault()
            let height = window.innerHeight - event.touches[0].clientY;
            this.setConsoleHeight(height);
        },
        setConsoleHeight: function (height) {
            let headerHeight = this.$refs.header.offsetHeight;
            // if (height <= headerHeight) {
            //     height = headerHeight;
            // }

            this.$refs.console.style.height = height + 'px';
            this.scrollLogsToBottom();
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        }
    },
    mounted() {
        let consoleInfo = console.info;
        console.info = (message) => {
            consoleInfo(message);
            this.info(message);
        }

        let consoleLog = console.log;
        console.log = (message) => {
            consoleLog(message);
            this.log(message);
        }

        let consoleWarn = console.warn;
        console.warn = (message) => {
            consoleWarn(message);
            this.warn(message);
        }

        let consoleError = console.error;
        console.error = (message) => {
            consoleError(message);
            this.error(message);
        }
    },
    updated() {
        this.scrollLogsToBottom();
    },
    data() {
        return {
            visible: true,
            logs: [],
        };
    },
}
</script>
