var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    {
      class: _vm.classes,
      attrs: { color: _vm.color, outlined: _vm.outlined, small: "" },
      on: { click: _vm.clickEvent },
    },
    [
      _c(
        "span",
        { staticClass: "offer-card-button-label" },
        [
          _vm.hasIcon
            ? _c("font-awesome-icon", {
                staticClass: "mx-2",
                attrs: { icon: _vm.icon, size: "lg" },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.small ? [_vm._v(_vm._s(_vm.label))] : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }