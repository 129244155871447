var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      { class: _vm.labelClass, attrs: { for: _vm.id } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checkedValue,
              expression: "checkedValue",
            },
          ],
          ref: "input_radio",
          class: _vm.inputClass,
          attrs: {
            type: "radio",
            id: _vm.id,
            name: _vm.name,
            disabled: _vm.disabled,
          },
          domProps: {
            value: _vm.value,
            checked: _vm._q(_vm.checkedValue, _vm.value),
          },
          on: {
            change: function ($event) {
              _vm.checkedValue = _vm.value
            },
          },
        }),
        _vm._v(" "),
        _vm.displayCheckedSlot
          ? _vm._t("checked")
          : _vm.displayDisabledSlot
          ? _vm._t("disabled")
          : _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }