var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "conversation-message-metadata" },
    [
      _c(
        "transition",
        { attrs: { name: "flip", appear: "", mode: "out-in" } },
        [
          _vm.error
            ? _c("font-awesome-icon", {
                key: "error",
                staticClass: "text-danger",
                attrs: { size: "sm", icon: ["fas", "exclamation"] },
              })
            : _vm.read
            ? _c("font-awesome-icon", {
                key: "read",
                attrs: { size: "sm", icon: ["far", "check-double"] },
              })
            : _vm.sent
            ? _c("font-awesome-icon", {
                key: "sent",
                attrs: { size: "sm", icon: ["far", "check"] },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }