<template>
    <a :href="href" :class="linkClass" v-if="!inactive" @click="linkClicked">
        <slot/>
    </a>
    <span v-else>
        <slot/>
    </span>
</template>

<style lang="scss">
.base-link {
    &--color-primary, &--color-primary:visited {
        @apply text-primary hover:text-primary-hover;
    }

    &--color-secondary, &--color-secondary:visited {
        @apply text-secondary hover:text-secondary-hover;
    }

    &--color-white, &--color-white:visited {
        @apply text-white hover:text-neutral-100;
    }

    &--color-dark, &--color-dark:visited {
        @apply text-dark hover:text-dark;
    }
}
</style>

<script>
export default {
    name: "BaseLink",
    props: {
        color: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary', 'white', 'dark'].indexOf(value) !== -1;
            },
        },
        href: String,
        inactive: Boolean,
        underline: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        linkClicked() {
            if (!this.inactive) {
                this.$emit('click');
            }
        }
    },
    computed: {
        linkClass() {
            return {
                'base-link': true,
                'base-link--color-primary': this.color === 'primary',
                'base-link--color-secondary': this.color === 'secondary',
                'base-link--color-white': this.color === 'white',
                'base-link--color-dark': this.color === 'dark',
                'underline': this.underline,
                'no-underline': !this.underline,
            }
        }
    }
}
</script>
