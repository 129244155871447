<template>
    <div class="game-wheel-background-snowfall">
        <div class="game-wheel-background-snowfall-snowflake" v-for="i in 50"></div>
    </div>
</template>

<style lang="scss">
.game-wheel-background-snowfall-snowflake {
    --size: 1vw;
    width: var(--size);
    height: var(--size);
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
}

@keyframes snowfall {
    0% {
        transform: translate3d(var(--left-ini), 0, 0);
    }
    100% {
        transform: translate3d(var(--left-end), 110vh, 0);
    }
}

@for $i from 1 through 50 {
    .game-wheel-background-snowfall-snowflake:nth-child(#{$i}) {
        --size: #{random(5) * 0.2}vw;
        --left-ini: #{random(20) - 10}vw;
        --left-end: #{random(20) - 10}vw;
        left: #{random(100)}vw;
        animation: snowfall #{5 + random(10)}s linear infinite;
        animation-delay: -#{random(10)}s;
    }
}

.game-wheel-background-snowfall-snowflake:nth-child(6n) {
    filter: blur(1px);
}
</style>

<script>
export default {
    name: "GameWheelBackgroundSnowfall",
}
</script>
