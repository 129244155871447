<template>
    <div class="reset-this leading-normal">
        <base-menu :loading="isLoading" @open="openMenu" drop-left scrollable ref="messageMenu">
            <div style="line-height: 55px;">
                <base-button small color="light" class="messages-button messages-button--desktop">
                    <font-awesome-icon v-if="isOpen()" :icon="['fas', 'envelope-open-text']"/>
                    <font-awesome-icon v-else :icon="['fas', 'envelope']"/>
                    {{ trans.get('chat.header.messages') }}
                    <base-pin v-if="newConversationsCount > 0" :amount="newConversationsCount"/>
                </base-button>
                <base-button small color="light" class="messages-button messages-button--mobile">
                    <font-awesome-icon v-if="isOpen()" :icon="['fas', 'envelope-open-text']" size="lg"/>
                    <font-awesome-icon v-else :icon="['fas', 'envelope']" size="lg"/>
                    <base-pin v-if="newConversationsCount > 0" :amount="newConversationsCount"/>
                </base-button>
            </div>
            <template #header>
                <div class="text-center font-bold text-primary text-lg py-3">
                    {{ trans.get('chat.header.my_messages') }}
                </div>
            </template>

            <template #items>
                <div v-if="conversations.length > 0" class="divide-y divide-neutral-300 max-w-96">
                    <template v-for="conversation in conversations">
                        <conversation-item v-bind="getConversationItemProps(conversation)"
                                           @click="selectConversation(conversation)"/>
                    </template>
                </div>

                <div v-else>
                    <div class="p-3 max-w-96 text-neutral-800 text-left">
                        {{ trans.get('chat.header.no_messages') }}
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="p-3">
                    <base-button v-if="!containsOnlyDigitalArtMessage" block @click="openChat">
                        {{ trans.get('chat.header.see_all_chat') }}
                    </base-button>

                    <base-button v-else block @click="openDigitalMailBox">
                        {{ trans.get('chat.header.see_all_chat') }}
                    </base-button>
                </div>
            </template>
        </base-menu>
    </div>
</template>

<style lang="scss">
.messages-button {
    @apply relative;

    &--mobile {
        @apply inline-block lg:hidden;
    }

    &--desktop {
        @apply hidden lg:inline-block;
    }
}
</style>

<script>
import BaseButton from '../elements/BaseButton';
import BasePin from '../elements/BasePin';
import BaseAvatar from '../elements/BaseAvatar';
import {mapActions, mapGetters} from 'vuex';
import ConversationItem from './ConversationItem';
import ChatMixin from '../chat/ChatMixin';
import moment from 'moment';
import {ziggy_route} from '../ZiggyMixin';
import listeners from '../chat/listeners';
import BaseMenu from "../elements/BaseMenu";

export default {
    name: 'Messages',
    components: {BaseMenu, ConversationItem, BasePin, BaseButton, BaseAvatar},
    mixins: [ChatMixin],
    data: function () {
        return {
            loaded: false,
            newConversationsCount: 0,
            containsOnlyDigitalArtMessage: false,
        };
    },
    methods: {
        ...mapActions('chat', [
            'loadConversations',
            'loadDigitalArtPresaleConversations',
            'selectParticipants',
            'setConversationUrl'
        ]),
        ...mapActions('user', ['fetchUserInfos']),
        getLastMessage(conversation) {
            return conversation.messages[conversation.messages.length - 1];
        },
        openMenu() {
            if (!this.loaded && this.getConversations.length <= 0) {
                this.loadConversations(true);
            }
            this.loaded = true;
        },
        message(conversation) {
            let result = '';

            if (this.isConversationDigitalArts(conversation)) {
                result = conversation.text;
            } else {
                const lastMessage = this.getLastMessage(conversation);

                switch (lastMessage.type) {
                    case 'text':
                        result = lastMessage.content.text;
                        break;
                    case 'image':
                        result = this.trans.get('generic.chat_message_image');
                        break;
                    case 'audio':
                        result = this.trans.get('generic.chat_message_audio');
                        break;
                    case 'sticker':
                        result = this.trans.get('generic.chat_message_sticker');
                        break;
                }
            }

            return result;
        },
        lastMessageType(conversation) {
            const lastMessage = this.getLastMessage(conversation);
             return lastMessage.type;
        },
        lastMessageWrittenSince(conversation) {
            if (this.isConversationDigitalArts(conversation)) {
                return moment(conversation.dt_send, 'YYYY-MM-DD HH:mm:ss').fromNow();
            }
            return moment(this.getLastMessage(conversation).created_at, 'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        selectConversation(conversation) {
            if (this.isConversationDigitalArts(conversation)) {
                window.location.href = this.getDigitalArtsMessageUrl(conversation);
            } else {
                if (this.mainChatComponentIsMounted) {
                    this.selectParticipants(conversation.participants);
                    this.setConversationUrl(conversation.participants);
                } else {
                    window.location.href = this.getConversationUrl(conversation.participants);
                }
            }
        },
        openChat() {
            window.location.href = 'chat';
        },
        openDigitalMailBox(){
            window.location.href = this.getDigitalArtMailboxUrl;
        },
        isConversationDigitalArts(conversation) {
            return conversation.digital_mailbox_id !== undefined;
        },
        getConversationItemProps(conversation) {
            if (this.isConversationDigitalArts(conversation)) {
                return {
                    'avatar-url': conversation.master.photo,
                    'message': this.message(conversation),
                    'nickname': conversation.master.name,
                    'read': !!conversation.read,
                    'written-since': this.lastMessageWrittenSince(conversation)
                }
            } else {
                return {
                    'avatar-url': this.interlocutor(conversation.participants).photo,
                    'message': this.message(conversation),
                    'nickname': this.getParticipantName(this.interlocutor(conversation.participants)),
                    'status': this.interlocutor(conversation.participants).status,
                    'read': conversation.read,
                    'written-since': this.lastMessageWrittenSince(conversation),
                    'type': this.lastMessageType(conversation),
                }
            }
        },
        isOpen() {
            return this.$refs.messageMenu !== undefined && this.$refs.messageMenu.isOpen();
        },
    },
    computed: {
        ...mapGetters('chat', [
            'getConversations',
            'getConversationUrl',
            'getDigitalArtsMessageUrl',
            'getDigitalArtMailboxUrl',
            'mainChatComponentIsMounted',
            'isConversationsLoading'
        ]),
        conversations() {
            let chatConversations = this.getConversations.filter(c => c.messages && c.messages.length > 0 && c.messages[0] !== null).slice(0, 4);
            let digitalArtsConversations = this.getConversations.filter(c => c.digital_mailbox_id).slice(0, 1);

            if (chatConversations.length === 0 && digitalArtsConversations.length > 0) {
                this.containsOnlyDigitalArtMessage = true;
            }

            if (chatConversations.length === 4 && digitalArtsConversations.length > 0) {
                chatConversations.pop();
            }
            return chatConversations.concat(digitalArtsConversations);
        },
        isLoading() {
            return this.isConversationsLoading;
        }
    },
    async mounted() {
        axios.get(ziggy_route('chat.conversation.getUnreadConversationsCount')).then(r => this.newConversationsCount = r.data.data);

        if (!this.meFromUserStore().id) {
            await this.fetchUserInfos();
        }

        const channel = ChatMixin.Echo.private(ChatMixin.methods.getChannelNameForGeneralChat());

        listeners.listen(channel, 'ChatConversationReadStatusUpdated', e => {
            this.newConversationsCount = e.unreadConversationsCount;
        });
    }
};
</script>

<style scoped>

</style>
