<template>
    <div>
        <div v-if="hasError && errorTop" class="text-danger mb-1 text-sm" v-html="error"></div>

        <div class="relative">
            <div ref="input_container" :class="inputContainerClasses" @click="focusInput">
                <textarea
                    ref="input"
                    :id="id"
                    :name="name"
                    :disabled="disabled"
                    :class="inputClasses"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :value="value"
                    :style="inputStyles"
                    :maxlength="maxLength"
                    :auto-grow="autoGrow"
                    :rows="rows"
                    :readonly="readonly"
                    @input="updateValueAndInputSize($event.target.value)"
                    @keydown="$emit('keydown', $event)"
                    @keypress="$emit('keypress', $event)"
                    @focus="$emit('focus', $event)"
                    @blur="$emit('blur', $event)"
                />

                <label v-if="hasLabel" :for="id" :class="labelClasses">{{ label }}</label>
            </div>
            <div v-if="hasIcon" :class="iconClasses">
                <slot name="icon"></slot>
            </div>
        </div>

        <div v-if="hasError && !errorTop" class="text-danger mt-1 text-sm" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.base-textarea-input-container {
    @apply cursor-text leading-normal flex flex-col w-full pl-4 text-dark rounded-md appearance-none placeholder-neutral-500 m-0;

    &--icon {
        &.base-textarea-input-container--icon-right {
            @apply pr-12;
        }

        &:not(&.base-textarea-input-container--icon-right) {
            @apply pl-10;
        }
    }

    &--outlined {
        @apply border border-neutral-400 bg-white;
    }

    &:not(&.base-textarea-input-container--outlined) {
        @apply border-none bg-neutral-100;
    }

    &--small {
        @apply pb-1 pt-3;
        &:not(&.base-textarea-input-container--label) {
            @apply py-2;
        }
    }

    &--medium {
        @apply pb-1 pt-5;
        &:not(&.base-textarea-input-container--label) {
            @apply py-3;
        }
    }

    &--large {
        @apply pb-1 pt-7;
        &:not(&.base-textarea-input-container--label) {
            @apply py-4;
        }
    }

    &:focus-within {
        @apply border-primary outline-none;
    }

    &--disabled {
        @apply cursor-not-allowed bg-disabled border-disabled;
    }

    &--danger {
        @apply border-danger;
        &:focus-within {
            @apply border-danger;
        }
    }
}

.base-textarea-input {
    @apply outline-none w-full p-0 pr-2 mb-0 border-none;

    &--outlined {
        @apply bg-white;
    }

    &:not(&.base-textarea-input--outlined) {
        @apply bg-neutral-100;
    }

    &--label {
        @apply placeholder-transparent;
        &:focus {
            @apply placeholder-neutral-500;
        }
    }

    &--disabled {
        @apply cursor-not-allowed bg-disabled border-disabled;
    }

    &:focus ~ .base-textarea-input-label,
    &:not(:placeholder-shown) ~ .base-textarea-input-label {
        @apply text-primary;
        transform: translate(0, -0.3rem) scale(0.7);
    }

    &:focus ~ .base-textarea-input-label.base-textarea-input-label--danger,
    &:not(:placeholder-shown) ~ .base-textarea-input-label.base-textarea-input-label--danger {
        @apply text-danger;
    }
}

.base-textarea-input-icon {
    @apply inline-flex items-center justify-center absolute top-0 h-full w-10 pointer-events-none text-primary;

    &--icon-top {
        @apply items-start;
    }

    &--icon-right {
        @apply right-0;
    }

    &:not(&.base-textarea-input-icon--icon-right) {
        @apply left-0;
    }

    &--disabled {
        @apply text-dark;
    }

    &--danger {
        @apply text-danger;
    }

    &--small {
        @apply text-base;

        &.base-textarea-input-icon--icon-top {
            @apply pt-1;
        }
    }

    &--medium {
        @apply text-2xl;

        &.base-textarea-input-icon--icon-top {
            @apply pt-3;
        }
    }

    &--large {
        @apply text-2xl;

        &.base-textarea-input-icon--icon-top {
            @apply pt-5;
        }
    }
}

.base-textarea-input-label {
    @apply leading-normal absolute top-0 block h-full py-3 left-4 border border-transparent pointer-events-none origin-top-left text-neutral-500;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

    &--icon:not(&.base-textarea-input-label--icon-right) {
        @apply left-10;
    }

    &--disabled {
        @apply text-dark;
    }

    &--danger {
        @apply text-danger;
    }
}

</style>

<script>
export default {
    name: "BaseTextarea",
    props: {
        id: {
            type: String,
            default() {
                return `textarea-${this._uid}`;
            }
        },
        rows: {
            type: Number,
            default: 2
        },
        maxRows: {
            type: Number,
            default: 5
        },
        rowHeight: {
            type: Number,
            default: 21
        },
        autoGrow: Boolean,
        autocomplete: String,
        autofocus: Boolean,
        disabled: Boolean,
        error: String,
        errorTop: Boolean,
        iconRight: Boolean,
        iconTop: Boolean,
        label: String,
        large: Boolean,
        maxLength: Number,
        name: String,
        outlined: Boolean,
        placeholder: String,
        readonly: Boolean,
        small: Boolean,
        value: String | Number,
    },
    data() {
        return {
            maxHeightScroll: !this.autoGrow,
            height: '0'
        };
    },
    computed: {
        hasIcon() {
            return !!this.$slots.icon;
        },
        hasLabel() {
            return this.label !== undefined && this.label.length > 0;
        },
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
        inputContainerClasses() {
            return {
                'base-textarea-input-container': true,
                'base-textarea-input-container--icon': this.hasIcon,
                'base-textarea-input-container--icon-right': this.iconRight,
                'base-textarea-input-container--label': this.hasLabel,
                'base-textarea-input-container--danger': this.hasError,
                'base-textarea-input-container--outlined': this.outlined,
                'base-textarea-input-container--small': this.small,
                'base-textarea-input-container--medium': !this.small && !this.large,
                'base-textarea-input-container--large': this.large,
                'base-textarea-input-container--disabled': this.disabled,
            };
        },
        inputClasses() {
            return {
                'base-textarea-input': true,
                'base-textarea-input--label': this.hasLabel,
                'base-textarea-input--outlined': this.outlined,
                'base-textarea-input--disabled': this.disabled,
            };
        },
        inputStyles() {
            return {
                'resize': 'none',
                'height': this.height,
                'overflow': this.maxHeightScroll ? 'auto' : 'hidden'
            };
        },
        iconClasses() {
            return {
                'base-textarea-input-icon': true,
                'base-textarea-input-icon--icon-right': this.iconRight,
                'base-textarea-input-icon--icon-top': this.iconTop,
                'base-textarea-input-icon--disabled': this.disabled,
                'base-textarea-input-icon--danger': !this.disabled && this.hasError,
                'base-textarea-input-icon--small': this.small,
                'base-textarea-input-icon--medium': !this.small && !this.large,
                'base-textarea-input-icon--large': this.large
            };
        },
        labelClasses() {
            return {
                'base-textarea-input-label': true,
                'base-textarea-input-label--icon': this.hasIcon,
                'base-textarea-input-label--icon-right': this.iconRight,
                'base-textarea-input-label--disabled': this.disabled,
                'base-textarea-input-label--danger': this.hasError
            };
        },
        paddingHeight() {
            const computedStyle = getComputedStyle(this.$refs.input);
            return parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
        },
        minContentHeight() {
            return (this.rowHeight * this.rows) + this.paddingHeight + 1;
        },
        maxContentHeight() {
            return (this.rowHeight * this.maxRows) + this.paddingHeight + 1;
        }
    },
    methods: {
        updateValueAndInputSize: function (value) {
            if (this.autoGrow) {
                this.updateInputSize();
            }
            this.$emit('input', value);
        },
        updateInputSize: function () {
            this.height = '0';
            this.$nextTick(() => {
                let contentHeight = this.$refs.input.scrollHeight + 1;
                if (contentHeight > this.maxContentHeight) {
                    contentHeight = this.maxContentHeight;
                    this.maxHeightScroll = true;
                } else {
                    contentHeight = Math.max(this.minContentHeight, contentHeight);
                    this.maxHeightScroll = false;
                }
                const heightVal = (contentHeight - 3) + 'px';
                this.height = `${heightVal}`;
            });
            return this;
        },
        focusInput: function () {
            if (!this.disabled) {
                document.getElementById(this.id).focus();
            }
        },
        blurInput: function () {
            if (!this.disabled) {
                document.getElementById(this.id).blur();
            }
        },
    },
    mounted() {
        if (this.autoGrow) {
            this.updateInputSize();
        } else {
            this.height = this.minContentHeight + 'px';
        }

        if (this.autofocus) {
            this.focusInput();
        }
    }
}
</script>
