<template>
    <div class="flex flex-col">
        <base-alert v-if="send_success"
                    dismissible
                    small
                    type="success"
                    class="top-5 right-0 fixed z-50"
        >{{trans.get('sponsorship.sponsor_sent')}}</base-alert>
        <base-alert v-if="error"
                    dismissible
                    :delay="5000"
                    small
                    type="danger"
                    class="top-5 right-0 fixed z-50"
        >{{trans.get('generic.an_error_has_occurred')}}</base-alert>
        <base-alert v-if="noEmail"
                    dismissible
                    :delay="5000"
                    small
                    type="warning"
                    class="top-5 right-0 fixed z-50"
        >{{trans.get('sponsorship.empty_input')}}</base-alert>
        <form  @submit.prevent="sendEmail" class="py-0">
            <base-text-input v-for="i in emails.keys()"
                             :key="i"
                             v-model="emails[i]"
                             :error="errors[i]"
                             @input="errors[i] = ''"
                             @blur="isValidEmail(i)"
                             :placeholder="getLabelInput"
                             :label="getLabelInput"
                             type="email"
                             outlined
                             class="pb-3"
                             autocomplete="off"
            >
                <template #icon><font-awesome-icon :icon="['fal', 'envelope']"/></template>
            </base-text-input>
            <base-button link color="dark" font-normal normal-case class="mx-auto my-2 underline" :disabled="canAddSponsored" @click="addInput">{{ this.trans.get('sponsorship.add_sponsored') }}</base-button>
            <base-button type="submit" :loading="loading" block class="mt-5">{{ trans.get('sponsorship.send_invitation') }}</base-button>
        </form>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import axios from "axios";
import {ziggy_route} from "../ZiggyMixin";
import BaseAlert from "../elements/BaseAlert";

export default {
    name: "sponsorship-invitation",
    components: {BaseAlert, BaseButton},
    methods: {
      addInput() {
          if(!this.emails.includes('')){
              this.emails.push('');
          }
      },
        isValidEmail(key) {
            // if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emails[key]) && this.emails[key]) {
            //     Vue.set(this.errors, key, this.trans.get('errors.sponsorship.invalid_email'));
            // }
        },
        sendEmail() {
            this.loading = true;
            this.error = false;
            this.noEmail = false;

            if(this.emails.filter(email => email !== '').length > 0) {
                axios.post(ziggy_route('master.account.sponsorship.send.email'), {mails: this.emails}).then(response => {
                    this.loading = false;
                    if (response.data.message === 'envoye') {
                        this.send_success = true;
                        setTimeout(() => {
                            location.reload();
                        }, 1000);
                    } else if (response.data.message === 'mail_errors') {
                        const email_errors = Object.values(response.data.mail_error);
                        email_errors.forEach( email_error => {
                            const key = this.emails.findIndex(email => email === email_error);
                            Vue.set(this.errors, key, this.trans.get('errors.sponsorship.invalid_email'));
                        });
                    }
                }).catch(() =>{
                    this.loading = false;
                    this.error = true;
                });
            } else {
                this.loading = false;
                this.noEmail = true;
            }

        },
    },
    computed: {
        getLabelInput() {
            return this.trans.get('generic.email');
        },
        canAddSponsored() {
            return this.emails.includes('');
        }
    },
    data() {
        return {
            error: false,
            noEmail: false,
            loading: false,
            emails: ['', '', ''],
            errors: ['', '', ''],
            send_success: false,
        }
    },
}
</script>
