var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.containerClass }, [
    _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
      _c("div", { staticClass: "px-2" }, [
        _c("input", {
          staticClass: "base-checkbox-input",
          attrs: {
            type: "checkbox",
            disabled: _vm.disabled,
            id: _vm.id,
            name: _vm.name,
          },
          domProps: { checked: _vm.checked },
          on: {
            change: function ($event) {
              return _vm.updateValue($event.target.checked)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.checkboxClass },
          [
            _c("font-awesome-icon", {
              staticClass: "base-checkbox-checkmark",
              attrs: { icon: ["far", "check"] },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.labelContentClass }, [_vm._t("default")], 2),
    ]),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", {
          class: _vm.messageClass,
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }