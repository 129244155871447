var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment-card" }, [
    _c("a", { attrs: { href: _vm.comment.offer.url } }, [
      _c("div", { staticClass: "comment-card-header" }, [
        _c(
          "p",
          { staticClass: "comment-card-title" },
          [
            _c("span", { staticClass: "mr-0.5" }, [
              _vm._v(_vm._s(_vm.comment.user.name)),
            ]),
            _vm._v(" "),
            _vm.hasRating
              ? _c(
                  "span",
                  { staticClass: "text-star mr-0.5" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.comment.mark / 10))]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "star"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "mr-0.5" }, [
              _vm._v(_vm._s(_vm.trans.get("generic.to"))),
            ]),
            _vm._v(" "),
            _c(
              "base-link",
              { attrs: { href: _vm.comment.offer.url, underline: false } },
              [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.comment.offer.name)),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "comment-card-date" }, [
          _vm._v(_vm._s(_vm.comment.created_since)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "comment-card-content" }, [
        _c("p", { staticClass: "comment-card-text" }, [
          _vm._v(_vm._s(_vm.comment.comment)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }