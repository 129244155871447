<template>
    <development>
        <development-heading>Lottie File Player</development-heading>

        <div class="conversation-messages-list-container conversation-messages-list-container--background h-100">
            <base-lottie-file-player src="https://lottie.host/4db68bbd-31f6-4cd8-84eb-189de081159a/IGmMCqhzpt.lottie"
                                     class="w-full h-full"
                                     ref="player"
                                     :autoplay="autoplay"
                                     :loop="loop"
                                     @load="addLog('load')"
                                     @play="addLog('play')"
                                     @pause="addLog('pause')"
                                     @stop="addLog('stop')"
                                     @loop="addLog('loop')"
                                     @complete="addLog('complete')"
            />
        </div>

        <div class="flex gap-2 my-2">
            <base-button @click="play">Play</base-button>
            <base-button @click="pause">Pause</base-button>
            <base-button @click="stop">Stop</base-button>
            <base-button @click="init">Init</base-button>
            <base-checkbox v-model="autoplay">Autoplay</base-checkbox>
            <base-checkbox v-model="loop">Loop</base-checkbox>
        </div>

        <div class="w-full">
            <base-textarea v-model="logs" label="Logs" placeholder="Logs" class="w-full"
                           :rows="8" disabled outlined/>
        </div>

        <development-heading :level="2">Props :</development-heading>
        <ul>
            <li><strong>src</strong>: String</li>
            <li><strong>loop</strong>: Boolean</li>
            <li><strong>autoplay</strong>: Boolean</li>
        </ul>
    </development>
</template>

<script>
import Development from "./Development";
import DevelopmentParagraph from "./elements/DevelopmentParagraph.vue";
import DevelopmentHeading from "./elements/DevelopmentHeading.vue";
import BaseLottieFilePlayer from "../elements/BaseLottieFilePlayer.vue";
import BaseSelect from "../elements/BaseSelect.vue";
import BaseTextarea from "../elements/BaseTextarea.vue";
import BaseVideoPlayer from "../elements/BaseVideoPlayer.vue";
import BaseCheckbox from "../elements/BaseCheckbox.vue";

export default {
    components: {
        BaseCheckbox,
        BaseVideoPlayer, BaseTextarea, BaseSelect,
        BaseLottieFilePlayer,
        DevelopmentParagraph,
        DevelopmentHeading,
        Development
    },
    methods: {
        play() {
            this.$refs.player.play();
        },
        pause() {
            this.$refs.player.pause();
        },
        stop() {
            this.$refs.player.stop();
        },
        init() {
            this.$refs.player.init();
        },
        addLog(log) {
            this.logs = log + "\n" + this.logs;
        },
    },
    data() {
        return {
            videos: ['video-example', 'aquarius-sign', 'good-luck', 'evil-eye'],
            autoplay: true,
            loop: true,
            logs: "",
        }
    }
}
</script>
