var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Button")]),
        _vm._v(" "),
        _c("base-button", [_vm._v("button")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("type")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'button', 'submit'")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [
              _vm._v(
                "'primary', 'secondary', 'available', 'busy', 'offline',\n                'neutral', 'neutral-secondary', 'light', 'dark'"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("block")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("fab")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("Pill")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("icon")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("loading")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Colors")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("color")]),
          _vm._v(" : primary "),
          _c("small", [_vm._v("(default)")]),
          _vm._v(
            " / secondary / busy /\n            neutral / light / dark\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2 mb-4" },
          [
            _c("base-button", { attrs: { color: "primary" } }, [
              _vm._v("primary"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "secondary" } }, [
              _vm._v("secondary"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "available" } }, [
              _vm._v("available"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "busy" } }, [_vm._v("busy")]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "neutral" } }, [
              _vm._v("neutral"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "neutral-secondary" } }, [
              _vm._v("neutral-secondary"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "light" } }, [_vm._v("light")]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "white" } }, [_vm._v("white")]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "danger" } }, [
              _vm._v("danger"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "prime" } }, [_vm._v("prime")]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "facebook" } }, [
              _vm._v("facebook"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "whatsapp" } }, [
              _vm._v("whatsapp"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { color: "dark" } }, [_vm._v("dark")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("small", [
            _c("strong", { staticClass: "text-danger" }, [
              _vm._v("Attention :"),
            ]),
            _vm._v(" "),
            _c("strong", [_vm._v("neutral")]),
            _vm._v(" is\n            for the default/system button and "),
            _c("strong", [_vm._v("offline")]),
            _vm._v(
              " is for a status (ex: master offline). Same\n            color, different uses."
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("outlined")]),
          _vm._v(
            " buttons inherit their borders from the current color\n            applied.\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-button", { attrs: { outlined: "", color: "primary" } }, [
              _vm._v("primary"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "secondary" } }, [
              _vm._v("secondary"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "available" } }, [
              _vm._v("available"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "busy" } }, [
              _vm._v("busy"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "neutral" } }, [
              _vm._v("neutral"),
            ]),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { outlined: "", color: "neutral-secondary" } },
              [_vm._v("neutral-secondary")]
            ),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "light" } }, [
              _vm._v("light"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "danger" } }, [
              _vm._v("danger"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "prime" } }, [
              _vm._v("prime"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "facebook" } }, [
              _vm._v("facebook"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "whatsapp" } }, [
              _vm._v("whatsapp"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { outlined: "", color: "dark" } }, [
              _vm._v("dark"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2 bg-primary p-4 mt-4" },
          [
            _c("base-button", { attrs: { outlined: "", color: "white" } }, [
              _vm._v("white"),
            ]),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { outlined: "", loading: "", color: "white" } },
              [_vm._v("white")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Link")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-button", { attrs: { link: "", color: "primary" } }, [
              _vm._v("primary"),
            ]),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { link: "", "font-normal": "", color: "secondary" } },
              [_vm._v("secondary (font-normal)")]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { link: "", "normal-case": "", color: "available" } },
              [_vm._v("Available (Normal-case)")]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              { attrs: { link: "", lowercase: "", color: "busy" } },
              [_vm._v("BUSY (LOWERCASE)")]
            ),
            _vm._v(" "),
            _c("base-button", { attrs: { link: "", color: "offline" } }, [
              _vm._v("offline"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { link: "", color: "neutral" } }, [
              _vm._v("neutral"),
            ]),
            _vm._v(" "),
            _c("base-button", { attrs: { link: "", color: "light" } }, [
              _vm._v("light"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2 bg-primary p-4 mt-4" },
          [
            _c(
              "base-button",
              { attrs: { link: "", color: "white" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "magnifying-glass"], size: "lg" },
                }),
                _vm._v(" white with icon"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Block")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("block")]),
          _vm._v(" buttons extend the full available width.\n        "),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { block: "" } }, [_vm._v("block button")]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-button", { attrs: { disabled: "" } }, [_vm._v("button")]),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", disabled: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { icon: "", disabled: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Loading")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("loading")]),
          _vm._v(" state"),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { loading: "" } }, [_vm._v("button")]),
        _vm._v(" "),
        _c("base-button", { attrs: { outlined: "", loading: "" } }, [
          _vm._v("button"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Skeleton-loader")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Skeleton-loader")]),
          _vm._v(" state"),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { "skeleton-loader": "" } }, [
          _vm._v("button"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Floating")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("Floating buttons are rounded and usually contain an icon."),
        ]),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", color: "secondary" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", outlined: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", disabled: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", loading: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { fab: "", outlined: "", loading: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Pill")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _vm._v("Pill buttons are full border-radius keeping their size"),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { small: "", pill: "" } }, [
          _vm._v("\n            Small Pill Button\n        "),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { pill: "" } }, [
          _vm._v("\n            medium Pill Button\n        "),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { large: "", pill: "" } }, [
          _vm._v("\n            large Pill Button\n        "),
        ]),
        _vm._v(" "),
        _c("base-button", { attrs: { small: "", pill: "", disabled: "" } }, [
          _vm._v("\n            small Pill Button Disabled\n        "),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Icon")]),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { icon: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { icon: "", color: "secondary" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { icon: "", color: "neutral" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { icon: "", color: "light" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Text")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-2" },
          [
            _c("base-button", { attrs: { text: "" } }, [
              _vm._v("J'ai compris !"),
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-secondary" },
              [
                _vm._v("It's a button inside a paragraph\n                "),
                _c("base-button", { attrs: { text: "" } }, [
                  _vm._v("J'ai compris !"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c("base-button", { attrs: { small: "" } }, [_vm._v("small button")]),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { small: "", outlined: "", color: "primary" } },
          [_vm._v("primary")]
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { small: "", fab: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c("base-button", { attrs: { small: "", disabled: "" } }, [
          _vm._v("button"),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c("base-button", { attrs: { large: "" } }, [_vm._v("large button")]),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { large: "", outlined: "", color: "primary" } },
          [_vm._v("primary")]
        ),
        _vm._v(" "),
        _c(
          "base-button",
          { attrs: { large: "", fab: "" } },
          [_c("font-awesome-icon", { attrs: { icon: ["fal", "xmark"] } })],
          1
        ),
        _vm._v(" "),
        _c("base-button", { attrs: { large: "", disabled: "" } }, [
          _vm._v("button"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }