var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { dense: "", error: _vm.loadingError } }, [
    _c(
      "div",
      { staticClass: "px-6 pt-6 pb-3" },
      [
        _c("modal-heading", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get("chat.modals.customer_end_chat_title", {
                  master_name: _vm.master.name,
                })
              ) +
              "\n        "
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center bg-neutral-100 p-4" }, [
      _c(
        "div",
        { staticClass: "flex-none justify-center mr-4" },
        [
          _c("base-avatar", {
            attrs: {
              large: "",
              outlined: "",
              name: _vm.master.name,
              photo: _vm.master.photo,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow" },
        [
          _c(
            "base-list",
            [
              _c("base-list-item", [
                _c(
                  "strong",
                  { staticClass: "text-primary text-base leading-4" },
                  [_vm._v(_vm._s(_vm.master.name))]
                ),
              ]),
              _vm._v(" "),
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(_vm.trans.get("chat.chat_duration")) + " :"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _c("base-timer", {
                          ref: "timerDuration",
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ hours, minutes, seconds }) {
                                return [
                                  hours > 0
                                    ? [_vm._v(_vm._s(hours + "h"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  minutes > 0
                                    ? [_vm._v(_vm._s(minutes + "min"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  seconds > 0
                                    ? [_vm._v(_vm._s(seconds + "s"))]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  seconds == 0 ? [_vm._v("0s")] : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("base-list-item", {
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-chevron-right"] },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(_vm._s(_vm.trans.get("generic.cost")) + " :"),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("formatMoney")(_vm.cost)) +
                            " (" +
                            _vm._s(_vm.trans.get("generic.stickers_included")) +
                            ")"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "p-6" }, [
      _vm.isFreeChat
        ? _c(
            "div",
            [
              _c(
                "modal-heading",
                { staticClass: "tracking-widest mb-0.5", attrs: { level: 2 } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-star text-4xl align-top",
                    attrs: { icon: ["far", "messages"] },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.trans.get(
                          "chat.modals.prime_advantage_free_chat_used"
                        )
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("modal-paragraph", { staticClass: "grid grid-cols-1 ml-12" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get(
                        "chat.modals.prime_advantage_free_chat_description"
                      )
                    ) +
                    "\n        "
                ),
              ]),
            ],
            1
          )
        : _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              _vm._s(
                _vm.trans.get("chat.modals.customer_end_chat_read_messages")
              )
            ),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mt-5" },
        [
          _vm.canLeaveComment
            ? _c("base-button", { on: { click: _vm.displayCommentModal } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans.get("chat.modals.leave_a_comment")) +
                    "\n            "
                ),
              ])
            : _c(
                "base-button",
                { attrs: { autofocus: "" }, on: { click: _vm.closeModal } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("chat.modals.continue_on_kang")) +
                      "\n            "
                  ),
                ]
              ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }