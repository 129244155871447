<template>
    <base-modal small>
        <form @submit="submitPhoneVerification">
            <modal-heading>
                {{ trans.get('generic.modals.user_phone_verification.confirm_your_number_to_start_the_chat') }}
            </modal-heading>

            <modal-paragraph>
                {{ trans.get('generic.modals.user_phone_verification.take_a_moment_to_confirm_your_phone_number') }}
            </modal-paragraph>

            <base-phone-input outlined
                              autofocus
                              mobile-only
                              ref="input_phone_number"
                              v-model="userFullMobilePhoneNumber"
                              :only-countries="config.get('sms.phone_number_available_countries')"
                              :initial-country="config.get('sms.default_phone_number_country_code')"
                              :label="this.trans.get('generic.phone')"
                              :placeholder="this.trans.get('generic.phone')"
                              v-bind:error="input_phone_number_error"
            />

            <div class="grid grid-cols-1 mt-6">
                <base-button type="submit">
                    {{ trans.get('generic.modals.user_phone_verification.send_sms') }}
                </base-button>
            </div>
        </form>
    </base-modal>
</template>

<script>
import ModalHeading from './elements/ModalHeading';
import ModalParagraph from './elements/ModalParagraph';
import BasePhoneInput from '../elements/BasePhoneInput';
import BaseButton from '../elements/BaseButton';
import {mapActions, mapGetters, mapState} from 'vuex';
import {ziggy_route} from '../ZiggyMixin';
import ValidationLang from "../ValidationLang";

export default {
    name: 'ModalUserPhoneVerification',
    components: {BaseButton, BasePhoneInput, ModalParagraph, ModalHeading},
    methods: {
        ...mapActions('modals', ['openModal']),
        getPhoneAsString() {
            if (Number.isInteger(this.userFullMobilePhoneNumber)) {
                return '+' + this.userFullMobilePhoneNumber.toString();
            }

            return this.userFullMobilePhoneNumber;
        },
        submitPhoneVerification(e) {
            e.preventDefault();

            let phone_input = this.$refs.input_phone_number;
            if (phone_input.checkValue()) {
                let active_country = phone_input.getActiveCountry();
                let phone_number = this.getPhoneAsString();
                let dialCode = active_country.dialCode;
                let iso2 = active_country.iso2;

                axios.post(ziggy_route('phone.free-customers-phone-verification.send-code'), {
                    phone: phone_number,
                }).then(response => {
                    this.openModal({
                        name: globals.USER_PHONE_VERIFICATION_CODE,
                        param: {
                            onSuccessVerificationCallback: this.onSuccessVerificationCallback,
                            phone_number: phone_number,
                        },
                    });
                }).catch(errorResponse => {
                    if (errorResponse.response) {
                        if (errorResponse.response.status === 429) {
                            this.input_phone_number_error = this.trans.get('generic.loading');
                        }
                        if (errorResponse.response.data.errors) {
                            let errorsInResponse = errorResponse.response.data.errors;
                            let validationLang = new ValidationLang();
                            let validationErrorsMap = validationLang.getMessageTranslated(errorsInResponse);
                            for (let value of validationErrorsMap.values()) {
                                this.input_phone_number_error += value + '<br/>';
                            }
                        } else {
                            this.input_phone_number_error = errorResponse.response.data.message;
                        }
                    } else if (errorResponse.request) {
                        this.input_phone_number_error = errorResponse.toString();
                    } else {
                        this.input_phone_number_error = errorResponse.message;
                    }
                });
            } else {
                this.input_phone_number_error = this.trans.get('validation.phone_number_invalid');
            }
        },
    },
    computed: {
        ...mapState('modals', ['additionalParams']),
        ...mapGetters('modals', ['getParam']),
        hasError() {
            return this.input_phone_number_error.length > 0;
        },
    },
    data() {
        return {
            userFullMobilePhoneNumber: '',
            input_phone_number_error: '',
            onSuccessVerificationCallback: () => {
            },
        };
    },
    mounted() {
        this.userFullMobilePhoneNumber = this.getParam['user-phone-verification']['phone_number'];
        this.onSuccessVerificationCallback = this.getParam['user-phone-verification']['onSuccessVerificationCallback'];
    },
};
</script>
