<template>
    <base-modal small :error="loadingError">
        <modal-heading>
            {{ trans.get('chat.modals.master_refused_chat_title', {'master_name': name}) }}
        </modal-heading>

        <p>{{ trans.get('chat.modals.master_refused_chat_text') }}</p>

        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button autofocus @click="cancel">
                {{ trans.get('generic.i_understood') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import {mapMutations} from 'vuex';
import {mapGetters} from 'vuex';
import BaseButton from '../elements/BaseButton';
import ModalHeading from './elements/ModalHeading';

export default {
    components: {BaseButton, ModalHeading},
    computed: {
        ...mapState('modals', ['additionalParams']),
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapMutations('modals', [
            'changeLoadingState', 'addParam',
        ]),
        ...mapActions('chat', []),
        cancel() {
            this.$emit('cancel');
        },
    },
    data()
    {
        return {
            loadingError: '',
            name: '',
        };
    },
    mounted()
    {
        if (this.getParam['chat-customer-info-master-refused-live-chat']['name']) {
            this.name = this.getParam['chat-customer-info-master-refused-live-chat']['name'];
        }
    },
};
</script>
