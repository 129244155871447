var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { small: "", error: _vm.loadingError } }, [
    _vm.refillSuccess
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("chat.chat_live_refill_success")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-5" },
              [
                _c("base-button", { on: { click: _vm.closeModal } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("chat.modals.back_to_chat")) +
                      "\n            "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm.refillFailed
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("chat.chat_live_refill_error")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-5" },
              [
                _c("base-button", { on: { click: _vm.closeModal } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans.get("chat.modals.back_to_chat")) +
                      "\n            "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.credit_soon_exhausted")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _vm.hasTime
              ? _c("base-list-item", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-chevron-right"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.trans.get("generic.time_left_is")) +
                                "\n                "
                            ),
                            _c("base-timer", {
                              ref: "billing_timer",
                              attrs: { countdown: "", "auto-start": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ hours, minutes, seconds }) {
                                      return [
                                        hours > 0
                                          ? [_vm._v(_vm._s(hours + "h"))]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        minutes > 0
                                          ? [_vm._v(_vm._s(minutes + "min"))]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        seconds > 0
                                          ? [_vm._v(_vm._s(seconds + "s"))]
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                846791857
                              ),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1094774429
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-5" },
              [
                _c(
                  "base-button",
                  {
                    attrs: { loading: _vm.loadingRefill },
                    on: { click: _vm.refill },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.trans.get(
                            "chat.chat_live_almost_over_notif_customer_button",
                            { amount: _vm.refillAmount }
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }