var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    { attrs: { error: _vm.loadingError } },
    [
      _c("modal-heading", [
        _vm._v(_vm._s(_vm.trans.get("generic.password_reset_title"))),
      ]),
      _vm._v(" "),
      _vm.postFormSucceed
        ? _c(
            "div",
            [
              _c("modal-paragraph", [
                _vm._v(_vm._s(_vm.trans.get("generic.password_reseted"))),
              ]),
              _vm._v(" "),
              _c(
                "base-button",
                { attrs: { block: "" }, on: { click: _vm.cancel } },
                [_vm._v("OK")]
              ),
            ],
            1
          )
        : _c("div", [
            _c(
              "form",
              { on: { submit: _vm.postForm } },
              [
                _c("modal-paragraph", [
                  _vm._v(_vm._s(_vm.trans.get("generic.enter_new_password"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("base-text-input", {
                      attrs: {
                        type: "password",
                        autofocus: "",
                        outlined: "",
                        label: _vm.trans.get("generic.password"),
                        placeholder: _vm.trans.get("generic.password"),
                        error: _vm.errorPassword,
                      },
                      on: {
                        keydown: function ($event) {
                          _vm.errorPassword = ""
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "envelope"] },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("base-text-input", {
                      attrs: {
                        type: "password",
                        outlined: "",
                        label: _vm.trans.get("generic.password_confirmation"),
                        placeholder: _vm.trans.get(
                          "generic.password_confirmation"
                        ),
                        error: _vm.errorPasswordConfirmation,
                      },
                      on: {
                        keydown: function ($event) {
                          _vm.errorPasswordConfirmation = ""
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "envelope"] },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.password_confirmation,
                        callback: function ($$v) {
                          _vm.password_confirmation = $$v
                        },
                        expression: "password_confirmation",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    attrs: {
                      type: "submit",
                      block: "",
                      loading: _vm.formLoading,
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.validate")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }