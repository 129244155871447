var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.getSelectedParticipants.length === 0
    ? _c("div", { staticClass: "conversation-messages-suggestion-box" }, [
        _c(
          "div",
          { staticClass: "conversation-messages-suggestion-box-container" },
          [
            _c("img", {
              staticClass: "conversation-messages-suggestion-box-image",
              attrs: {
                src: _vm.assetManager.getImageAsset("chat/chat_en_ligne.gif"),
                alt: "Online chat",
              },
            }),
            _vm._v(" "),
            !this.meFromUserStore().is_master
              ? _c(
                  "span",
                  {
                    staticClass: "conversation-messages-suggestion-box-message",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.choose_a_master_to_start_chat"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _c(
                  "span",
                  {
                    staticClass: "conversation-messages-suggestion-box-message",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.relaunching_your_customers"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
          ]
        ),
      ])
    : this.speakingWithMaster
    ? _c("div", { staticClass: "conversation-messages-suggestion-box" }, [
        this.isMasterOffline(this.interlocutor())
          ? _c(
              "div",
              { staticClass: "conversation-messages-suggestion-box-container" },
              [
                _c("img", {
                  staticClass: "conversation-messages-suggestion-box-image",
                  attrs: {
                    src: _vm.assetManager.getImageAsset(
                      "chat/chat_hors_ligne.gif"
                    ),
                    alt: "Offline chat",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "conversation-messages-suggestion-box-message conversation-messages-suggestion-box-message--offline",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.choose_master_is_offline",
                            { master_name: this.interlocutor().name }
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "min-w-30",
                    attrs: { color: "neutral", outlined: "", small: "" },
                    on: { click: _vm.displayModalNotifMasterOnline },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "bell"], size: "1x" },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.trans.get("generic.alert_notif_master_online")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : this.isMasterBusy(this.interlocutor())
          ? _c(
              "div",
              { staticClass: "conversation-messages-suggestion-box-container" },
              [
                _c("img", {
                  staticClass: "conversation-messages-suggestion-box-image",
                  attrs: {
                    src: _vm.assetManager.getImageAsset("chat/chat_occupe.gif"),
                    alt: "Busy chat",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "conversation-messages-suggestion-box-message conversation-messages-suggestion-box-message--busy",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.choose_master_is_busy",
                            {
                              master_name: this.interlocutor().name,
                            }
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            )
          : this.isMasterAvailableForChat(this.interlocutor())
          ? _c(
              "div",
              { staticClass: "conversation-messages-suggestion-box-container" },
              [
                _c("img", {
                  staticClass: "conversation-messages-suggestion-box-image",
                  attrs: {
                    src: _vm.assetManager.getImageAsset(
                      "chat/chat_en_ligne.gif"
                    ),
                    alt: "Online chat",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "conversation-messages-suggestion-box-message",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.choose_start_a_conversation_with",
                            { master_name: this.interlocutor().name }
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-neutral-50 flex my-5 p-5 sm:rounded-2xl sm:w-9/12 w-full",
                  },
                  [
                    _c("img", {
                      staticClass: "h-24 mr-5 rounded-full w-24",
                      attrs: {
                        src: this.interlocutor().photo,
                        alt: "Photo master",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex flex-col justify-between w-full" },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center justify-between",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-bold text-primary" },
                                [_vm._v(_vm._s(this.interlocutor().name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-bold" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(this.interlocutor().calls_count) +
                                      "\n                        "
                                  ),
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fas", "phone-square-alt"],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "block mt-2" }, [
                            _vm._v(
                              _vm._s(this.interlocutor().short_description)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between mt-2",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "font-bold text-star" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      this.interlocutor().average_mark / 10
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "star"] },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", [
                              this.interlocutor()
                                .cost_by_minute_without_advantage !==
                              this.interlocutor().cost_by_minute
                                ? _c("span", { staticClass: "line-through" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("formatMoneyPerMinute")(
                                            this.interlocutor()
                                              .cost_by_minute_without_advantage /
                                              100
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-bold text-primary" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoneyPerMinute")(
                                        this.interlocutor().cost_by_minute / 100
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "min-w-30",
                    attrs: { outlined: "", small: "" },
                    on: { click: _vm.startLiveChat },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "message-lines"] },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.trans.get("generic.chat_live")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "conversation-messages-suggestion-box-container" },
              [
                _c("img", {
                  staticClass: "conversation-messages-suggestion-box-image",
                  attrs: {
                    src: _vm.assetManager.getImageAsset(
                      "chat/chat_desactive.gif"
                    ),
                    alt: "Busy chat",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "conversation-messages-suggestion-box-message",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          this.trans.get(
                            "chat.suggestion_box.choose_master_is_not_available",
                            {
                              master_name: this.interlocutor().name,
                            }
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-link",
                  { attrs: { href: this.interlocutor().url } },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "min-w-30",
                        attrs: { color: "primary", small: "" },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "user"], size: "1x" },
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.trans.get("generic.see_the_master_profile")
                            ) +
                            "\n            "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }