<template>
    <div v-if="hasSticker"
         v-bind:class="['conversation-sticker-animations', {'minimized': this.isConversationInputFocused }]">
        <base-lottie-file-player :src="src"
                                 class="conversation-sticker-animations-player"
                                 ref="player"
                                 @complete="complete"
                                 autoplay/>
    </div>
</template>

<style lang="scss">
.conversation-sticker-animations {
    @apply absolute flex items-center w-full h-full bottom-0 left-0 pointer-events-none;

    &-player {
        @apply w-full h-full;
    }

    &.minimized {
        @apply h-1/2 sm:h-full;
    }
}

</style>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseVideoPlayer from "../elements/BaseVideoPlayer.vue";
import BaseLottieFilePlayer from "../elements/BaseLottieFilePlayer.vue";

export default {
    name: 'ConversationStickerAnimations',
    components: {BaseLottieFilePlayer, BaseVideoPlayer},
    created() {
        this.unwatchStickers = this.$store.watch(this.getStickersQueue, () => {
            this.playNextStickerInQueue();
        }, {immediate: true});
    },
    computed: {
        ...mapGetters('chat', ['getNextStickerInQueue', 'isConversationInputFocused']),
        src() {
            return this.hasSticker ? this.sticker.content.url : null;
        },
        hasSticker() {
            return this.sticker !== null;
        },
    },
    methods: {
        ...mapGetters('chat', ['getStickersQueue']),
        ...mapActions('chat', ['removeStickerInQueue']),
        complete() {
            this.removeStickerInQueue(this.sticker);
            this.sticker = null;
            this.playNextStickerInQueue();
        },
        playNextStickerInQueue() {
            let stickerInQueue = this.getNextStickerInQueue;
            if (!this.hasSticker && stickerInQueue) {
                this.sticker = stickerInQueue;
                this.$nextTick(() => {
                    this.$refs.player.init();
                });
            }
        },
    },
    beforeDestroy() {
        this.unwatchStickers();
    },
    data() {
        return {
            sticker: null,
        }
    },
}
</script>
