export default {
    namespaced: true,

    state: {
        coupon: null,
    },

    mutations: {
        setCoupon: (state, coupon) => {
            state.coupon = coupon;
        },
    },
    getters: {
        getCoupon: (state) => state.coupon,
    },
    actions: {
        fetchCoupon({commit})
        {
            if (window.coupon !== 'null') {
                commit('setCoupon', JSON.parse(window.coupon));
            }
        },
    },
};
