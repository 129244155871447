var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("conversation-message-content-text", {
        attrs: {
          text: _vm.trans.get("chat.chat_bot.offer_answer_text", {
            nickname: _vm.nickname,
          }),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-3 px-3" },
        [
          _c(
            "base-button",
            { attrs: { small: "" }, on: { click: _vm.askDisponibilities } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get("chat.chat_bot.knowing_the_availability")
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }