var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Badge")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("base-badge", [_c("base-button", [_vm._v("Button")])], 1),
            _vm._v(" "),
            _c(
              "base-badge",
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("color")]),
            _vm._v(": String - "),
            _c("em", [
              _vm._v(
                "'primary', 'secondary', 'available', 'busy', 'offline',\n                'neutral', 'light', 'white', 'danger', 'prime', 'facebook', 'whatsapp', 'horoscope'"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("distance")]),
            _vm._v(": String | Number"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("bottom")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("center")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("content")]),
            _vm._v(": String | Number,"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("dot")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("floating")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("inline")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("left")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean,")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("square")]), _vm._v(": Boolean,")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Color")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-4 mt-4" },
          [
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "primary", content: "1" },
              },
              [_c("base-button", [_vm._v("primary")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "secondary", content: "2" },
              },
              [_c("base-button", [_vm._v("secondary")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "available", content: "3" },
              },
              [_c("base-button", [_vm._v("available")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "busy", content: "4" },
              },
              [_c("base-button", [_vm._v("busy")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "offline", content: "5" },
              },
              [_c("base-button", [_vm._v("offline")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "neutral", content: "6" },
              },
              [_c("base-button", [_vm._v("neutral")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "light", content: "7" },
              },
              [_c("base-button", [_vm._v("light")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "white", content: "8" },
              },
              [_c("base-button", [_vm._v("white")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "danger", content: "9" },
              },
              [_c("base-button", [_vm._v("danger")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "prime", content: "10" },
              },
              [_c("base-button", [_vm._v("prime")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "facebook", content: "11" },
              },
              [_c("base-button", [_vm._v("facebook")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "whatsapp", content: "12" },
              },
              [_c("base-button", [_vm._v("whatsapp")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                staticClass: "mr-3",
                attrs: { outlined: "", color: "horoscope", content: "13" },
              },
              [_c("base-button", [_vm._v("horoscope")])],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Dot")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { dot: "" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { dot: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { outlined: "" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { outlined: "", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { outlined: "", content: "123" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Square")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { square: "" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { square: "", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { square: "", outlined: "", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { square: "", outlined: "", content: "123" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Left")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { left: "", color: "secondary" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { left: "", dot: "", color: "secondary" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { left: "", content: "12", color: "secondary" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Center")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { center: "", color: "secondary" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { center: "", color: "secondary", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { center: "", color: "secondary", content: "10000" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Bottom")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { bottom: "", color: "secondary" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { bottom: "", color: "secondary", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { bottom: "", color: "secondary", content: "10000" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "browser"], size: "3x" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Inline")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { inline: "", outlined: "" } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { inline: "", outlined: "", dot: "" } },
              [_c("base-button", [_vm._v("Button")])],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              {
                attrs: {
                  inline: "",
                  outlined: "",
                  content: "123",
                  color: "danger",
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "trash-can"], size: "3x" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v(" Inbox\n                "),
                _c("base-badge", { attrs: { inline: "", content: "123" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Distance")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.distance,
                expression: "distance",
              },
            ],
            attrs: {
              type: "range",
              min: "-4",
              max: "4",
              value: "0",
              step: "0.05",
            },
            domProps: { value: _vm.distance },
            on: {
              __r: function ($event) {
                _vm.distance = $event.target.value
              },
            },
          }),
          _vm._v(" " + _vm._s(_vm.distance) + "rem\n        "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { left: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { center: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-8 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { bottom: "", left: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { bottom: "", center: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "base-badge",
              { attrs: { bottom: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex gap-16 mt-4" },
          [
            _c(
              "base-badge",
              { attrs: { inline: "", distance: _vm.distance } },
              [
                _c("base-avatar", {
                  attrs: {
                    large: "",
                    photo: "https://via.placeholder.com/150",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }