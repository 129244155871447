var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", [
    _vm.error === "MASTER_IS_BUSY"
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                _vm._s(
                  _vm.trans.get("recall-customer.modals.impossible_recall", {
                    customer_nick_name: _vm.customer.nick_name,
                  })
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(
                _vm._s(
                  _vm.trans.get(
                    "recall-customer.modals.you_are_already_in_a_conversation_with_a_customer"
                  )
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center my-8" },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-warning",
                  attrs: { icon: ["fas", "triangle-exclamation"], size: "4x" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-8" },
              [
                _c(
                  "base-button",
                  { attrs: { block: "" }, on: { click: _vm.hide } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.ok")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm.error === "CUSTOMER_IS_BUSY"
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                _vm._s(
                  _vm.trans.get("recall-customer.modals.customer_is_busy", {
                    customer_nick_name: _vm.customer.nick_name,
                  })
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(
                _vm._s(
                  _vm.trans.get("recall-customer.modals.you_cannot_recall")
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center my-8" },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-primary",
                  attrs: { icon: ["fal", "face-sad-tear"], size: "4x" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-8" },
              [
                _c(
                  "base-button",
                  { attrs: { block: "" }, on: { click: _vm.hide } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.ok")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm.error === "MAX_TIME_TO_RECALL_CUSTOMER_EXCEEDED" || _vm.seconds <= 0
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                _vm._s(
                  _vm.trans.get(
                    "recall-customer.modals.call_with_customer_expired",
                    { customer_nick_name: _vm.customer.nick_name }
                  )
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(
                _vm._s(
                  _vm.trans.get(
                    "recall-customer.modals.you_cannot_recall_this_customer_anymore"
                  )
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center my-8" },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-primary",
                  attrs: { icon: ["fal", "face-sad-tear"], size: "4x" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(
                _vm._s(
                  _vm.trans.get(
                    "recall-customer.modals.do_not_forget_you_have_one_minute_to_recall_a_customer"
                  )
                ) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-8" },
              [
                _c(
                  "base-button",
                  { attrs: { block: "" }, on: { click: _vm.hide } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans.get("generic.ok")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm.seconds > 0
      ? _c(
          "div",
          [
            _c("modal-heading", [
              _vm._v(
                _vm._s(_vm.trans.get("recall-customer.modals.recall_customer"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "-mx-6 px-6 py-4 mb-4 bg-neutral-100 flex items-start justify-left",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-white flex h-20 items-center justify-center mr-5 rounded-full w-20",
                  },
                  [
                    _c("img", {
                      staticClass: "w-14",
                      attrs: {
                        src: _vm.getSegmentPhoto(_vm.customer.segment),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex flex-col justify-center" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-primary text-xl mb-2 font-bold leading-6",
                      },
                      [_vm._v(_vm._s(_vm.customer.nick_name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-list",
                      [
                        _c("base-list-item", {
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fas", "circle-chevron-right"],
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans.get("generic.user_segment.type")
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "description",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans.get(
                                        "generic.user_segment." +
                                          _vm.customer.segment
                                      )
                                    ) + "\n                        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("modal-paragraph", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get(
                      "recall-customer.modals.do_you_want_to_recall_customer",
                      { customer_nick_name: _vm.customer.nick_name }
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow" },
              [
                _c(
                  "base-list",
                  [
                    _c("base-list-item", {
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "circle-chevron-right"],
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("base-timer", {
                                attrs: {
                                  time: _vm.seconds,
                                  "auto-start": "",
                                  countdown: "",
                                },
                                on: { end: () => (_vm.seconds = 0) },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ minutes, seconds }) {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.trans.get(
                                                "recall-customer.modals.time_left_to_recall",
                                                {
                                                  time:
                                                    (minutes > 0
                                                      ? minutes + "min"
                                                      : "") + seconds,
                                                }
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-8" },
              [
                _c(
                  "base-button",
                  {
                    attrs: { loading: _vm.loading, block: "" },
                    on: { click: _vm.recallCustomer },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.trans.get(
                            "recall-customer.modals.i_restart_the_call"
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }