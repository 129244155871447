var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get(
                "generic.modals.conversation_report.report_a_problem"
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      !_vm.postFormSucceed
        ? _c("div", [
            _c(
              "form",
              { on: { submit: _vm.postForm } },
              [
                _c("modal-paragraph", [
                  _c("p", { staticClass: "font-smaller" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.trans.get(
                            "generic.modals.conversation_report.problem_with_this_consultation"
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "modal-paragraph",
                  [
                    _c("base-select", {
                      attrs: {
                        placeholder: _vm.trans.get(
                          "generic.modals.conversation_report.reason_for_report"
                        ),
                        label: _vm.trans.get(
                          "generic.modals.conversation_report.reason_for_report"
                        ),
                        items: _vm.subjectItems,
                        error: _vm.subjectError,
                        block: "",
                        outlined: "",
                      },
                      model: {
                        value: _vm.subject,
                        callback: function ($$v) {
                          _vm.subject = $$v
                        },
                        expression: "subject",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "modal-paragraph",
                  [
                    _c("base-textarea", {
                      attrs: {
                        outlined: "",
                        placeholder: _vm.trans.get(
                          "generic.modals.conversation_report.optional_message"
                        ),
                        label: _vm.trans.get(
                          "generic.modals.conversation_report.optional_message"
                        ),
                        rows: 5,
                        "max-length": 1000,
                      },
                      model: {
                        value: _vm.message,
                        callback: function ($$v) {
                          _vm.message = $$v
                        },
                        expression: "message",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid grid-cols-2 gap-4 mt-5" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { type: "button", outlined: "" },
                        on: { click: _vm.closeModal },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.cancel")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      { attrs: { type: "submit", loading: _vm.formLoading } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.trans.get("generic.validate")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c("modal-paragraph", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get(
                        "generic.modals.conversation_report.the_problem_has_been_reported"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: { autofocus: "", block: "" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v(_vm._s(_vm.trans.get("generic.close")))]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }