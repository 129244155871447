var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasSticker
    ? _c(
        "div",
        {
          class: [
            "conversation-sticker-animations",
            { minimized: this.isConversationInputFocused },
          ],
        },
        [
          _c("base-lottie-file-player", {
            ref: "player",
            staticClass: "conversation-sticker-animations-player",
            attrs: { src: _vm.src, autoplay: "" },
            on: { complete: _vm.complete },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }