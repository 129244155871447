var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Tooltip")]),
        _vm._v(" "),
        _c("base-tooltip", {
          attrs: {
            type: "info",
            icon: ["fal", "circle-info"],
            "text-tooltip": "Text in Tooltip box",
            text: "Example",
            position: "bottom",
          },
        }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("type")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'info', 'success', 'warning', 'danger'")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("position")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'top', 'bottom', 'right', 'left'")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("text")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("text-tooltip")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("icon")]), _vm._v(": Array")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Type")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : info / success / warning / danger"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "space-y-2" }, [
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "success",
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "warning",
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "danger",
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Icons")]),
        _vm._v(" "),
        _c("div", { staticClass: "space-y-2" }, [
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  icon: ["fal", "circle-info"],
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  icon: ["fal", "envelope"],
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  icon: ["far", "cake-candles"],
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  icon: ["fal", "message-lines"],
                  "text-tooltip": "Text in Tooltip box",
                  text: "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Position")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("type")]),
          _vm._v(" : top / bottom / right / left"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "space-y-2" }, [
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "info",
                  icon: ["fal", "circle-info"],
                  text: "Top",
                  "text-tooltip": "I'm an Alert Usage Example",
                  position: "top",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "success",
                  icon: ["fal", "envelope"],
                  text: "Bottom",
                  "text-tooltip": "I'm an Alert Usage Example",
                  position: "bottom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "warning",
                  icon: ["far", "cake-candles"],
                  text: "Right",
                  "text-tooltip": "I'm an Alert Usage Example",
                  position: "right",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("base-tooltip", {
                attrs: {
                  type: "danger",
                  icon: ["fal", "message-lines"],
                  text: "Left",
                  "text-tooltip": "I'm an Alert Usage Example",
                  position: "left",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }