var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayBasket
    ? _c(
        "div",
        { staticClass: "reset-this pl-4 pr-2" },
        [
          _c(
            "base-menu",
            {
              attrs: { icon: "", "drop-left": "", "open-on-hover": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "header-basket-menu-title" }, [
                          _vm._v(
                            _vm._s(_vm.trans.get("header.basket.my_basket"))
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _vm.isBasketEmpty
                          ? _c(
                              "base-menu-item",
                              { staticClass: "header-basket-menu-item" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.trans.get(
                                        "header.basket.your_basket_is_empty"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _c(
                              "base-menu-item",
                              {
                                staticClass: "text-sm",
                                attrs: {
                                  "background-color": "dark",
                                  color: "white",
                                },
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: {
                                      icon: "",
                                      small: "",
                                      color: "white",
                                    },
                                    on: { click: _vm.emptyBasket },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fal", "trash-can"] },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { on: { click: _vm.redirectToOfferPage } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getBasketDetails
                                            .digitalOfferProductTitle
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "grid grid-cols-2" },
                                      [
                                        _c("span", { staticClass: "italic" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans.get(
                                                "header.basket.quantity"
                                              )
                                            ) +
                                              " : " +
                                              _vm._s(
                                                _vm.getBasketDetails.quantity
                                              )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatMoney")(
                                                  _vm.getBasketDetails
                                                    .orderPrice / 100
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        !_vm.isBasketEmpty
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex bg-neutral-500 justify-center py-2",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "text-white uppercase",
                                    attrs: { small: "", color: "danger" },
                                    on: { click: _vm.orderAction },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.trans.get("header.basket.order")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3205420555
              ),
            },
            [
              _c(
                "base-button",
                {
                  attrs: { icon: "", small: "", color: "white" },
                  on: { click: _vm.orderAction },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "basket-shopping"], size: "lg" },
                  }),
                  _vm._v(" "),
                  _c("base-pin", {
                    staticClass: "text-xs",
                    attrs: { amount: _vm.getNbItemsBasket },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }