<template>
    <div class="offer-card-small offer-card-small-loader">
        <div class="flex items-center w-full">
            <div class="offer-card-small-col-left">
                <base-avatar color="neutral-secondary" skeleton-loader x-large/>
            </div>

            <div class="offer-card-small-col-right">
                <div class="offer-card-small-col-right-header">
                    <p class="offer-card-small-name">
                        <span class="skeleton-text">&nbsp;</span>
                    </p>
                    <p class="offer-card-small-price">
                        <span class="skeleton-text">&nbsp;</span>
                    </p>
                </div>

                <div class="flex-grow flex min-w-0">
                    <div class="flex-grow min-w-0">
                        <p class="offer-card-small-description">
                            <span class="skeleton-text leading-4 max-h-4">&nbsp;</span>
                        </p>
                    </div>
                </div>

                <div class="offer-card-actions">
                    <base-button class="offer-card-action w-14" color="neutral-secondary" small skeleton-loader/>
                    <base-button class="offer-card-action w-14" color="neutral-secondary" small skeleton-loader/>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.offer-card-small-loader {
    &-name {
        @apply pr-8;
    }

    &-price {
        @apply w-1/4;
    }

    .skeleton-text {
        @apply block bg-neutral-300 animate-pulse rounded h-3/4;
    }
}
</style>

<script>
import BaseAvatar from "../elements/BaseAvatar.vue";
import BaseButton from "../elements/BaseButton.vue";
import BaseBadge from "../elements/BaseBadge.vue";

export default {
    name: 'OfferCardSmallLoader',
    components: {BaseBadge, BaseButton, BaseAvatar}
}
</script>
