var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _c("modal-heading", [_vm._v(_vm._s(_vm.trans.get("generic.well_done")))]),
      _vm._v(" "),
      _c("modal-paragraph", {
        domProps: {
          innerHTML: _vm._s(
            this.trans.get(
              "generic.promo_code.you_benefit_from_several_minutes_of_free_clairvoyance"
            )
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "px-4" },
        [
          _c("modal-heading", { attrs: { level: 2 } }, [
            _vm._v(
              _vm._s(_vm.trans.get("generic.promo_code.i_choose_my_master"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full grid grid-cols-1 place-items-center mb-2" },
            [
              _c("img", {
                staticClass: "max-h-20",
                attrs: {
                  src: _vm.assetManager.getImageAsset("promo-code/masters.png"),
                  alt: _vm.trans.get("generic.site_name"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("modal-heading", { attrs: { level: 2 } }, [
            _vm._v(_vm._s(_vm.trans.get("generic.promo_code.i_click_on"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full grid grid-cols-1 place-items-center my-4" },
            [
              _c("img", {
                staticClass: "max-h-8",
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    "promo-code/call_and_chat_buttons.png"
                  ),
                  alt: _vm.trans.get("generic.site_name"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("modal-heading", { attrs: { level: 2 } }, [
            _vm._v(
              _vm._s(
                _vm.trans.get("generic.promo_code.im_online_with_my_master")
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full grid grid-cols-1 place-items-center mb-2" },
            [
              _c("img", {
                staticClass: "max-h-16",
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    "promo-code/master_in_consultation.png"
                  ),
                  alt: _vm.trans.get("generic.site_name"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-1 mt-8" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.config.get("app.promo_code_redirect_button_url"),
            },
          },
          [
            _c("base-button", { attrs: { block: "" } }, [
              _vm._v(_vm._s(_vm.trans.get("generic.i_take_advantage_of_it"))),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }