<template>
    <div class="flex flex-col sponsorship-share">
        <base-alert v-if="copy_success"
                    ref="alert"
                    dismissible
                    :delay="5000"
                    small
                    type="success"
                    class="top-2 right-0 absolute z-10"
        >{{trans.get('sponsorship.sponsor_link_was_copied')}}</base-alert>

        <base-text-input :value="link" readonly="readonly" outlined class="pb-3">
            <template #icon><font-awesome-icon :icon="['fal', 'link']"/></template>
        </base-text-input>
        <base-button block @click="copy_link">{{trans.get('sponsorship.copy_link')}}</base-button>
        <div class="flex items-center my-4">
            <hr class="flex-1 my-0"><span class="px-4">{{trans.get('sponsorship.or')}}</span><hr class="flex-1 my-0">
        </div>
        <base-button block color="facebook" @click="fbshome_click()"><font-awesome-icon :icon="['fab', 'facebook-f']" class="mr-2" size="lg"/>{{trans.get('sponsorship.share_by_facebook')}}</base-button>
    </div>
</template>

<script>
import BaseButton from "../elements/BaseButton";
import BaseAlert from "../elements/BaseAlert";

export default {
    name: "sponsorship-share",
    components: {BaseAlert, BaseButton},
    props: {
        link: {
            type: String
        },
    },
    data: () => {
        return {
            copy_success: false,
        };
    },
    methods: {
        fbshome_click() {
            const title = document.title;
            window.open('https://www.facebook.com/sharer.php?u=' + encodeURIComponent(this.link) + '&t=' + encodeURIComponent(title), 'sharer', 'toolbar=0,status=0,width=626,height=436');
            return false;
        },
        copy_link() {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                this.copy_success = true;
                if(this.$refs.alert) {
                    this.$refs.alert.show();
                }
                return navigator.clipboard.writeText(this.link);
            }
            return Promise.reject('The Clipboard API is not available.');
        }
    },
}
</script>
