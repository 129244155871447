<template>
    <base-modal dense scrollable-content @scroll="onScroll">
        <template #content-header>
            <follow-up-to-one-search-bar
                v-model="search"
                @back="backToFollowUpChoiceModal"
                @input="initialLoad"/>
        </template>

        <div class="modal-follow-up-to-one-list">
            <template v-for="conversation in conversations">
                <follow-up-to-one-search-list-item :conversation="conversation" @selectItem="followUpWithConversation"/>
            </template>

            <div v-if="loading || this.page < this.lastPage" id="follow-up-list-items-loader" class="text-center p-4">
                <font-awesome-icon :icon="['fas', 'spinner']" class="text-primary" spin size="3x"/>
            </div>

            <div v-else-if="!loading && conversations.length === 0" class="flex items-center justify-center p-5">
                <font-awesome-icon :icon="['far', 'face-frown']" class="text-primary" size="5x"/>
            </div>
        </div>
    </base-modal>
</template>

<style lang="scss">
.modal-follow-up-to-one-list {
    @apply w-full h-full;
}
</style>

<script>
import BaseTextInput from '../elements/BaseTextInput';
import BaseButton from '../elements/BaseButton';
import {mapActions} from 'vuex';
import BaseTimer from '../elements/BaseTimer';
import FollowUpToOneSearchBar from '../chat/FollowUpToOneSearchBar';
import FollowUpToOneSearchListItem from '../chat/FollowUpToOneSearchListItem';
import {ziggy_route} from '../ZiggyMixin';

export default {
    name: 'ModalFollowUpToOne',
    components: {FollowUpToOneSearchListItem, FollowUpToOneSearchBar, BaseTimer, BaseTextInput, BaseButton},
    data() {
        return {
            loading: false,
            search: '',
            conversations: [],
            page: 1,
            lastPage: Infinity,
            axiosCancelToken: null
        };
    },
    methods: {
        ...mapActions('modals', ['openModal', 'closeModal']),
        ...mapActions('chat', ['selectConversationAsFollowUp']),
        backToFollowUpChoiceModal() {
            this.openModal({
                name: globals.FOLLOW_UP_TYPE_CHOICE,
            });
        },
        initialLoad() {
            this.load(false);
        },
        loadNextPage()
        {
            this.load(true);
        },
        load(nextPage)
        {
            if (!nextPage) {
                this.lastPage = Infinity;
                this.conversations = [];
            }

            if (this.page >= this.lastPage) {
                return false;
            }

            if (nextPage) {
                this.page++;
            } else {
                this.page = 1;
            }

            if (this.axiosCancelToken !== null) {
                this.axiosCancelToken.cancel();
            }

            this.loading = true;

            this.axiosCancelToken = axios.CancelToken.source();

            axios.post(ziggy_route('chat.searchFollowUpCustomer', {page: this.page}), {
                keyword: this.search,
            }, {cancelToken: this.axiosCancelToken.token}).then(response => {
                this.loading = false;
                this.conversations.push(...response.data.data);
                this.lastPage = response.data.last_page;
            }).catch(error => {
                this.lastPage = this.page;
            });
        },
        followUpWithConversation(conversation) {
            this.selectConversationAsFollowUp(conversation);
        },
        onScroll(event) {
            if (!this.loading && event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - document.querySelector('#follow-up-list-items-loader').clientHeight) {
                this.loadNextPage();
            }
        }
    },
    mounted() {
        this.initialLoad();
    },
};
</script>
