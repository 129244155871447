<template>
    <base-modal :small="small">
        <modal-heading v-if="hasTitle">{{ title }}</modal-heading>

        <img :src="src" :alt="alt"/>

        <div class="grid grid-cols-1 gap-4 mt-5">
            <base-button @click="closeModal">{{ buttonText }}</base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from "./elements/ModalHeading";
import BaseButton from "../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ModalImage",
    components: {BaseButton, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam']),
        hasTitle() {
            return this.title !== undefined && this.title.length > 0;
        },
        externalSource() {
            return this.externalSource !== undefined && this.externalSource === true;
        },
        src() {
            return this.externalSource ? this.url : this.assetManager.getImageAsset(this.url);
        }
    },
    methods: {
        ...mapActions('modals', ['closeModal'])
    },
    data() {
        return {
            small: false,
            title: '',
            url: '',
            alt: '',
            externalSource: false,
            buttonText: this.trans.get('generic.close'),
        }
    },
    mounted() {
        this.small = this.getParam['image']['small'];
        this.title = this.getParam['image']['title'];
        this.url = this.getParam['image']['url'];
        this.alt = this.getParam['image']['alt'];
        this.externalSource = this.getParam['image']['externalSource'];
        if (this.getParam['image']['buttonText'] !== undefined && this.getParam['image']['buttonText'] !== '') {
            this.buttonText = this.getParam['image']['buttonText'];
        }
    }
}
</script>
