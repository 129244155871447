var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-modal",
    [
      _c("modal-heading", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.trans.get("generic.master_is_no_longer_available", {
                master_name: _vm.master.name,
              })
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("modal-paragraph", [
        _vm._v(_vm._s(_vm.trans.get("generic.please_retry_later"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center my-8" },
        [
          _c("font-awesome-icon", {
            staticClass: "text-primary",
            attrs: { icon: ["fal", "face-sad-tear"], size: "4x" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-5" },
        [
          _c(
            "base-button",
            {
              attrs: { autofocus: "" },
              on: { click: _vm.closeModalAndReload },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("generic.back_to_site")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }