var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Textarea")]),
        _vm._v(" "),
        _c("base-textarea"),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("id")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("rows")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("maxRows")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("rowHeight")]), _vm._v(": Number")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("autocomplete")]),
            _vm._v(": String"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autofocus")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autoGrow")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("error")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("label")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("iconRight")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("iconTop")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("large")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("name")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("outlined")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("placeholder")]), _vm._v(": String")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("readonly")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("small")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("value")]),
            _vm._v(": String|Number"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("maxLength")]), _vm._v(": Number")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Placeholder")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { outlined: "", placeholder: "Textarea with placeholder" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label")]),
        _vm._v(" "),
        _c("base-textarea", { attrs: { outlined: "", label: "Label" } }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Label + Placeholder")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            outlined: "",
            label: "Focus me",
            placeholder: "Placeholder",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Rows")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            outlined: "",
            rows: 10,
            label: "10 rows",
            placeholder: "10 rows",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("AutoGrow")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            outlined: "",
            "auto-grow": "",
            placeholder: "Textarea with AutoGrow",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("RowHeight")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            outlined: "",
            "row-height": 25,
            label: "Row height 25",
            placeholder: "Row height 25",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Outlined")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { outlined: "", placeholder: "Outlined textarea" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Readonly")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { readonly: "", value: "I am a readonly textarea" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Readonly + outlined")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            readonly: "",
            outlined: "",
            value: "I am an outlined readonly textarea",
          },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { disabled: "", placeholder: "Disabled textarea" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Large")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { large: "", placeholder: "Large", outlined: "" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Small")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: { small: "", placeholder: "Small input", outlined: "" },
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("With icon")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            label: "Textarea with icon",
            placeholder: "Textarea with icon",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("With right icon")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            label: "Textarea with icon",
            "icon-right": "",
            placeholder: "Textarea with icon",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("With top icon")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            label: "Textarea with icon",
            "icon-top": "",
            placeholder: "Textarea with icon",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("With right + top icon")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            label: "Textarea with icon",
            "icon-right": "",
            "icon-top": "",
            placeholder: "Textarea with icon",
            outlined: "",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Error")]),
        _vm._v(" "),
        _c("base-textarea", {
          attrs: {
            error: "This is an error",
            outlined: "",
            label: "Focus me",
            placeholder: "Placeholder",
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "envelope"] },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Conversation Input (Chat)")]),
        _vm._v(" "),
        _c("base-textarea", {
          ref: "basetextarea",
          attrs: {
            outlined: "",
            "max-length": 100,
            rows: 1,
            "max-rows": 3,
            "auto-grow": "",
            "icon-right": true,
            placeholder: _vm.trans.get(
              "generic.conversation_input_placeholder"
            ),
          },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("span", { staticClass: "conversation-input-gif-icon" }, [
                    _vm._v("GIF"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }