var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-follow-up-to-one-header" },
    [
      _c(
        "div",
        { staticClass: "flex-grow" },
        [
          _c("base-text-input", {
            attrs: {
              type: "text",
              placeholder: _vm.trans.get(
                "generic.modals.follow_up_to_one_search_placeholder"
              ),
              small: "",
              outlined: "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "magnifying-glass"] },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-button",
        {
          attrs: { small: "", color: "light" },
          on: {
            click: function ($event) {
              return _vm.$emit("back")
            },
          },
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.trans.get("generic.cancel")) + "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }