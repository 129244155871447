var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-radio-input",
    {
      staticClass: "mr-2 text-center",
      attrs: {
        name: _vm.name,
        value: _vm.value,
        disabled: _vm.disabled,
        "input-hidden": "",
      },
      scopedSlots: _vm._u([
        {
          key: "checked",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "base-radio-ppu base-radio-ppu--checked",
                  style: _vm.cssVars,
                },
                [
                  _c("div", { staticClass: "base-radio-ppu-price-container" }, [
                    _c("p", { staticClass: "base-radio-ppu-title" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "base-radio-ppu-price" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatMoneyPerMinute")(
                            _vm.cost_by_minute / 100
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "base-radio-ppu-icon base-radio-ppu-icon--checked",
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-check"], size: "lg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.pack,
        callback: function ($$v) {
          _vm.pack = $$v
        },
        expression: "pack",
      },
    },
    [
      [
        _c("div", { staticClass: "base-radio-ppu" }, [
          _c("div", { staticClass: "base-radio-ppu-price-container" }, [
            _c("p", { staticClass: "base-radio-ppu-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "base-radio-ppu-price" }, [
              _vm._v(
                _vm._s(_vm._f("formatMoneyPerMinute")(_vm.cost_by_minute / 100))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "base-radio-ppu-icon text-neutral-400" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle"], size: "lg" },
              }),
            ],
            1
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }