var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.meFromUserStore().is_master &&
    this.interlocutor().type === "client" &&
    !this.hasLiveSessionBillingStarted &&
    this.isLiveConversationInProgress
    ? _c(
        "base-alert",
        {
          staticClass: "mt-5 mx-2 md:mx-0",
          attrs: { elevated: "", "text-center": "", delay: 10000 },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "text-primary",
            attrs: { icon: ["fas", "circle-exclamation"] },
          }),
          _vm._v(" "),
          this.hasPrimeFreeChatAdvantage()
            ? [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.trans.get("chat.user_use_free_chat_prime")) +
                    "\n    "
                ),
              ]
            : [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.trans.get(
                        "chat.the_user_will_be_billed_from_the_first_message",
                        { name: this.interlocutor().name }
                      )
                    ) +
                    "\n    "
                ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }