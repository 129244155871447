window.globals = {
    GAME_OLD_CLIENT_ONLY: 'game-old-client-only',
    PASSWORD_RESET: 'password-reset',
    PASSWORD_RESET_INVALID_TOKEN: 'password-reset-invalid-token',
    PASSWORD_LOST: 'password-lost',
    CHAT_MASTER_CONFIRMATION_TO_START_LIVE_CHAT: 'chat-master-confirmation-to-start-live-chat',
    CHAT_CUSTOMER_INFO_MASTER_REFUSED_LIVE_CHAT: 'chat-customer-info-master-refused-live-chat',
    CHAT_CUSTOMER_CONFIRMATION_END_CHAT_SESSION: 'chat-customer-confirmation-end-chat-session',
    CHAT_MASTER_END_CHAT: 'chat-master-end-chat',
    CHAT_CUSTOMER_END_CHAT: 'chat-customer-end-chat',
    CHAT_CUSTOMER_NOTIF_MASTER_ONLINE: 'chat-customer-notif-master-online',
    CHAT_ADD_COMMENT: 'chat-add-comment',
    CHAT_CONFIRMATION_FOR_PRIME_FREE_LIVE_CHAT: 'chat-confirmation-for-prime-free-live-chat',
    CHAT_REPORT_PROBLEM: 'chat-report-problem',
    FOLLOW_UP_TYPE_CHOICE: 'follow-up-type-choice',
    FOLLOW_UP_TO_ALL: 'follow-up-to-all',
    FOLLOW_UP_TO_ONE: 'follow-up-to-one',
    MESSAGE: 'message',
    IMAGE: 'image',
    CHAT: 'chat',
    USER_PHONE_VERIFICATION: 'user-phone-verification',
    USER_PHONE_VERIFICATION_CODE: 'user-phone-verification-code',
    USER_PHONE_VERIFICATION_ALREADY_USED: 'user-phone-verification-already-used',
    CHAT_MASTER_CONNECTED: 'chat-master-connected',
    MASTER_OFFLINE_AT_THE_MOMENT: 'master-offline-at-the-moment',
    MASTER_IS_NO_LONGER_AVAILABLE: 'master-is-no-longer-available',
    GENERIC_ERROR: 'generic-error',
    CHAT_RECHARGE_LIVE: 'chat-recharge-live',
    PROMO_CODE_GIFT: 'promo-code-gift',
    EMAIL_CATCHER: 'email-catcher',
    GAME_FIRST_DAY: 'game-first-day',
    RECALL_CUSTOMER: 'recall-customer',
    AUTHORIZE_MICROPHONE: 'authorize-microphone',
    CONVERSATION_REPORT: 'conversation-report',
    PAYMENT_PROCESSING: 'payment-processing',
    PAYMENT_OPTIONS: 'payment-options',
    PACK_OPTIONS: 'pack-options',
    BIRTHDAY_COUPON: 'birthday-coupon',
    WHATSAPP_CATCHER: 'whatsapp-catcher',
    MASTER_USER_NOTES: 'master-user-notes',
    FAILED_PAY_PER_USE_PAYMENT: 'failed-pay-per-use-payment',
};
Vue.prototype.globals = window.globals;
