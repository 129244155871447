var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Timer")]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: { "auto-start": "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ minutes, seconds }) {
                return [_vm._v(_vm._s(minutes) + " : " + _vm._s(seconds))]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("tag")]),
            _vm._v(": String - "),
            _c("em", [_vm._v("'span', 'p', 'div', ...")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("autoStart")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("time")]),
            _vm._v(": Number - "),
            _c("em", [_vm._v("in milliseconds")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("countdown")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Time")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("time")]),
          _vm._v(" in milliseconds"),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: { "auto-start": "", time: 60000 },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ minutes, seconds }) {
                return [
                  _vm._v(
                    "\n            Start at 1min/60000ms - " +
                      _vm._s(minutes) +
                      " : " +
                      _vm._s(seconds) +
                      "\n        "
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Countdown")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("countdown")]),
          _vm._v(" to reverse time !"),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: { "auto-start": "", countdown: "", time: 10000 },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ seconds }) {
                return [_vm._v(_vm._s(seconds))]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Exemple :")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Pluralization")]),
          _vm._v(" test"),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: { "auto-start": "", time: 57000 },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ minutes, seconds }) {
                return [
                  minutes > 0
                    ? [
                        _vm._v(
                          _vm._s(
                            minutes + (minutes > 1 ? " minutes" : " minute")
                          )
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  seconds > 0
                    ? [
                        _vm._v(
                          _vm._s(
                            seconds + (seconds > 1 ? " secondes" : " seconde")
                          )
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Exemple :")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Countdown")]),
          _vm._v(" with days, hours, minutes and seconds"),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: {
            time: 3 * 24 * 60 * 60 * 1000,
            "auto-start": "",
            countdown: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ days, hours, minutes, seconds }) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(days) +
                      " jours " +
                      _vm._s(hours) +
                      " heures " +
                      _vm._s(minutes) +
                      " min " +
                      _vm._s(seconds) +
                      " sec rest.\n        "
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Exemple :")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Play")]),
          _vm._v(" with time"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.startTimer } }, [_vm._v("Play")]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.pauseTimer } }, [_vm._v("Pause")]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.stopTimer } }, [_vm._v("Stop")]),
        _vm._v(" "),
        _c("base-timer", {
          ref: "timerplay",
          attrs: { "auto-start": false },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ minutes, seconds }) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(minutes) +
                      ":" +
                      _vm._s(seconds) +
                      "\n        "
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Exemple :")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Buttons and events")]),
          _vm._v(" at start, pause, stop or end"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.startCountdown } }, [
          _vm._v("Play"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.pauseCountdown } }, [
          _vm._v("Pause"),
        ]),
        _vm._v(" "),
        _c("base-button", { on: { click: _vm.stopCountdown } }, [
          _vm._v("Stop"),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          ref: "timercountdown",
          attrs: { time: 3000, countdown: "" },
          on: {
            start: function ($event) {
              return _vm.addEvent("Start", "success")
            },
            pause: function ($event) {
              return _vm.addEvent("Pause", "warning")
            },
            stop: function ($event) {
              return _vm.addEvent("Stop", "danger")
            },
            end: function ($event) {
              return _vm.addEvent("End", "danger")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ seconds }) {
                return [
                  _vm._v("\n            " + _vm._s(seconds) + "\n        "),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "notifications" },
          [
            _vm._l(_vm.timerEvents, function (timerEvent, index) {
              return [
                _c(
                  "base-alert",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      type: timerEvent.type,
                      dismissible: "",
                      elevated: "",
                      delay: 5000,
                    },
                  },
                  [_vm._v(_vm._s(timerEvent.message))]
                ),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Exemple :")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Threshold event")]),
          _vm._v(
            " launches an event when timer reaches a given time (3 seconds in this example)"
          ),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: {
            "auto-start": "",
            "threshold-events": _vm.thresholdEventGivenTime,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ seconds }) {
                return [
                  _vm._v("\n            " + _vm._s(seconds) + "\n        "),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Threshold event")]),
          _vm._v(
            " launches an event when countdown timer reaches a given time left (4 seconds left in this example)"
          ),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: {
            "auto-start": "",
            countdown: "",
            time: 10000,
            "threshold-events": _vm.thresholdEventCountdown,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ seconds }) {
                return [
                  _vm._v("\n            " + _vm._s(seconds) + "\n        "),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Multiple threshold events")]),
          _vm._v(
            " launches multiple events when timer reaches each event given time (respectively 5, 10 and 20 seconds in this example)"
          ),
        ]),
        _vm._v(" "),
        _c("base-timer", {
          attrs: {
            "auto-start": "",
            "threshold-events": _vm.thresholdMultipleEvents,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ seconds }) {
                return [
                  _vm._v("\n            " + _vm._s(seconds) + "\n        "),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }