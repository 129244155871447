var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Audio player")]),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("src")]), _vm._v(": String")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Full example")]),
        _vm._v(" "),
        _c("base-audio-player", {
          ref: "audio-player",
          attrs: {
            src: _vm.assetManager.getAudioAsset("example/audio-example.mp3"),
          },
          on: { update: _vm.update, end: _vm.end },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mb-6" }, [
          _c("p", [_vm._v("Informations :")]),
          _vm._v(" "),
          _c("p", [_vm._v("Status : " + _vm._s(_vm.status))]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Loading : " + _vm._s(_vm.loadingProgression) + " %"),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Current Time : " + _vm._s(_vm.currentTime))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Playing Progression : " + _vm._s(_vm.playingProgression) + " %"
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Duration : " + _vm._s(_vm.duration))]),
          _vm._v(" "),
          _c("p", [_vm._v("Volume : " + _vm._s(_vm.volume))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4 gap-4" }, [
          _c("span", { staticClass: "flex-none" }, [
            _vm._v(_vm._s(_vm._f("formatSecondsToTimer")(_vm.currentTime))),
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "flex-grow",
            attrs: { type: "range", min: "0", step: "0.001", max: 100 },
            domProps: { value: _vm.playingProgression },
            on: { input: _vm.setCurrentTime },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "flex-none" }, [
            _vm._v(_vm._s(_vm._f("formatSecondsToTimer")(_vm.duration))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex mb-4 gap-4" },
          [
            _c("base-button", { on: { click: _vm.play } }, [_vm._v("Play")]),
            _vm._v(" "),
            _c("base-button", { on: { click: _vm.pause } }, [_vm._v("Pause")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex gap-4" }, [
          _c("span", [_vm._v("Volume")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "range", min: "0", max: "1", step: "0.01" },
            on: { input: _vm.setVolume, change: _vm.setVolume },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }