<template>
    <base-modal :error="loadingError">
        <div v-if="postFormSucceed">
            <modal-paragraph>{{ trans.get('generic.email_sent') }}</modal-paragraph>

            <base-button block @click="cancel">OK</base-button>
        </div>

        <div v-else>
            <modal-paragraph>{{ trans.get('generic.password_link_expired') }}</modal-paragraph>

            <modal-paragraph>{{ trans.get('generic.send_new_password_link') }}</modal-paragraph>

            <div class="grid grid-cols-2 gap-4">
                <base-button @click="cancel" outlined>
                    {{ trans.get('generic.cancel') }}
                </base-button>
                <base-button autofocus @click="postForm" :loading="formLoading">
                    Ok
                </base-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
import axios from 'axios';

import {mapState} from 'vuex';
import {mapMutations} from 'vuex';
import {mapGetters} from 'vuex';
import BaseButton from "../elements/BaseButton";
import ModalParagraph from "./elements/ModalParagraph";

export default {
    components: {ModalParagraph, BaseButton},
    computed: {
        ...mapState('modals', ['additionalParams']),
        ...mapGetters('modals', ['getParam']),
    },
    methods: {
        ...mapMutations('modals', [
            'changeLoadingState', 'addParam',
        ]),
        cancel() {
            this.$emit('cancel');
        },
        postForm() {
            if (this.email !== undefined && this.email.length > 0) {
                this.formLoading = true;
                axios.post('/password/lost', {
                    email: this.email
                }).then((res) => {
                    this.postFormSucceed = true;
                }).catch((errorResponse) => {
                    window.globalVue.$store.commit('modals/show', globals.PASSWORD_LOST);
                }).finally(() => {
                    this.formLoading = false;
                });
            } else {
                window.globalVue.$store.commit('modals/show', globals.PASSWORD_LOST);
            }
        },
    },
    data() {
        return {
            url: '/ajax/get-online-offers-call-button-text.js',
            response: null,
            loadingError: '',
            email: '',
            csrf: '',
            postFormSucceed: false,
            errorEmail: '',
            formLoading: false,
        }
    },
    mounted() {
        this.csrf = window.csrf_token;
        this.email = this.getParam['password-reset-invalid-token']['email'];
    }
}
</script>
