var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", { attrs: { small: "" } }, [
    _c(
      "form",
      { on: { submit: _vm.submitPhoneVerification } },
      [
        _c("modal-heading", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get(
                  "generic.modals.user_phone_verification.confirm_your_number_to_start_the_chat"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("modal-paragraph", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.trans.get(
                  "generic.modals.user_phone_verification.take_a_moment_to_confirm_your_phone_number"
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("base-phone-input", {
          ref: "input_phone_number",
          attrs: {
            outlined: "",
            autofocus: "",
            "mobile-only": "",
            "only-countries": _vm.config.get(
              "sms.phone_number_available_countries"
            ),
            "initial-country": _vm.config.get(
              "sms.default_phone_number_country_code"
            ),
            label: this.trans.get("generic.phone"),
            placeholder: this.trans.get("generic.phone"),
            error: _vm.input_phone_number_error,
          },
          model: {
            value: _vm.userFullMobilePhoneNumber,
            callback: function ($$v) {
              _vm.userFullMobilePhoneNumber = $$v
            },
            expression: "userFullMobilePhoneNumber",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6" },
          [
            _c("base-button", { attrs: { type: "submit" } }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.trans.get(
                      "generic.modals.user_phone_verification.send_sms"
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }