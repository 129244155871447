Vue.component('base-console', require('../elements/BaseConsole').default);
Vue.component('development-alert', require('./DevelopmentAlert').default);
Vue.component('development-audio-player', require('./DevelopmentAudioPlayer').default);
Vue.component('development-avatar', require('./DevelopmentAvatar').default);
Vue.component('development-badge', require('./DevelopmentBadge').default);
Vue.component('development-bar', require('./DevelopmentBar').default);
Vue.component('development-button', require('./DevelopmentButton').default);
Vue.component('development-checkbox', require('./DevelopmentCheckbox').default);
Vue.component('development-code-input', require('./DevelopmentCodeInput').default);
Vue.component('development-console', require('./DevelopmentConsole').default);
Vue.component('development-icon', require('./DevelopmentIcon').default);
Vue.component('development-link', require('./DevelopmentLink').default);
Vue.component('development-list', require('./DevelopmentList').default);
Vue.component('development-lottie-file-player', require('./DevelopmentLottieFilePlayer').default);
Vue.component('development-menu', require('./DevelopmentMenu').default);
Vue.component('development-modal', require('./DevelopmentModal').default);
Vue.component('development-offer-card', require('./DevelopmentOfferCard').default);
Vue.component('development-offer-card-small', require('./DevelopmentOfferCardSmall').default);
Vue.component('development-offer-list-banner', require('./DevelopmentOfferListBanner.vue').default);
Vue.component('development-phone-input', require('./DevelopmentPhoneInput').default);
Vue.component('development-radio-input', require('./DevelopmentRadioInput').default);
Vue.component('development-rating', require('./DevelopmentRating').default);
Vue.component('development-select', require('./DevelopmentSelect').default);
Vue.component('development-separator', require('./DevelopmentSeparator').default);
Vue.component('development-slider', require('./DevelopmentSlider.vue').default);
Vue.component('development-text-input', require('./DevelopmentTextInput').default);
Vue.component('development-text-typography', require('./DevelopmentTextTypography').default);
Vue.component('development-textarea', require('./DevelopmentTextarea').default);
Vue.component('development-timer', require('./DevelopmentTimer').default);
Vue.component('development-title', require('./DevelopmentTitle').default);
Vue.component('development-tooltip', require('./DevelopmentTooltip').default);
Vue.component('development-webm-player', require('./DevelopmentWebmPlayer').default);
Vue.component('development-wheel', require('./DevelopmentWheel').default);
