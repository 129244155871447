<template>
    <div :class="containerClass">
        <div class="relative">
            <div :disabled="disabled" tabindex="0" role="button" :class="selectClass" :id="id"
                 ref="selectButton"
                 @click="toggleFocused()"
                 @blur="toggleFocused(false);$emit('blur', $event)"
                 @keydown="keydownHandler"
            >

                <span v-if="hasSelectedItem" class="base-select-selected-item">
                    <slot name="selected">{{ itemName(selectedItem) }}</slot>
                </span>
                <span v-else-if="hasPlaceholderSlot" class="base-select-placeholder">
                    <slot name="placeholder"/>
                </span>
                <span v-else-if="placeholder" class="base-select-placeholder">{{ placeholder }}</span>
                <span v-else-if="label" class="invisible">{{ label }}</span>
                <span v-else>&nbsp;</span>

            </div>

            <label :class="labelClass" v-if="label" :for="id">{{ label }}</label>

            <div :class="caretDownClass">
                <font-awesome-icon :icon="['fas', 'caret-down']"/>
            </div>

            <div ref="itemList"
                 :class="itemListClass"
                 :style="itemListStyle"
                 @mouseover="listHover = true"
                 @mouseleave="listHover = false"
                 tabindex="-1">
                <div v-for="(item, index) in items"
                     :class="{'base-select-item': true, 'base-select-item--selected': item === selectedItem}"
                     @click="selectItem({item:item, index:index, closeOnSelect:true})"
                     tabindex="-2">
                    <slot name="item" v-bind:item="item">{{ itemName(item) }}</slot>
                </div>
            </div>
        </div>

        <div v-if="hasError" :class="messageClass" v-html="error"></div>
    </div>
</template>

<style lang="scss">
.base-select-container {
    @apply select-none inline-block;

    &--block {
        @apply block;
    }
}

.base-select {
    @apply leading-normal w-full pl-4 pr-10 text-dark rounded-md appearance-none placeholder-neutral-500 focus:outline-none cursor-pointer border border-transparent;
    -webkit-tap-highlight-color: transparent;

    &:not(&.base-select--outlined):not(&.base-select--transparent) {
        @apply bg-white;

        &:focus:not(&.base-select--disabled) {
            @apply bg-white border-neutral-500;
        }
    }

    &--outlined {
        @apply bg-white border border-neutral-300;

        &:focus:not(&.base-select--disabled) {
            @apply border-primary;
        }
    }

    &--label {
        &:focus .base-select-placeholder {
            @apply visible;
        }

        .base-select-placeholder {
            @apply invisible;
        }
    }

    &--small {
        @apply pb-1 pt-3;
        &:not(&.base-select--label) {
            @apply py-2;
        }

        & ~ .base-select-caret-down {
            @apply h-8;
        }
    }

    &--medium {
        @apply pb-1 pt-5;
        &:not(&.base-select--label) {
            @apply py-3;
        }

        & ~ .base-select-caret-down {
            @apply h-12;
        }
    }

    &--large {
        @apply pb-2 pt-6;
        &:not(&.base-select--label) {
            @apply py-4;
        }

        & ~ .base-select-caret-down {
            @apply h-16;
        }
    }

    &--disabled {
        @apply cursor-not-allowed bg-disabled border-disabled;
    }

    &--danger {
        @apply border-danger;

        &:focus {
            @apply border-danger;
        }
    }

    .base-select-placeholder {
        @apply text-neutral-500;
    }

    &:focus ~ .base-select-label,
    &--not-placeholder-shown ~ .base-select-label {
        @apply text-primary;
        transform: translate(0, -0.3rem) scale(0.7);
    }

    &:focus ~ .base-select-label.base-select-label--danger,
    &--not-placeholder-shown ~ .base-select-label.base-select-label--danger {
        @apply text-danger;
    }
}

.base-select-caret-down {
    @apply inline-flex items-center justify-center absolute right-0 top-0 w-10 pointer-events-none;

    &--disabled {
        @apply text-neutral-300;
    }
}

.base-select-label {
    @apply leading-normal absolute top-0 h-full block left-4 border border-transparent pointer-events-none origin-top-left text-neutral-500;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

    &--disabled {
        @apply text-dark;
    }

    &--small {
        @apply py-2;
    }

    &--medium {
        @apply py-3;
    }

    &--large {
        @apply py-4;
    }
}

.base-select-item-list {
    @apply absolute invisible w-max max-h-60 overflow-y-auto z-10 bg-white border border-neutral-300 rounded-md shadow-large;

    &--visible {
        @apply visible;
    }
}

.base-select-item {
    @apply p-3 cursor-pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover, &:focus, &--selected {
        @apply bg-neutral-100;
    }
}

.base-select-message {
    @apply mt-1 text-sm;

    &--error {
        @apply text-danger;
    }
}
</style>

<script>
export default {
    name: "BaseSelect",
    props: {
        id: {
            type: String,
            default() {
                return `select-${this._uid}`;
            }
        },
        block: Boolean,
        disabled: Boolean,
        error: String,
        items: Array,
        label: String,
        large: Boolean,
        outlined: Boolean,
        transparent: Boolean,
        placeholder: String,
        small: Boolean,
        value: String | Array | Object,
    },
    data() {
        return {
            focused: false,
            listHover: false,
            selectedItem: null,
            maxWidth: 'auto',
            right: null,
            left: null,
            top: null,
            key: {
                ENTER: 13,
                ESCAPE: 27,
                SPACE: 32,
                HOME: 36,
                END: 35,
                ARROW_UP: 38,
                ARROW_DOWN: 40,
            }
        }
    },
    computed: {
        containerClass() {
            return {
                'base-select-container': true,
                'base-select-container--block': this.block,
            }
        },
        selectClass() {
            return {
                'base-select': true,
                'base-select--transparent': this.transparent,
                'base-select--outlined': this.outlined,
                'base-select--danger': this.hasError,
                'base-select--label': this.label,
                'base-select--disabled': this.disabled,
                'base-select--small': this.small,
                'base-select--medium': !this.small && !this.large,
                'base-select--large': this.large,
                'base-select--not-placeholder-shown': !this.placeholder || this.hasSelectedItem,
            };
        },
        caretDownClass() {
            return {
                'base-select-caret-down': true,
                'base-select-caret-down--disabled': this.disabled,
            }
        },
        labelClass() {
            return {
                'base-select-label': true,
                'base-select-label--danger': this.hasError,
                'base-select-label--small': this.small,
                'base-select-label--medium': !this.small && !this.large,
                'base-select-label--large': this.large,
                'base-select-label--disabled': this.disabled,
            };
        },
        itemListClass() {
            return {
                'base-select-item-list': true,
                'base-select-item-list--visible': this.isOpen(),
            }
        },
        itemListStyle() {
            return {
                maxWidth: this.maxWidth + 'px',
                right: this.right !== null ? this.right + 'px' : null,
                left: this.left !== null ? this.left + 'px' : null,
                top: this.top !== null ? this.top + 'px' : null,
            }
        },
        messageClass() {
            return {
                'base-select-message': true,
                'base-select-message--error': this.hasError
            };
        },
        hasSelectedItem() {
            return this.selectedItem !== undefined && this.selectedItem !== null;
        },
        hasPlaceholderSlot() {
            return !!this.$slots.placeholder;
        },
        hasError() {
            return this.error !== undefined && this.error.length > 0;
        },
    },
    methods: {
        keydownHandler(event) {
            if (event.keyCode === this.key.SPACE) {
                event.preventDefault();
                this.toggleFocused(true);
            }

            if (event.keyCode === this.key.ENTER) {
                event.preventDefault();
                this.toggleFocused();
            }

            if (event.keyCode === this.key.ESCAPE) {
                event.preventDefault();
                this.toggleFocused(false);
            }

            if (event.keyCode === this.key.HOME) {
                event.preventDefault();
                this.selectFirstItem();
            }

            if (event.keyCode === this.key.END) {
                event.preventDefault();
                this.selectLastItem();
            }

            if (event.keyCode === this.key.ARROW_UP) {
                event.preventDefault();
                this.selectPreviousItem();
            }

            if (event.keyCode === this.key.ARROW_DOWN) {
                event.preventDefault();
                this.selectNextItem();
            }
        },
        toggleFocused(focused) {
            if (!this.disabled) {
                if (focused !== undefined) {
                    this.focused = focused;
                } else {
                    this.focused = !this.focused;
                }
            }
            if (this.isOpen()) {
                this.updateMaxSize();
                this.$emit('open');
            }
        },
        selectNextItem() {
            let itemIndex = 0;
            for (const [index, item] of this.items.entries()) {
                if (item === this.selectedItem) {
                    let nextIndex = index + 1;
                    itemIndex = nextIndex >= this.items.length - 1 ? this.items.length - 1 : nextIndex;
                    break;
                }
            }
            this.selectItemByIndex(itemIndex);
        },
        selectPreviousItem() {
            let itemIndex = 0;
            for (const [index, item] of this.items.entries()) {
                if (item === this.selectedItem) {
                    let previousIndex = index - 1;
                    itemIndex = previousIndex <= 0 ? 0 : previousIndex;
                    break;
                }
            }
            this.selectItemByIndex(itemIndex);
        },
        selectFirstItem() {
            this.selectItemByIndex(0);
        },
        selectLastItem() {
            this.selectItemByIndex(this.items.length - 1);
        },
        selectItemByIndex(index) {
            this.selectItem({item: this.items[index], index: index});
        },
        selectItem(data) {
            if (data.hasOwnProperty('item') && data.item !== null) {
                this.selectedItem = data.item;
                this.$emit('input', this.itemValue(data.item));
                if (data.hasOwnProperty('closeOnSelect') && data.closeOnSelect) {
                    this.focused = false;
                    this.listHover = false;
                }
            }
        },
        itemName(item) {
            return item.hasOwnProperty('name') ? item.name : item;
        },
        itemValue(item) {
            return item.hasOwnProperty('value') ? item.value : item;
        },
        updateMaxSize() {
            this.maxWidth = document.documentElement.clientWidth;
        },
        isOpen() {
            return this.focused || this.listHover;
        },
    },
    updated() {
        if (this.isOpen()) {
            const screenWidth = document.documentElement.clientWidth;
            const screenHeight = document.documentElement.clientHeight;
            const listPosition = this.$refs.itemList.getBoundingClientRect();
            const buttonPosition = this.$refs.selectButton.getBoundingClientRect();

            if (listPosition.height + buttonPosition.bottom > screenHeight) {
                this.top = listPosition.height * -1;
            } else {
                this.top = null;
            }

            if (listPosition.width >= screenWidth) {
                this.left = buttonPosition.left * -1;
            } else {
                this.left = 0;
            }
        }
    },
    mounted() {
        this.selectedItem = this.value;
        this.updateMaxSize();
    }
}
</script>
