<template>
    <div :class="classes" v-if="isShow">
        <div class="flex space-x-4">
            <div v-if="hasIcon" class="flex-none">
                <slot name="icon"></slot>
            </div>
            <div v-else-if="this.type === 'info'" class="flex-none">
                <font-awesome-icon :icon="['fas', 'circle-info']"/>
            </div>
            <div v-else-if="this.type === 'success'" class="flex-none">
                <font-awesome-icon :icon="['fas', 'circle-check']"/>
            </div>
            <div v-else-if="this.type === 'warning'" class="flex-none">
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>
            </div>
            <div v-else-if="this.type === 'danger'" class="flex-none">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']"/>
            </div>

            <div class="flex-grow base-alert-text">
                <slot/>
            </div>

            <div v-if="dismissible" class="flex-none self-center">
                <font-awesome-icon :icon="['far', 'circle-xmark']" @click="close"/>
            </div>
        </div>

        <div v-if="hasAction" class="flex flex-row-reverse">
            <slot name="action"></slot>
        </div>

        <base-timer auto-start v-if="hasTime" countdown :time="delay" @end="close"></base-timer>
    </div>
</template>

<style lang="scss">
.base-alert {
    @apply inline-block bg-white text-dark rounded space-y-4 leading-normal;

    &--color {
        &-info {
            @apply bg-primary text-white;
        }

        &-success {
            @apply bg-success text-white;
        }

        &-warning {
            @apply bg-warning text-white;
        }

        &-danger {
            @apply bg-danger text-white;
        }

        &-pack-1 {
            @apply text-white;
            background: linear-gradient(to right, var(--color-pack1-from), var(--color-pack1-to));
        }
    }

    &--block {
        @apply block;
    }

    &--x-small {
        @apply px-2 py-1.5 text-xs;
    }

    &--small {
        @apply px-3 py-2 text-sm;
    }

    &--medium {
        @apply p-3;
    }

    &--large {
        @apply p-4;
    }

    &--text-center {
        .base-alert-text {
            @apply text-center;
        }
    }

    &--elevated {
        @apply shadow-large;
    }

    &--pill {
        @apply rounded-full;
    }
}

</style>

<script>
import BaseButton from "./BaseButton";
import BaseTimer from "./BaseTimer";

export default {
    name: "BaseAlert",
    components: {BaseTimer, BaseButton},
    props: {
        type: {
            type: String,
            validator: function (value) {
                return ['info', 'success', 'warning', 'danger', 'pack-1'].indexOf(value) !== -1
            }
        },
        color: {
            type: String,
            validator: function (value) {
                return ['info', 'success', 'warning', 'danger', 'pack-1'].indexOf(value) !== -1
            }
        },
        block: Boolean,
        delay: Number,
        dismissible: Boolean,
        elevated: Boolean,
        large: Boolean,
        pill: Boolean,
        small: Boolean,
        xSmall: Boolean,
        textCenter: Boolean,
    },
    data: () => {
        return {
            isShow: true,
        };
    },
    computed: {
        hasIcon() {
            return !!this.$slots.icon;
        },
        hasAction() {
            return !!this.$slots.action;
        },
        hasTime() {
            return this.delay !== undefined && Number.isInteger(this.delay) && this.delay > 0;
        },
        classes() {
            return {
                'base-alert': true,
                'base-alert--color-info': this.type === 'info' || this.color === 'info',
                'base-alert--color-success': this.type === 'success' || this.color === 'success',
                'base-alert--color-warning': this.type === 'warning' || this.color === 'warning',
                'base-alert--color-danger': this.type === 'danger' || this.color === 'danger',
                'base-alert--color-pack-1': this.type === 'pack-1' || this.color === 'pack-1',
                'base-alert--block': this.block,
                'base-alert--elevated': this.elevated,
                'base-alert--text-center': this.textCenter,
                'base-alert--x-small': this.xSmall,
                'base-alert--small': this.small,
                'base-alert--medium': !this.xSmall && !this.small && !this.large,
                'base-alert--large': this.large,
                'base-alert--pill': this.pill,
            }
        },
    },
    methods: {
        close() {
            this.isShow = false;
        },
        show() {
            this.isShow = true;
        }
    },
}
</script>
