<template>
    <div class="hidden lg:block text-header-top text-base" style="line-height: 55px;">
        <a :href="ziggy_route('account.favorites', {page: 0}, true)">
            <font-awesome-icon size="lg" :icon="['fas', 'circle-heart']"></font-awesome-icon>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Favorites',
}
</script>
