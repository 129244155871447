<template>
    <base-modal>
        <modal-heading>
            {{ trans.get('generic.master_is_offline_at_the_moment', {master_name: master.name}) }}
        </modal-heading>

        <modal-paragraph>{{ trans.get('generic.comeback_notification_by_sms') }}</modal-paragraph>

        <div class="text-center my-8">
            <font-awesome-icon :icon="['fal', 'face-sad-tear']" class="text-primary" size="4x"/>
        </div>

        <div class="grid grid-cols-1 mt-5">
            <base-button @click="displayModalNotifMasterOnline" class="mb-4">
                <font-awesome-icon :icon="['fas', 'bell']"/>
                {{ trans.get('generic.be_notified_of_his_return') }}
            </base-button>

            <base-button outlined @click="closeModalAndReload">
                {{ trans.get('generic.back_to_site') }}
            </base-button>
        </div>
    </base-modal>
</template>

<script>
import ModalHeading from "./elements/ModalHeading";
import ModalParagraph from "./elements/ModalParagraph";
import BaseButton from "../elements/BaseButton";
import {mapActions, mapGetters} from "vuex";
import ChatMixin from "../chat/ChatMixin";

export default {
    name: "ModalMasterOfflineAtTheMoment",
    components: {BaseButton, ModalParagraph, ModalHeading},
    computed: {
        ...mapGetters('modals', ['getParam'])
    },
    mixins: [ChatMixin],
    methods: {
        ...mapActions('modals', ['openModal', 'closeModalAndReload']),
        displayModalNotifMasterOnline() {
            this.openModal({
                name: globals.CHAT_CUSTOMER_NOTIF_MASTER_ONLINE,
                param: {
                    master: this.master,
                    userFullMobilePhoneNumber: this.meFromUserStore().mobile_phone
                }
            });
        }
    },
    data() {
        return {
            master: {name: ''},
        }
    },
    mounted() {
        this.master = this.getParam['master-offline-at-the-moment']['master'];
    }
}
</script>
