var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-modal", [
    !_vm.postFormSucceed
      ? _c(
          "form",
          { on: { submit: _vm.postForm } },
          [
            _c("modal-heading", { staticClass: "text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("email_catcher.wait")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", { staticClass: "text-center" }, [
              _c("strong", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.trans.get(
                        "email_catcher.a_consultation_offered_for_several_minutes"
                      )
                    ) +
                    "\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full text-center" }, [
              _c("img", {
                staticClass: "inline-block max-h-48",
                attrs: {
                  src: _vm.assetManager.getImageAsset(
                    "email-catcher/email-catcher.gif"
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-4" },
              [
                _c("base-text-input", {
                  attrs: {
                    type: "email",
                    outlined: "",
                    label: _vm.trans.get("generic.email"),
                    placeholder: _vm.trans.get("generic.email"),
                    error: _vm.email_error,
                  },
                  on: {
                    keydown: function ($event) {
                      _vm.email_error = ""
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fal", "envelope"] },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3295453108
                  ),
                  model: {
                    value: _vm.email_value,
                    callback: function ($$v) {
                      _vm.email_value = $$v
                    },
                    expression: "email_value",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.config.get("app.email_catcher_optional_newsletter")
              ? _c(
                  "base-checkbox",
                  {
                    ref: "checkbox_right_of_withdrawal",
                    attrs: { block: "", error: _vm.newsletter_error },
                    on: {
                      change: function ($event) {
                        _vm.newsletter_error = ""
                      },
                    },
                    model: {
                      value: _vm.newsletter_value,
                      callback: function ($$v) {
                        _vm.newsletter_value = $$v
                      },
                      expression: "newsletter_value",
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          this.trans.get(
                            "email_catcher.i_agree_to_receive_emails_from_the_site"
                          )
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: { type: "submit", loading: _vm.formLoading, block: "" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.trans.get("email_catcher.lets_go")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        )
      : _c(
          "form",
          {
            attrs: {
              action: "ajax/go-to-second-register-step.js",
              method: "POST",
              target: "ajax_upload",
            },
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "newsletter_already_accepted",
                value: "1",
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email_value,
                  expression: "email_value",
                },
              ],
              attrs: { type: "hidden", name: "email" },
              domProps: { value: _vm.email_value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email_value = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("modal-heading", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans.get("email_catcher.well_done")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("modal-paragraph", {
              domProps: {
                innerHTML: _vm._s(
                  this.trans.get(
                    "email_catcher.your_free_consultation_awaits_you"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c("modal-paragraph", {
              domProps: {
                innerHTML: _vm._s(
                  this.trans.get(
                    "email_catcher.find_the_answers_to_your_questions_and_consult_the_best_psychics"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: { type: "submit", block: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.trans.get("email_catcher.i_continue_my_registration")
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-main mt-4 text-xs" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans.get("email_catcher.check_your_spam_emails")
                  ) +
                  "\n        "
              ),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }