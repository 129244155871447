export default {
    install(Vue, options) {
        Vue.prototype.config = {
            get: function (config) {
                if (config === undefined || config === null || config === "") {
                    console.error('Invalid configuration key, it is undefined, null or empty.');
                    return;
                }

                const file = config.slice(0, config.indexOf('.'));
                const key = config.slice(config.indexOf('.') + 1);

                if (!(file in window.configs) || !(key in window.configs[file])) {
                    console.error('Invalid configuration key, "' + config + '" doesn\'t exist.');
                    return;
                }

                return window.configs[file][key];

            }
        };
    }
}
