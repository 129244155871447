import Vue from 'vue';

Vue.directive('clickOutside', {
    inserted: function (el,  binding, vnode) {
        let action = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', action)
    }
})
