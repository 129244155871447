var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.componentType,
    _vm._g(
      {
        ref: "button",
        tag: "component",
        class: _vm.classes,
        attrs: {
          disabled: _vm.disabled || _vm.loading || _vm.skeletonLoader,
          href: _vm.href,
          type: _vm.buttonType,
        },
      },
      _vm.$listeners
    ),
    [
      _vm.loading
        ? _c("font-awesome-icon", {
            staticClass: "border-none",
            attrs: { icon: ["fas", "spinner"], spin: "" },
          })
        : _vm.skeletonLoader
        ? [_vm._v(" ")]
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }