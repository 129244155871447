var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("development", [
    _c(
      "div",
      [
        _c("development-heading", [_vm._v("Menu")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Dropdown")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", { attrs: { level: 2 } }, [_vm._v("Props :")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_c("strong", [_vm._v("disabled")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("dropLeft")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("dropTop")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("loading")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("open")]), _vm._v(": Boolean")]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("openOnHover")]),
            _vm._v(": Boolean"),
          ]),
          _vm._v(" "),
          _c("li", [_c("strong", [_vm._v("scrollable")]), _vm._v(": Boolean")]),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Default slot")]),
        _vm._v(" "),
        _c("development-paragraph", [
          _c("strong", [_vm._v("Default slot")]),
          _vm._v(" can be any html tag"),
        ]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_vm._v("\n            Click on me !\n            ")]
        ),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Button")])],
          1
        ),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "base-button",
              { attrs: { icon: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "ellipsis-vertical"] },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Items")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c("base-menu-item", [_vm._v("Item 1")]),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      { attrs: { color: "info" } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-info"] },
                        }),
                        _vm._v(" Item info\n                "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      { attrs: { color: "success" } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-check"] },
                        }),
                        _vm._v(" Item success\n                "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      { attrs: { color: "warning" } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                        _vm._v(
                          " Item\n                    warning\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      { attrs: { color: "danger" } },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "circle-exclamation"] },
                        }),
                        _vm._v(
                          " Item\n                    danger\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("base-menu-item", { attrs: { selected: "" } }, [
                      _vm._v("Selected item"),
                    ]),
                    _vm._v(" "),
                    _c("base-menu-item", { attrs: { separator: "" } }),
                    _vm._v(" "),
                    _c("base-menu-item", [_vm._v("Separated item")]),
                    _vm._v(" "),
                    _c("base-menu-item", { attrs: { separator: "" } }),
                    _vm._v(" "),
                    _c("base-menu-item", { attrs: { dense: "" } }, [
                      _vm._v("Dense item"),
                    ]),
                    _vm._v(" "),
                    _c("base-menu-item", { attrs: { separator: "" } }),
                    _vm._v(" "),
                    _c("base-menu-item", [
                      _vm._v(
                        "\n                    Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Curabitur\n                    arcu erat, accumsan id imperdiet et, porttitor at sem.\n                "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "base-button",
              { attrs: { icon: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "ellipsis-vertical"] },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Scrollable")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return _vm._l(20, function (n) {
                    return _c("base-menu-item", { key: n }, [
                      _vm._v("Item " + _vm._s(n)),
                    ])
                  })
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Not scrollable")])],
          1
        ),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { scrollable: "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return _vm._l(20, function (n) {
                    return _c("base-menu-item", { key: n }, [
                      _vm._v("Item " + _vm._s(n)),
                    ])
                  })
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Scrollable")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Header / Footer")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { scrollable: "" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [_c("div", { staticClass: "p-6" }, [_vm._v("Header")])]
                },
                proxy: true,
              },
              {
                key: "items",
                fn: function () {
                  return _vm._l(20, function (n) {
                    return _c("base-menu-item", { key: n }, [
                      _vm._v("Item " + _vm._s(n)),
                    ])
                  })
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [_c("div", { staticClass: "p-6" }, [_vm._v("Footer")])]
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Scrollable")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Loading")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { scrollable: "", loading: "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return _vm._l(20, function (n) {
                    return _c("base-menu-item", { key: n }, [
                      _vm._v("Item " + _vm._s(n)),
                    ])
                  })
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Loading")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Disabled")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { disabled: "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("base-button", { attrs: { disabled: "" } }, [
              _vm._v("Dropdown"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { disabled: "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "base-button",
              { attrs: { disabled: "", icon: "" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "ellipsis-vertical"] },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Open on hover")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            attrs: { "open-on-hover": "" },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Dropdown")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Events")]),
        _vm._v(" "),
        _c(
          "base-menu",
          {
            on: {
              open: function ($event) {
                return _vm.addEvent("opened", "info")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function () {
                  return [
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 1", "info")
                          },
                        },
                      },
                      [_vm._v("Item 1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 2", "info")
                          },
                        },
                      },
                      [_vm._v("Item 2")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 3", "info")
                          },
                        },
                      },
                      [_vm._v("Item 3")]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-menu-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addEvent("Item 4", "info")
                          },
                        },
                      },
                      [_vm._v("Item 4")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_c("base-button", [_vm._v("Dropdown")])],
          1
        ),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Drop direction")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Curabitur aliquet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Curabitur aliquet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vestibulum ac diam sit amet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Vestibulum ac\n                            diam\n                            sit amet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Praesent sapien massa",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Praesent sapien massa\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Pellentesque in ipsum id orci porta dapibus\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.\n                            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("base-button", [
                    _vm._v(
                      "\n                        default\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  attrs: { "drop-left": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Curabitur aliquet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Curabitur aliquet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vestibulum ac diam sit amet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Vestibulum ac\n                            diam\n                            sit amet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Praesent sapien massa",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Praesent sapien massa\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Pellentesque in ipsum id orci porta dapibus\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.\n                            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("base-button", [
                    _vm._v(
                      "\n                        left\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mt-4" }, [
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  attrs: { "drop-top": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Curabitur aliquet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Curabitur aliquet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vestibulum ac diam sit amet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Vestibulum ac\n                            diam\n                            sit amet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Praesent sapien massa",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Praesent sapien massa\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Pellentesque in ipsum id orci porta dapibus\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.\n                            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("base-button", [
                    _vm._v(
                      "\n                        top\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  attrs: { "drop-top": "", "drop-left": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Curabitur aliquet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Curabitur aliquet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vestibulum ac diam sit amet quam",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Vestibulum ac\n                            diam\n                            sit amet quam\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Praesent sapien massa",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Praesent sapien massa\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Pellentesque in ipsum id orci porta dapibus\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Pellentesque in ipsum id orci porta dapibus",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.\n                            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("base-button", [
                    _vm._v(
                      "\n                        top / left\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Chat example")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  attrs: { icon: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vous avez offert 10 minutes",
                                    "success"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "gift"] },
                              }),
                              _vm._v(
                                " Offrir 10 minutes cadeau chat\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Conversation non lue",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "envelope"] },
                              }),
                              _vm._v(
                                " Marquer la conversation comme non lue\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              attrs: { color: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Êtes-vous sûr de vouloir signaler Pseudo ?",
                                    "warning"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                " Signaler Pseudo\n                        "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "base-button",
                    { attrs: { icon: "" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "ellipsis-vertical"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-none" },
            [
              _c(
                "base-menu",
                {
                  attrs: { icon: "", "drop-left": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Vous avez offert 10 minutes",
                                    "success"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "gift"] },
                              }),
                              _vm._v(
                                " Offrir 10 minutes cadeau chat\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Conversation non lue",
                                    "info"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "envelope"] },
                              }),
                              _vm._v(
                                " Marquer la conversation comme non lue\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "base-menu-item",
                            {
                              attrs: { color: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.addEvent(
                                    "Êtes-vous sûr de vouloir signaler Pseudo ?",
                                    "warning"
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                " Signaler Pseudo\n                        "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "base-button",
                    { attrs: { icon: "" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "ellipsis-vertical"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("development-heading", [_vm._v("Input photo example")]),
        _vm._v(" "),
        _c("div", { staticClass: "block bg-neutral-100 h-36" }),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "flex-grow relative self-center py-1 pl-2" },
            [
              _c("base-textarea", {
                attrs: {
                  outlined: "",
                  rows: 1,
                  "max-rows": 3,
                  "auto-grow": "",
                  placeholder: "Rédigez votre réponse",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-none self-end" },
            [
              _c(
                "base-menu",
                {
                  attrs: { icon: "", open: "", "drop-left": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function () {
                        return [
                          _c(
                            "base-alert",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-primary",
                                        attrs: { icon: ["fas", "camera"] },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("p", { staticClass: "text-primary w-48" }, [
                                _vm._v(
                                  "\n                                Nouveau ! Vous pouvez envoyer des photos (ex: photo de l'être aimé).\n                            "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "base-button",
                    { attrs: { icon: "", large: "", color: "neutral" } },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "camera"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notifications" },
      [
        _vm._l(_vm.timerEvents, function (timerEvent, index) {
          return [
            _c(
              "base-alert",
              {
                staticClass: "mb-2",
                attrs: {
                  type: timerEvent.type,
                  dismissible: "",
                  elevated: "",
                  delay: 5000,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(timerEvent.message) +
                    "\n            "
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }